import { textStyles } from "@/utils/configs/excelOptions";

type Props = {
	basicSupportUnits: OutpatientProductionBulletinVehicle[];
	isExcel?: boolean;
};

export function BasicSupportUnitRows({ basicSupportUnits, isExcel = false }: Props) {
	return (
		<>
			{basicSupportUnits?.map((basicSupportUnit, index) => (
				<tr key={`basic-support-unit-${index}`} style={isExcel ? textStyles : {}}>
					<td>{index + 1}</td>
					<td>{basicSupportUnit.name}</td>
					<td>{basicSupportUnit.national_health_registration}</td>
					<td className="text-center">
						{basicSupportUnit.primary_attendance_count || "0"}
					</td>
					<td className="text-center">
						{basicSupportUnit.secondary_attendance_count || "0"}
					</td>
					<td className="text-center">{basicSupportUnit.total_attendance_count}</td>
				</tr>
			))}
		</>
	);
}
