import { useEffect, useRef, useState } from "react";
import classNames from "clsx";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import { FaEye, FaPowerOff } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import { useSwal } from "@/hooks/useSwal";
import { useApi } from "@/hooks/useApi";
import { useDebounce } from "@/hooks/useDebounce";

import { RegionalGroupRegistrationModal } from "@/components/Configurations/RegionalGroup/Modals/RegionalGroupRegistrationModal";
import { RegionalGroupViewingModal } from "@/components/Configurations/RegionalGroup/Modals/RegionalGroupViewingModal";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { PaginationLinks } from "@/components/PaginationLinks";

export function RegionalGroups() {
	const [regionalGroups, setRegionalGroups] = useState<PaginatedRegionalGroups>(
		{} as PaginatedRegionalGroups
	);
	const [showRegionalGroupRegistrationModal, setShowRegionalGroupRegistrationModal] =
		useState(false);
	const [showRegionalGroupViewingModal, setShowRegionalGroupViewingModal] = useState(false);
	const [regionalGroup, setRegionalGroup] = useState<RegionalGroup>({} as RegionalGroup);
	const [skeleton, setSkeleton] = useState(true);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);

	const debouncedSearch = useDebounce(search, 500);
	const { Toast, toastRequestErrors } = useSwal();
	const { api } = useApi();
	const isFirstRender = useRef(true);
	const forcePage = useRef(1);

	const handleShowRegionalGroupRegistrationModal = (regionalGroup?: RegionalGroup) => {
		setShowRegionalGroupRegistrationModal(true);

		if (regionalGroup) {
			setRegionalGroup(regionalGroup);

			return;
		}

		setRegionalGroup({} as RegionalGroup);
	};
	const handleCloseRegisterRegionalGroupModal = () => {
		setShowRegionalGroupRegistrationModal(false);

		setRegionalGroup({} as RegionalGroup);
	};

	const handleShowRegionalGroupViewingModal = (regionalGroup: RegionalGroup) => {
		setShowRegionalGroupViewingModal(true);
		setRegionalGroup(regionalGroup);
	};
	const handleCloseRegionalGroupViewingModal = () => {
		setShowRegionalGroupViewingModal(false);

		setRegionalGroup({} as RegionalGroup);
	};

	const { results } = regionalGroups;

	async function fetchRegionalGroups(pageToUse?: number) {
		try {
			setSkeleton(true);

			const { data } = await api.get("/regional-group", {
				params: {
					page: pageToUse || page,
					search: debouncedSearch,
					show_all: true,
				},
			});

			setRegionalGroups(data);
		} catch (error) {
			console.error(error);
		} finally {
			setSkeleton(false);
		}
	}

	async function handleChangeStatus(id: string) {
		try {
			setLoading(true);

			await api.put(`/regional-group/change-status/${id}`);

			Toast.fire({
				icon: "success",
				title: "Status alterado com sucesso",
			});

			fetchRegionalGroups();
		} catch (error: any) {
			if (error.isAxiosError && error.response.status === 422) {
				toastRequestErrors(error.response.data.errors);
			}

			Toast.fire({
				icon: "error",
				title: "Erro ao alterar status",
			});
		} finally {
			setLoading(false);
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchRegionalGroups(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	useEffect(() => {
		forcePage.current = page;
		fetchRegionalGroups();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		forcePage.current = 1;
		fetchRegionalGroups(1);
	}, [debouncedSearch]);

	return (
		<>
			<button
				className="d-flex align-items-center btn button-bg-white-color-samu mb-6 gap-4"
				onClick={() => handleShowRegionalGroupRegistrationModal()}
			>
				<FaPlus /> Cadastrar grupo regional
			</button>

			<Card>
				<Card.Header className="d-flex justify-content-between align-items-center">
					<h3 className="fw-normal m-0">Grupos regionais cadastrados</h3>

					<div>
						<SearchFormControl
							placeholder="Pesquisar grupo regional"
							onChange={(event) => setSearch(event.target.value)}
						/>
					</div>
				</Card.Header>

				<Card.Body>
					<Table className="table-row-dashed table-row-gray-300">
						<thead>
							<tr className="fw-bold fs-6">
								<th>Grupos regionais</th>
								<th>Bases</th>
								<th>Status</th>
								<th>Ações</th>
							</tr>
						</thead>

						<tbody>
							{skeleton ? (
								<TableBodySkeleton columns={4} />
							) : (
								<>
									{results?.map((regionalGroup, index) => {
										const isActive = regionalGroup.is_active;
										const basesLength = regionalGroup.bases.length;
										const newBasesLength = regionalGroup.bases.slice(0, 3);

										const linkedBases = newBasesLength?.map((base, index) => {
											const baseLabel = `${base.vehicle_type?.name} ${base.city?.name}`;

											return <div key={`base-${index}`}>{baseLabel}</div>;
										});

										return (
											<tr key={`regional-group-${index}`}>
												<td className="align-middle">
													{regionalGroup?.name}
												</td>

												<td className="align-middle">
													<div className="d-flex flex-column">
														{linkedBases}
														{basesLength > 3 && <span>...</span>}
													</div>
												</td>

												<td className="align-middle">
													<Badge
														className={classNames(
															"formatted-badge",
															isActive ? "badge-bg-green" : "bg-samu"
														)}
														pill
													>
														{isActive ? "Ativado" : "Desativado"}
													</Badge>
												</td>

												<td className="align-middle w-1px">
													<div className="d-flex gap-2">
														<OverlayTrigger
															overlay={<Tooltip>Visualizar</Tooltip>}
														>
															<Button
																variant="secondary"
																className="btn-icon btn-sm"
																onClick={() =>
																	handleShowRegionalGroupViewingModal(
																		regionalGroup
																	)
																}
															>
																<FaEye className="text-gray-700" />
															</Button>
														</OverlayTrigger>

														<OverlayTrigger
															overlay={<Tooltip>Editar</Tooltip>}
														>
															<Button
																variant="secondary"
																className="btn-icon btn-sm"
																onClick={() =>
																	handleShowRegionalGroupRegistrationModal(
																		regionalGroup
																	)
																}
															>
																<MdEdit className="text-gray-700" />
															</Button>
														</OverlayTrigger>

														<OverlayTrigger
															overlay={
																<Tooltip>
																	{isActive
																		? "Desativar"
																		: "Ativar"}
																</Tooltip>
															}
														>
															<Button
																variant="secondary"
																className="btn-icon btn-sm"
																disabled={loading}
																onClick={() =>
																	handleChangeStatus(
																		regionalGroup.id
																	)
																}
															>
																{loading ? (
																	<Spinner
																		variant="samu"
																		animation="border"
																		size="sm"
																	/>
																) : (
																	<FaPowerOff className="text-gray-700" />
																)}
															</Button>
														</OverlayTrigger>
													</div>
												</td>
											</tr>
										);
									})}

									{!results?.length && (
										<tr>
											<td className="text-center" colSpan={12}>
												<h2 className="mt-12 mb-0">
													Não há grupos regionais cadastrados
												</h2>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</Table>

					{!!results?.length && (
						<div className="d-flex justify-content-end mt-8">
							<PaginationLinks
								itemsPerPage={10}
								totalItems={regionalGroups.meta?.total}
								forcePage={forcePage.current - 1}
								changeSelectedPage={handleChangeSelectedPage}
							/>
						</div>
					)}
				</Card.Body>
			</Card>

			<RegionalGroupRegistrationModal
				showRegionalGroupRegistrationModal={showRegionalGroupRegistrationModal}
				handleCloseRegionalGroupRegistrationModal={handleCloseRegisterRegionalGroupModal}
				fetchRegionalGroups={fetchRegionalGroups}
				regionalGroup={regionalGroup}
			/>

			<RegionalGroupViewingModal
				showRegionalGroupViewingModal={showRegionalGroupViewingModal}
				handleCloseRegionalGroupViewingModal={handleCloseRegionalGroupViewingModal}
				regionalGroup={regionalGroup}
			/>
		</>
	);
}
