import FormControl, { FormControlProps } from "react-bootstrap/FormControl";
import { FiSearch } from "react-icons/fi";

import "./styles.scss";

export function SearchFormControl({ ...rest }: FormControlProps) {
	return (
		<div>
			<FiSearch className="fs-4 position-absolute mt-0.85rem ms-4" />
			<FormControl
				type="search"
				className="ps-12 shadow border-0"
				autoComplete="nope"
				list="autocompleteOff"
				{...rest}
			/>
		</div>
	);
}
