import { Fragment, useEffect, useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { observationsSchema } from "@/utils/validation/observationsSchema";

import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";
import { Separator } from "@/components/Separator";

type Props = {
	showObservationsModal: boolean;
	handleCloseObservationsModal: () => void;
	selectedAttendance?: Attendance;
	selectedAttendanceConsultation?: AttendanceConsultation;
};

export function ObservationsModal({
	showObservationsModal,
	handleCloseObservationsModal,
	selectedAttendance,
	selectedAttendanceConsultation,
}: Props) {
	const [observations, setObservations] = useState<Observation[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const { Toast } = useSwal();
	const { api } = useApi();

	const attendance = selectedAttendance || selectedAttendanceConsultation;

	const formik = useFormik({
		initialValues: {
			observation: "",
		},
		validationSchema: observationsSchema,
		async onSubmit(values, { setSubmitting, resetForm, validateForm }) {
			try {
				await api.post("/attendance/observation", {
					...values,
					attendance_id: attendance?.id,
				});

				Toast.fire({
					title: "Observação realizada com sucesso!",
					icon: "success",
				});

				handleCloseObservationsModal();
				resetForm();

				setTimeout(() => {
					validateForm();
				});
			} catch (error) {
				console.log(error);

				Toast.fire({
					icon: "error",
					title: "Erro ao realizar observação!",
				});

				setSubmitting(false);
			}
		},
	});

	const { errors } = formik;

	async function handleGetAttendanceObservations() {
		setIsLoading(true);

		try {
			const { data } = await api.get(`/attendance/observation/${attendance?.id}`);

			setObservations(data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	function handleCloseModal() {
		formik.resetForm();
		setObservations([]);
	}

	useEffect(() => {
		if (showObservationsModal && attendance?.id) {
			handleGetAttendanceObservations();
		}

		formik.validateForm();
	}, [showObservationsModal, attendance?.id]);

	return (
		<Modal
			show={showObservationsModal}
			onHide={handleCloseObservationsModal}
			onExited={handleCloseModal}
			centered
		>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Observações</h2>
			</Modal.Header>

			<Modal.Body>
				<Row className="fw-bold mb-4">
					<Col lg={2}>
						N°:{" "}
						{selectedAttendance?.number ||
							selectedAttendanceConsultation?.attendance_number}
					</Col>

					<Col>Paciente: {attendance?.patient?.name || "-"}</Col>
				</Row>

				{isLoading ? (
					<div className="text-center">
						<Spinner variant="samu" animation="border" />
					</div>
				) : (
					<div className="overflow-y-scroll overflow-x-hidden max-h-50vh">
						{observations?.map((observation, index) => (
							<Fragment key={`observation-${index}`}>
								{index > 0 && <Separator className="py-4" />}

								<Row>
									<Col lg="auto">
										<span className="fw-bold">Data e hora: </span>
										{dayjs(observation.created_at).format("DD/MM/YY [às] H:mm")}
									</Col>

									<Col>
										<span className="fw-bold">Profissional: </span>
										{observation.creator?.name}
									</Col>
								</Row>

								<div className="text-break">
									<span className="fw-bold">Observação: </span>
									{observation.observation}
								</div>
							</Fragment>
						))}
					</div>
				)}

				{!isLoading && !observations?.length && (
					<span>Nenhuma observação registrada anteriormente.</span>
				)}

				<Form onSubmit={formik.handleSubmit} className="mt-4">
					<FormControlWithCharactersCount
						id="observation"
						as="textarea"
						placeholder="Descreva"
						maxLength={3000}
						isInvalid={!!errors.observation}
						validationMessage={errors.observation}
						{...formik.getFieldProps("observation")}
					/>

					<div className="d-flex justify-content-center gap-2 mt-8">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseObservationsModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : (
								"Registrar"
							)}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
