import classNames from "clsx";

import {
	VehicleStatus,
	VehicleStatusMessages,
	VehicleStatusesTextColorsClasses,
} from "@/enums/VehicleStatus";

type Props = {
	attendanceId?: string;
	status?: number | null;
	number?: string;
};

export function AttendanceLink({ attendanceId, status, number }: Props) {
	function handleSetPathname() {
		window.history.pushState(
			{ pathname: "/resumo" },
			"",
			`/ocorrencias/resumo/${attendanceId}`
		);
	}

	return (
		<a
			href={`/ocorrencias/resumo/${attendanceId}`}
			rel="noreferrer"
			target="_blank"
			onClick={handleSetPathname}
		>
			<span
				className={classNames(
					"text-decoration-underline",
					VehicleStatusesTextColorsClasses[status as VehicleStatus]
				)}
			>
				{VehicleStatusMessages[status as VehicleStatus]} - N° {number}
			</span>
		</a>
	);
}
