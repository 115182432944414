import Yup from "@/utils/mixins/yup";

export const attendanceValidationSchema = Yup.object().shape({
	requester: Yup.object({
		name: Yup.string().required("Campo obrigatório"),
		primary_phone: Yup.string()
			.min(14, "O telefone deve ter conter todos os caracteres")
			.required("Campo obrigatório"),
	}),
	number_of_victims: Yup.number().min(1, "A qtd. vítimas deve ser no mínimo 1"),
	city_id: Yup.string().required("Campo obrigatório"),
	street: Yup.string().when("ticket_type_id", {
		is: "1",
		then: Yup.string().required("Campo obrigatório"),
	}),
	neighborhood: Yup.string().when("ticket_type_id", {
		is: "1",
		then: Yup.string().required("Campo obrigatório"),
	}),
	primary_complaint: Yup.string().when("ticket_type_id", {
		is: "1",
		then: Yup.string().required("Campo obrigatório"),
	}),
	diagnostic_hypothesis: Yup.string().when("ticket_type_id", {
		is: "2",
		then: Yup.string().required("Campo obrigatório"),
	}),
	unit_origin_id: Yup.string().when("ticket_type_id", {
		is: "2",
		then: Yup.string().required("Campo obrigatório"),
	}),
	unit_destination_id: Yup.string().when("ticket_type_id", {
		is: "2",
		then: Yup.string().required("Campo obrigatório"),
	}),
});
