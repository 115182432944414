import { FormikProps } from "formik";
import { StylesConfig } from "react-select";

import Card, { CardProps } from "react-bootstrap/Card";
import { LuCalendarDays } from "react-icons/lu";
import { FaSearch } from "react-icons/fa";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { timeUnitsOptions } from "@/utils/options/timeUnits";
import { natureTypesOptions } from "@/utils/options/natureTypes";
import { genderCodesOptions } from "@/utils/options/genderCodes";
import { vehicleTypeOptions } from "@/utils/options/vehicleType";
import { closingTypeOptions } from "@/utils/options/closingType";
import { locationTypeOptions } from "@/utils/options/locationType";
import { distanceTypeOptions } from "@/utils/options/distanceType";
import { priorityTypeOptions } from "@/utils/options/priorityType";
import { requesterTypeOptions } from "@/utils/options/requesterType";
import { transferReasonOptions } from "@/utils/options/transferReason";
import { attendanceTypeOptions } from "@/utils/options/attendanceType";
import { antecedentTypesOptions } from "@/utils/options/antecedentTypes";
import { attendanceStatusOptions } from "@/utils/options/attendanceStatus";
import { vehicleMovementCodeOptions } from "@/utils/options/vehicleMovementCode";
import { supportingOrganizationsOptions } from "@/utils/options/supportingOrganizations";
import { attendanceConsultationInitialValues } from "@/utils/initialValues/attendanceConsultation";
import { indicatedThrombolyticAppliedOptions } from "@/utils/options/indicatedThrombolyticApplied";
import { indicatedThrombolyticRecommendedOptions } from "@/utils/options/indicatedThrombolyticRecommended";
import { useAuth } from "@/modules/auth";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { ProfessionalsSelect } from "@/components/Selects/ProfessionalsSelect";
import { VehicleSelect } from "@/components/Selects/VehicleSelect";
import { CitiesSelect } from "@/components/Selects/CitiesSelect";
import { UnitsSelect } from "@/components/Selects/UnitsSelect";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { BasesSelect } from "@/components/Selects/BasesSelect";
import { DiagnosticHypothesesSelect } from "@/components/Selects/DiagnosticHypothesesSelect";

type ColorOptions = ReactSelectOption & {
	color: string;
	background: string;
};

type AttendanceConsultationInitialValues = typeof attendanceConsultationInitialValues;

type Props = CardProps & {
	isLoading?: boolean;
	formik: FormikProps<AttendanceConsultationInitialValues>;
};

export function Filter({ isLoading, formik, ...rest }: Props) {
	const { currentAuth } = useAuth();

	const { values } = formik;

	const natureTypes = values.nature_types.map((option) =>
		Number((option as ReactSelectOption).value)
	);

	const colorOptionStyles: StylesConfig<ColorOptions> = {
		option: (styles, { data }) => ({
			...styles,
			color: data.color,
		}),
		multiValue: (styles, { data }) => ({
			...styles,
			borderRadius: "1rem",
			backgroundColor: data.background,
		}),
		multiValueLabel: (styles, { data }) => ({
			...styles,
			padding: "0.25rem 0.5rem !important",
			backgroundColor: data.background,
			color: data.color,
			borderRadius: "0.5rem",
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.color,
			background: data.background,
			borderRadius: "0.5rem",
		}),
	};

	function manageActionTypes(type: string) {
		const array = [...values.action_types] as string[];

		const index = array.indexOf(type);

		if (index !== -1) {
			array.splice(index, 1);
		} else {
			array.push(type);
		}

		formik.setFieldValue("action_types", array);
	}

	function handleResetForm() {
		formik.resetForm();
	}

	return (
		<Card {...rest}>
			<Card.Header className="d-flex align-items-center">
				<h3 className="fw-normal mb-0">Consulta de ocorrências</h3>
			</Card.Header>

			<Card.Body>
				<Form onSubmit={formik.handleSubmit} className="row">
					<Col lg={4} className="d-flex flex-column gap-4 mb-4">
						<Row>
							<Col lg={6} className="d-flex flex-column">
								<Form.Label className="fw-bold">Período</Form.Label>

								<DatePicker
									showIcon
									selectsRange
									dateFormat="dd/MM/yyyy"
									className="form-control ps-10"
									calendarIconClassname="h-100 position-absolute py-0 px-4"
									showPopperArrow={false}
									selected={values.start_date}
									startDate={values.start_date}
									endDate={values.end_date}
									icon={<LuCalendarDays />}
									onChange={(dates) => {
										formik.setFieldValue("start_date", dates[0] as Date);
										formik.setFieldValue("end_date", dates[1] as Date);
									}}
								/>
							</Col>

							<Col lg={6}>
								<FormattedFormControl
									id="attendance-number"
									label="N° do chamado"
									labelClassName="fw-bold"
									{...formik.getFieldProps("attendance_number")}
								/>
							</Col>
						</Row>

						<div>
							<FormattedFormControl
								id="requesting-name"
								label="Nome do solicitante"
								labelClassName="fw-bold"
								{...formik.getFieldProps("requesting_name")}
							/>
						</div>

						<Row>
							<Col lg={6}>
								<Form.Label className="fw-bold" htmlFor="gender-code">
									Sexo
								</Form.Label>

								<ReactSelect
									inputId="gender-code"
									options={genderCodesOptions}
									value={
										(values.gender as ReactSelectOption)?.value
											? values.gender
											: null
									}
									placeholder="Selecione"
									onChange={(option) => formik.setFieldValue("gender", option)}
								/>
							</Col>

							<Col lg={6}>
								<FormattedFormControl
									id="requesting-phone"
									label="Telefone"
									labelClassName="fw-bold"
									placeholder="(00) 0 0000-0000"
									isMaskedInput
									mask="(00) 0 0000-0000"
									{...formik.getFieldProps("requesting_phone")}
								/>
							</Col>
						</Row>

						<Row>
							<Col lg={6}>
								<Form.Label htmlFor="vehicle-id" className="fw-bold">
									VTR
								</Form.Label>

								<VehicleSelect
									inputId="vehicle-id"
									value={values.vehicles}
									showNoVehicleOption={false}
									showAllVehicles
									isMulti
									onChange={(option) => formik.setFieldValue("vehicles", option)}
								/>
							</Col>

							<Col lg={6}>
								<Form.Label htmlFor="vehicle-type-id" className="fw-bold">
									Tipo VTR
								</Form.Label>

								<ReactSelect
									inputId="vehicle-type-id"
									value={values.vehicles_types}
									options={vehicleTypeOptions}
									isMulti
									placeholder="Selecione um tipo"
									onChange={(option) =>
										formik.setFieldValue("vehicles_types", option)
									}
								/>
							</Col>
						</Row>
					</Col>

					<Col lg={8} className="d-flex flex-column gap-4 mb-4">
						<Row>
							<Col lg={6} className="d-flex flex-column gap-4">
								<div>
									<Form.Label htmlFor="attendance-status-id" className="fw-bold">
										Status
									</Form.Label>

									<ReactSelect
										inputId="attendance-status-id"
										options={attendanceStatusOptions}
										value={
											(values.attendance_status as ReactSelectOption)?.value
												? values.attendance_status
												: null
										}
										placeholder="Selecione um status"
										onChange={(option) =>
											formik.setFieldValue("attendance_status", option)
										}
									/>
								</div>

								<div>
									<FormattedFormControl
										id="patient-name"
										label="Nome do paciente"
										labelClassName="fw-bold"
										{...formik.getFieldProps("patient_name")}
									/>
								</div>

								<div>
									<Form.Label htmlFor="city-id" className="fw-bold">
										Cidade
									</Form.Label>

									<CitiesSelect
										inputId="city-id"
										federalUnitId={currentAuth?.city.federal_unit_id}
										value={values.cities}
										placeholder="Selecione uma cidade"
										isMulti
										onChange={(option) =>
											formik.setFieldValue("cities", option)
										}
									/>
								</div>
							</Col>

							<Col lg={6} className="d-flex flex-column gap-4">
								<div>
									<Form.Label htmlFor="user-id" className="fw-bold">
										Profissional
									</Form.Label>

									<ProfessionalsSelect
										inputId="user-id"
										placeholder="Selecione um profissional"
										value={values.user}
										onChange={(option) => formik.setFieldValue("user", option)}
									/>
								</div>

								<div>
									<FormattedFormControl
										id="neighborhood"
										label="Bairro"
										labelClassName="fw-bold"
										{...formik.getFieldProps("neighborhood")}
									/>
								</div>

								<Row>
									<Form.Label className="fw-bold">Faixa de idade</Form.Label>

									<Col lg={6}>
										<div className="d-flex gap-4">
											<div className="d-flex align-items-center gap-2">
												<Form.Label
													htmlFor="initial-birth-date"
													className="fw-bold text-body-tertiary m-0"
												>
													de
												</Form.Label>

												<FormattedFormControl
													id="initial-birth-date"
													className="max-width-10rem min-width-3.5rem"
													isMaskedInput
													mask={Number}
													{...formik.getFieldProps("initial_birth_date")}
												/>
											</div>

											<div className="d-flex align-items-center gap-2">
												<Form.Label
													htmlFor="final-birth-date"
													className="fw-bold text-body-tertiary m-0"
												>
													até
												</Form.Label>

												<FormattedFormControl
													id="final-birth-date"
													className="max-width-10rem min-width-3.5rem"
													isMaskedInput
													mask={Number}
													{...formik.getFieldProps("final_birth_date")}
												/>
											</div>
										</div>
									</Col>

									<Col lg={6}>
										<ReactSelect
											options={timeUnitsOptions}
											value={values.time_unit_id}
											onChange={(option) =>
												formik.setFieldValue("time_unit_id", option)
											}
										/>
									</Col>
								</Row>
							</Col>
						</Row>

						<div>
							<FormattedFormControl
								id="street"
								label="Logradouro"
								labelClassName="fw-bold"
								{...formik.getFieldProps("street")}
							/>
						</div>
					</Col>

					<Col lg={12}>
						<Row>
							<Col className="d-flex flex-column gap-4" lg={4}>
								<div>
									<Form.Label
										htmlFor="supporting-organizations-medical-regulation"
										className="fw-bold"
									>
										Apoio chamado
									</Form.Label>

									<ReactSelect
										isMulti
										inputId="supporting-organizations-medical-regulation"
										options={supportingOrganizationsOptions}
										placeholder="Selecione um apoio chamado"
										value={values.supporting_organizations_medical_regulation}
										onChange={(options) =>
											formik.setFieldValue(
												"supporting_organizations_medical_regulation",
												options
											)
										}
									/>
								</div>

								<div>
									<Form.Label htmlFor="nature-type" className="fw-bold">
										Natureza
									</Form.Label>

									<ReactSelect
										isMulti
										inputId="nature-type"
										options={natureTypesOptions}
										placeholder="Selecione uma natureza"
										value={values.nature_types}
										onChange={(options) =>
											formik.setFieldValue("nature_types", options)
										}
									/>
								</div>

								<Row>
									<Col lg={6}>
										<Form.Label className="fw-bold">Intervenção</Form.Label>

										<div className="d-flex gap-4">
											<div className="d-flex align-items-center gap-2">
												<input
													id="yes"
													type="checkbox"
													checked={(
														values.action_types as string[]
													).includes("1")}
													onChange={() => manageActionTypes("1")}
												/>

												<Form.Label className="m-0" htmlFor="yes">
													Sim
												</Form.Label>
											</div>

											<div className="d-flex align-items-center gap-2">
												<input
													id="not"
													type="checkbox"
													checked={(
														values.action_types as string[]
													).includes("2")}
													onChange={() => manageActionTypes("2")}
												/>

												<Form.Label className="m-0" htmlFor="not">
													Não
												</Form.Label>
											</div>
										</div>
									</Col>

									<Col lg={6}>
										<Form.Label htmlFor="requester-type-id" className="fw-bold">
											Quem solicitou
										</Form.Label>

										<ReactSelect
											isMulti
											inputId="requester-type-id"
											options={requesterTypeOptions}
											placeholder="Selecione quem solicitou"
											value={values.requester_types}
											onChange={(options) =>
												formik.setFieldValue("requester_types", options)
											}
										/>
									</Col>
								</Row>

								<div>
									<Form.Label className="fw-bold" htmlFor="unit-origin-id">
										Estabelecimento de origem
									</Form.Label>

									<UnitsSelect
										inputId="unit-origin-id"
										placeholder="Selecione um estabelecimento de origem"
										value={values.units_origin}
										onChange={(option) =>
											formik.setFieldValue("units_origin", option)
										}
										isMulti
									/>
								</div>

								<div>
									<Form.Label htmlFor="base-id" className="fw-bold">
										Base
									</Form.Label>

									<BasesSelect
										inputId="base-id"
										placeholder="Selecione uma base"
										isMulti
										value={values.bases}
										onChange={(option) => formik.setFieldValue("bases", option)}
									/>
								</div>
							</Col>

							<Col className="d-flex flex-column gap-4" lg={4}>
								<div>
									<Form.Label
										htmlFor="supporting-organizations-scene-recording"
										className="fw-bold"
									>
										Apoio cena
									</Form.Label>

									<ReactSelect
										isMulti
										inputId="supporting-organizations-scene-recording"
										options={supportingOrganizationsOptions}
										placeholder="Selecione um apoio cena"
										value={values.supporting_organizations_scene_recording}
										onChange={(options) =>
											formik.setFieldValue(
												"supporting_organizations_scene_recording",
												options
											)
										}
									/>
								</div>

								<div>
									<Form.Label htmlFor="ticket-type-id" className="fw-bold">
										Tipo de chamado
									</Form.Label>

									<ReactSelect
										isMulti
										inputId="ticket-type-id"
										options={attendanceTypeOptions}
										placeholder="Selecione um tipo"
										value={values.ticket_types}
										onChange={(options) =>
											formik.setFieldValue("ticket_types", options)
										}
									/>
								</div>

								<Row>
									<Col lg={6}>
										<Form.Label htmlFor="distance-type" className="fw-bold">
											Distância do paciente
										</Form.Label>

										<ReactSelect
											isMulti
											inputId="distance-type"
											options={distanceTypeOptions}
											placeholder="Selecione a distância"
											value={values.distance_types}
											onChange={(options) =>
												formik.setFieldValue("distance_types", options)
											}
										/>
									</Col>

									<Col lg={6}>
										<Form.Label htmlFor="location-type" className="fw-bold">
											Local
										</Form.Label>

										<ReactSelect
											isMulti
											inputId="location-type"
											options={locationTypeOptions}
											placeholder="Selecione um local"
											value={values.location_types}
											onChange={(options) =>
												formik.setFieldValue("location_types", options)
											}
										/>
									</Col>
								</Row>

								<div>
									<Form.Label htmlFor="unit-destination-id" className="fw-bold">
										Estabelecimento de destino
									</Form.Label>

									<UnitsSelect
										inputId="unit-destination-id"
										placeholder="Selecione um estabelecimento de destino"
										value={values.units_destination}
										onChange={(option) =>
											formik.setFieldValue("units_destination", option)
										}
										isMulti
									/>
								</div>

								<div>
									<Form.Label htmlFor="unit-destination-id" className="fw-bold">
										Uso de trombolítico indicado
									</Form.Label>
									<div className="d-flex gap-4 m-0">
										<ReactSelect
											isMulti
											inputId="thrombolytic-recommended"
											options={indicatedThrombolyticRecommendedOptions}
											placeholder="Selecione recomendado"
											value={values.thrombolytic_recommended}
											onChange={(options) =>
												formik.setFieldValue(
													"thrombolytic_recommended",
													options
												)
											}
										/>

										<ReactSelect
											isMulti
											inputId="thrombolytic-applied"
											options={indicatedThrombolyticAppliedOptions}
											placeholder="Selecione aplicado"
											value={values.thrombolytic_applied}
											onChange={(options) =>
												formik.setFieldValue(
													"thrombolytic_applied",
													options
												)
											}
										/>
									</div>
								</div>
							</Col>

							<Col className="d-flex flex-column gap-4" lg={4}>
								<Row>
									<Col lg={6}>
										<Form.Label htmlFor="priority-type" className="fw-bold">
											Prioridade
										</Form.Label>

										<ReactSelect
											isMulti
											inputId="priority-type"
											styles={colorOptionStyles}
											options={priorityTypeOptions}
											placeholder="Selecione uma prioridade"
											value={values.priority_types}
											onChange={(options) =>
												formik.setFieldValue("priority_types", options)
											}
										/>
									</Col>

									<Col lg={6}>
										<Form.Label
											htmlFor="vehicle-movement-code"
											className="fw-bold"
										>
											Código
										</Form.Label>

										<ReactSelect
											isMulti
											inputId="vehicle-movement-code"
											styles={colorOptionStyles}
											options={vehicleMovementCodeOptions}
											placeholder="Selecione um código"
											value={values.vehicle_movement_codes}
											onChange={(options) =>
												formik.setFieldValue(
													"vehicle_movement_codes",
													options
												)
											}
										/>
									</Col>
								</Row>

								<div>
									<Form.Label htmlFor="diagnostic-hypotheses" className="fw-bold">
										Hipótese diagnóstica
									</Form.Label>

									<DiagnosticHypothesesSelect
										isMulti
										inputId="diagnostic-hypotheses"
										natureTypes={natureTypes}
										value={values.diagnostic_hypotheses}
										onChange={(option) =>
											formik.setFieldValue("diagnostic_hypotheses", option)
										}
									/>
								</div>

								<div>
									<Form.Label htmlFor="closing-type" className="fw-bold">
										Encerramento
									</Form.Label>

									<ReactSelect
										isMulti
										inputId="closing-type"
										placeholder="Selecione um encerramento"
										options={closingTypeOptions}
										value={values.closing_types}
										onChange={(options) =>
											formik.setFieldValue("closing_types", options)
										}
									/>
								</div>

								<div>
									<Form.Label htmlFor="transfer-reason-id" className="fw-bold">
										Motivo da transferência
									</Form.Label>

									<ReactSelect
										inputId="transfer-reason-id"
										options={transferReasonOptions}
										value={
											(values.transfer_reason as ReactSelectOption)?.value
												? values.transfer_reason
												: null
										}
										placeholder="Selecione o motivo"
										onChange={(option) =>
											formik.setFieldValue("transfer_reason", option)
										}
									/>
								</div>

								<div>
									<Form.Label htmlFor="antecedent-type-id" className="fw-bold">
										Antecedentes
									</Form.Label>

									<ReactSelect
										inputId="antecedent-type-id"
										placeholder="Selecione um antecedente"
										options={antecedentTypesOptions}
										value={values.antecedents}
										isMulti
										onChange={(option) =>
											formik.setFieldValue("antecedents", option)
										}
									/>
								</div>
							</Col>
						</Row>
					</Col>

					<div className="d-flex align-items-center justify-content-center gap-2 mt-16">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleResetForm}
						>
							Limpar
						</button>

						<button
							type="submit"
							disabled={isLoading}
							className="d-flex align-items-center btn button-bg-samu-color-white gap-4"
						>
							{isLoading ? (
								"Aguarde..."
							) : (
								<>
									<FaSearch /> Filtrar
								</>
							)}
						</button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
}
