import { TransferReason, TransferReasonsMessages } from "@/enums/TransferReason";

export const transferReasonOptions = [
	{
		label: TransferReasonsMessages[TransferReason.ZeroVacancy],
		value: TransferReason.ZeroVacancy,
	},
	{
		label: TransferReasonsMessages[TransferReason.VacancyReleasedByBedCentral],
		value: TransferReason.VacancyReleasedByBedCentral,
	},
];
