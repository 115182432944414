export enum RecommendedEnum {
	Recommended = 2,
	NotRecommended = 1,
}

export enum AppliedEnum {
	Applied = 2,
	NotApplied = 1,
}

export const RecommendedMessages = {
	[RecommendedEnum.Recommended]: "Recomendado",
	[RecommendedEnum.NotRecommended]: "Não recomendado",
};

export const AppliedMessages = {
	[AppliedEnum.Applied]: "Aplicado",
	[AppliedEnum.NotApplied]: "Não aplicado",
};
