import { Route, Routes } from "react-router-dom";

import { Professionals } from "@/pages/Professionals";
import { RegistrationForm } from "@/components/Users/Forms/RegistrationForm";

export function ProfessionalRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Professionals />} />
			<Route path="cadastro" element={<RegistrationForm isProfessionalRegistration />} />
		</Routes>
	);
}
