import { useState } from "react";
import dayjs from "dayjs";

import InfiniteScroll from "react-infinite-scroll-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "react-bootstrap/Spinner";
import Tooltip from "react-bootstrap/Tooltip";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import { MdAssignmentAdd } from "react-icons/md";
import { Link } from "react-router-dom";

import { Role } from "@/enums/Role";
import { useCanAccess } from "@/hooks/useCanAccess";
import { TimeUnitMessages } from "@/enums/TimeUnit";
import { createSentence } from "@/utils/createSentence";
import { SimplifiedAttendanceTypeMessages } from "@/enums/AttendanceType";

import { DiagnosticHypothesesTooltipContent } from "@/components/DiagnosticHypothesesTooltipContent";
import { CancelAttendanceModal } from "@/components/Modals/CancelAttendanceModal";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { VehicleStatusModal } from "@/components/Modals/VehicleStatusModal";
import { ObservationsModal } from "@/components/Modals/ObservationsModal";
import { StatusBadge } from "@/components/Badges/StatusBadge";
import { LateralToolbar } from "@/components/LateralToolbar";
import { ActionsButton } from "@/components/ActionsButton";
import { Bullet } from "@/components/Bullet";

type KeyOfTimeUnitMessages = keyof typeof TimeUnitMessages;

type AttendanceCardProps = {
	title: string;
	isLoading: boolean;
	attendances: PaginatedAttendance;
	next: () => Promise<void>;
	scrollableTarget: string;
	onSearch: (value: string) => void;
	isTheAwaitingVehiclesTable?: boolean;
	fetchAttendances: () => Promise<void>;
};

export function AttendanceCard({
	title,
	isLoading = false,
	attendances,
	next,
	scrollableTarget,
	onSearch,
	isTheAwaitingVehiclesTable,
	fetchAttendances,
}: AttendanceCardProps) {
	const [showCancelAttendanceModal, setShowCancelAttendanceModal] = useState(false);
	const [showVehicleStatusModal, setShowVehicleStatusModal] = useState(false);
	const [showObservationsModal, setShowObservationsModal] = useState(false);
	const [selectedAttendance, setSelectedAttendance] = useState<Attendance>({} as Attendance);
	const { can } = useCanAccess();

	const { results } = attendances;
	const dataLength = results?.length;
	const hasMore = Boolean(dataLength) && dataLength !== attendances.meta?.total;

	const handleShowCancelAttendanceModal = (attendance: Attendance) => {
		setShowCancelAttendanceModal(true);
		setSelectedAttendance(attendance);
	};
	const handleCloseCancelAttendanceModal = () => setShowCancelAttendanceModal(false);

	const handleShowObservationsModal = (attendance: Attendance) => {
		setShowObservationsModal(true);
		setSelectedAttendance(attendance);
	};
	const handleCloseObservationsModal = () => setShowObservationsModal(false);

	const handleShowVehicleStatusModal = () => setShowVehicleStatusModal(true);
	const handleCloseVehicleStatusModal = () => setShowVehicleStatusModal(false);

	return (
		<>
			<LateralToolbar>
				<LateralToolbar.Button onClick={handleShowVehicleStatusModal}>
					Status das <br /> viaturas
				</LateralToolbar.Button>
			</LateralToolbar>

			<Card className="mb-9 pb-4">
				<Card.Header className="d-flex justify-content-between align-items-center">
					<h3 className="fw-normal m-0">{title}</h3>

					<SearchFormControl
						placeholder="Pesquisar atendimento"
						onChange={(event) => onSearch(event.target.value)}
					/>
				</Card.Header>

				<Card.Body className="overflow-y-scroll max-h-110rem py-0" id={scrollableTarget}>
					<InfiniteScroll
						style={{ overflow: "hidden" }}
						dataLength={dataLength || 0}
						hasMore={hasMore}
						next={next}
						scrollableTarget={scrollableTarget}
						loader={
							<div className="d-flex flex-center">
								<Spinner animation="border" variant="samu" />
							</div>
						}
					>
						<Table responsive className="table-row-dashed table-row-gray-300 fs-7">
							<thead>
								<tr className="fw-bold">
									<OverlayTrigger overlay={<Tooltip>Prioridade</Tooltip>}>
										<th className="pe-2">Pr.</th>
									</OverlayTrigger>
									<th className="px-2">Paciente</th>
									<OverlayTrigger
										overlay={
											<Tooltip>Hipótese diagnóstica ou sintomas</Tooltip>
										}
									>
										<th className="px-2">HD</th>
									</OverlayTrigger>
									{isTheAwaitingVehiclesTable && <th className="px-2">Tipo</th>}
									<th className="px-2">Med. reg.</th>
									<th className="px-2 text-center">Status</th>
									<th className="px-2 min-w-100px">VTR</th>
									<th className="px-2">N° ch.</th>
									<th className="px-2">Data</th>
									<th className="px-2 min-w-100px">Origem</th>
									<th className="px-2 min-w-100px">Destino</th>
									<th className="ps-2">Ações</th>
								</tr>
							</thead>

							<tbody>
								{isLoading ? (
									<TableBodySkeleton columns={12} />
								) : (
									results?.map((attendance, index) => {
										const { attendable } = attendance;

										const sceneRecording = attendance?.scene_recording;
										const latestMedicalRegulation =
											attendance?.latest_medical_regulation;
										const latestUserAttendance =
											attendance?.latest_user_attendance;

										const timeUnitMessage =
											TimeUnitMessages[
												attendance.patient
													?.time_unit_id as KeyOfTimeUnitMessages
											];

										const formattedAge = attendance?.patient?.age
											? `Idade: ${attendance.patient?.age} ${
													timeUnitMessage || ""
											  }`
											: "";

										const vehicles = createSentence(
											attendance.radio_operation?.vehicles.map((vehicle) => {
												const vehicleBaseCity = vehicle.base?.city?.name
													? `- ${vehicle.base?.city?.name}`
													: "";

												return `${vehicle.vehicle_type?.name || ""} ${
													vehicle.code
												} ${vehicleBaseCity}`;
											}) ?? []
										);

										const attendanceTypeMessage =
											SimplifiedAttendanceTypeMessages[
												attendance.ticket
													.ticket_type_id as keyof typeof SimplifiedAttendanceTypeMessages
											];

										const status = latestUserAttendance ? (
											<OverlayTrigger
												overlay={
													<Tooltip>
														Em atendimento por{" "}
														{latestUserAttendance?.user?.name}
													</Tooltip>
												}
											>
												<div>
													<StatusBadge
														statusId={attendance.attendance_status_id}
													/>
												</div>
											</OverlayTrigger>
										) : (
											<StatusBadge
												statusId={attendance.attendance_status_id}
											/>
										);

										const lastDiagnosticHypothesisSceneRecording =
											sceneRecording?.diagnostic_hypotheses?.at(-1)?.name ??
											"";
										const lastDiagnosticHypothesisMedicalRegulation =
											latestMedicalRegulation?.diagnostic_hypotheses?.at(-1)
												?.name ?? "";

										const diagnosticHypothesisOrPrimaryComplaint =
											lastDiagnosticHypothesisSceneRecording ||
											lastDiagnosticHypothesisMedicalRegulation ||
											attendable?.diagnostic_hypothesis ||
											attendable?.primary_complaint;

										const diagnosticHypotheses =
											sceneRecording?.diagnostic_hypotheses ||
											latestMedicalRegulation?.diagnostic_hypotheses;

										const transformedDiagnosticHypotheses =
											diagnosticHypotheses?.map(
												(diagnosticHypothesis) => diagnosticHypothesis.name
											) as string[];

										const unitDestination =
											sceneRecording?.latest_counter_referral
												?.unit_destination?.name ||
											sceneRecording?.unit_destination?.name ||
											attendable?.unit_destination?.name;

										const sceneRecordingIsMoreRecent =
											sceneRecording?.created_at &&
											dayjs(latestMedicalRegulation?.created_at).isBefore(
												dayjs(sceneRecording?.created_at)
											);

										const medicalRegulator = sceneRecordingIsMoreRecent
											? sceneRecording?.created_by?.name
											: latestMedicalRegulation?.createdBy?.name;

										const priorityType = sceneRecording
											? sceneRecording?.priority_type_id
											: latestMedicalRegulation?.priority_type_id;

										const occurrenceNumber = `
											${attendance?.ticket.ticket_sequence_per_urgency_regulation_center}
											/ ${attendance?.attendance_sequence_per_ticket}`;

										return (
											<tr key={`attendance-${index}`}>
												<td className="align-middle pe-2">
													<Bullet attribute={priorityType || 0} />
												</td>

												<td className="align-middle px-2">
													{attendance?.patient?.name || ""}
													<div>{formattedAge}</div>
												</td>

												<td className="align-middle w-1px px-2">
													<OverlayTrigger
														overlay={
															<Tooltip>
																<DiagnosticHypothesesTooltipContent
																	diagnosticHypotheses={
																		transformedDiagnosticHypotheses
																	}
																	diagnosticHypothesis={
																		attendable.diagnostic_hypothesis
																	}
																	primaryComplaint={
																		attendable.primary_complaint
																	}
																/>
															</Tooltip>
														}
													>
														<span className="text-limit">
															{diagnosticHypothesisOrPrimaryComplaint}
														</span>
													</OverlayTrigger>
												</td>

												{isTheAwaitingVehiclesTable && (
													<td className="align-middle px-2">
														{attendanceTypeMessage}
													</td>
												)}

												<td className="align-middle px-2">
													{medicalRegulator}
												</td>

												<td className="text-center align-middle px-2">
													{status}
												</td>

												<td className="align-middle px-2">
													<OverlayTrigger
														overlay={<Tooltip>{vehicles}</Tooltip>}
													>
														<span className="text-limit">
															{vehicles}
														</span>
													</OverlayTrigger>
												</td>

												<td className="align-middle px-2">
													{
														attendance?.ticket
															.ticket_sequence_per_urgency_regulation_center
													}
													/{attendance?.attendance_sequence_per_ticket}
												</td>

												<td className="align-middle px-2">
													{dayjs(attendance?.ticket.opening_at).format(
														"DD/MM/YYYY"
													)}
													<div className="fw-bold">
														Hora:{" "}
														{dayjs(
															attendance?.ticket.opening_at
														).format("HH:mm[h]")}
													</div>
												</td>

												<td className="align-middle px-2">
													{attendance?.ticket.city.name || ""}
													<div>{attendance.attendable.neighborhood}</div>
												</td>

												<td className="align-middle w-1px px-2">
													<OverlayTrigger
														overlay={
															<Tooltip>{unitDestination}</Tooltip>
														}
													>
														<span className="text-limit">
															{unitDestination}
														</span>
													</OverlayTrigger>
												</td>

												<td className="align-middle w-1px ps-2">
													<div className="d-flex gap-2">
														{can([
															Role.tarm,
															Role.medic,
															Role.radioOperator,
															Role.attendanceOrAmbulanceTeam,
															Role.admin,
															Role.teamLeader,
														]) && (
															<ActionsButton
																showCancelButton={can([
																	Role.medic,
																	Role.teamLeader,
																])}
																showDuplicateButton
																showObservationsButton
																showSummaryButton
																occurrenceNumber={occurrenceNumber}
																attendanceId={attendance.id}
																cancelButtonFunction={() =>
																	handleShowCancelAttendanceModal(
																		attendance
																	)
																}
																observationsButtonFunction={() =>
																	handleShowObservationsModal(
																		attendance
																	)
																}
																fetchBeforeAction={fetchAttendances}
															/>
														)}

														{Boolean(latestMedicalRegulation) &&
															can([
																Role.admin,
																Role.radioOperator,
															]) && (
																<OverlayTrigger
																	overlay={
																		<Tooltip>
																			Rádio operação
																		</Tooltip>
																	}
																>
																	<Link
																		to={`/controle-de-frota/radio-operador/${attendance?.id}`}
																		state="/radio-operador"
																		className="btn btn-icon btn-sm bg-secondary"
																	>
																		<MdAssignmentAdd className="text-gray-700" />
																	</Link>
																</OverlayTrigger>
															)}
													</div>
												</td>
											</tr>
										);
									})
								)}

								{!isLoading && !dataLength && (
									<tr>
										<td className="text-center" colSpan={12}>
											<h2 className="mt-7 mb-0">
												Nenhuma ocorrência encontrada
											</h2>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</InfiniteScroll>
				</Card.Body>
			</Card>

			<CancelAttendanceModal
				showCancelAttendanceModal={showCancelAttendanceModal}
				handleCloseCancelAttendanceModal={handleCloseCancelAttendanceModal}
				selectedAttendance={selectedAttendance}
				fetchAttendances={fetchAttendances}
			/>

			<ObservationsModal
				showObservationsModal={showObservationsModal}
				handleCloseObservationsModal={handleCloseObservationsModal}
				selectedAttendance={selectedAttendance}
			/>

			<VehicleStatusModal
				showVehicleStatusModal={showVehicleStatusModal}
				handleCloseVehicleStatusModal={handleCloseVehicleStatusModal}
				handleShowVehicleStatusModal={handleShowVehicleStatusModal}
			/>
		</>
	);
}
