import Yup from "@/utils/mixins/yup";

export const cancelAttendanceSchema = Yup.object().shape({
	reason: Yup.string()
		.required("Campo obrigatório")
		.max(500, "O campo deve conter no máximo 500 caracteres"),
	requester: Yup.string()
		.required("Campo obrigatório")
		.max(100, "O campo deve conter no máximo 100 caracteres"),
});
