import { useEffect, useRef, useState } from "react";
import { useApi } from "@/hooks/useApi";

import { ReactSelect, ReactSelectProps, Additional } from "@/components/Selects/ReactSelect";

type OccupationsSelectProps = ReactSelectProps & {
	isProfessionalRegistration?: boolean;
};

export function OccupationsSelect({ isProfessionalRegistration, ...rest }: OccupationsSelectProps) {
	const { api } = useApi();
	const abortControllerRef = useRef(new AbortController());

	const [occupations, setOccupations] = useState<ReactSelectOption[]>([]);

	async function fetchOccupations(page: number, search: string = "") {
		abortControllerRef.current.abort();
		abortControllerRef.current = new AbortController();

		try {
			const { data } = await api.get("/occupations", {
				signal: abortControllerRef.current.signal,
				params: {
					page,
					search,
					search_professionals: Number(isProfessionalRegistration) || 0,
				},
			});

			const transformedData = data?.results.map(({ code, name }: Occupation) => ({
				value: code,
				label: name,
			}));

			setOccupations(transformedData);

			return data as PaginatedOccupation;
		} catch (error) {
			console.log(error);
		}
	}

	const loadAsyncOccupationsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, _: Occupation, { page }: Additional) {
			try {
				const occupations = await fetchOccupations(page, search);

				return {
					options: occupations?.results.map((occupation) => ({
						value: occupation.code,
						label: occupation.name,
					})),
					hasMore: Boolean(occupations?.links?.next),
					additional: {
						page: page + 1,
					},
				};
			} catch (error) {
				console.error(error);

				return {
					options: [],
					hasMore: false,
					additional: {
						page: 1,
					},
				};
			}
		},
	};

	useEffect(() => {
		fetchOccupations(1);
	}, []);

	return <ReactSelect options={occupations} {...loadAsyncOccupationsProps} {...rest} />;
}
