import Yup from "@/utils/mixins/yup";

export const vehicleRegistrationSchema = Yup.object().shape(
	{
		code: Yup.string().required("Campo obrigatório"),
		license_plate: Yup.string().required("Campo obrigatório"),
		tracking_device_imei: Yup.string().when("tracking_system_id", {
			is: (value: number | string | null) => Boolean(value),
			then: (schema) => schema.required("Campo obrigatório"),
		}),
		tracking_system_id: Yup.number()
			.integer()
			.when("tracking_device_imei", {
				is: (value: number | string | null) => Boolean(value),
				then: (schema) => schema.required("Campo obrigatório"),
			}),
	},
	[["tracking_device_imei", "tracking_system_id"]]
);
