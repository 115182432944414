import { scheduleInitialValues } from "./schedule";

export const userInitialValues = {
	name: "",
	gender_code: "",
	phone: "",
	whatsapp: "",
	identifier: "",
	birthdate: "",
	email: "",
	city_id: "",
	city_label: "",
	neighborhood: "",
	street: "",
	street_type: "",
	house_number: "",
	council_number: "",
	cbo: "",
	cbo_label: "",
	password: "",
	password_confirmation: "",
	federal_unit: "",
	federal_unit_label: "",
	national_health_card: "",
	profiles: [] as FormikProfiles[],
	...scheduleInitialValues,
};
