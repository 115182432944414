import { forwardRef, LegacyRef } from "react";
import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { AdvancedSupportUnitRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/AdvancedSupportUnitRows";
import { MotorcycleAmbulanceRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/MotorcycleAmbulanceRows";
import { BasicSupportUnitRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/BasicSupportUnitRows";
import { AeromedicalRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/AeromedicalRows";
import { ProceduresRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/ProceduresRows";
import { NoRecordsFoundRow } from "@/components/Tables/NoRecordsFoundRow";
import { Header } from "@/components/Prints/Layout/Header";

import "./styles.scss";

type Props = {
	startDate: Date;
	endDate: Date;
	totalTickets: number;
	totalTicketsWithMedicalOrientation: number;
	totalAttendancesWithBasicSupportUnits: number;
	totalAttendancesWithAdvancedSupportUnits: number;
	totalTicketsWithMultipleAttendances: number;
	vehicleTypes: OutpatientProductionBulletinTotalAttendancesPerBaseAndType;
	urgencyRegulationCenterName: string;
};

export const OutpatientProductionBulletinPrint = forwardRef(
	(
		{
			startDate,
			endDate,
			totalTickets,
			totalTicketsWithMedicalOrientation,
			totalAttendancesWithAdvancedSupportUnits,
			totalAttendancesWithBasicSupportUnits,
			totalTicketsWithMultipleAttendances,
			vehicleTypes,
			urgencyRegulationCenterName,
		}: Props,
		ref: LegacyRef<HTMLTableElement>
	) => {
		const advancedSupportUnits = vehicleTypes?.advanced_support_unit;
		const basicSupportUnits = vehicleTypes?.basic_support_unit;
		const motorcycleAmbulances = vehicleTypes?.motorcycle_ambulance;
		const aeromedicals = vehicleTypes?.aeromedical;

		return (
			<table ref={ref} className="w-100">
				<thead>
					<tr>
						<th className="w-100 pb-5 px-10">
							<Header
								title={`CENTRAL DE REGULAÇÃO DE ${urgencyRegulationCenterName}`}
							/>
						</th>
					</tr>
				</thead>

				<tbody>
					<tr>
						<td className="px-10">
							<div className="bg-dark-samu rounded mb-3 p-4 text-center">
								<h6 className="m-0 text-white text-uppercase">
									CRU {urgencyRegulationCenterName} –{" "}
									{dayjs(startDate).format("DD/MM/YYYY")} a{" "}
									{dayjs(endDate).format("DD/MM/YYYY")}
								</h6>
							</div>

							<Table bordered className="text-center fs-8 border-dark">
								<thead>
									<tr>
										<th>PROCEDIMENTO</th>
										<th>CÓDIGO</th>
										<th>CBO</th>
										<th>TOTAL</th>
									</tr>
								</thead>

								<tbody>
									<ProceduresRows
										showOccupationCodeColumn
										totalTickets={totalTickets}
										totalTicketsWithMedicalOrientation={
											totalTicketsWithMedicalOrientation
										}
										totalAttendancesWithBasicSupportUnits={
											totalAttendancesWithBasicSupportUnits
										}
										totalAttendancesWithAdvancedSupportUnits={
											totalAttendancesWithAdvancedSupportUnits
										}
										totalTicketsWithMultipleAttendances={
											totalTicketsWithMultipleAttendances
										}
									/>
								</tbody>
							</Table>

							<div className="bg-dark-samu rounded mb-3 p-4 text-center">
								<h6 className="m-0 text-white">
									UNIDADE DE SUPORTE AVANÇADO - USA - CBO:223505
								</h6>
							</div>

							<Table bordered className="text-center fs-8 border-dark">
								<thead>
									<tr>
										<th>N°</th>
										<th>BASE</th>
										<th>CNES</th>
										<th className="p-0">
											<div className="d-flex flex-column">
												<span className="border-bottom border-dark">
													PRIMÁRIO
												</span>
												<span>0301030090</span>
											</div>
										</th>
										<th className="p-0">
											<div className="d-flex flex-column">
												<span className="border-bottom border-dark">
													SECUNDÁRIO
												</span>
												<span>0301030170</span>
											</div>
										</th>
										<th>TOTAL</th>
									</tr>
								</thead>

								<tbody>
									{advancedSupportUnits?.data?.length ? (
										<>
											<AdvancedSupportUnitRows
												advancedSupportUnits={advancedSupportUnits?.data}
											/>

											<tr>
												<td colSpan={3} className="text-end fw-bold">
													TOTAL DE OCORRÊNCIAS
												</td>
												<td>{advancedSupportUnits?.primary}</td>
												<td>{advancedSupportUnits?.secondary}</td>
												<td>{advancedSupportUnits?.total}</td>
											</tr>
										</>
									) : (
										<NoRecordsFoundRow colSpanColumn={6} />
									)}
								</tbody>
							</Table>

							<div className="bg-dark-samu rounded mb-3 p-4 text-center">
								<h6 className="m-0 text-white">
									UNIDADES DE SUPORTE BÁSICO - USB - CBO:322205
								</h6>
							</div>

							<Table bordered className="text-center fs-8 border-dark">
								<thead>
									<tr>
										<th rowSpan={2}>N°</th>
										<th rowSpan={2}>BASE</th>
										<th rowSpan={2}>CNES</th>
										<th className="p-0">
											<div className="d-flex flex-column">
												<span className="border-bottom border-dark">
													PRIMÁRIO
												</span>
												<span>0301030090</span>
											</div>
										</th>
										<th className="p-0">
											<div className="d-flex flex-column">
												<span className="border-bottom border-dark">
													SECUNDÁRIO
												</span>
												<span>0301030170</span>
											</div>
										</th>
										<th>TOTAL</th>
									</tr>
								</thead>

								<tbody>
									{basicSupportUnits?.data?.length ? (
										<>
											<BasicSupportUnitRows
												basicSupportUnits={basicSupportUnits?.data}
											/>

											<tr>
												<td colSpan={3} className="text-end fw-bold">
													TOTAL DE OCORRÊNCIAS
												</td>
												<td>{basicSupportUnits?.primary}</td>
												<td>{basicSupportUnits?.secondary}</td>
												<td>{basicSupportUnits?.total}</td>
											</tr>
										</>
									) : (
										<NoRecordsFoundRow colSpanColumn={6} />
									)}
								</tbody>
							</Table>

							<div className="bg-dark-samu rounded mb-3 p-4 text-center">
								<h6 className="m-0 text-white">
									UNIDADES DE SUPORTE BÁSICO - MOTOLÂNCIA - CBO:322205
								</h6>
							</div>

							<Table bordered className="text-center fs-8 border-dark">
								<thead>
									<tr>
										<th>N°</th>
										<th>BASE</th>
										<th>CNES</th>
										<th>CÓDIGO</th>
										<th>TOTAL</th>
									</tr>
								</thead>

								<tbody>
									<MotorcycleAmbulanceRows
										motorcycleAmbulances={motorcycleAmbulances?.data}
									/>

									{!motorcycleAmbulances?.data?.length && (
										<NoRecordsFoundRow colSpanColumn={5} />
									)}
								</tbody>
							</Table>

							<div className="bg-dark-samu rounded mb-3 p-4 text-center">
								<h6 className="m-0 text-white">
									UNIDADES DE SUPORTE AVANÇADO - AEROMÉDICO - CBO:223505
								</h6>
							</div>

							<Table bordered className="text-center fs-8 border-dark">
								<thead>
									<tr>
										<th>N°</th>
										<th>BASE</th>
										<th>CNES</th>
										<th>CÓDIGO</th>
										<th>PRIMÁRIO</th>
										<th>SECUNDÁRIO</th>
										<th>TOTAL</th>
									</tr>
								</thead>

								<tbody>
									<AeromedicalRows aeromedicals={aeromedicals?.data} />

									{!aeromedicals?.data?.length && (
										<NoRecordsFoundRow colSpanColumn={7} />
									)}
								</tbody>
							</Table>
						</td>
					</tr>
				</tbody>

				<tfoot className="position-fixed bottom-0 text-center">
					<tr>
						<td className="px-10 fs-8">FONTE: SAMU 360°</td>
					</tr>
				</tfoot>
			</table>
		);
	}
);
