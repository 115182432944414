import { useEffect } from "react";
import { useFormik } from "formik";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { VehicleTypeMessages } from "@/enums/VehicleType";
import { vehicleStatusOptions } from "@/utils/options/vehicleStatus";
import {
	VehicleStatus,
	VehicleStatusMessages,
	unmodifiableVehicleStatuses,
} from "@/enums/VehicleStatus";
import { changeVehicleStatusAndBaseSchema } from "@/utils/validation/changeVehicleStatusAndBaseSchema";
import { changeVehicleStatusAndBaseInitialValues } from "@/utils/initialValues/changeVehicleStatusAndBase";

import { BasesSelect } from "@/components/Selects/BasesSelect";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { RedAsterisk } from "@/components/RedAsterisk";

type Props = {
	showChangeVehicleStatusAndBaseModal: boolean;
	handleCloseChangeVehicleStatusAndBaseModal: () => void;
	handleShowVehicleStatusModal: () => void;
	vehicle: Vehicle;
};

export function ChangeVehicleStatusAndBaseModal({
	showChangeVehicleStatusAndBaseModal,
	handleCloseChangeVehicleStatusAndBaseModal,
	handleShowVehicleStatusModal,
	vehicle,
}: Props) {
	const { api } = useApi();
	const { toastRequestErrors, Swal, Toast } = useSwal();

	const formik = useFormik({
		initialValues: changeVehicleStatusAndBaseInitialValues,
		validationSchema: changeVehicleStatusAndBaseSchema,
		async onSubmit(values, { resetForm, validateForm, setSubmitting }) {
			if (Number(values.vehicle_status_id) !== VehicleStatus.Unavailable) {
				values.description = "";
			}

			const newValues = {
				...values,
				base_id: values.base_id === "0" ? null : values.base_id,
			};

			try {
				await api.post(`/vehicles/${vehicle?.id}/status`, newValues);

				handleCloseChangeVehicleStatusAndBaseModal();
				handleShowVehicleStatusModal();

				Toast.fire({
					icon: "success",
					title: "Status da viatura atualizado com sucesso!",
				});

				setTimeout(() => {
					resetForm();
					validateForm();
				});
			} catch (error: any) {
				console.error(error);

				toastRequestErrors(error.response.data?.errors);
				setSubmitting(false);
			}
		},
	});

	const { values, errors } = formik;

	const vehicleTypeMessage =
		VehicleTypeMessages[vehicle.vehicle_type?.id as keyof typeof VehicleTypeMessages];

	const vehicleStatusId = Number(values.vehicle_status_id);
	const isSolicitedOrCommitted = unmodifiableVehicleStatuses.includes(vehicleStatusId);

	const selectedVehicleStatusOption = vehicleStatusOptions.find(
		(option) => option.value === vehicleStatusId
	);

	const selectedOptionWithDescription = {
		...selectedVehicleStatusOption,
		label: `${selectedVehicleStatusOption?.label} - ${values.description}`,
	};

	function handleCloseModal() {
		handleCloseChangeVehicleStatusAndBaseModal();
		handleShowVehicleStatusModal();

		formik.resetForm();
	}

	function handleSelectBase(option: ReactSelectOption) {
		formik.setFieldValue("base_id", option.value);
		formik.setFieldValue("base_label", option.label);
	}

	function verifyVehicleStatus() {
		if (unmodifiableVehicleStatuses.includes(vehicleStatusId)) {
			return VehicleStatusMessages[vehicleStatusId as VehicleStatus];
		}

		return (
			<ReactSelect
				options={vehicleStatusOptions}
				value={
					vehicleStatusId === VehicleStatus.Unavailable
						? selectedOptionWithDescription
						: selectedVehicleStatusOption
				}
				onChange={(option) => handleUpdatedVehicleStatus(option as ReactSelectOption)}
			/>
		);
	}

	const vehicleStatus = verifyVehicleStatus();

	async function handleUpdatedVehicleStatus(option: ReactSelectOption) {
		formik.setFieldValue("vehicle_status_id", option.value);

		const isUnavailable = option.value === VehicleStatus.Unavailable;

		setTimeout(() => {
			document.querySelector("#base-show-modal")?.parentElement?.removeAttribute("tabindex");
		});

		if (isUnavailable) {
			const result = await Swal.fire({
				title: "Insira o motivo:",
				input: "textarea",
				inputAttributes: {
					maxlength: "1000",
					placeholder: "Insira o motivo com no maximo 1000 caracteres.",
				},
				showCancelButton: true,
			});

			if (result.isDismissed) {
				return;
			}

			if (!result.value) {
				Toast.fire({
					icon: "error",
					title: "Insira o motivo",
				});

				return;
			}

			formik.setFieldValue("description", result.value);
		}
	}

	useEffect(() => {
		if (vehicle) {
			const latestVehicleStatusHistory = vehicle.latest_vehicle_status_history;

			formik.setFieldValue(
				"vehicle_status_id",
				latestVehicleStatusHistory?.vehicle_status_id
			);
			formik.setFieldValue(
				"description",
				latestVehicleStatusHistory?.description ?? "Informe o motivo da indisponibilidade"
			);
			formik.setFieldValue("base_id", vehicle.base_id);
			formik.setFieldValue("base_label", vehicle.base?.name);
		}
	}, [vehicle]);

	useEffect(() => {
		if (showChangeVehicleStatusAndBaseModal) {
			formik.validateForm();
		}
	}, [values.vehicle_status_id, values.base_id, showChangeVehicleStatusAndBaseModal]);

	return (
		<Modal
			id="base-show-modal"
			show={showChangeVehicleStatusAndBaseModal}
			onHide={handleCloseModal}
			centered
		>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">{`${vehicleTypeMessage} ${vehicle.code}`}</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-4">
					<Col>
						<Form.Label className="fw-bold w-100" htmlFor="victim-gender-code">
							Status <RedAsterisk />
						</Form.Label>

						{vehicleStatus}

						{!!errors.vehicle_status_id && (
							<span className="text-danger">{errors.vehicle_status_id}</span>
						)}
					</Col>

					<Col>
						<Form.Label className="fw-bold" htmlFor="base-id">
							Base vinculada <RedAsterisk />
						</Form.Label>

						<BasesSelect
							inputId="base-id"
							isInvalid={!!errors.base_id}
							onChange={(option) => handleSelectBase(option as ReactSelectOption)}
							value={
								values.base_id && { id: values.base_id, label: values.base_label }
							}
							canSelectNoBase
							isDisabled={isSolicitedOrCommitted}
						/>

						{!!errors.base_id && <span className="text-danger">{errors.base_id}</span>}
					</Col>

					<div className="d-flex justify-content-center gap-4 mt-5">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={
								formik.isSubmitting || !formik.isValid || isSolicitedOrCommitted
							}
						>
							Salvar
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
