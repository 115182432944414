import SwalDefault from "sweetalert2";

export function useSwal() {
	const Swal = SwalDefault.mixin({
		confirmButtonText: "Confirmar",
		cancelButtonText: "Cancelar",
		reverseButtons: true,
		customClass: {
			confirmButton: "btn button-bg-samu-color-white rounded",
			cancelButton: "btn button-bg-light-color-gray-400 rounded",
		},
	});

	const SwalToInAttendance = (message: string) =>
		Swal.fire({
			text: message,
			confirmButtonText: "Fechar",
			buttonsStyling: false,
			customClass: {
				confirmButton: "btn button-bg-light-color-gray-400 w-100px m-0",
				htmlContainer: "text-center fs-7 fw-bold mt-2",
			},
			timer: 4000,
			timerProgressBar: true,
		});

	const Toast = SwalDefault.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast: Element) => {
			toast.addEventListener("mouseenter", SwalDefault.stopTimer);
			toast.addEventListener("mouseleave", SwalDefault.resumeTimer);
		},
	});

	function toastRequestErrors(errors: GenericObject) {
		const messages = Object.values(errors);

		messages.forEach((message) => Toast.fire({ icon: "error", title: message }));
	}

	return { Swal, SwalToInAttendance, Toast, toastRequestErrors };
}
