export enum BleedingType {
	ExternalActive = 1,
	Controlled = 2,
	SuspectedInternalBleeding = 3,
	NoSuggestiveSigns = 4,
}

export const BleedingTypeMessages = {
	[BleedingType.ExternalActive]: "Externo ativo",
	[BleedingType.Controlled]: "Controlado",
	[BleedingType.SuspectedInternalBleeding]: "Suspeito de sangramento interno",
	[BleedingType.NoSuggestiveSigns]: "Sem sinais sugestivos",
};
