export enum Resource {
	BasicSupportUnit = 1,
	AdvancedSupportUnit = 2,
	Aeromedical = 3,
}

export const ResourceMessages = {
	[Resource.BasicSupportUnit]: "USB",
	[Resource.AdvancedSupportUnit]: "USA",
	[Resource.Aeromedical]: "Aeromédico",
};
