export enum Role {
	superAdmin = "super-admin",
	admin = "admin",
	manager = "manager",
	teamLeader = "team-leader",
	coordinator = "coordinator",
	tarm = "TARM",
	medic = "medic",
	radioOperator = "radio-operator",
	attendanceOrAmbulanceTeam = "attendance-or-ambulance-team",
	fleetControl = "fleet-control",
	teamScale = "team-scale",
	hospital = "hospital",
	support = "support",
	reportsConsulting = "reports-consulting",
	monitor = "monitor",
}

export const RoleMessages = {
	[Role.superAdmin]: "Super administrador",
	[Role.admin]: "Administrador",
	[Role.manager]: "Gestor",
	[Role.teamLeader]: "Chefe de equipe",
	[Role.coordinator]: "Gerente",
	[Role.tarm]: "TARM",
	[Role.medic]: "Médico",
	[Role.radioOperator]: "Rádio operador",
	[Role.attendanceOrAmbulanceTeam]: "Equipe de atendimento / Ambulância",
	[Role.fleetControl]: "Controle de frota",
	[Role.teamScale]: "Escala de equipe",
	[Role.hospital]: "Hospital",
	[Role.support]: "Apoio",
	[Role.reportsConsulting]: "Consultas de relatórios",
	[Role.monitor]: "Monitor",
};

export const roleSlugs = {
	[RoleMessages[Role.superAdmin]]: "super-admin",
	[RoleMessages[Role.admin]]: "admin",
	[RoleMessages[Role.manager]]: "manager",
	[RoleMessages[Role.teamLeader]]: "team-leader",
	[RoleMessages[Role.coordinator]]: "coordinator",
	[RoleMessages[Role.tarm]]: "TARM",
	[RoleMessages[Role.medic]]: "medic",
	[RoleMessages[Role.radioOperator]]: "radio-operator",
	[RoleMessages[Role.attendanceOrAmbulanceTeam]]: "attendance-or-ambulance-team",
	[RoleMessages[Role.fleetControl]]: "fleet-control",
	[RoleMessages[Role.teamScale]]: "team-scale",
	[RoleMessages[Role.hospital]]: "hospital",
	[RoleMessages[Role.support]]: "support",
	[RoleMessages[Role.reportsConsulting]]: "reports-consulting",
	[RoleMessages[Role.monitor]]: "monitor",
};
