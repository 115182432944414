import { useApi } from "@/hooks/useApi";

import { Additional, ReactSelect, ReactSelectProps } from "@/components/Selects/ReactSelect";

type PaginatedProcedures = GenericPagination<Procedure>;

export function ProceduresSelect({ ...rest }: ReactSelectProps) {
	const { api } = useApi();

	async function handleGetProcedures(page?: number, search?: string) {
		try {
			const { data } = await api.get("/procedures", {
				params: {
					page: page || 1,
					search,
				},
			});

			return data as PaginatedProcedures;
		} catch (error) {
			console.log(error);
			return {} as PaginatedProcedures;
		}
	}

	const loadAsyncProceduresOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, _: Procedure, { page }: Additional) {
			const procedures = await handleGetProcedures(page, search);

			return {
				options: procedures.results?.map((procedure) => ({
					value: procedure.code,
					label: `${procedure.code} - ${procedure.name}`,
				})),
				hasMore: procedures.meta?.current_page < procedures.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	return <ReactSelect {...loadAsyncProceduresOptionsProps} {...rest} />;
}
