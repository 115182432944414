import { Icon } from "leaflet";

import { MapContainer, TileLayer, Marker } from "react-leaflet";

import "react-leaflet-fullscreen-control";

import "leaflet/dist/leaflet.css";

type Props = {
	accessHistory: AccessHistory;
};

export function LocationAccessMap({ accessHistory }: Props) {
	const latitude = Number(accessHistory.latitude);
	const longitude = Number(accessHistory.longitude);

	const icon = new Icon({
		iconUrl: `${window.location.origin}/media/icons/tracking/user-marker-icon.svg`,
		iconSize: [45, 45],
	});

	return (
		<MapContainer
			center={[latitude, longitude]}
			zoom={10}
			className="w-100 h-100 rounded"
			fullscreenControl
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				maxZoom={25}
			/>
			<Marker position={[latitude, longitude]} icon={icon} title={accessHistory.name} />
		</MapContainer>
	);
}
