import { useEffect, useState } from "react";
import { useFormik } from "formik";

import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";

import { useAuth } from "../core/Auth";
import { useApi } from "@/hooks/useApi";
import { KTSVG } from "@/../_metronic/helpers";
import { onlyNumbers } from "@/utils/onlyNumbers";
import { loginSchema } from "@/utils/validation/loginSchema";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";

type Props = {
	userData: UserData;
	setUserData: (userData: UserData) => void;
};

export function Login({ userData, setUserData }: Props) {
	const navigate = useNavigate();
	const { saveAuth } = useAuth();
	const { api } = useApi();

	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");

	const formik = useFormik({
		initialValues: {
			identifier: "",
			password: "",
		},
		validationSchema: loginSchema,
		async onSubmit(values, { setStatus, setSubmitting }) {
			const unformattedIdentifier = onlyNumbers(values.identifier) || "";

			try {
				const { data: auth } = await api.get("/auth/check-credentials", {
					params: {
						identifier: unformattedIdentifier,
						password: values.password,
					},
				});

				const credentials = {
					user_id: auth.user_id,
					user_name: auth.user_name,
					identifier: unformattedIdentifier,
					password: values.password,
					latitude: latitude,
					longitude: longitude,
				};

				const { urgencyRegulationCenters } = auth;

				if (!urgencyRegulationCenters.length) {
					setStatus("Você não possui nenhuma unidade vinculada à sua conta.");
					return;
				}

				if (urgencyRegulationCenters.length === 1) {
					try {
						const { data: roles } = await api.get("/user/roles-by-unit", {
							params: {
								user_id: auth.user_id,
								urc_id: urgencyRegulationCenters[0].id,
							},
						});

						if (roles.length === 1) {
							const { data: token } = await api.post("/auth/login", {
								identifier: unformattedIdentifier,
								password: values.password,
								urc_id: urgencyRegulationCenters[0].id,
								role_id: roles[0].id,
							});

							saveAuth(token);

							return;
						} else {
							setUserData({
								...credentials,
								urgencyRegulationCenters: urgencyRegulationCenters,
								roles,
							});
						}
					} catch (error) {
						console.error(error);
					}
				} else {
					setUserData({
						...userData,
						...credentials,
						urgencyRegulationCenters: urgencyRegulationCenters,
					});
				}

				navigate("/login/selecionar-unidade-ou-perfil");
			} catch (error: any) {
				console.error(error);
				saveAuth(undefined);
				setStatus(error.response.data.message);
				setSubmitting(false);
			}
		},
	});

	const { errors } = formik;

	function getLocation() {
		navigator.permissions.query({ name: "geolocation" }).then(function (result) {
			if (result.state === "granted" || result.state === "prompt") {
				navigator.geolocation.getCurrentPosition((position) => {
					setLatitude(String(position.coords.latitude));
					setLongitude(String(position.coords.longitude));
				});
			}
		});
	}

	useEffect(() => {
		if (navigator.geolocation) {
			getLocation();
		}
	}, []);

	return (
		<Form onSubmit={formik.handleSubmit}>
			<div className="text-center text-dark mt-3 mb-10">
				<h2>LOGIN</h2>
			</div>

			{formik.status && (
				<div className="mb-6 custom-alert-warning">
					<div className="d-flex flex-center py-2">
						<KTSVG
							path="/media/icons/gen044.svg"
							className="svg-icon svg-icon-2x svg-icon-orange me-4"
						/>
						<span className="fw-bold text-gray-800 fs-6">{formik.status}</span>
					</div>
				</div>
			)}

			<div className="fv-row mb-10">
				<FormattedFormControl
					id="login-identifier"
					className="form-control-lg form-control-solid"
					label="CPF"
					labelClassName="fw-bold m-0"
					placeholder="000.000.000-00"
					mask="000.000.000-00"
					isInvalid={!!errors.identifier}
					validationMessage={errors.identifier}
					{...formik.getFieldProps("identifier")}
					isMaskedInput
				/>
			</div>

			<div className="fv-row mb-10">
				<FormattedFormControl
					id="login-password"
					type="password"
					className="form-control-lg form-control-solid"
					label="SENHA"
					labelClassName="fw-bold m-0"
					placeholder="● ● ● ● ● ● ● ●"
					isInvalid={!!errors.password}
					{...formik.getFieldProps("password")}
				/>

				<div className="text-end mt-1">
					<Link
						to="/login/recuperar-senha"
						className="fw-bolder text-decoration-underline color-samu-with-hover"
					>
						Recuperar senha
					</Link>
				</div>

				{errors.password && <span className="text-danger">{errors.password}</span>}
			</div>

			<div className="text-center">
				<button
					type="submit"
					className="btn text-white fs-3 w-75 bg-samu login-button mb-5 py-3"
					disabled={formik.isSubmitting || !formik.isValid}
				>
					{!formik.isSubmitting && (
						<span className="indicator-label text-white">ENTRAR</span>
					)}

					{formik.isSubmitting && (
						<span className="d-block indicator-progress">
							Por favor, aguarde...
							<span className="spinner-border spinner-border-sm align-middle ms-2" />
						</span>
					)}
				</button>
			</div>
		</Form>
	);
}
