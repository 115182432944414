import { useEffect, useState } from "react";
import ReactPaginate, { ReactPaginateProps } from "react-paginate";

import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";

import "./styles.scss";

type PaginationLinksProps = Omit<ReactPaginateProps, "pageCount" | "onPageChange"> & {
	itemsPerPage: number;
	changeSelectedPage: (value: number) => void;
	totalItems: number;
	forcePage?: number;
};

type PageChangeEvent = {
	selected: number;
};

export function PaginationLinks({
	itemsPerPage,
	changeSelectedPage,
	totalItems,
	forcePage,
	...rest
}: PaginationLinksProps) {
	const [itemOffset, setItemOffset] = useState(0);
	const [pageCount, setPageCount] = useState(1);

	const pages = Array.from({ length: totalItems }, (_, index) => index + 1);

	useEffect(() => {
		setPageCount(Math.ceil(pages.length / itemsPerPage));
	}, [itemOffset, itemsPerPage, totalItems]);

	const handlePageClick = (event: PageChangeEvent) => {
		const newOffset = event.selected + 1;

		changeSelectedPage(newOffset);
		setItemOffset(newOffset);
	};

	return (
		<ReactPaginate
			containerClassName="pagination"
			pageClassName="page-item"
			pageLinkClassName="page-link"
			activeClassName="active"
			nextClassName="page-item next"
			nextLinkClassName="page-link"
			previousClassName="page-item previous"
			previousLinkClassName="page-link"
			breakLabel="..."
			nextLabel={<IoIosArrowForward color="gray" />}
			previousLabel={<IoIosArrowBack color="gray" />}
			pageRangeDisplayed={5}
			onPageChange={handlePageClick}
			pageCount={pageCount}
			forcePage={forcePage}
			{...rest}
		/>
	);
}
