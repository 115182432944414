import { textStyles } from "@/utils/configs/excelOptions";

type Props = {
	colSpanColumn: number;
	isFormatted?: boolean;
	isExcel?: boolean;
};

export function NoRecordsFoundRow({ colSpanColumn, isFormatted, isExcel }: Props) {
	return (
		<tr>
			<td colSpan={colSpanColumn} style={isExcel ? textStyles : {}}>
				{isFormatted ? (
					<h2 className="mt-12 mb-0 text-center">Nenhum registro encontrado</h2>
				) : (
					"Nenhum registro encontrado"
				)}
			</td>
		</tr>
	);
}
