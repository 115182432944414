export const medicalRegulationInitialValues = {
	attendance_id: "",
	medical_regulation: "",
	diagnostic_hypotheses: [
		{
			nature_type_id: "",
			diagnostic_hypothesis_id: "",
			diagnostic_hypothesis_label: "",
			recommended: "",
			applied: "",
		},
	],
	action_type_id: "",
	action_details: [] as string[],
	priority_type_id: "",
	vehicle_movement_code_id: "",
	consciousness_level_id: "",
	respiration_type_id: "",
	supporting_organizations: [] as string[],
	destination_unit_id: "",
	destination_unit_label: "",
};
