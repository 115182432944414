import { forwardRef, LegacyRef } from "react";
import dayjs from "dayjs";

import { titleStyles, textStyles } from "@/utils/configs/excelOptions";

import { AdvancedSupportUnitRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/AdvancedSupportUnitRows";
import { BasicSupportUnitRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/BasicSupportUnitRows";
import { NoRecordsFoundRow } from "@/components/Tables/NoRecordsFoundRow";

type Props = {
	startDate: Date;
	endDate: Date;
	totalTickets: number;
	totalTicketsWithMedicalOrientation: number;
	totalAttendancesWithBasicSupportUnits: number;
	totalAttendancesWithAdvancedSupportUnits: number;
	totalTicketsWithMultipleAttendances: number;
	outpatientProductionBulletin: OutpatientProductionBulletin;
	vehicleTypes: OutpatientProductionBulletinTotalAttendancesPerBaseAndType;
	urgencyRegulationCenterName: string;
};

export const ExportTable = forwardRef(
	(
		{
			startDate,
			endDate,
			totalTickets,
			totalTicketsWithMedicalOrientation,
			totalAttendancesWithAdvancedSupportUnits,
			totalAttendancesWithBasicSupportUnits,
			totalTicketsWithMultipleAttendances,
			vehicleTypes,
			urgencyRegulationCenterName,
		}: Props,
		ref: LegacyRef<HTMLTableElement>
	) => {
		const cellStyles = {
			fontWeight: "bold",
			backgroundColor: "#7bb4e3",
			...textStyles,
		};

		const advancedSupportUnits = vehicleTypes?.advanced_support_unit;
		const basicSupportUnits = vehicleTypes?.basic_support_unit;
		const motorcycleAmbulances = vehicleTypes?.motorcycle_ambulance;
		const aeromedicals = vehicleTypes?.aeromedical;

		return (
			<table ref={ref}>
				<thead>
					<tr>
						<th
							colSpan={6}
							style={{
								backgroundColor: "#7bb4e3",
								textTransform: "uppercase",
								...textStyles,
							}}
						>
							CRU {urgencyRegulationCenterName} -{" "}
							{dayjs(startDate).format("DD/MM/YYYY")} a{" "}
							{dayjs(endDate).format("DD/MM/YYYY")}
						</th>
					</tr>

					<tr>
						<th style={cellStyles}>N°</th>
						<th style={cellStyles}>CRU {urgencyRegulationCenterName} - 6238807</th>
						<th style={cellStyles}>PROCEDIMENTO</th>
						<th style={cellStyles}>CBO</th>
						<th colSpan={2} style={cellStyles}>
							TOTAL
						</th>
					</tr>
				</thead>

				<tbody>
					<tr style={textStyles}>
						<td>1</td>
						<td>Total de chamadas recebidas pela CRU (TARM)</td>
						<td>301030014</td>
						<td>422205</td>
						<td colSpan={2}>{totalTickets}</td>
					</tr>

					<tr style={textStyles}>
						<td>2</td>
						<td>Total de chamadas recebidas pela CRU com orientação médica</td>
						<td>301030146</td>
						<td>225125</td>
						<td colSpan={2}>{totalTicketsWithMedicalOrientation}</td>
					</tr>

					<tr style={textStyles}>
						<td>3</td>
						<td>Chamados com envio de Suporte Básico (USB e MOTO)</td>
						<td>301030138</td>
						<td>225125</td>
						<td colSpan={2}>{totalAttendancesWithBasicSupportUnits}</td>
					</tr>

					<tr style={textStyles}>
						<td>4</td>
						<td>Chamados com envio de Suporte Avançado (USA)</td>
						<td>301030120</td>
						<td>225125</td>
						<td colSpan={2}>{totalAttendancesWithAdvancedSupportUnits}</td>
					</tr>

					<tr style={textStyles}>
						<td>5</td>
						<td>Acionamento de múltiplos meios</td>
						<td>0301030111</td>
						<td>225125</td>
						<td colSpan={2}>{totalTicketsWithMultipleAttendances}</td>
					</tr>

					<tr>
						<td colSpan={6} style={cellStyles}>
							UNIDADES DE SUPORTE AVANÇADO – USA – CBO: 223505
						</td>
					</tr>

					<tr>
						<td style={cellStyles} rowSpan={2}>
							N°
						</td>
						<td style={cellStyles} rowSpan={2}>
							BASE
						</td>
						<td style={cellStyles} rowSpan={2}>
							CNES
						</td>
						<td style={cellStyles}>ATENDIMENTO</td>
						<td style={cellStyles}>TRANSFERÊNCIA</td>
						<td style={cellStyles} rowSpan={2}>
							TOTAL
						</td>
					</tr>

					<tr>
						<td style={cellStyles}>0301030090</td>
						<td style={cellStyles}>0301030170</td>
					</tr>

					{advancedSupportUnits?.data?.length ? (
						<>
							<AdvancedSupportUnitRows
								advancedSupportUnits={advancedSupportUnits?.data}
								isExcel
							/>

							<tr style={textStyles}>
								<td colSpan={3} style={titleStyles}>
									TOTAL DE OCORRÊNCIAS
								</td>
								<td>{advancedSupportUnits?.primary}</td>
								<td>{advancedSupportUnits?.secondary}</td>
								<td>{advancedSupportUnits?.total}</td>
							</tr>
						</>
					) : (
						<NoRecordsFoundRow colSpanColumn={6} isExcel />
					)}

					<tr>
						<td colSpan={6} style={cellStyles}>
							UNIDADES DE SUPORTE BÁSICO – USB – CBO: 322205
						</td>
					</tr>

					<tr>
						<td style={cellStyles} rowSpan={2}>
							N°
						</td>
						<td style={cellStyles} rowSpan={2}>
							BASE
						</td>
						<td style={cellStyles} rowSpan={2}>
							CNES
						</td>
						<td style={cellStyles}>ATENDIMENTO</td>
						<td style={cellStyles}>TRANSFERÊNCIA</td>
						<td style={cellStyles} rowSpan={2}>
							TOTAL
						</td>
					</tr>

					<tr>
						<td style={cellStyles}>0301030103</td>
						<td style={cellStyles}>0301030189</td>
					</tr>

					{basicSupportUnits?.data?.length ? (
						<>
							<BasicSupportUnitRows
								basicSupportUnits={basicSupportUnits?.data}
								isExcel
							/>

							<tr style={textStyles}>
								<td colSpan={3} style={titleStyles}>
									TOTAL DE OCORRÊNCIAS
								</td>
								<td>{basicSupportUnits?.primary}</td>
								<td>{basicSupportUnits?.secondary}</td>
								<td>{basicSupportUnits?.total}</td>
							</tr>
						</>
					) : (
						<NoRecordsFoundRow colSpanColumn={6} isExcel />
					)}

					<tr>
						<td colSpan={6} style={cellStyles}>
							UNIDADES DE SUPORTE BÁSICO - MOTOLÂNCIA - CBO: 322205
						</td>
					</tr>

					<tr>
						<td style={cellStyles}>N°</td>
						<td style={cellStyles}>BASE</td>
						<td style={cellStyles}>CNES</td>
						<td style={cellStyles} colSpan={3}>
							PROCEDIMENTO 0301030197 - CBO: 322205
						</td>
					</tr>

					{motorcycleAmbulances?.data?.map((motorcycleAmbulance, index) => (
						<tr key={`motorcycle-ambulance-${index}`} style={textStyles}>
							<td>{index + 1}</td>
							<td>{motorcycleAmbulance.name}</td>
							<td>{motorcycleAmbulance.national_health_registration}</td>
							<td colSpan={3}>{motorcycleAmbulance.total_attendance_count || "0"}</td>
						</tr>
					))}

					{!motorcycleAmbulances?.data?.length && (
						<NoRecordsFoundRow colSpanColumn={6} isExcel />
					)}

					<tr>
						<td colSpan={6} style={cellStyles}>
							UNIDADES DE SUPORTE AVANÇADO - AEROMÉDICO - CBO: 223505
						</td>
					</tr>

					<tr>
						<td style={cellStyles}>N°</td>
						<td style={cellStyles}>BASE</td>
						<td style={cellStyles}>CNES</td>
						<td style={cellStyles} colSpan={3}>
							PROCEDIMENTO 0301030049 - CBO: 223505
						</td>
					</tr>

					{aeromedicals?.data?.map((aeromedical, index) => (
						<tr key={`aeromedical-${index}`} style={textStyles}>
							<td>{index + 1}</td>
							<td>{aeromedical.name}</td>
							<td>{aeromedical.national_health_registration}</td>
							<td colSpan={3}>{aeromedical.total_attendance_count || "0"}</td>
						</tr>
					))}

					{!aeromedicals?.data?.length && <NoRecordsFoundRow colSpanColumn={6} isExcel />}
				</tbody>
			</table>
		);
	}
);
