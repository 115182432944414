import { useRef } from "react";

import { useApi } from "@/hooks/useApi";

import { ReactSelect, ReactSelectProps, Additional } from "../ReactSelect";

type PatrimonySelectProps = {
	onlyAvailable?: boolean;
} & ReactSelectProps;

export function PatrimoniesSelect({ onlyAvailable = false, ...rest }: PatrimonySelectProps) {
	const abortControllerRef = useRef(new AbortController());
	const { api } = useApi();

	async function fetchPatrimonies(page: number, search: string = "") {
		abortControllerRef.current.abort();
		abortControllerRef.current = new AbortController();

		const { data } = await api.get("/patrimonies", {
			params: {
				page,
				search,
				only_available: Number(onlyAvailable),
			},
			signal: abortControllerRef.current.signal,
		});

		return data as PaginatedPatrimony;
	}

	const loadAsyncPatrimoniesOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, _: Patrimony, { page }: Additional) {
			try {
				const patrimonies = await fetchPatrimonies(page, search);

				return {
					options: patrimonies?.results.map((patrimony) => ({
						value: patrimony.id,
						label: `${patrimony.identifier} - ${patrimony.patrimony_type?.name}`,
						extraData: patrimony,
					})),
					hasMore: Boolean(patrimonies.links?.next),
					additional: {
						page: page + 1,
					},
				};
			} catch (error) {
				console.error(error);

				return {
					options: [],
					hasMore: false,
					additional: {
						page: 1,
					},
				};
			}
		},
	};

	return <ReactSelect {...loadAsyncPatrimoniesOptionsProps} {...rest} />;
}
