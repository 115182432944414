import { DistanceType, DistanceTypeMessages } from "@/enums/DistanceType";

export const distanceTypeOptions = [
	{
		label: DistanceTypeMessages[DistanceType.WithPatient],
		value: DistanceType.WithPatient,
	},
	{
		label: DistanceTypeMessages[DistanceType.Near],
		value: DistanceType.Near,
	},
	{
		label: DistanceTypeMessages[DistanceType.Away],
		value: DistanceType.Away,
	},
	{
		label: DistanceTypeMessages[DistanceType.NotWithPatient],
		value: DistanceType.NotWithPatient,
	},
];
