import { FormikProps } from "formik";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { attendanceInitialValues } from "@/utils/initialValues/attendance";

import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";
import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { FormRadio } from "@/components/Radios/FormRadio";
import { Separator } from "@/components/Separator";

type Props = {
	formik: FormikProps<typeof attendanceInitialValues>;
};

export function PrimaryAttendance({ formik }: Props) {
	const { values, errors } = formik;

	return (
		<>
			<Separator />

			<h3 className="mb-4">Dados da ocorrência</h3>

			<Row>
				<Col lg={8} className="d-flex flex-column gap-4">
					<div className="d-flex gap-4">
						<Col lg={10}>
							<FormattedFormControl
								id="street"
								label="Logradouro"
								labelClassName="fw-bold"
								isInvalid={!!errors.street}
								validationMessage={errors.street}
								{...formik.getFieldProps("street")}
								isRequired
							/>
						</Col>

						<Col>
							<FormattedFormControl
								maxLength={20}
								isMaskedInput
								mask={/^[a-zA-Z0-9]+$/}
								label="Nº"
								id="house-number"
								labelClassName="fw-bold"
								{...formik.getFieldProps("house_number")}
							/>
						</Col>
					</div>

					<div>
						<FormattedFormControl
							id="neighborhood"
							label="Bairro"
							labelClassName="fw-bold"
							isInvalid={!!errors.neighborhood}
							validationMessage={errors.neighborhood}
							{...formik.getFieldProps("neighborhood")}
							isRequired
						/>
					</div>

					<FormattedFormControl
						id="reference-place"
						label="Referência / Via de acesso"
						labelClassName="fw-bold"
						{...formik.getFieldProps("reference_place")}
					/>
				</Col>
			</Row>

			<div className="d-flex flex-column gap-4 my-4">
				<div>
					<FormattedFormControl
						id="primary-complaint"
						label="Queixa primária"
						labelClassName="fw-bold"
						isInvalid={!!errors.primary_complaint}
						validationMessage={errors.primary_complaint}
						{...formik.getFieldProps("primary_complaint")}
						isRequired
					/>
				</div>

				<FormControlWithCharactersCount
					id="observations"
					as="textarea"
					label="Observações"
					labelClassName="fw-bold"
					placeholder="Descreva"
					maxLength={3000}
					{...formik.getFieldProps("observations")}
				/>
			</div>

			<div className="d-flex flex-column gap-4">
				<h3 className="mb-0">Quem solicitou</h3>

				<FormGroupRadio
					requestedValue={values.requester.requester_type_id}
					{...formik.getFieldProps("requester.requester_type_id")}
					inline
				>
					<FormRadio id="himself" label="O próprio" value="3" />
					<FormRadio id="family" label="Familiar" value="4" />
					<FormRadio id="friend" label="Amigo" value="5" />
					<FormRadio id="passerby" label="Transeunte" value="6" />
					<FormRadio id="health-professional" label="Prof. saúde" value="7" />
					<FormRadio id="medical" label="Médico" value="1" />
					<FormRadio id="municipal-guard" label="Guarda municipal" value="8" />
					<FormRadio id="firefighters" label="Bombeiros" value="9" />
					<FormRadio id="military-police" label="PM" value="10" />
					<FormRadio id="state-highway-police" label="PRE" value="11" />
					<FormRadio id="federal-highway-police" label="PRF" value="12" />
					<FormRadio id="municipal-authority" label="AMC" value="13" />
				</FormGroupRadio>

				<h3 className="mb-0">Distância do paciente</h3>

				<FormGroupRadio
					requestedValue={values.distance_type_id}
					{...formik.getFieldProps("distance_type_id")}
					inline
				>
					<FormRadio id="with-patient" label="Com o paciente" value="1" />
					<FormRadio id="near" label="Próximo" value="2" />
					<FormRadio id="away" label="Longe" value="3" />
					<FormRadio id="not-with-patient" label="Não está com o paciente" value="4" />
				</FormGroupRadio>

				<h3 className="mb-0">Local</h3>

				<FormGroupRadio
					requestedValue={values.location_type_id}
					{...formik.getFieldProps("location_type_id")}
					inline
				>
					<FormRadio id="public-road" label="Via pública" value="1" />
					<FormRadio id="residence" label="Residência" value="2" />
					<FormRadio id="school" label="Escola" value="3" />
					<FormRadio id="company" label="Empresa" value="4" />
					<FormRadio id="rest-home" label="Casa de repouso" value="5" />
					<FormRadio id="emergency-care-unit" label="UPA" value="6" />
					<FormRadio id="hospital" label="Hospital" value="7" />
					<FormRadio id="basic-support-unit" label="UBS" value="8" />
					<FormRadio id="rural-area" label="Zona rural" value="9" />
					<FormRadio
						id="police-station-or-prison"
						label="Delegacia / Presídio"
						value="10"
					/>
					<FormRadio id="highway" label="Rodovia" value="12" />
				</FormGroupRadio>
			</div>
		</>
	);
}
