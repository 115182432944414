import { useEffect, useRef, useState } from "react";
import classNames from "clsx";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";

import { FaPowerOff, FaPlus } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { useDebounce } from "@/hooks/useDebounce";

import { CyclicScheduleTypesModal } from "@/components/Configurations/CyclicScheduleTypes/CyclicScheduleTypesModal";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { PaginationLinks } from "@/components/PaginationLinks";

type Props = {
	activeTab: string;
};

export function CyclicScheduleTypes({ activeTab }: Props) {
	const [cyclicScheduleTypes, setCyclicScheduleTypes] = useState<PaginatedCyclicScheduleTypes>(
		{} as PaginatedCyclicScheduleTypes
	);
	const [currentCyclicScheduleType, setCurrentCyclicScheduleType] = useState<CyclicScheduleTypes>(
		{} as CyclicScheduleTypes
	);
	const [showCyclicScheduleTypeModal, setShowCyclicScheduleTypeModal] = useState(false);
	const [skeleton, setSkeleton] = useState(true);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);

	const forcePage = useRef(1);
	const isFirstRender = useRef(true);

	const { api } = useApi();
	const { Toast } = useSwal();
	const debouncedSearch = useDebounce(search, 500);

	const { results } = cyclicScheduleTypes;

	async function fetchPaginatedCyclicScheduleTypes(pageToUse?: number) {
		try {
			setSkeleton(true);

			const { data } = await api.get(`/cyclic-schedule-type`, {
				params: {
					search: debouncedSearch,
					per_page: 10,
					page: pageToUse || page,
				},
			});

			setCyclicScheduleTypes(data);
		} catch (error) {
			console.log(error);
		} finally {
			setSkeleton(false);
		}
	}

	async function handleChangeStatus(id: string) {
		try {
			setLoading(true);

			const { data } = await api.put<CyclicScheduleTypes>(
				`/cyclic-schedule-type/change-status/${id}`
			);

			const isActive = data.is_active;

			Toast.fire({
				icon: "success",
				title: `Escala ${isActive ? "ativada" : "desativada"} com sucesso!`,
			});

			fetchPaginatedCyclicScheduleTypes();
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchPaginatedCyclicScheduleTypes(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	function handleShowCyclicScheduleTypesModal(diagnosticHypothesis?: CyclicScheduleTypes) {
		setShowCyclicScheduleTypeModal(true);

		if (diagnosticHypothesis) {
			setCurrentCyclicScheduleType(diagnosticHypothesis);

			return;
		}

		setCurrentCyclicScheduleType({} as CyclicScheduleTypes);
	}

	function handleCloseCyclicScheduleTypeModal() {
		setShowCyclicScheduleTypeModal(false);
		setCurrentCyclicScheduleType({} as CyclicScheduleTypes);
	}

	useEffect(() => {
		forcePage.current = page;
		fetchPaginatedCyclicScheduleTypes();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		if (activeTab === "cyclic-schedule-types") {
			fetchPaginatedCyclicScheduleTypes();
		}
	}, [debouncedSearch, activeTab]);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center my-6">
				<button
					className="d-flex align-items-center btn button-bg-samu-color-white gap-4"
					onClick={() => handleShowCyclicScheduleTypesModal()}
				>
					<FaPlus /> Cadastrar
				</button>

				<div>
					<SearchFormControl
						placeholder="Pesquisar por escala"
						onChange={(event) => setSearch(event.target.value)}
					/>
				</div>
			</div>

			<Table responsive className="table-row-dashed table-row-gray-300">
				<thead>
					<tr className="fw-bold fs-6">
						<th>Escala</th>
						<th>Horas trabalhadas</th>
						<th>Horas de folga</th>
						<th>Status</th>
						<th>Ações</th>
					</tr>
				</thead>

				<tbody>
					{skeleton ? (
						<TableBodySkeleton columns={5} />
					) : (
						<>
							{results?.map((result, index) => {
								const isActive = result.is_active;

								return (
									<tr key={`cyclic-schedule-type-${index}`}>
										<td className="align-middle">{result.name}</td>
										<td className="align-middle">{result.work_hours} Horas</td>
										<td className="align-middle">{result.break_hours} Horas</td>
										<td className="align-middle">
											<Badge
												className={classNames(
													"formatted-badge",
													isActive ? "badge-bg-green" : "bg-samu"
												)}
												pill
											>
												{isActive ? "Ativado" : "Desativado"}
											</Badge>
										</td>
										<td className="align-middle w-1px">
											<div className="d-flex gap-2">
												<OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
													<Button
														variant="secondary"
														className="btn-icon btn-sm"
														onClick={() =>
															handleShowCyclicScheduleTypesModal(
																result
															)
														}
													>
														<MdEdit className="text-gray-700" />
													</Button>
												</OverlayTrigger>

												<OverlayTrigger
													overlay={
														<Tooltip>
															{isActive ? "Desativar" : "Ativar"}
														</Tooltip>
													}
												>
													<Button
														variant="secondary"
														className="btn-icon btn-sm"
														onClick={() =>
															handleChangeStatus(result.id)
														}
													>
														{loading ? (
															<Spinner
																variant="samu"
																animation="border"
																size="sm"
															/>
														) : (
															<FaPowerOff className="text-gray-700" />
														)}
													</Button>
												</OverlayTrigger>
											</div>
										</td>
									</tr>
								);
							})}

							{!results?.length && (
								<tr>
									<td className="text-center" colSpan={12}>
										<h2 className="mt-12 mb-0">Não há escalas cadastradas</h2>
									</td>
								</tr>
							)}
						</>
					)}
				</tbody>
			</Table>

			{!!results?.length && (
				<div className="d-flex justify-content-end mt-8">
					<PaginationLinks
						itemsPerPage={10}
						totalItems={cyclicScheduleTypes.meta?.total}
						forcePage={forcePage.current - 1}
						changeSelectedPage={handleChangeSelectedPage}
					/>
				</div>
			)}

			<CyclicScheduleTypesModal
				showCyclicScheduleTypeModal={showCyclicScheduleTypeModal}
				handleCloseCyclicScheduleTypeModal={handleCloseCyclicScheduleTypeModal}
				fetchPaginatedCyclicScheduleTypes={fetchPaginatedCyclicScheduleTypes}
				currentCyclicScheduleType={currentCyclicScheduleType}
			/>
		</>
	);
}
