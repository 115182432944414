import dayjs from "dayjs";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { ConductMessages } from "@/enums/Conduct";
import { WoundTypeMessages } from "@/enums/WoundType";
import { createSentence } from "@/utils/createSentence";
import { indicatedThrombolyticsMessage } from "@/utils/indicatedThrombolyticsMessage";
import { NatureTypeMessages } from "@/enums/NatureType";
import { BleedingTypeMessages } from "@/enums/BleedingType";
import { SweatingTypeMessages } from "@/enums/SweatingType";
import { PriorityTypeMessages } from "@/enums/PriorityType";
import { WoundPlaceTypeMessages } from "@/enums/WoundPlaceType";
import { ClosingType, ClosingTypeMessages } from "@/enums/ClosingType";
import { SkinColorationTypeMessages } from "@/enums/SkinColorationType";
import { VacancyType, VacancyTypeMessages } from "@/enums/VacancyType";

import { Separator } from "@/components/Separator";

type Props = {
	sceneRegister: SceneRegister;
};

type KeyOfSkinColorationTypeMessages = keyof typeof SkinColorationTypeMessages;
type KeyOfWoundPlaceTypeMessages = keyof typeof WoundPlaceTypeMessages;
type KeyOfBleedingTypeMessages = keyof typeof BleedingTypeMessages;
type KeyOfSweatingTypeMessages = keyof typeof SweatingTypeMessages;
type KeyOfPriorityTypeMessages = keyof typeof PriorityTypeMessages;
type KeyOfClosingTypeMessages = keyof typeof ClosingTypeMessages;
type KeyOfNatureTypeMessages = keyof typeof NatureTypeMessages;
type KeyOfWoundTypeMessages = keyof typeof WoundTypeMessages;
type KeyOfConductMessages = keyof typeof ConductMessages;

export function SceneRegisterData({ sceneRegister }: Props) {
	const antecedentType = createSentence(
		sceneRegister?.antecedents_types.map((antecedent) => antecedent.antecedent_type?.name) ?? []
	);

	const closingTypeMessage =
		ClosingTypeMessages[sceneRegister?.closing_type_id as KeyOfClosingTypeMessages];

	function renderSupportNeededDescription() {
		const description = sceneRegister?.support_needed_description?.filter(Boolean);

		if (Array.isArray(description) && description.length) {
			return createSentence(description);
		}

		return "-";
	}

	return (
		<div>
			<h2 className="mb-4">Dados do registro de cena</h2>

			<Row className="gap-2">
				<Col>
					<span className="fw-bold">Médico: </span>
					<span>{sceneRegister?.created_by?.name}</span>
				</Col>

				{sceneRegister?.diagnostic_hypotheses?.map((diagnosticHypothesis, index) => {
					const natureTypeMessage =
						NatureTypeMessages[
							diagnosticHypothesis?.pivot.nature_type_id as KeyOfNatureTypeMessages
						];

					const useOfThrombolyticsMessage = indicatedThrombolyticsMessage(
						diagnosticHypothesis?.pivot.recommended,
						diagnosticHypothesis?.pivot.applied
					);

					return (
						<Row key={`diagnostic-hypothesis-${index}`}>
							<Col sm={2}>
								<span className="fw-bold">Natureza: </span>
								<span>{natureTypeMessage ?? "-"}</span>
							</Col>

							<Col lg={2} sm={2}>
								<span className="fw-bold">Hipótese diagnóstica: </span>
								<span>{diagnosticHypothesis?.name ?? "-"}</span>
							</Col>

							{diagnosticHypothesis.pivot.recommended && (
								<Col>
									<span className="fw-bold">Uso de trombolíticos indicado? </span>
									<span>{useOfThrombolyticsMessage}</span>
								</Col>
							)}
						</Row>
					);
				})}

				<Col>
					<span className="fw-bold">Descrição da cena: </span>
					<span>{sceneRegister?.scene_description ?? "-"}</span>
				</Col>

				<Row>
					<Col lg={2} sm={3}>
						<span className="fw-bold">CID: </span>
						<span>{sceneRegister?.icd_code ?? "-"}</span>
					</Col>

					<Col lg={2} sm={3}>
						<span className="fw-bold">Tipo de vítima: </span>
						<span>{sceneRegister?.victim_type ?? "-"}</span>
					</Col>

					<Col lg={3} sm={5} className="ms-10">
						<span className="fw-bold">Equipamento de segurança: </span>
						<span>{sceneRegister?.security_equipment ?? "-"}</span>
					</Col>
				</Row>
			</Row>

			<Separator />

			<div>
				<h2 className="mb-4">Sinais vitais</h2>

				{sceneRegister?.metrics?.map((metric, index, array) => {
					const formattedTemperature = metric.temperature
						?.replace(".", ",")
						.replace(/(^0+(?=\d))|(,?0+$)/g, "");

					return (
						<div key={`scene-register-metric-${index}`}>
							<Row className="mb-2">
								<Col lg={2} sm={3}>
									<span className="fw-bold">Data: </span>
									<span>
										{dayjs(metric.start_at).format("DD/MM/YY [às] H:mm")}
									</span>
								</Col>

								<Col lg={2} sm={3}>
									<span className="fw-bold">Pressão arterial: </span>
									<span>
										{metric.systolic_blood_pressure &&
										metric.diastolic_blood_pressure
											? `${metric.systolic_blood_pressure}x${metric.diastolic_blood_pressure}`
											: "-"}
									</span>
								</Col>

								<Col lg={2} sm={3}>
									<span className="fw-bold">Frequência cardíaca: </span>
									<span>{metric.heart_rate ?? "-"}</span>
								</Col>

								<Col>
									<span className="fw-bold">Frequência respiratória: </span>
									<span>{metric.respiratory_frequency ?? "-"}</span>
								</Col>
							</Row>

							<Row>
								<Col lg={2} sm={3}>
									<span className="fw-bold">DX: </span>
									<span>{metric.diagnostic_evaluation ?? "-"}</span>
								</Col>

								<Col lg={2} sm={3}>
									<span className="fw-bold">Temperatura: </span>
									<span>{formattedTemperature ?? "-"}</span>
								</Col>

								<Col lg={2} sm={3}>
									<span className="fw-bold">Saturação: </span>
									<span>{metric.oxygen_saturation ?? "-"}</span>
								</Col>

								<Col>
									<span className="fw-bold">Glasglow: </span>
									<span>{metric.glasgow_scale ?? "-"}</span>
								</Col>
							</Row>

							{index !== array.length - 1 && <Separator className="py-4 w-75" />}
						</div>
					);
				})}
			</div>

			<Row className="mt-5">
				<Col sm={3}>
					<span className="fw-bold">Sangramento: </span>
					<span>
						{BleedingTypeMessages[
							sceneRegister?.bleeding_type_id as KeyOfBleedingTypeMessages
						] ?? "-"}
					</span>
				</Col>

				<Col sm={2}>
					<span className="fw-bold">Sudorese: </span>
					<span>
						{SweatingTypeMessages[
							sceneRegister?.sweating_type_id as KeyOfSweatingTypeMessages
						] ?? "-"}
					</span>
				</Col>

				<Col lg={2} sm={3}>
					<span className="fw-bold">Condição de pele: </span>
					<span>
						{SkinColorationTypeMessages[
							sceneRegister?.skin_coloration_type_id as KeyOfSkinColorationTypeMessages
						] ?? "-"}
					</span>
				</Col>

				<Col>
					<span className="fw-bold">Prioridade: </span>
					<span>
						{PriorityTypeMessages[
							sceneRegister?.priority_type_id as KeyOfPriorityTypeMessages
						] ?? "-"}
					</span>
				</Col>
			</Row>

			<Col className="mt-2">
				<span className="fw-bold">Observações: </span>
				<span>{sceneRegister?.observations ?? "-"}</span>
			</Col>

			<Separator />

			<Row>
				<Col
					xs={5}
					className="d-flex flex-column gap-2 border-block-end border-end border-end-dashed border-gray-500 me-4"
				>
					<h2 className="mb-4">Ferimentos</h2>

					{sceneRegister?.wounds?.map((wound, index, array) => (
						<div key={`scene-register-wound-${index}`}>
							<Row>
								<Col lg={4}>
									<span className="fw-bold">Ferimento: </span>
									<span>
										{WoundTypeMessages[
											wound.wound_type_id as KeyOfWoundTypeMessages
										] ?? "-"}
									</span>
								</Col>

								<Col>
									<span className="fw-bold">Local do ferimento: </span>
									<span>
										{WoundPlaceTypeMessages[
											wound.wound_place_type_id as KeyOfWoundPlaceTypeMessages
										] ?? "-"}
									</span>
								</Col>
							</Row>

							{index !== array.length - 1 && <Separator className="py-4 w-75" />}
						</div>
					))}
				</Col>

				<Col>
					<h2 className="mb-4">Procedimentos</h2>

					{sceneRegister?.procedures?.map((procedure, index) => (
						<div key={`scene-register-procedure-${index}`}>
							<Row>
								<Col lg={4}>
									<span className="fw-bold">Procedimento: </span>
									<span>{procedure.procedure_label ?? "-"}</span>
								</Col>

								<Col>
									<span className="fw-bold">Observação: </span>
									<span>{procedure.observations ?? "-"}</span>
								</Col>
							</Row>
						</div>
					))}
				</Col>
			</Row>

			<Separator />

			<Row>
				<Col
					xs={5}
					className="d-flex flex-column gap-2 border-block-end border-end border-end-dashed border-gray-500 me-4"
				>
					<h2 className="mb-4">Medicamentos</h2>

					{sceneRegister?.medicines?.map((medicine, index, array) => (
						<div key={`scene-register-medicine-${index}`}>
							<Col lg={7}>
								<span className="fw-bold">Medicamento - unidade: </span>
								<span>{medicine.medicine_label ?? "-"}</span>
							</Col>

							{index !== array.length - 1 && <Separator className="py-4 w-75" />}
						</div>
					))}
				</Col>

				<Col>
					<h2 className="mb-4">Antecedentes</h2>

					<Row>
						<Col lg={4}>
							<span className="fw-bold">Antecedentes: </span>
							<span>
								{sceneRegister?.antecedents_types.length > 0 ? antecedentType : "-"}
							</span>
						</Col>

						<Col>
							<span className="fw-bold">Alergia: </span>
							<span>{sceneRegister?.allergy ?? "-"}</span>
						</Col>
					</Row>
				</Col>
			</Row>

			<Separator />

			<div>
				<h2 className="mb-4">Apoio</h2>

				<Row>
					<Col lg={4}>
						<span className="fw-bold">Necessita de apoio?: </span>
						<span>{sceneRegister?.support_needed ? "Sim" : "Não"}</span>
					</Col>

					{sceneRegister?.support_needed && (
						<Col>
							<span className="fw-bold">Tipo de apoio: </span>
							<span>{renderSupportNeededDescription()}</span>
						</Col>
					)}
				</Row>
			</div>

			<Separator />

			<div>
				<h2 className="mb-4">Conduta</h2>

				<Row>
					<span className="fw-bold mb-2">
						{createSentence(sceneRegister?.conduct_types ?? [])}
					</span>

					<Col lg={4}>
						<span className="fw-bold ">Tipo de vaga: </span>
						<span>
							{VacancyTypeMessages[sceneRegister?.vacancy_type_id as VacancyType] ??
								"-"}
						</span>
					</Col>

					{sceneRegister?.conducts?.map((conduct, index, array) => {
						return (
							<div key={`scene-register-conduct-${index}`}>
								<Row>
									<Col sm={4}>
										<span className="fw-bold">Conduta: </span>
										<span>
											{ConductMessages[
												conduct.conduct_id as KeyOfConductMessages
											] ?? "-"}
										</span>
									</Col>

									<Col>
										<span className="fw-bold">Descrição: </span>
										<span>{conduct.conduct_description ?? "-"}</span>
									</Col>
								</Row>

								{index !== array.length - 1 && <Separator className="py-4 w-75" />}
							</div>
						);
					})}
				</Row>
			</div>

			{sceneRegister?.closed && (
				<>
					<Separator />

					<span className="fw-bold">Encerramento por {closingTypeMessage}</span>

					{sceneRegister?.closing_type_id === ClosingType.Death && (
						<Row className="mt-5">
							<Col lg={2}>
								<span className="fw-bold">Data e hora: </span>
								<span>
									{sceneRegister?.death_at
										? dayjs(sceneRegister?.death_at).format(
												"DD/MM/YY [às] H:mm"
										  )
										: "-"}
								</span>
							</Col>

							<Col lg={2}>
								<span className="fw-bold">Tipo de óbito: </span>
								<span>{sceneRegister?.death_type ?? "-"}</span>
							</Col>

							<Col lg={4}>
								<span className="fw-bold">Nome do profissional: </span>
								<span>{sceneRegister?.death_professional ?? "-"}</span>
							</Col>

							<Col>
								<span className="fw-bold">Número do registro: </span>
								<span>
									{sceneRegister?.death_professional_registration_number ?? "-"}
								</span>
							</Col>
						</Row>
					)}
				</>
			)}
		</div>
	);
}
