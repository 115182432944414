export enum StreetType {
	Street = 0,
	AVENUE = 1,
	PLATTER = 2,
	ROAD = 3,
	TREE_LINED_AVENUE = 4,
}

export const StreetTypeMessages = {
	[StreetType.Street]: "Rua",
	[StreetType.AVENUE]: "Avenida",
	[StreetType.PLATTER]: "Travessa",
	[StreetType.ROAD]: "Rodovia",
	[StreetType.TREE_LINED_AVENUE]: "Alameda",
};
