import axios, { AxiosError, AxiosRequestConfig } from "axios";

import { useAuth } from "@/modules/auth";

export function useApi() {
	const { auth, setTimer, timerIntervalId, logout } = useAuth();

	const baseURL = process.env.REACT_APP_API_URL || "/api";
	const LOGIN_URL = `${baseURL}/auth/check-credentials`;

	function requestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
		if (auth && auth.access_token) {
			if (timerIntervalId.current) {
				clearTimeout(timerIntervalId.current);
				setTimer(0);
			}

			if (!config.headers) {
				config.headers = {};
			}

			config.headers.Authorization = `Bearer ${auth.access_token}`;
		}

		return config;
	}

	async function responseInterceptor(error: AxiosError) {
		const originalConfig = error.config;

		if (originalConfig?.url !== LOGIN_URL && error.response) {
			if (error.response.status === 401) {
				logout();
			}
		}

		return Promise.reject(error);
	}

	function createApi(config?: AxiosRequestConfig) {
		const api = axios.create(config);

		api.interceptors.request.use(requestInterceptor, (err: any) => Promise.reject(err));
		api.interceptors.response.use((response) => response, responseInterceptor);

		return api;
	}

	const api = createApi({ baseURL });

	function getUserByToken() {
		return api.get<User>("/me");
	}

	return { getUserByToken, api };
}
