export enum RegisterType {
	MedicalRegulation = 1,
	AttendanceObservation = 2,
	RadioOperationObservation = 3,
}

export const RegisterTypeMessages = {
	[RegisterType.MedicalRegulation]: "Regulação",
	[RegisterType.AttendanceObservation]: "Obs. Geral",
	[RegisterType.RadioOperationObservation]: "Obs. Rádio Operação",
};
