import { TimeUnit } from "@/enums/TimeUnit";
import dayjs from "dayjs";

export const sceneRegisterInitialValues = {
	attendanceType: "",
	patient: {
		name: "",
		gender_code: "",
		age: "",
		time_unit_id: String(TimeUnit.Years),
	},

	diagnostic_hypotheses: [
		{
			nature_type_id: "",
			nature_type_label: "",
			diagnostic_hypothesis_id: "",
			diagnostic_hypothesis_label: "",
			recommended: "",
			applied: "",
		},
	],

	scene_description: "",

	icd_code: "",
	icd_code_label: "",

	victim_type: "",
	security_equipment: "",
	metrics: [
		{
			start_at: dayjs().format("YYYY-MM-DD[T]HH:mm"),
			diagnostic_evaluation: "",
			systolic_blood_pressure: "",
			diastolic_blood_pressure: "",
			heart_rate: "",
			respiratory_frequency: "",
			temperature: "",
			oxygen_saturation: "",
			glasgow_scale: "",
		},
	],
	bleeding_type_id: "",
	sweating_type_id: "",
	skin_coloration_type_id: "",
	priority_type_id: "",
	observations: "",
	wounds: [
		{
			wound_type_id: "",
			wound_place_type_id: "",
		},
	],
	procedures: [],
	medicines: [],
	antecedents: [],
	allergy: "",
	support_needed: "",
	support_needed_description: [] as string[],
	conduct_types: [] as string[],

	unit_destination_id: "",
	unit_destination_label: "",

	conducts: [
		{
			conduct_id: "",
			conduct_description: "",
		},
	],
	vacancy_type_id: "",

	closed: false,
	closing_type_id: "",
	closed_justification: "",

	death_at: "",
	death_type: "",
	death_professional: "",
	death_professional_registration_number: "",
};
