import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { indicatedThrombolyticsMessage } from "@/utils/indicatedThrombolyticsMessage";
import { createSentence } from "@/utils/createSentence";
import { NatureTypeMessages } from "@/enums/NatureType";
import { ActionTypeMessages } from "@/enums/ActionType";

import { FormattedBadge } from "@/components/Badges/FormattedBadge";

type LastMedicalRegulationDataProps = {
	medicalRegulation?: MedicalRegulation;
};

type KeyOfNatureTypeMessages = keyof typeof NatureTypeMessages;
type keyOfActionTypeMessages = keyof typeof ActionTypeMessages;

export function LastMedicalRegulationData({ medicalRegulation }: LastMedicalRegulationDataProps) {
	const actionTypeMessage =
		ActionTypeMessages[medicalRegulation?.action_type_id as keyOfActionTypeMessages];

	return (
		<div>
			<h4 className="mb-4">Última regulação médica</h4>

			{medicalRegulation?.diagnostic_hypotheses?.map((diagnosticHypothesis, index) => {
				const natureTypeMessage =
					NatureTypeMessages[
						diagnosticHypothesis.pivot.nature_type_id as KeyOfNatureTypeMessages
					];

				const useOfThrombolyticsMessage = indicatedThrombolyticsMessage(
					diagnosticHypothesis.pivot.recommended,
					diagnosticHypothesis.pivot.applied
				);

				return (
					<Col key={`diagnostic-hypothesis-${index}`}>
						<Row>
							<Col lg={5}>
								<span className="fw-bold">Natureza: </span>
								<span>{natureTypeMessage}</span>
							</Col>

							<Col>
								<span className="fw-bold">HD: </span>
								<span>{diagnosticHypothesis.name}</span>
							</Col>
						</Row>

						{diagnosticHypothesis.pivot.recommended && (
							<Col>
								<span className="fw-bold">Uso de trombolíticos indicado? </span>
								<span>{useOfThrombolyticsMessage}</span>
							</Col>
						)}
					</Col>
				);
			})}

			<Row>
				<Col lg={5}>
					<span className="fw-bold">Prioridade: </span>
					{medicalRegulation?.priority_type_id ? (
						<FormattedBadge attribute={medicalRegulation?.priority_type_id} />
					) : (
						"Sem prioridade"
					)}
				</Col>

				<Col>
					<span className="fw-bold">Código: </span>
					{medicalRegulation?.vehicle_movement_code_id ? (
						<FormattedBadge
							isVehicleMovementCode
							attribute={medicalRegulation?.vehicle_movement_code_id}
						/>
					) : (
						"Sem código de movimento"
					)}
				</Col>
			</Row>

			<span className="fw-bold">
				Ação <span className="text-lowercase">{actionTypeMessage}</span>
				{medicalRegulation?.action_details.length ? ": " : ""}
			</span>

			{createSentence(medicalRegulation?.action_details ?? [])}
		</div>
	);
}
