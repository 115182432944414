export const vehicleStatusOptions = [
	{ value: 1, label: "Ativo" },
	{ value: 2, label: "Em manutenção" },
	{ value: 3, label: "Indisponível" },
	{ value: 6, label: "Baixada" },
];

export const mapVehicleStatusesOptions = [
	{ value: 1, label: "Ativo" },
	{ value: 2, label: "Em manutenção" },
	{ value: 3, label: "Indisponível" },
	{ value: 4, label: "Empenhada" },
	{ value: 5, label: "Solicitada" },
	{ value: 6, label: "Baixada" },
];

export const mapVehicleStatusesLimitedOptions = [
	{ value: 1, label: "Ativo" },
	{ value: 3, label: "Indisponível" },
	{ value: 4, label: "Empenhada" },
	{ value: 5, label: "Solicitada" },
];
