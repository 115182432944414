export function filterObject(data: GenericObject, wantedKeys: string[]): GenericObject {
	return Object.fromEntries(Object.entries(data).filter(([key]) => wantedKeys.includes(key)));
}

export function filterObjectExcluding(data: GenericObject, unwantedKeys: string[]): GenericObject {
	return Object.fromEntries(Object.entries(data).filter(([key]) => !unwantedKeys.includes(key)));
}

export function filterObjectReplacingNullWithEmptyString(data: GenericObject): GenericObject {
	return Object.fromEntries(
		Object.entries(data).map(([key, value]) => {
			if (value === null) {
				return [key, ""];
			}

			return [key, value];
		})
	);
}
