import {
	FaAddressCard,
	FaAmbulance,
	FaFilter,
	FaUser,
	FaStethoscope,
	FaKey,
	FaMapMarkerAlt,
	FaMap,
	FaUserCog,
} from "react-icons/fa";
import {
	FaClipboardList,
	FaGear,
	FaBuilding,
	FaHospital,
	FaFileLines,
	FaUserDoctor,
} from "react-icons/fa6";
import { MdSpaceDashboard, MdEditDocument } from "react-icons/md";
import { BsChatSquareTextFill } from "react-icons/bs";
import { FaMapLocationDot } from "react-icons/fa6";
import { RiHistoryFill } from "react-icons/ri";

import { Role } from "@/enums/Role";
import { useCanAccess } from "@/hooks/useCanAccess";

import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";

import "./styles.scss";

export function AsideMenuMain() {
	const { can } = useCanAccess();

	const {
		admin,
		manager,
		tarm,
		medic,
		radioOperator,
		attendanceOrAmbulanceTeam,
		fleetControl,
		reportsConsulting,
		teamLeader,
		coordinator,
	} = Role;

	return (
		<>
			<div className="d-flex flex-column gap-2">
				{can([manager, admin]) && (
					<AsideMenuItem
						to="/dashboard"
						icon={<MdSpaceDashboard />}
						title="Dashboard geral"
					/>
				)}

				{can([admin]) && (
					<AsideMenuItem
						to="/dashboard/admin"
						icon={<MdSpaceDashboard />}
						title="Dashboard"
					/>
				)}

				{can([admin, tarm, medic, attendanceOrAmbulanceTeam, teamLeader]) && (
					<AsideMenuItem
						to="/ocorrencias"
						icon={<FaClipboardList />}
						title="Ocorrências"
					/>
				)}

				{can([admin, radioOperator, fleetControl]) && (
					<AsideMenuItemWithSub
						to="/controle-de-frota"
						icon={<FaAddressCard />}
						title="Controle de frota"
					>
						{can([admin, radioOperator]) && (
							<AsideMenuItem
								to="/controle-de-frota/ocorrencias"
								icon={<FaClipboardList />}
								title="Ocorrências"
							/>
						)}

						{can([admin, radioOperator, fleetControl]) && (
							<AsideMenuItem
								to="/controle-de-frota/viaturas"
								icon={<FaAmbulance />}
								title="Viaturas"
							/>
						)}

						{can([]) && (
							<AsideMenuItem
								to="/controle-de-frota/equipamentos"
								icon={<FaStethoscope />}
								title="Equipamentos"
							/>
						)}
					</AsideMenuItemWithSub>
				)}

				{can([admin, radioOperator, fleetControl, manager]) && (
					<AsideMenuItemWithSub
						to="/rastreamento"
						icon={<FaMapMarkerAlt />}
						title="Rastreamento"
					>
						<AsideMenuItem
							to="/rastreamento/mapa-de-viaturas"
							icon={<FaMap />}
							title="Mapa de viaturas"
						/>
					</AsideMenuItemWithSub>
				)}

				{can([admin]) && (
					<AsideMenuItem to="/usuarios" icon={<FaUser />} title="Usuários" />
				)}

				{can([admin, coordinator]) && (
					<AsideMenuItem
						to="/profissionais"
						icon={<FaUserDoctor />}
						title="Profissionais"
					/>
				)}

				{can([admin]) && <AsideMenuItem to="/bases" icon={<FaBuilding />} title="Bases" />}

				{can([admin, tarm, radioOperator, medic]) && (
					<AsideMenuItem
						to="/unidades"
						icon={<FaHospital />}
						title="Unidades hospitalares"
					/>
				)}

				<AsideMenuItemWithSub to="/relatorios" icon={<FaFileLines />} title="Relatórios">
					{can([
						admin,
						tarm,
						medic,
						radioOperator,
						attendanceOrAmbulanceTeam,
						reportsConsulting,
						teamLeader,
						coordinator,
					]) && (
						<AsideMenuItem
							to="/relatorios/consultas"
							icon={<FaFilter />}
							title="Consulta de ocorrências"
						/>
					)}

					{can([admin, teamLeader, radioOperator]) && (
						<AsideMenuItem
							to="/relatorios/plantao"
							icon={<MdEditDocument />}
							title="Relatório de plantão"
						/>
					)}

					{can([admin, reportsConsulting, manager]) && (
						<AsideMenuItem to="/relatorios/bpa" icon={<MdEditDocument />} title="BPA" />
					)}

					<AsideMenuItem
						to="/relatorios/power-bi"
						icon={<MdEditDocument />}
						title="Power BI"
					/>
				</AsideMenuItemWithSub>

				{can([admin, manager]) && (
					<AsideMenuItemWithSub
						to="/configuracoes"
						icon={<FaGear />}
						title="Configurações"
					>
						{can([admin]) && (
							<AsideMenuItem
								to="/configuracoes/gerenciamento"
								icon={<FaUserCog />}
								title="Gerenciamento"
							/>
						)}

						{can([admin]) && (
							<AsideMenuItem
								to="/configuracoes/grupos-regionais"
								icon={<FaMapLocationDot />}
								title="Grupos regionais"
							/>
						)}

						{can([admin]) && (
							<AsideMenuItem
								to="/configuracoes/recados"
								icon={<BsChatSquareTextFill />}
								title="Recados"
							/>
						)}

						{can([manager]) && (
							<AsideMenuItem
								to="/configuracoes/token"
								icon={<FaKey />}
								title="Token"
							/>
						)}
						{can([admin, manager]) && (
							<AsideMenuItem
								to="/configuracoes/historico-de-acessos"
								icon={<RiHistoryFill />}
								title="Histórico de acessos"
							/>
						)}
					</AsideMenuItemWithSub>
				)}
			</div>
		</>
	);
}
