import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

import InfiniteScroll from "react-infinite-scroll-component";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import { useApi } from "@/hooks/useApi";
import { createSentence } from "@/utils/createSentence";
import { indicatedThrombolyticsMessage } from "@/utils/indicatedThrombolyticsMessage";
import { NatureTypeMessages } from "@/enums/NatureType";
import { ActionTypeMessages } from "@/enums/ActionType";
import { RegisterType, RegisterTypeMessages } from "@/enums/RegisterType";

import { Timeline } from "@/components/Timeline";
import { Separator } from "@/components/Separator";
import { FormattedBadge } from "@/components/Badges/FormattedBadge";

type Props = {
	attendanceId?: string;
};

type keyOfNatureTypeMessages = keyof typeof NatureTypeMessages;
type keyOfActionTypeMessages = keyof typeof ActionTypeMessages;

export function HistoryRegister({ attendanceId }: Props) {
	const [historyRegister, setHistoryRegister] = useState<PaginatedAttendanceRecord>(
		{} as PaginatedAttendanceRecord
	);
	const [isLoading, setIsLoading] = useState(false);
	const isFirstRender = useRef(true);
	const { api } = useApi();

	const historyRegisterLength = historyRegister.results?.length;

	async function fetchHistoryRegister() {
		try {
			if (isFirstRender.current) {
				setIsLoading(true);
				isFirstRender.current = false;
			}

			const { data } = await api.get<PaginatedAttendanceRecord>(
				historyRegister.links?.next || `/attendance-record-history/${attendanceId}`
			);

			const oldResults = historyRegister?.results || [];
			const newResults = oldResults.concat(data?.results);

			setHistoryRegister({ ...data, results: newResults });
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		if (attendanceId) {
			fetchHistoryRegister();
		}
	}, [attendanceId]);

	return (
		<>
			{isLoading ? (
				<div className="d-flex flex-center vh-80">
					<Spinner
						variant="samu"
						animation="border"
						style={{ width: "4rem", height: "4rem" }}
					/>
				</div>
			) : (
				<InfiniteScroll
					style={{ overflow: "" }}
					dataLength={historyRegisterLength || 0}
					next={fetchHistoryRegister}
					hasMore={historyRegister.meta?.current_page < historyRegister.meta?.last_page}
					scrollableTarget="history-register-modal-body"
					loader={
						<div className="text-center my-3">
							<Spinner animation={"border"} variant="samu" />
						</div>
					}
				>
					<Timeline>
						{historyRegister.results?.map((result, index) => {
							function generateTimelineBadgeBackground(type: RegisterType) {
								if (type === RegisterType.AttendanceObservation) {
									return "color-orange";
								}

								if (type === RegisterType.RadioOperationObservation) {
									return "color-yellow";
								}

								return "color-dark-samu";
							}

							const actionTypeMessage =
								ActionTypeMessages[
									result.action_type_id as keyOfActionTypeMessages
								];

							const registerTypeMessage =
								RegisterTypeMessages[result.type as RegisterType];

							return (
								<Timeline.Item key={`register-${index}`}>
									<Timeline.VerticalLine />

									<Timeline.Content
										timeLineBadgeIconClassNames={generateTimelineBadgeBackground(
											result.type
										)}
									>
										{index > 0 && <Separator className="pb-8 pt-3" />}

										<Row>
											<Col lg={8} className="pb-5">
												<div>
													<span className="fw-bold">
														{result.type ===
														RegisterType.MedicalRegulation
															? "Méd. reg."
															: "Usuário"}
														:
													</span>{" "}
													Dr(a). {result.responsible_professional} CRM{" "}
													{result.responsible_professional_council_number}
												</div>

												<div className="break-word">
													<span className="fw-bold">
														{registerTypeMessage}:{" "}
													</span>

													{result.note}
												</div>

												<div>
													{createSentence(
														result.supporting_organizations ?? []
													)}
												</div>
											</Col>

											<Col lg={4}>
												<div>
													<span className="fw-bold">Data: </span>
													{dayjs(result.datetime).format(
														"DD/MM/YYYY [às] HH:mm"
													)}
												</div>

												{result.type === RegisterType.MedicalRegulation && (
													<>
														{result.diagnostic_hypotheses?.map(
															(diagnosticHypothesis) => {
																const natureTypeMessage =
																	NatureTypeMessages[
																		diagnosticHypothesis.pivot
																			.nature_type_id as keyOfNatureTypeMessages
																	];

																const useOfThrombolyticsMessage =
																	indicatedThrombolyticsMessage(
																		diagnosticHypothesis.pivot
																			.recommended,
																		diagnosticHypothesis.pivot
																			.applied
																	);

																return (
																	<Col
																		key={`diagnostic-hypothesis-${index}`}
																	>
																		<Row>
																			<Col>
																				<span className="fw-bold">
																					Natureza:{" "}
																				</span>
																				{natureTypeMessage}
																			</Col>

																			<Col>
																				<span className="fw-bold">
																					HD:{" "}
																				</span>
																				{
																					diagnosticHypothesis.name
																				}
																			</Col>
																		</Row>

																		{diagnosticHypothesis.pivot
																			.recommended && (
																			<Col>
																				<span className="fw-bold">
																					Uso de
																					trombolíticos
																					indicado?{" "}
																				</span>
																				{
																					useOfThrombolyticsMessage
																				}
																			</Col>
																		)}
																	</Col>
																);
															}
														)}

														<Row>
															<Col>
																<span className="fw-bold">
																	Prioridade:{" "}
																</span>
																{result.priority_type_id ? (
																	<FormattedBadge
																		attribute={
																			result.priority_type_id
																		}
																	/>
																) : (
																	"Sem prioridade"
																)}
															</Col>

															<Col>
																<span className="fw-bold">
																	Código:{" "}
																</span>
																{result.vehicle_movement_code_id ? (
																	<FormattedBadge
																		isVehicleMovementCode
																		attribute={
																			result.vehicle_movement_code_id
																		}
																	/>
																) : (
																	"Sem código de movimento"
																)}
															</Col>
														</Row>

														<span className="fw-bold">
															Ação{" "}
															<span className="text-lowercase">
																{actionTypeMessage}
															</span>
															{result.action_details.length
																? ": "
																: ""}
														</span>
														{createSentence(
															result.action_details ?? []
														)}
													</>
												)}
											</Col>
										</Row>
									</Timeline.Content>
								</Timeline.Item>
							);
						})}
					</Timeline>
				</InfiniteScroll>
			)}
			{!isLoading && !historyRegisterLength && (
				<h2 className="text-center mt-12 mb-0">Sem histórico de registros</h2>
			)}
		</>
	);
}
