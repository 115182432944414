import { useRef } from "react";

import { useApi } from "@/hooks/useApi";
import { createSentence } from "@/utils/createSentence";

import { ReactSelect, ReactSelectProps, Additional } from "@/components/Selects/ReactSelect";

type AbleUsersSelectProps = ReactSelectProps & {
	roles: string[];
	filterUrc?: boolean;
};

export function AbleUsersSelect({ roles, filterUrc = false, ...rest }: AbleUsersSelectProps) {
	const abortControllerRef = useRef(new AbortController());
	const { api } = useApi();

	async function fetchAbleUsers(page: number, search: string) {
		try {
			abortControllerRef.current.abort();
			abortControllerRef.current = new AbortController();

			const { data } = await api.get("/users/able-professionals", {
				params: {
					page,
					roles,
					search,
					filter_urc: Number(filterUrc),
				},
				signal: abortControllerRef.current.signal,
			});

			return data as PaginatedUser;
		} catch (error) {
			console.log(error);
			return {} as PaginatedUser;
		}
	}

	const loadAsyncUsersOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, _: User, { page }: Additional) {
			const users = await fetchAbleUsers(page, search);

			return {
				options: users?.results.map((user) => ({
					value: user.id,
					label: `${user.name} - ${createSentence(
						user.urgency_regulation_centers?.map((urc) => urc.name) || []
					)}`,
				})),
				hasMore: users.meta?.current_page < users.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	return <ReactSelect {...loadAsyncUsersOptionsProps} {...rest} />;
}
