import { useEffect, useState } from "react";
import { validatePhone } from "validations-br";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";

import { ImArrowLeft2 } from "react-icons/im";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { TimeUnit } from "@/enums/TimeUnit";
import { onlyNumbers } from "@/utils/onlyNumbers";
import { attendanceInitialValues } from "@/utils/initialValues/attendance";
import { AttendanceType, AttendanceTypeMessages } from "@/enums/AttendanceType";
import { attendanceValidationSchema } from "@/utils/validation/attendanceValidationSchema";

import { SecondaryAttendance } from "@/components/Attendance/NewAttendance/SecondaryAttendance";
import { PrimaryAttendance } from "@/components/Attendance/NewAttendance/PrimaryAttendance";
import { OthersAttendances } from "@/components/Attendance/NewAttendance/OtherAttendances";
import { RequesterAndPatientData } from "./RequesterAndPatientData";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { FormRadio } from "@/components/Radios/FormRadio";
import { Separator } from "@/components/Separator";

type CheckPrankCallResponse = {
	hasPrankCallHistory: boolean;
};

export function NewAttendance() {
	const initialVictimData = {
		name: "",
		sex: "",
		age: "",
		timeUnit: String(TimeUnit.Years),
		noIdentification: false,
	};
	const [victims, setVictims] = useState<Victim[]>([initialVictimData]);
	const { Swal, Toast, toastRequestErrors } = useSwal();
	const navigate = useNavigate();
	const { api } = useApi();

	const isAgeInvalid = victims.some(({ age, timeUnit }) => timeUnit === "3" && Number(age) > 140);

	const isFieldsInvalid = victims.some(
		({ sex, age, timeUnit }) => !sex.length || !age.length || isAgeInvalid || !timeUnit.length
	);

	const formik = useFormik({
		validationSchema: attendanceValidationSchema,
		initialValues: attendanceInitialValues,
		async onSubmit(values, { setSubmitting }) {
			try {
				const formattedData = {
					...values,
					patients: victims.map((victim) => ({
						name: victim.name,
						gender_code: victim.sex,
						age: Number(victim.age.replace(",", ".")).toFixed(),
						time_unit_id: victim.timeUnit,
					})),
				};

				if (values.ticket_type_id === String(AttendanceType.PrimaryOccurrence)) {
					await api.post("/ticket/primary-attendance", formattedData);
				} else if (values.ticket_type_id === String(AttendanceType.SecondaryOccurrence)) {
					await api.post("/ticket/secondary-attendance", formattedData);
				} else {
					await api.post("/ticket/other-attendance", formattedData);
				}

				Toast.fire({
					icon: "success",
					title: "Chamado registrado com sucesso!",
				});

				navigate("/ocorrencias");
			} catch (error: any) {
				console.log(error);

				toastRequestErrors(error.response.data.errors);

				setSubmitting(false);
			}
		},
	});

	const { values } = formik;

	const verifyAttendanceType =
		values.ticket_type_id === String(AttendanceType.PrimaryOccurrence) ||
		values.ticket_type_id === String(AttendanceType.SecondaryOccurrence);

	function verifyTicketType() {
		formik.setFieldValue("ticket_type_id", values.ticket_type_id);
		formik.setFieldValue("opening_at", values.opening_at);

		const attendanceType =
			AttendanceTypeMessages[Number(values.ticket_type_id) as AttendanceType];

		if (
			Number(values.ticket_type_id) > AttendanceType.SecondaryOccurrence &&
			values.requester.name === ""
		) {
			formik.setFieldValue("requester.name", `Não informado - ${attendanceType}`);
		}
	}

	function handleResetForm() {
		formik.resetForm();

		setTimeout(() => {
			formik.validateForm();
		});

		setVictims([initialVictimData]);

		verifyTicketType();
	}

	async function checkPrankCall(phoneNumber: string): Promise<boolean> {
		try {
			const { data } = await api.get<CheckPrankCallResponse>("/prank-call/check", {
				params: {
					search: phoneNumber,
				},
			});

			return data.hasPrankCallHistory;
		} catch (error) {
			console.error(error);
			return false;
		}
	}

	async function handleCheckPrankCall(value: string) {
		const parsedPhoneNumber = onlyNumbers(value);

		if (!parsedPhoneNumber) {
			return;
		}

		const isValidPhoneNumber = validatePhone(parsedPhoneNumber ?? "");

		if (!isValidPhoneNumber) {
			return;
		}

		const hasPrankCallHistory = await checkPrankCall(parsedPhoneNumber);

		if (!hasPrankCallHistory) {
			return;
		}

		Swal.fire({
			title: "Existem registros de Trote para	o contato adicionado.",
			icon: "warning",
			cancelButtonText: "Fechar",
			showCancelButton: true,
			showConfirmButton: false,
			timerProgressBar: true,
			timer: 5000,
		});
	}

	useEffect(() => {
		verifyTicketType();
	}, [values.ticket_type_id]);

	useEffect(() => {
		formik.setFieldValue("number_of_victims", 1);

		setVictims([victims[0]]);
	}, [values.multiple_victims]);

	useEffect(() => {
		if (victims.length > Number(values.number_of_victims)) {
			formik.setFieldValue("number_of_victims", victims.length);
		}
	}, [victims]);

	useEffect(() => {
		formik.setFieldValue("opening_at", dayjs().format());
	}, []);

	useEffect(() => {
		formik.validateForm();
	}, [values.city_id, values.unit_origin_id, values.unit_destination_id]);

	useEffect(() => {
		const primaryPhone = values.requester.primary_phone;

		handleCheckPrankCall(primaryPhone);
	}, [values.requester.primary_phone]);

	useEffect(() => {
		const secondaryPhone = values.requester.secondary_phone;

		handleCheckPrankCall(secondaryPhone);
	}, [values.requester.secondary_phone]);

	return (
		<Card>
			<Card.Header>
				<div className="d-flex justify-content-between align-items-center w-100 default-form-card-header">
					<div>{dayjs().format("[Aberto em] DD/MM/YYYY [às] HH:mm[hr]")}</div>

					<h2 className="m-0">Nova ocorrência</h2>

					<div>
						<button
							className="d-flex align-items-center btn button-bg-light-color-gray-400 gap-2 w-100px"
							onClick={() => navigate(-1)}
						>
							<ImArrowLeft2 />
							Voltar
						</button>
					</div>
				</div>
			</Card.Header>

			<Card.Body>
				<Form onSubmit={formik.handleSubmit}>
					<FormGroupRadio
						label="Tipo de chamado"
						labelClassName="fs-4 fw-bold"
						requestedValue={values.ticket_type_id}
						{...formik.getFieldProps("ticket_type_id")}
						inline
					>
						<FormRadio label="Ocorrência primária" value="1" id="primary-attendance" />
						<FormRadio
							label="Ocorrência secundária"
							value="2"
							id="secondary-attendance"
						/>
						<FormRadio label="Trote" value="3" id="prank-call" />
						<FormRadio label="Informação" value="4" id="information" />
						<FormRadio label="Engano" value="5" id="mistake" />
						<FormRadio label="Queda da ligação" value="6" id="connection-drop" />
						<FormRadio
							label="Contato com equipe SAMU"
							value="7"
							id="contact-with-EMS-team"
						/>
					</FormGroupRadio>

					<Separator />

					<RequesterAndPatientData
						formik={formik}
						initialVictimData={initialVictimData}
						victims={victims}
						setVictims={setVictims}
					/>

					{values.ticket_type_id === String(AttendanceType.PrimaryOccurrence) && (
						<PrimaryAttendance formik={formik} />
					)}

					{values.ticket_type_id === String(AttendanceType.SecondaryOccurrence) && (
						<SecondaryAttendance formik={formik} />
					)}

					{values.ticket_type_id >= String(AttendanceType.PrankCall) && (
						<OthersAttendances formik={formik} />
					)}

					<div className="d-flex justify-content-center gap-2 mt-16">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleResetForm}
						>
							Limpar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={
								formik.isSubmitting ||
								!formik.isValid ||
								(verifyAttendanceType && isFieldsInvalid)
							}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : (
								"Registrar"
							)}
						</button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
}
