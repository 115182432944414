import { useEffect } from "react";
import { useFormik } from "formik";

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Role } from "@/enums/Role";
import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { useSwal } from "@/hooks/useSwal";

import { unmodifiableVehicleStatuses } from "@/enums/VehicleStatus";
import { vehiclesInitialValues } from "@/utils/initialValues/vehicles";
import { vehicleRegistrationSchema } from "@/utils/validation/vehicleRegistrationSchema";
import { createSentence } from "@/utils/createSentence";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { BasesSelect } from "@/components/Selects/BasesSelect";

type Props = {
	showVehicleRegistrationModal: boolean;
	handleCloseVehicleRegistrationModal: () => void;
	vehicle?: Vehicle;
	fetchVehicles: (pageToUse?: number) => void;
};

export function VehicleRegistrationModal({
	showVehicleRegistrationModal,
	handleCloseVehicleRegistrationModal,
	vehicle,
	fetchVehicles,
}: Props) {
	const { Toast, toastRequestErrors } = useSwal();
	const { currentAuth } = useAuth();
	const { api } = useApi();
	const { Swal } = useSwal();

	const vehicleLength = vehicle && Object.entries(vehicle).length;

	const isSolicitedOrCommitted =
		vehicle &&
		unmodifiableVehicleStatuses.includes(
			vehicle?.latest_vehicle_status_history?.vehicle_status_id
		);

	const formik = useFormik({
		validationSchema: vehicleRegistrationSchema,
		initialValues: vehiclesInitialValues,
		async onSubmit(values, { setSubmitting }) {
			const method = vehicleLength ? "put" : "post";
			const route = vehicleLength ? `/vehicles/${vehicle?.id}` : "/vehicles";
			const newValues = {
				...values,
				base_id: values.base_id === "0" ? null : values.base_id,
			};

			try {
				const { data } = await api[method](route, newValues);

				if (!data.length) {
					Toast.fire({
						icon: "success",
						title: `Viatura ${
							vehicleLength ? "atualizada" : "cadastrada"
						} com sucesso!`,
					});
				}

				if (data.length) {
					unlinkVehicle(vehicle?.id, data, newValues);
				}

				handleCloseModal();
				fetchVehicles(1);
			} catch (error: any) {
				console.log(error);

				toastRequestErrors(error.response.data?.errors);

				setSubmitting(false);
			}
		},
	});

	const { values, errors } = formik;

	const selectedBase = values.base_id
		? {
				value: values.base_id,
				label: values.base_label,
		  }
		: {
				value: null,
				label: "Sem base",
		  };

	function handleCloseModal() {
		handleCloseVehicleRegistrationModal();

		formik.resetForm();
	}

	function handleChangeBase(option: ReactSelectOption) {
		formik.setValues({
			...values,
			base_id: String(option.value),
			base_label: String(option.label),
		});
	}

	async function unlinkVehicle(
		vehicleId: string | undefined,
		result: Vehicle[],
		values: NewVehicleValues
	) {
		const method = vehicleLength ? "put" : "post";
		const route = vehicleLength ? `/vehicles/force/${vehicleId}` : "/vehicles/force";
		const vehiclesCode = result?.map((vehicle) => vehicle.code);
		const vehiclesCodeSentence = createSentence(vehiclesCode ?? []);
		const baseLabel = values.base_label;
		const title = `Base ${baseLabel} está vinculada à(s) viatura(s) ${vehiclesCodeSentence}, deseja trocar de VTR?`;

		const { isConfirmed } = await Swal.fire({
			title: title,
			text: `A(s) VTR ${vehiclesCodeSentence} irá(ão) ficar sem base vinculada.`,
			icon: "warning",
			showConfirmButton: true,
			showCancelButton: true,
		});

		if (isConfirmed) {
			try {
				const { data } = await api[method](route, values);

				if (!Boolean(data.length)) {
					Toast.fire({
						icon: "success",
						title: `Viatura ${
							vehicleLength ? "atualizada" : "cadastrada"
						} com sucesso!`,
					});
				}

				if (Boolean(data.length)) {
					const vehiclesCodeCommitted = data?.map((vehicle: Vehicle) => vehicle.code);

					Toast.fire({
						icon: "warning",
						title: `Viatura(s) ${createSentence(
							vehiclesCodeCommitted ?? []
						)} está(ão) em operação, finalize-a(s) para desvincular essa(s) viatura(s)`,
						timer: 2800,
					});
				}

				handleCloseModal();
				fetchVehicles(1);
			} catch (error: any) {
				toastRequestErrors(error.response.data?.errors);
			} finally {
				fetchVehicles(1);
			}
		}
	}

	useEffect(() => {
		if (vehicleLength) {
			formik.setValues({
				...vehicle,
				tracking_device_imei: vehicle.tracking_device_imei || "",
				tracking_system_id: String(vehicle.tracking_system_id ?? ""),
				base_label: vehicle.base?.name || "",
			});
		}
	}, [vehicle]);

	useEffect(() => {
		formik.validateForm();
	}, [values.code, values.license_plate, values.tracking_device_imei, values.tracking_system_id]);

	return (
		<Modal size="lg" show={showVehicleRegistrationModal} onHide={handleCloseModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">{vehicleLength ? "Edição " : "Cadastro "} de viatura</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<div className="d-flex flex-column gap-4">
						{(currentAuth?.current_role.name !== Role.radioOperator ||
							!vehicleLength) && (
							<Row>
								<Col>
									<FormattedFormControl
										id="code"
										label="Código"
										labelClassName="fw-bold"
										{...formik.getFieldProps("code")}
										isInvalid={!!errors.code}
										validationMessage={errors.code}
										isRequired
									/>
								</Col>

								<Col>
									<FormattedFormControl
										id="license-plate"
										label="Placa"
										labelClassName="fw-bold"
										{...formik.getFieldProps("license_plate")}
										isInvalid={!!errors.license_plate}
										validationMessage={errors.license_plate}
										isRequired
									/>
								</Col>
							</Row>
						)}

						<Row>
							<Col>
								<Form.Label className="fw-bold" htmlFor="base-id">
									Base vinculada
								</Form.Label>

								<BasesSelect
									inputId="base-id"
									placeholder="Selecione uma base"
									canSelectNoBase
									value={selectedBase}
									onChange={(option) =>
										handleChangeBase(option as ReactSelectOption)
									}
									isDisabled={isSolicitedOrCommitted}
								/>
							</Col>

							{(currentAuth?.current_role.name !== Role.radioOperator ||
								!vehicleLength) && (
								<Col>
									<FormattedFormControl
										id="chassi"
										label="Chassi"
										labelClassName="fw-bold"
										{...formik.getFieldProps("chassis")}
									/>
								</Col>
							)}
						</Row>

						{(currentAuth?.current_role.name !== Role.radioOperator ||
							!vehicleLength) && (
							<Row>
								<Col>
									<FormattedFormControl
										id="tracking-device-imei"
										label="IMEI rastreador"
										labelClassName="fw-bold"
										{...formik.getFieldProps("tracking_device_imei")}
										isInvalid={!!errors.tracking_device_imei}
										validationMessage={errors.tracking_device_imei}
									/>
								</Col>

								<Col>
									<FormattedFormControl
										id="tracking-system-id"
										label="ID rastreamento"
										labelClassName="fw-bold"
										{...formik.getFieldProps("tracking_system_id")}
										isInvalid={!!errors.tracking_system_id}
										validationMessage={errors.tracking_system_id}
										mask={Number}
										isMaskedInput
									/>
								</Col>
							</Row>
						)}

						<div className="d-flex gap-2">
							<input
								type="checkbox"
								id="general-availability"
								checked={values.general_availability}
								{...formik.getFieldProps("general_availability")}
							/>

							<Form.Label htmlFor="general-availability" className="m-0">
								Disponibilidade geral
							</Form.Label>
						</div>
					</div>

					<div className="d-flex justify-content-center gap-2 mt-8">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : vehicleLength ? (
								"Salvar"
							) : (
								"Cadastrar"
							)}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
