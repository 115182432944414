import { useEffect, useRef, useState } from "react";
import classNames from "clsx";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";

import { FaPowerOff, FaPlus } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { useDebounce } from "@/hooks/useDebounce";
import { createSentence } from "@/utils/createSentence";
import { NatureTypeMessages } from "@/enums/NatureType";

import { DiagnosticHypothesisModal } from "@/components/Configurations/DiagnosticHypotheses/DiagnosticHypothesisModal";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { PaginationLinks } from "@/components/PaginationLinks";
import { Spinner } from "react-bootstrap";

type Props = {
	activeTab: string;
};

type KeyOfNatureTypeMessages = keyof typeof NatureTypeMessages;

export function DiagnosticHypotheses({ activeTab }: Props) {
	const [diagnosticHypotheses, setDiagnosticHypotheses] = useState<PaginatedDiagnosticHypothesis>(
		{} as PaginatedDiagnosticHypothesis
	);
	const [currentDiagnosticHypothesis, setCurrentDiagnosticHypothesis] =
		useState<DiagnosticHypothesis>({} as DiagnosticHypothesis);
	const [showDiagnosticHypothesisModal, setShowDiagnosticHypothesisModal] = useState(false);
	const [skeleton, setSkeleton] = useState(true);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);

	const forcePage = useRef(1);
	const isFirstRender = useRef(true);

	const { api } = useApi();
	const { Toast } = useSwal();
	const debouncedSearch = useDebounce(search, 500);

	const { results } = diagnosticHypotheses;

	async function fetchPaginatedDiagnosticHypotheses(pageToUse?: number) {
		setSkeleton(true);

		try {
			const { data } = await api.get(`/diagnostic-hypothesis`, {
				params: {
					load_nature_types: 1,
					search: debouncedSearch,
					per_page: 10,
					page: pageToUse || page,
				},
			});

			setDiagnosticHypotheses(data);
		} catch (error) {
			console.log(error);
		} finally {
			setSkeleton(false);
		}
	}

	async function handleChangeStatus(id: number) {
		setLoading(true);

		try {
			const { data } = await api.put<DiagnosticHypothesis>(
				`/diagnostic-hypothesis/change-status/${id}`
			);

			const isActive = data.is_active;

			Toast.fire({
				icon: "success",
				title: `Hipótese Diagnóstica ${isActive ? "ativada" : "desativada"} com sucesso!`,
			});

			fetchPaginatedDiagnosticHypotheses();
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchPaginatedDiagnosticHypotheses(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	function handleShowDiagnosticHypothesesModal(diagnosticHypothesis?: DiagnosticHypothesis) {
		setShowDiagnosticHypothesisModal(true);

		if (diagnosticHypothesis) {
			setCurrentDiagnosticHypothesis(diagnosticHypothesis);

			return;
		}

		setCurrentDiagnosticHypothesis({} as DiagnosticHypothesis);
	}

	function handleCloseDiagnosticHypothesisModal() {
		setShowDiagnosticHypothesisModal(false);
		setCurrentDiagnosticHypothesis({} as DiagnosticHypothesis);
	}

	useEffect(() => {
		forcePage.current = page;
		fetchPaginatedDiagnosticHypotheses();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		if (activeTab === "diagnostic-hypotheses") {
			fetchPaginatedDiagnosticHypotheses();
		}
	}, [debouncedSearch, activeTab]);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center my-6">
				<button
					className="d-flex align-items-center btn button-bg-samu-color-white gap-4"
					onClick={() => handleShowDiagnosticHypothesesModal()}
				>
					<FaPlus /> Cadastrar
				</button>

				<SearchFormControl
					placeholder="Pesquisar hipótese"
					onChange={(event) => setSearch(event.target.value)}
				/>
			</div>

			<Table responsive className="table-row-dashed table-row-gray-300">
				<thead>
					<tr className="fw-bold fs-6">
						<th>Hipótese diagnóstica</th>
						<th>Natureza</th>
						<th>Status</th>
						<th>Ações</th>
					</tr>
				</thead>

				<tbody>
					{skeleton ? (
						<TableBodySkeleton columns={4} />
					) : (
						<>
							{results?.map((result, index) => {
								const isActive = result.is_active;

								const natureTypes = createSentence(
									result?.nature_types?.map(
										(natureType) =>
											NatureTypeMessages[
												natureType.id as KeyOfNatureTypeMessages
											]
									) ?? []
								);

								return (
									<tr key={`diagnostic-hypothesis-${index}`}>
										<td className="align-middle">{result.name}</td>
										<td className="align-middle">
											{natureTypes ?? "Sem natureza"}
										</td>
										<td className="align-middle">
											<Badge
												className={classNames(
													"formatted-badge",
													isActive ? "badge-bg-green" : "bg-samu"
												)}
												pill
											>
												{isActive ? "Ativado" : "Desativado"}
											</Badge>
										</td>
										<td className="align-middle w-1px">
											<div className="d-flex gap-2">
												<OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
													<Button
														variant="secondary"
														className="btn-icon btn-sm"
														onClick={() =>
															handleShowDiagnosticHypothesesModal(
																result
															)
														}
													>
														<MdEdit className="text-gray-700" />
													</Button>
												</OverlayTrigger>

												<OverlayTrigger
													overlay={
														<Tooltip>
															{isActive ? "Desativar" : "Ativar"}
														</Tooltip>
													}
												>
													<Button
														variant="secondary"
														className="btn-icon btn-sm"
														onClick={() =>
															handleChangeStatus(Number(result.id))
														}
													>
														{loading ? (
															<Spinner
																variant="samu"
																animation="border"
																size="sm"
															/>
														) : (
															<FaPowerOff className="text-gray-700" />
														)}
													</Button>
												</OverlayTrigger>
											</div>
										</td>
									</tr>
								);
							})}

							{!results?.length && (
								<tr>
									<td className="text-center" colSpan={12}>
										<h2 className="mt-12 mb-0">
											Não há hipóteses diagnósticas cadastradas
										</h2>
									</td>
								</tr>
							)}
						</>
					)}
				</tbody>
			</Table>

			{!!results?.length && (
				<div className="d-flex justify-content-end mt-8">
					<PaginationLinks
						itemsPerPage={10}
						totalItems={diagnosticHypotheses.meta?.total}
						forcePage={forcePage.current - 1}
						changeSelectedPage={handleChangeSelectedPage}
					/>
				</div>
			)}

			<DiagnosticHypothesisModal
				showDiagnosticHypothesisModal={showDiagnosticHypothesisModal}
				handleCloseDiagnosticHypothesisModal={handleCloseDiagnosticHypothesisModal}
				fetchPaginatedDiagnosticHypotheses={fetchPaginatedDiagnosticHypotheses}
				currentDiagnosticHypothesis={currentDiagnosticHypothesis}
			/>
		</>
	);
}
