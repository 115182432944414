import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { createSentence } from "@/utils/createSentence";

type RegionalGroupViewingModalProps = {
	showRegionalGroupViewingModal: boolean;
	handleCloseRegionalGroupViewingModal: () => void;
	regionalGroup: RegionalGroup;
};

export function RegionalGroupViewingModal({
	showRegionalGroupViewingModal,
	handleCloseRegionalGroupViewingModal,
	regionalGroup,
}: RegionalGroupViewingModalProps) {
	const bases = regionalGroup?.bases?.map(
		(base) => `${base.vehicle_type?.name} ${base.city?.name}`
	);

	return (
		<Modal show={showRegionalGroupViewingModal} onHide={handleCloseRegionalGroupViewingModal}>
			<Modal.Header className="d-flex align-items-center justify-content-center">
				<h2 className="m-0">Visualização de grupo regional</h2>
			</Modal.Header>

			<Modal.Body>
				<div className="d-flex flex-column gap-4">
					<Row>
						<Col>
							<span className="fw-bold">Nome: </span>
							{regionalGroup.name}
						</Col>

						<Col>
							<span className="fw-bold">Status: </span>
							{regionalGroup.is_active ? "Ativado" : "Desativado"}
						</Col>
					</Row>

					<Row>
						<Col>
							<span className="fw-bold">Bases vinculadas: </span>
							{createSentence(bases ?? [])}
						</Col>
					</Row>
				</div>

				<div className="text-center mt-8">
					<button
						className="btn button-bg-light-color-gray-400"
						onClick={handleCloseRegionalGroupViewingModal}
						type="button"
					>
						Fechar
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
