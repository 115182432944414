import { useRef } from "react";

import { useApi } from "@/hooks/useApi";

import { ReactSelect, ReactSelectProps, Additional } from "../ReactSelect";

type Props = ReactSelectProps;

export function ProfessionalsSelect({ ...rest }: Props) {
	const abortControllerRef = useRef(new AbortController());
	const { api } = useApi();

	async function fetchProfessionals(page: number, search?: string) {
		try {
			abortControllerRef.current.abort();
			abortControllerRef.current = new AbortController();

			const { data } = await api.get("/users", {
				params: {
					page,
					search,
				},
				signal: abortControllerRef.current.signal,
			});

			return data as PaginatedUser;
		} catch (error) {
			console.log(error);
			return {} as PaginatedUser;
		}
	}

	const loadAsyncProfessinalsOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, __: User, { page }: Additional) {
			const professionals = await fetchProfessionals(page, search);

			return {
				options: professionals?.results.map((professional) => ({
					label: professional.name,
					value: professional.id,
				})),
				hasMore: professionals.meta?.current_page < professionals.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	return <ReactSelect {...loadAsyncProfessinalsOptionsProps} {...rest} />;
}
