import { AttendanceType, AttendanceTypeMessages } from "@/enums/AttendanceType";

export const attendanceTypeOptions = [
	{
		label: AttendanceTypeMessages[AttendanceType.PrimaryOccurrence],
		value: AttendanceType.PrimaryOccurrence,
	},
	{
		label: AttendanceTypeMessages[AttendanceType.SecondaryOccurrence],
		value: AttendanceType.SecondaryOccurrence,
	},
	{
		label: AttendanceTypeMessages[AttendanceType.PrankCall],
		value: AttendanceType.PrankCall,
	},
	{
		label: AttendanceTypeMessages[AttendanceType.Information],
		value: AttendanceType.Information,
	},
	{
		label: AttendanceTypeMessages[AttendanceType.Mistake],
		value: AttendanceType.Mistake,
	},
	{
		label: AttendanceTypeMessages[AttendanceType.CallDrop],
		value: AttendanceType.CallDrop,
	},
	{
		label: AttendanceTypeMessages[AttendanceType.ContactWithMECSTeam],
		value: AttendanceType.ContactWithMECSTeam,
	},
];
