import { useFormik } from "formik";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { RoleMessages } from "@/enums/Role";

import { ReactSelect } from "@/components/Selects/ReactSelect";

type Props = {
	showChangeProfileModal: boolean;
	handleCloseChangeProfileModal: () => void;
	roles: Role[];
};

export function ChangeProfileModal({
	showChangeProfileModal,
	handleCloseChangeProfileModal,
	roles,
}: Props) {
	const { currentAuth, setCurrentAuth } = useAuth();
	const { api } = useApi();

	const formik = useFormik({
		initialValues: {
			current_role: currentAuth?.current_role.id,
		},
		async onSubmit(values, { setSubmitting }) {
			if (values.current_role !== currentAuth?.current_role.id) {
				try {
					const { data } = await api.put("change-profile", formik.values);

					setCurrentAuth(data);
				} catch (error) {
					console.error(error);

					setSubmitting(false);
				}
			}

			handleCloseChangeProfileModal();
		},
	});

	const rolesOptions = roles?.map((role) => ({
		value: role.id,
		label: RoleMessages[role.name as keyof typeof RoleMessages],
	}));

	const defaultOption = rolesOptions.find((role) => role.value === currentAuth?.current_role.id);

	const updatedOptions = rolesOptions.map((option) => {
		if (option.value === currentAuth?.current_role.id) {
			return { ...option, isDisabled: true };
		}

		return option;
	});

	return (
		<Modal show={showChangeProfileModal} onHide={handleCloseChangeProfileModal} centered>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Selecione um perfil</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<ReactSelect
						options={updatedOptions}
						defaultValue={defaultOption}
						onChange={(option) =>
							formik.setFieldValue(
								"current_role",
								(option as ReactSelectOption).value
							)
						}
					/>

					<div className="d-flex justify-content-center gap-2 mt-8">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseChangeProfileModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting}
						>
							Salvar
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
