import {
	FC,
	useState,
	useEffect,
	createContext,
	useContext,
	useRef,
	Dispatch,
	SetStateAction,
	MutableRefObject,
} from "react";

import { AuthModel } from "./_models";
import { useApi } from "@/hooks/useApi";
import * as authHelper from "./AuthHelpers";
import { WithChildren } from "@/../_metronic/helpers";

import { LayoutSplashScreen } from "@/../_metronic/layout/core";

type TimerIntervalIdRef = MutableRefObject<NodeJS.Timer | null>;

type AuthContextProps = {
	auth: AuthModel | undefined;
	timer: number;
	setTimer: (timer: number) => void;
	timerIntervalId: TimerIntervalIdRef;
	saveAuth: (auth: AuthModel | undefined) => void;
	currentAuth: User | undefined;
	setCurrentAuth: Dispatch<SetStateAction<User | undefined>>;
	logout: () => void;
};

const initAuthContextPropsState = {
	auth: authHelper.getAuth(),
	timer: 0,
	setTimer: () => {},
	timerIntervalId: { current: null },
	saveAuth: () => {},
	currentAuth: undefined,
	setCurrentAuth: () => {},
	logout: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
	return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
	const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth());
	const [currentAuth, setCurrentAuth] = useState<User | undefined>();
	const [timer, setTimer] = useState(0);
	const timerIntervalId = useRef<NodeJS.Timer | null>(null);

	const saveAuth = (auth: AuthModel | undefined) => {
		setAuth(auth);
		if (auth) {
			authHelper.setAuth(auth);
		} else {
			authHelper.removeAuth();
		}
	};

	const logout = () => {
		saveAuth(undefined);
		setCurrentAuth(undefined);
	};

	useEffect(() => {
		if (currentAuth) {
			authHelper.setAuthData(currentAuth);
		} else {
			authHelper.removeAuthData();
		}

		const MAX_IDLE_TIME = 1800;
		if (timer === MAX_IDLE_TIME) {
			if (timerIntervalId.current) {
				clearInterval(timerIntervalId.current);
			}

			logout();
			return;
		}

		if (currentAuth) {
			timerIntervalId.current = setTimeout(() => {
				setTimer(timer + 1);
			}, 1000);
		}
	}, [timer, currentAuth]);

	return (
		<AuthContext.Provider
			value={{
				auth,
				timer,
				setTimer,
				timerIntervalId,
				saveAuth,
				currentAuth,
				setCurrentAuth,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

const AuthInit: FC<WithChildren> = ({ children }) => {
	const { auth, logout, currentAuth, setCurrentAuth } = useAuth();
	const { getUserByToken } = useApi();

	const didRequest = useRef(false);
	const [showSplashScreen, setShowSplashScreen] = useState(true);

	useEffect(() => {
		const requestUser = async () => {
			try {
				if (!didRequest.current && !currentAuth) {
					const { data } = await getUserByToken();
					setCurrentAuth(data);
				}
			} catch (error) {
				console.error(error);
				if (!didRequest.current) {
					logout();
				}
			} finally {
				setShowSplashScreen(false);
			}

			return () => (didRequest.current = true);
		};

		if (auth) {
			requestUser();
		} else {
			logout();
			setShowSplashScreen(false);
		}
	}, [auth]);

	return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
