import { useEffect, useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";

import { Link, useNavigate, useParams } from "react-router-dom";
import { ImArrowLeft2 } from "react-icons/im";

import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { GenderMessages } from "@/enums/Gender";
import { TimeUnitMessages } from "@/enums/TimeUnit";
import { AttendanceType } from "@/enums/AttendanceType";
import { timeUnitsOptions } from "@/utils/options/timeUnits";
import { genderCodesOptions } from "@/utils/options/genderCodes";
import { attendanceViewingInitialValues } from "@/utils/initialValues/attendanceViewing";
import { attendanceViewingValidationSchema } from "@/utils/validation/attendanceViewingSchema";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { OccurrenceData } from "@/components/OccurrenceData";
import { FormRadio } from "@/components/Radios/FormRadio";

export function AttendanceViewing() {
	const [occurrence, setOccurrence] = useState<Attendable>({} as Attendable);

	const { attendanceId, attendanceType } = useParams();
	const { Toast, toastRequestErrors } = useSwal();
	const navigate = useNavigate();
	const { api } = useApi();

	const primaryOrSecondaryAttendanceUrl =
		Number(attendanceType) === AttendanceType.PrimaryOccurrence
			? `/ticket/primary-attendance/${attendanceId}`
			: `/ticket/secondary-attendance/${attendanceId}`;

	const openingAtMessage = dayjs(occurrence?.attendance?.ticket?.opening_at).format(
		"[Aberto em] DD/MM/YYYY [às] HH:mm[hr]"
	);

	const formik = useFormik({
		initialValues: attendanceViewingInitialValues,
		validationSchema: attendanceViewingValidationSchema,
		onSubmit: async (values) => {
			try {
				await api.put(primaryOrSecondaryAttendanceUrl, values);

				Toast.fire({
					icon: "success",
					title: "Chamado atualizado com sucesso!",
				});

				navigate("/ocorrencias");
			} catch (error) {
				console.error(error);
			}
		},
	});

	const { values, errors } = formik;

	function handleClose() {
		navigate("/ocorrencias");
	}

	async function fetchAttendance() {
		try {
			const { data } = await api.get(primaryOrSecondaryAttendanceUrl);

			setOccurrence(data);
		} catch (error: any) {
			if (error.isAxiosError && error.response.status === 422) {
				return toastRequestErrors(error.response.data.errors);
			}

			Toast.fire({
				icon: "error",
				title: "Erro ao atualizar chamado",
			});
		}
	}

	const selectedGenderCode = genderCodesOptions.filter(
		(option) => option.value === values.gender_code
	);
	const selectedTimeUnit = timeUnitsOptions.filter(
		(option) => option.value === values.time_unit_id
	);

	const defaultGenderCode = {
		value: occurrence.attendance?.patient.gender_code,
		label: GenderMessages[
			occurrence.attendance?.patient.gender_code as keyof typeof GenderMessages
		],
	};

	const defaultTimeUnit = {
		value: occurrence.attendance?.patient.time_unit_id,
		label: TimeUnitMessages[
			occurrence.attendance?.patient.time_unit_id as keyof typeof TimeUnitMessages
		],
	};

	useEffect(() => {
		fetchAttendance();
	}, [attendanceId]);

	useEffect(() => {
		formik.setFieldValue("name", occurrence.attendance?.patient.name);
		formik.setFieldValue("age", String(occurrence.attendance?.patient.age || ""));
		formik.setFieldValue("gender_code", occurrence.attendance?.patient.gender_code || "");
		formik.setFieldValue(
			"time_unit_id",
			String(occurrence.attendance?.patient.time_unit_id || "")
		);
		formik.setFieldValue("in_central_bed", occurrence?.in_central_bed);
		formik.setFieldValue("protocol", occurrence?.protocol);
	}, [occurrence]);

	useEffect(() => {
		formik.validateForm();
	}, [values.gender_code, values.time_unit_id, values.age]);

	return (
		<Card>
			<Card.Header>
				<div className="d-flex justify-content-between align-items-center w-100 default-form-card-header">
					<div>{openingAtMessage}</div>

					<h2 className="m-0">Visualizar chamado</h2>

					<div>
						<Link
							className="d-flex align-items-center btn button-bg-light-color-gray-400 gap-2 w-100px"
							to="/ocorrencias"
						>
							<ImArrowLeft2 />
							Voltar
						</Link>
					</div>
				</div>
			</Card.Header>

			<Card.Body className="d-flex flex-column gap-4">
				<OccurrenceData
					attendanceType={Number(attendanceType)}
					attendable={occurrence}
					summarize
				/>

				<Form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-4">
					<Row>
						<Col md={6} className="d-flex flex-column gap-4">
							<FormattedFormControl
								id="name"
								label="Nome do paciente"
								labelClassName="fw-bold"
								{...formik.getFieldProps("name")}
							/>
						</Col>

						<Col>
							<Row>
								<Col md={6}>
									<Form.Label htmlFor="gender-code" className="fw-bold">
										Sexo
									</Form.Label>

									<ReactSelect
										inputId="gender-code"
										options={genderCodesOptions}
										value={
											values.gender_code
												? selectedGenderCode
												: defaultGenderCode
										}
										placeholder="Selecione"
										isInvalid={!!errors.gender_code}
										onChange={(option) =>
											formik.setFieldValue(
												"gender_code",
												(option as ReactSelectOption).value
											)
										}
									/>

									{errors.gender_code && (
										<span className="text-danger">{errors.gender_code}</span>
									)}
								</Col>

								<Col>
									<Form.Label htmlFor="age" className="fw-bold">
										Idade
									</Form.Label>

									<Row>
										<Col lg={4}>
											<FormattedFormControl
												id="age"
												mask={Number}
												isMaskedInput
												isInvalid={!!errors.age}
												validationMessage={errors.age}
												{...formik.getFieldProps("age")}
											/>
										</Col>

										<Col>
											<ReactSelect
												options={timeUnitsOptions}
												value={
													values.time_unit_id
														? selectedTimeUnit
														: defaultTimeUnit
												}
												isInvalid={!!errors.time_unit_id}
												onChange={(option) =>
													formik.setFieldValue(
														"time_unit_id",
														(option as ReactSelectOption).value
													)
												}
											/>

											{errors.time_unit_id && (
												<span className="text-danger">
													{errors.time_unit_id}
												</span>
											)}
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>

					<Row>
						<Col md={2}>
							<FormGroupRadio
								id="in-central-bed"
								label="Está na central de leitos?"
								labelClassName="fw-bold"
								requestedValue={values.in_central_bed}
								inline
								{...formik.getFieldProps("in_central_bed")}
							>
								<FormRadio id="yes" label="Sim" value={1} />
								<FormRadio id="not" label="Não" value={0} />
							</FormGroupRadio>
						</Col>

						{Number(values.in_central_bed) === 1 && (
							<Col md={3}>
								<FormattedFormControl
									id="protocol-number"
									label="Nº do protocolo"
									labelClassName="fw-bold"
									mask={Number}
									isMaskedInput
									{...formik.getFieldProps("protocol")}
								/>
							</Col>
						)}
					</Row>

					<div className="d-flex gap-4 justify-content-center mt-16">
						<button
							className="btn button-bg-light-color-gray-400"
							onClick={handleClose}
						>
							Fechar
						</button>

						<button
							className="btn button-bg-samu-color-white"
							type="submit"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{formik.isSubmitting ? (
								<Spinner size="sm" variant="light" animation="border" />
							) : (
								"Atualizar"
							)}
						</button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
}
