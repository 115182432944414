import { Ref, forwardRef } from "react";

import { RadioOperationView } from "@/components/AttendanceSummary/RadioOperationView";
import { SceneRegisterData } from "@/components/AttendanceSummary/SceneRegisterData";
import { AttendanceData } from "@/components/AttendanceSummary/AttendanceData";
import { HistoryRegister } from "@/components/HistoryRegister";
import { Content } from "@/components/Prints/Layout/Content";

import "./styles.scss";

type Props = {
	attendance: Attendance;
};

export const AttendanceSummarySheet = forwardRef(
	({ attendance }: Props, ref: Ref<HTMLTableElement>) => {
		const sceneRegisters = attendance?.scene_recordings;

		return (
			<Content ref={ref}>
				<div className="page-break-inside-avoid">
					<div className="bg-samu text-center rounded py-4 my-5">
						<h1 className="text-white m-0">DADOS DA OCORRÊNCIA</h1>
					</div>

					<div className="mx-2">
						<AttendanceData attendance={attendance} />
					</div>
				</div>

				<div className="page-break-inside-avoid">
					<div className="bg-samu text-center rounded py-4 my-5">
						<h1 className="text-white m-0">REGULAÇÃO MÉDICA</h1>
					</div>

					<div className="mx-2">
						<HistoryRegister attendanceId={attendance.id} />
					</div>
				</div>

				{sceneRegisters?.map((sceneRegister, index) => (
					<div className="page-break-inside-avoid" key={`scene-register-${index}`}>
						<div id="scene_register" className="bg-samu text-center rounded py-4 my-5">
							<h1 className="text-white m-0">REGISTRO DE CENA - {index + 1}</h1>
						</div>
						<div className="mx-2">
							<SceneRegisterData sceneRegister={sceneRegister} />
						</div>
					</div>
				))}

				{!sceneRegisters?.length && (
					<div className="page-break-inside-avoid pt-5">
						<div id="scene_register" className="bg-samu text-center rounded py-4 my-5">
							<h1 className="text-white m-0">REGISTRO DE CENA</h1>
						</div>

						<div className="text-center">
							<h2 className="m-0">Nenhum resultado encontrado</h2>
						</div>
					</div>
				)}

				<div className="page-break-inside-avoid">
					<div className="bg-samu text-center rounded py-4 my-5">
						<h2 className="text-white m-0">RÁDIO OPERADOR</h2>
					</div>

					<div className="mx-2">
						<RadioOperationView radioOperation={attendance.radio_operation} />
					</div>
				</div>
			</Content>
		);
	}
);
