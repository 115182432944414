import { useApi } from "@/hooks/useApi";

import { Additional, ReactSelect, ReactSelectProps } from "../ReactSelect";

export function WorkplacesSelect({ ...rest }: ReactSelectProps) {
	const { api } = useApi();

	async function fetchWorkplaces(page?: number, search?: string) {
		try {
			const { data } = await api.get("/workplace", {
				params: {
					page: page || 1,
					search,
				},
			});

			return data as PaginatedSchedulable;
		} catch (error) {
			console.log(error);

			return {} as PaginatedSchedulable;
		}
	}

	const loadAsyncWorkplacesOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, __: BaseOrUnit, { page }: Additional) {
			const workplaces = await fetchWorkplaces(page, search);

			const options = workplaces?.results.map((workplace: Schedulable) => {
				return {
					value: workplace.id,
					label: workplace.name,
				};
			});

			return {
				options,
				hasMore: workplaces.meta?.current_page < workplaces.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	return <ReactSelect {...loadAsyncWorkplacesOptionsProps} {...rest} />;
}
