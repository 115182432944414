import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { MdEdit } from "react-icons/md";
import { FaEye, FaPlus } from "react-icons/fa6";
import { TbAlarmPlusFilled } from "react-icons/tb";

import { useApi } from "@/hooks/useApi";
import { useCanAccess } from "@/hooks/useCanAccess";
import { Role, RoleMessages } from "@/enums/Role";
import { useDebounce } from "@/hooks/useDebounce";

import { SchedulesModal } from "@/components/Modals/SchedulesModal";
import { PaginationLinks } from "@/components/PaginationLinks";
import { ViewModal } from "@/components/Users/Modals/ViewModal";
import { EditModal } from "@/components/Users/Modals/EditModal";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";

export function Professionals() {
	const [professionals, setProfessionals] = useState<PaginatedUser>({} as PaginatedUser);
	const [currentProfessinal, setCurrentProfessinal] = useState<User>({} as User);
	const [skeleton, setSkeleton] = useState(true);
	const [showViewUserModal, setShowViewUserModal] = useState(false);
	const [showEditUserModal, setShowEditUserModal] = useState(false);
	const [showSchedulesModal, setShowSchedulesModal] = useState(false);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);

	const isFirstRender = useRef(true);
	const forcePage = useRef(1);
	const debouncedSearch = useDebounce(search, 500);

	const { api } = useApi();
	const { can } = useCanAccess();
	const { results } = professionals;

	const { admin } = Role;

	const handleShowViewUserModal = (professional: User) => {
		setShowViewUserModal(true);
		setCurrentProfessinal(professional);
	};
	const handleCloseViewUserModal = () => {
		setShowViewUserModal(false);
		setCurrentProfessinal({} as User);
	};

	const handleShowEditUserModal = (professional: User) => {
		setShowEditUserModal(true);
		setCurrentProfessinal(professional);
	};
	const handleCloseEditUserModal = () => {
		setShowEditUserModal(false);
		setCurrentProfessinal({} as User);
	};

	const handleShowSchedulesModal = (professional: User) => {
		setShowSchedulesModal(true);
		setCurrentProfessinal(professional);
	};
	const handleCloseSchedulesModal = () => {
		setShowSchedulesModal(false);
		setCurrentProfessinal({} as User);
	};

	async function fetchPaginatedProfessionals(pageToUse?: number) {
		setSkeleton(true);

		try {
			const { data } = await api.get("/professionals", {
				params: {
					page: pageToUse || page,
					search: debouncedSearch,
				},
			});

			setProfessionals(data);
		} catch (error) {
			console.error(error);
		} finally {
			setSkeleton(false);
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchPaginatedProfessionals(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	useEffect(() => {
		forcePage.current = page;
		fetchPaginatedProfessionals();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		forcePage.current = 1;
		fetchPaginatedProfessionals(1);
	}, [debouncedSearch]);

	return (
		<>
			{can([admin]) && (
				<Link to="cadastro">
					<button className="d-flex align-items-center btn button-bg-white-color-samu  mb-6 gap-4">
						<FaPlus />
						Cadastrar profissional
					</button>
				</Link>
			)}

			<Card>
				<Card.Header className="d-flex justify-content-between align-items-center">
					<h3 className="fw-normal m-0">Profissionais cadastrados</h3>

					<div>
						<SearchFormControl
							placeholder="Pesquisar profissional"
							onChange={(event) => setSearch(event.target.value)}
						/>
					</div>
				</Card.Header>

				<Card.Body>
					<Table responsive className="table-row-dashed table-row-gray-300">
						<thead>
							<tr className="fw-bold fs-6">
								<th>Perfil</th>
								<th>Nome</th>
								<th>CBO - Conselho</th>
								<th>Telefone</th>
								<th>Ações</th>
							</tr>
						</thead>

						<tbody>
							{skeleton ? (
								<TableBodySkeleton columns={5} />
							) : (
								results?.map((professional, index) => {
									const occupationCode = professional?.cbo;
									const councilNumber = professional?.council_number;
									const hasOccupationCodeAndCouncilNumber =
										occupationCode && councilNumber;

									return (
										<tr key={`professional-${index}`}>
											<td className="align-middle">
												{professional.roles.map((role, index) => (
													<div key={`role-${index}`}>
														{
															RoleMessages[
																role.name as keyof typeof RoleMessages
															]
														}
													</div>
												))}
											</td>
											<td className="align-middle">{professional?.name}</td>
											<td className="align-middle">
												<OverlayTrigger
													overlay={
														<Tooltip>
															{professional.occupation?.name}
														</Tooltip>
													}
												>
													<span>
														{hasOccupationCodeAndCouncilNumber
															? `${occupationCode} - ${councilNumber}`
															: occupationCode || councilNumber}
													</span>
												</OverlayTrigger>
											</td>
											<td className="align-middle">{professional?.phone}</td>
											<td className="align-middle w-1px">
												<div className="d-flex gap-2">
													<OverlayTrigger
														overlay={
															<Tooltip>Visualizar perfil</Tooltip>
														}
													>
														<Button
															variant="secondary"
															className="btn-icon btn-sm"
															onClick={() =>
																handleShowViewUserModal(
																	professional
																)
															}
														>
															<FaEye className="text-gray-700" />
														</Button>
													</OverlayTrigger>

													{can([admin]) && (
														<OverlayTrigger
															overlay={
																<Tooltip>Editar perfil</Tooltip>
															}
														>
															<Button
																variant="secondary"
																className="btn-icon btn-sm"
																onClick={() =>
																	handleShowEditUserModal(
																		professional
																	)
																}
															>
																<MdEdit className="text-gray-700" />
															</Button>
														</OverlayTrigger>
													)}

													<OverlayTrigger
														overlay={<Tooltip>Escala</Tooltip>}
													>
														<Button
															variant="secondary"
															className="btn-icon btn-sm"
															onClick={() =>
																handleShowSchedulesModal(
																	professional
																)
															}
														>
															<TbAlarmPlusFilled className="text-gray-700 fs-5" />
														</Button>
													</OverlayTrigger>
												</div>
											</td>
										</tr>
									);
								})
							)}

							{!results?.length && !skeleton && (
								<tr>
									<td className="text-center" colSpan={12}>
										<h2 className="mt-12 mb-0">
											Não há profissionais cadastrados
										</h2>
									</td>
								</tr>
							)}
						</tbody>
					</Table>

					{Boolean(results?.length) && (
						<div className="d-flex justify-content-end mt-8">
							<PaginationLinks
								itemsPerPage={10}
								totalItems={professionals.meta?.total}
								forcePage={forcePage.current - 1}
								changeSelectedPage={handleChangeSelectedPage}
							/>
						</div>
					)}
				</Card.Body>
			</Card>

			<ViewModal
				user={currentProfessinal}
				showViewUserModal={showViewUserModal}
				handleCloseViewUserModal={handleCloseViewUserModal}
			/>

			<EditModal
				currentUser={currentProfessinal}
				showEditUserModal={showEditUserModal}
				handleCloseEditUserModal={handleCloseEditUserModal}
				fetchPaginatedUsers={fetchPaginatedProfessionals}
			/>

			<SchedulesModal
				showSchedulesModal={showSchedulesModal}
				handleCloseSchedulesModal={handleCloseSchedulesModal}
				user={currentProfessinal}
				fetchProfessionals={fetchPaginatedProfessionals}
			/>
		</>
	);
}
