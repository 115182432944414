import { useEffect, useState } from "react";

import { ReactSelect, ReactSelectProps } from "../ReactSelect";

import { useApi } from "@/hooks/useApi";

type Props = ReactSelectProps & {
	selectedOptions?: string[];
	urgencyRegulationCenterId: string;
};

export function UrgencyRegulationCentersSelect({
	selectedOptions = [],
	urgencyRegulationCenterId,
	...rest
}: Props) {
	const [urgencyRegulationCentersOptions, setUrgencyRegulationCentersOptions] = useState<
		ReactSelectOption[]
	>([]);
	const { api } = useApi();

	const notUsedUrgencyRegulationCentersOptions = urgencyRegulationCentersOptions.filter(
		(option) => !selectedOptions.includes(option.label)
	);

	async function fetchUrgencyRegulationCenters() {
		try {
			const { data } = await api.get<UrgencyRegulationCenter[]>(
				"/urgency-regulation-centers"
			);

			const urgencyRegulationCentersOptions = data?.map((urc) => ({
				value: urc.id,
				label: urc.name,
			}));

			setUrgencyRegulationCentersOptions(urgencyRegulationCentersOptions);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		fetchUrgencyRegulationCenters();
	}, []);

	return (
		<ReactSelect
			options={notUsedUrgencyRegulationCentersOptions}
			placeholder="Selecione uma unidade"
			value={
				urgencyRegulationCentersOptions.find(
					(option) => option.value === urgencyRegulationCenterId
				) || null
			}
			{...rest}
		/>
	);
}
