export enum RequesterType {
	Medical = 1,
	OtherProfessional = 2,
	Himself = 3,
	Family = 4,
	Friend = 5,
	Passerby = 6,
	HealthProfessional = 7,
	MunicipalGuard = 8,
	Firefighters = 9,
	MilitaryPolice = 10,
	StateHighwayPolice = 11,
	FederalHighwayPolice = 12,
	MunicipalAuthority = 13,
}

export const RequesterTypeMessages = {
	[RequesterType.Medical]: "Médico",
	[RequesterType.OtherProfessional]: "Outro profissional",
	[RequesterType.Himself]: "O próprio",
	[RequesterType.Family]: "Familiar",
	[RequesterType.Friend]: "Amigo",
	[RequesterType.Passerby]: "Transeunte",
	[RequesterType.HealthProfessional]: "Prof. saúde",
	[RequesterType.MunicipalGuard]: "Guarda municipal",
	[RequesterType.Firefighters]: "Bombeiros",
	[RequesterType.MilitaryPolice]: "PM",
	[RequesterType.StateHighwayPolice]: "PRE",
	[RequesterType.FederalHighwayPolice]: "PRF",
	[RequesterType.MunicipalAuthority]: "AMC",
};
