import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import classNames from "clsx";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { useDebounce } from "@/hooks/useDebounce";
import { useAttendanceConsultation } from "@/hooks/useAttendanceConsultation";
import { attendanceConsultationInitialValues } from "@/utils/initialValues/attendanceConsultation";
import { filterObject } from "@/utils/filterObject";
import { deepEqual } from "@/utils/deepEqual";

import { Results } from "@/components/Reports/AttendanceConsultation/Results";
import { Filter } from "@/components/Reports/AttendanceConsultation/Filter";

export function AttendanceConsultation() {
	const [isLoading, setIsLoading] = useState(false);
	const [perPage, setPerPage] = useState(10);
	const [search, setSearch] = useState("");
	const { Toast } = useSwal();

	const {
		activeContent,
		setActiveContent,
		paginatedAttendanceConsultation,
		setPaginatedAttendanceConsultation,
		attendanceConsultationValues,
		setAttendanceConsultationValues,
	} = useAttendanceConsultation();
	const debouncedSearch = useDebounce(search, 500);
	const { api } = useApi();

	const controller = useRef<AbortController | null>(null);
	const isFirstRender = useRef(true);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: attendanceConsultationInitialValues,
		async onSubmit(values) {
			const hasDate = values.start_date && values.end_date;
			const attendanceNumber = values.attendance_number;

			if (!(hasDate || attendanceNumber)) {
				Toast.fire({
					icon: "error",
					title: "Selecione o período ou número do atendimento para realizar a consulta.",
				});

				return;
			}

			try {
				setIsLoading(true);

				setAttendanceConsultationValues(values);

				if (attendanceConsultationValues) {
					formik.setValues(attendanceConsultationValues);
				}

				setActiveContent("results");

				await handleSearchAttendances();
			} catch (error) {
				console.log(error);
			} finally {
				if (deepEqual(attendanceConsultationValues || values, values)) {
					setIsLoading(false);
				}
			}
		},
	});

	const { values } = formik;

	const filteredValues = filterObject(values, [
		"attendance_number",
		"patient_name",
		"initial_birth_date",
		"final_birth_date",
		"requesting_phone",
		"neighborhood",
		"street",
		"diagnostic_hypothesis",
		"requesting_name",
		"action_types",
	]);

	const supportingOrganizationsMedicalRegulation =
		values.supporting_organizations_medical_regulation.map(
			(option) => (option as ReactSelectOption).value
		);
	const supportingOrganizationsSceneRecording =
		values.supporting_organizations_scene_recording.map(
			(option) => (option as ReactSelectOption).value
		);
	const vehicleMovementCodes = values.vehicle_movement_codes.map(
		(option) => (option as ReactSelectOption).value
	);
	const priorityTypes = values.priority_types.map(
		(option) => (option as ReactSelectOption).value
	);
	const natureTypes = values.nature_types.map((option) => (option as ReactSelectOption).value);
	const ticketTypes = values.ticket_types.map((option) => (option as ReactSelectOption).value);
	const requesterTypes = values.requester_types.map(
		(option) => (option as ReactSelectOption).value
	);
	const distanceTypes = values.distance_types.map(
		(option) => (option as ReactSelectOption).value
	);
	const locationTypes = values.location_types.map(
		(option) => (option as ReactSelectOption).value
	);
	const closingTypes = values.closing_types.map((option) => (option as ReactSelectOption).value);
	const page = paginatedAttendanceConsultation.meta?.current_page || 1;
	const cities = values.cities.map((option) => (option as ReactSelectOption).value);
	const vehicleId = values.vehicles.map((option) => (option as ReactSelectOption).value);
	const vehiclesTypes = values.vehicles_types.map(
		(option) => (option as ReactSelectOption).value
	);
	const bases = values.bases.map((option) => (option as ReactSelectOption).value);
	const antecedents = values.antecedents.map((option) => (option as ReactSelectOption).value);
	const unitsOrigin = values.units_origin.map((option) => (option as ReactSelectOption).value);
	const unitsDestination = values.units_destination.map(
		(option) => (option as ReactSelectOption).value
	);
	const diagnosticHypotheses = values.diagnostic_hypotheses.map(
		(option) => (option as ReactSelectOption).value
	);

	const thrombolyticApplied = values.thrombolytic_applied.map(
		(option) => (option as ReactSelectOption).value
	);

	const thrombolyticRecommended = values.thrombolytic_recommended.map(
		(option) => (option as ReactSelectOption).value
	);

	const params = {
		start_date: values.start_date,
		end_date: values.end_date,
		attendance_status_id: (values.attendance_status as ReactSelectOption).value,
		user_id: (values.user as ReactSelectOption).value,
		time_unit_id: (values.time_unit_id as ReactSelectOption).value,
		gender_code: (values.gender as ReactSelectOption).value,
		cities: cities,
		vehicles: vehicleId,
		vehicles_types: vehiclesTypes,
		units_origin: unitsOrigin,
		units_destination: unitsDestination,
		antecedents: antecedents,
		transfer_reason_id: (values.transfer_reason as ReactSelectOption).value,
		supporting_organizations_medical_regulation: supportingOrganizationsMedicalRegulation,
		supporting_organizations_scene_recording: supportingOrganizationsSceneRecording,
		vehicle_movement_codes: vehicleMovementCodes,
		priority_types: priorityTypes,
		nature_types: natureTypes,
		ticket_types: ticketTypes,
		requesterTypes: requesterTypes,
		distance_types: distanceTypes,
		location_types: locationTypes,
		closing_types: closingTypes,
		bases: bases,
		diagnostic_hypotheses: diagnosticHypotheses,
		thrombolytic_recommended: thrombolyticRecommended,
		thrombolytic_applied: thrombolyticApplied,
	};

	if (!controller.current) {
		controller.current = new AbortController();
	}

	async function fetchAttendances(increasePage = false) {
		formik.setFieldValue("start_date", params.start_date);
		formik.setFieldValue("end_date", params.end_date);

		try {
			controller.current?.abort();
			controller.current = new AbortController();

			const { data } = await api.get("/attendance/consultation", {
				params: {
					...filteredValues,
					...(debouncedSearch && { search: debouncedSearch }),
					page: increasePage ? page + 1 : 1,
					per_page: perPage,
					...params,
				},
				signal: controller.current.signal,
			});
			return data as PaginatedAttendanceConsultation;
		} catch (error) {
			console.log(error);

			return {} as PaginatedAttendanceConsultation;
		}
	}

	async function fetchAttendancesAll() {
		try {
			const { data } = await api.get("/attendance/consultation", {
				params: {
					...filteredValues,
					...(debouncedSearch && { search: debouncedSearch }),
					list_all: 1,
					...params,
				},
			});

			return data.results as AttendanceConsultation[];
		} catch (error) {
			console.log(error);

			return [] as AttendanceConsultation[];
		}
	}

	async function loadMoreAttendances() {
		const newAttendances = await fetchAttendances(true);

		setPaginatedAttendanceConsultation({
			...newAttendances,
			results: [...paginatedAttendanceConsultation.results, ...newAttendances.results],
		});
	}

	async function handleSearchAttendances() {
		if (!(values.start_date && values.end_date)) {
			return;
		}

		try {
			setIsLoading(true);

			const data = await fetchAttendances();

			setPaginatedAttendanceConsultation(data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		handleSearchAttendances();
	}, [debouncedSearch, perPage]);

	async function changePerPageNumberAttendances(numberOfRecords: number) {
		setPerPage(numberOfRecords);
	}

	useEffect(() => {
		return () => {
			controller.current?.abort();
		};
	}, []);

	return (
		<>
			<Filter
				formik={formik}
				className={classNames(activeContent !== "filter" && "d-none")}
			/>

			<Results
				isLoading={isLoading}
				search={search}
				setSearch={setSearch}
				next={loadMoreAttendances}
				changePerPageNumberAttendances={changePerPageNumberAttendances}
				fetchAttendances={handleSearchAttendances}
				className={classNames(activeContent !== "results" && "d-none")}
				formik={formik}
				fetchAttendancesAll={fetchAttendancesAll}
				abortController={controller}
			/>
		</>
	);
}
