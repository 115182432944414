export enum AntecedentType {
	HeartDisease = 1,
	Diabetes = 2,
	Epilepsy = 3,
	Alcoholism = 4,
	Hypertension = 5,
	HIV = 6,
	Nephropathy = 7,
	Neoplasm = 8,
	Pneumopathy = 9,
	StrokeSequel = 10,
}

export const AntecedentTypeMessages = {
	[AntecedentType.HeartDisease]: "Cardiopatia",
	[AntecedentType.Diabetes]: "Diabetes",
	[AntecedentType.Epilepsy]: "Epilepsia",
	[AntecedentType.Alcoholism]: "Etilismo",
	[AntecedentType.Hypertension]: "Hipertensão",
	[AntecedentType.HIV]: "HIV",
	[AntecedentType.Nephropathy]: "Nefropatia",
	[AntecedentType.Neoplasm]: "Neoplasia",
	[AntecedentType.Pneumopathy]: "Pneumopatia",
	[AntecedentType.StrokeSequel]: "Sequela AVC",
};
