export enum PlaceType {
	Free = 1,
	Occupied = 2,
	Disabled = 3,
}

export const PlaceTypeMessages = {
	[PlaceType.Free]: "Livre",
	[PlaceType.Occupied]: "Ocupado",
	[PlaceType.Disabled]: "Desativado",
};
