export enum LocationType {
	PublicRoad = 1,
	Residence = 2,
	School = 3,
	Company = 4,
	RestHome = 5,
	EmergencyCareUnit = 6,
	Hospital = 7,
	BasicSupportUnit = 8,
	RuralArea = 9,
	PoliceStationOrPrison = 10,
	Others = 11,
}

export const LocationTypeMessages = {
	[LocationType.PublicRoad]: "Via pública",
	[LocationType.Residence]: "Residência",
	[LocationType.School]: "Escola",
	[LocationType.Company]: "Empresa",
	[LocationType.RestHome]: "Casa de repouso",
	[LocationType.EmergencyCareUnit]: "UPA",
	[LocationType.Hospital]: "Hospital",
	[LocationType.BasicSupportUnit]: "UBS",
	[LocationType.RuralArea]: "Zona rural",
	[LocationType.PoliceStationOrPrison]: "Delegacia/Presídio",
	[LocationType.Others]: "Outros",
};
