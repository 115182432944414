export enum SkinColorationType {
	Cyanosis = 1,
	Normal = 2,
	Pallor = 3,
}

export const SkinColorationTypeMessages = {
	[SkinColorationType.Cyanosis]: "Cianose",
	[SkinColorationType.Normal]: "Normal",
	[SkinColorationType.Pallor]: "Palidez",
};
