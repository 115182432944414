import classNames from "clsx";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";

import { Link } from "react-router-dom";
import { FaFilter, FaPlay } from "react-icons/fa6";

import { useAuth } from "@/modules/auth";
import { Role } from "@/enums/Role";
import { useCanAccess } from "@/hooks/useCanAccess";

type TotalAwaitingRegulation = {
	primary: number;
	secondary: number;
};

type AttendanceHeaderProps = {
	isLoadingTotalAwaitingRegulation: boolean;
	totalAwaitingRegulation: TotalAwaitingRegulation;
	handleShowFilterAttendanceModal: () => void;
};

export function AttendanceHeader({
	isLoadingTotalAwaitingRegulation,
	totalAwaitingRegulation,
	handleShowFilterAttendanceModal,
}: AttendanceHeaderProps) {
	const { currentAuth } = useAuth();
	const { can } = useCanAccess();

	const currentRole = currentAuth?.current_role;

	const screenWidth = window.innerWidth;
	const isMobile = screenWidth <= 543; // 543px is the breakpoint for mobile devices

	return (
		<div
			className={classNames(
				"mb-6 d-flex",
				isMobile && "flex-column gap-4",
				currentRole && currentRole.name === Role.attendanceOrAmbulanceTeam
					? "justify-content-end"
					: "justify-content-between"
			)}
		>
			{isMobile ? (
				<>
					<Row>
						<Col>
							{can([Role.admin, Role.tarm, Role.medic, Role.teamLeader]) && (
								<Link to="nova-ocorrencia">
									<button className="d-flex align-items-center btn button-bg-white-color-samu gap-4 w-100 h-100">
										<FaPlay /> Nova ocorrência
									</button>
								</Link>
							)}
						</Col>

						<Col>
							<button
								className="d-flex align-items-center btn button-bg-white-color-samu gap-4 w-100 h-100"
								onClick={handleShowFilterAttendanceModal}
							>
								<FaFilter /> Filtrar
							</button>
						</Col>
					</Row>

					<Row>
						<Col>
							<Card className="d-flex justify-content-center fw-bold px-6 h-100">
								<div className="d-flex align-items-center gap-4">
									Aguardando regulação primária:
									<span className="d-flex align-items-center fs-1">
										{isLoadingTotalAwaitingRegulation ? (
											<Spinner animation="border" variant="samu" />
										) : (
											totalAwaitingRegulation.primary || 0
										)}
									</span>
								</div>
							</Card>
						</Col>

						<Col>
							<Card className="d-flex justify-content-center fw-bold px-6 h-100">
								<div className="d-flex align-items-center gap-4">
									Aguardando regulação secundária:
									<span className="d-flex align-items-center fs-1">
										{isLoadingTotalAwaitingRegulation ? (
											<Spinner animation="border" variant="samu" />
										) : (
											totalAwaitingRegulation.secondary || 0
										)}
									</span>
								</div>
							</Card>
						</Col>
					</Row>
				</>
			) : (
				<>
					{can([Role.admin, Role.tarm, Role.medic, Role.teamLeader]) && (
						<Link to="nova-ocorrencia">
							<button className="d-flex align-items-center btn button-bg-white-color-samu gap-4">
								<FaPlay /> Nova ocorrência
							</button>
						</Link>
					)}

					<div className="d-flex gap-4">
						<Card className="d-flex justify-content-center fw-bold px-6">
							<div className="d-flex align-items-center gap-4">
								Aguardando regulação primária:
								<span className="d-flex align-items-center fs-1">
									{isLoadingTotalAwaitingRegulation ? (
										<Spinner animation="border" variant="samu" />
									) : (
										totalAwaitingRegulation.primary || 0
									)}
								</span>
							</div>
						</Card>

						<Card className="d-flex justify-content-center fw-bold px-6">
							<div className="d-flex align-items-center gap-4">
								Aguardando regulação secundária:
								<span className="d-flex align-items-center fs-1">
									{isLoadingTotalAwaitingRegulation ? (
										<Spinner animation="border" variant="samu" />
									) : (
										totalAwaitingRegulation.secondary || 0
									)}
								</span>
							</div>
						</Card>

						<button
							className="d-flex align-items-center btn button-bg-white-color-samu gap-4"
							onClick={handleShowFilterAttendanceModal}
						>
							<FaFilter /> Filtrar
						</button>
					</div>
				</>
			)}
		</div>
	);
}
