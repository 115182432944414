import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import dayjs from "dayjs";

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { createSentence } from "@/utils/createSentence";
import { narrativeCertificateSchema } from "@/utils/validation/narrativeCertificateSchema";
import { narrativeCertificateInitialValues } from "@/utils/initialValues/narrativeCertificate";

import { NarrativeCertificateSheet } from "@/components/Prints/NarrativeCertificateSheet";
import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { NarrativeCertificate } from "@/enums/narrativeCertificate";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { CitiesSelect } from "@/components/Selects/CitiesSelect";
import { UnitsSelect } from "@/components/Selects/UnitsSelect";
import { FormRadio } from "@/components/Radios/FormRadio";
import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";

type NarrativeCertificateModalProps = {
	showNarrativeCertificateModal: boolean;
	handleCloseNarrativeCertificateModal: () => void;
};
export function NarrativeCertificateModal({
	showNarrativeCertificateModal,
	handleCloseNarrativeCertificateModal,
}: NarrativeCertificateModalProps) {
	const [attendanceData, setAttendanceData] = useState<Attendance>({} as Attendance);
	const [isLoading, setIsLoading] = useState(false);

	const { currentAuth } = useAuth();
	const { attendanceId } = useParams();
	const { api } = useApi();

	const narrativeCertificateRef = useRef<HTMLTableElement>(null);

	const handlePrint = useReactToPrint({
		content: () => narrativeCertificateRef.current,
		onAfterPrint: () => setIsLoading(false),
	});

	const formik = useFormik({
		initialValues: narrativeCertificateInitialValues,
		validationSchema: narrativeCertificateSchema,
		onSubmit: () => {
			setIsLoading(true);
			handlePrint();
		},
	});

	const { values, errors } = formik;

	const formatDatetime = (datetime: string) => {
		if (datetime) {
			return dayjs(datetime).format("YYYY-MM-DDTHH:mm:ss");
		}

		return "";
	};

	const city = attendanceData?.ticket?.city;

	function getDestinationUnitDefaultValue() {
		if (attendanceData.scene_recording?.unit_destination_label) {
			return {
				label: attendanceData.scene_recording?.unit_destination_label,
				value: attendanceData.scene_recording?.unit_destination_id,
			};
		}

		return {
			label: attendanceData.attendable?.unit_destination?.name,
			value: attendanceData.attendable?.unit_destination?.id,
		};
	}

	const destinationUnitDefaultValue = getDestinationUnitDefaultValue();

	async function getAttendanceData() {
		try {
			const { data: attendance } = await api.get<Attendance>(`/attendance/${attendanceId}`);
			setAttendanceData(attendance);

			const destinationUnit =
				attendance.scene_recording?.unit_destination_label ||
				attendance.attendable.unit_destination?.name;

			function getDiagnosticHypotheses() {
				if (!attendance.scene_recording?.diagnostic_hypotheses?.length) {
					return attendance.latest_medical_regulation?.diagnostic_hypotheses?.map(
						(diagnosticHypothesis) => diagnosticHypothesis.name
					);
				}

				return attendance.scene_recording?.diagnostic_hypotheses?.map(
					(diagnosticHypothesis) => diagnosticHypothesis.name
				);
			}

			const diagnosticHypotheses = getDiagnosticHypotheses();

			formik.setFieldValue("patient_name", attendance.patient?.name || "");
			formik.setFieldValue("street", attendance.attendable?.street || "");
			formik.setFieldValue("date", formatDatetime(attendance.ticket?.opening_at));
			formik.setFieldValue("city", attendance.ticket.city?.name || "");
			formik.setFieldValue("destination_unit", destinationUnit);
			formik.setFieldValue(
				"diagnostic_hypotheses",
				createSentence(diagnosticHypotheses || [])
			);
		} catch (error) {
			console.error(error);
		}
	}

	function handleResetForm() {
		handleCloseNarrativeCertificateModal();
		formik.resetForm();
	}

	useEffect(() => {
		if (showNarrativeCertificateModal) {
			getAttendanceData();
		}
	}, [showNarrativeCertificateModal]);

	return (
		<Modal
			size="lg"
			show={showNarrativeCertificateModal}
			onHide={handleCloseNarrativeCertificateModal}
		>
			<Modal.Header className="justify-content-center">
				<h2 className="m-0">Certidão narrativa</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<FormGroupRadio
						label="Tipo de certidão narrativa"
						labelClassName="fw-bold"
						requestedValue={values.default_or_free}
						{...formik.getFieldProps("default_or_free")}
						inline
						className="mb-4"
					>
						<FormRadio
							id="default"
							label="Padrão"
							value={NarrativeCertificate.Default}
						/>

						<FormRadio id="free" label="Livre" value={NarrativeCertificate.Free} />
					</FormGroupRadio>

					<div className="mb-4">
						{Number(values.default_or_free) === NarrativeCertificate.Default ? (
							<div className="d-flex flex-column gap-3">
								<div>
									CERTIFICAMOS, em virtude da faculdade que nos é conferida por
									lei, e, tendo em vista requerimento por escrito da parte
									interessada, que o SAMU 192 CEARÁ prestou atendimento a
								</div>

								<Row className="d-flex align-items-center">
									<Col lg={2}>
										<Form.Label
											className="fw-bold text-nowrap mb-0"
											htmlFor="patient-name"
										>
											Nome do paciente
										</Form.Label>
									</Col>

									<Col>
										<FormattedFormControl
											id="patient-name"
											{...formik.getFieldProps("patient_name")}
										/>
									</Col>
								</Row>

								<Row className="d-flex align-items-center">
									<Col lg={2}>
										<Form.Label
											className="fw-bold mb-0"
											htmlFor="general-registration"
										>
											portador do RG
										</Form.Label>
									</Col>

									<Col lg={4}>
										<FormattedFormControl
											id="general-registration"
											{...formik.getFieldProps("general_registration")}
										/>
									</Col>

									<Col lg={2}>
										<Form.Label className="fw-bold mb-0" htmlFor="identifier">
											inscrito no CPF
										</Form.Label>
									</Col>

									<Col lg={4}>
										<FormattedFormControl
											isMaskedInput
											id="identifier"
											mask="000.000.000-00"
											{...formik.getFieldProps("identifier")}
										/>
									</Col>
								</Row>

								<Row className="d-flex align-items-center">
									<Col lg={1}>
										<Form.Label
											className="text-nowrap fw-bold mb-0"
											htmlFor="date"
										>
											na data
										</Form.Label>
									</Col>

									<Col lg={4}>
										<FormattedFormControl
											id="date"
											type="datetime-local"
											isInvalid={!!errors.date}
											validationMessage={errors.date}
											{...formik.getFieldProps("date")}
										/>
									</Col>
								</Row>

								<Row className="d-flex align-items-center">
									<Col lg={2}>
										<Form.Label className="fw-bold mb-0" htmlFor="city">
											no município de
										</Form.Label>
									</Col>

									<Col lg={5}>
										<CitiesSelect
											inputId="city"
											value={
												values.city
													? {
															label: values.city,
															value: null,
													  }
													: {
															value: city?.id,
															label: city?.name,
													  }
											}
											onChange={(option) =>
												formik.setFieldValue(
													"city",
													(option as ReactSelectOption).label
												)
											}
										/>
									</Col>
								</Row>

								<Row className="d-flex align-items-center">
									<Col lg={2}>
										<Form.Label className="fw-bold mb-0" htmlFor="street">
											no endereço
										</Form.Label>
									</Col>

									<Col>
										<FormattedFormControl
											id="street"
											{...formik.getFieldProps("street")}
										/>
									</Col>
								</Row>

								<Row className="d-flex align-items-center">
									<Col lg={1} className="fw-bold text-nowrap form-label mb-0">
										vítima de
									</Col>

									<Col>{values.diagnostic_hypotheses}</Col>
								</Row>

								<Row className="d-flex align-items-center">
									<Col lg={3}>
										<Form.Label
											className="fw-bold mb-0"
											htmlFor="destination-unit"
										>
											sendo encaminhado para
										</Form.Label>
									</Col>

									<Col>
										<UnitsSelect
											inputId="destination-unit"
											value={
												values.destination_unit
													? {
															label: values.destination_unit,
															values: null,
													  }
													: destinationUnitDefaultValue
											}
											onChange={(option) =>
												formik.setFieldValue(
													"destination_unit",
													(option as ReactSelectOption).label
												)
											}
										/>
									</Col>
								</Row>

								<span>
									E para constar, eu, {currentAuth?.name}, lavrei a presente
									Certidão, a qual vai datada e assinada.
								</span>
							</div>
						) : (
							<FormControlWithCharactersCount
								id="free_narrative_certificate"
								as="textarea"
								placeholder="Descreva"
								maxLength={5000}
								isRequired
								{...formik.getFieldProps("free_narrative_certificate")}
							/>
						)}
					</div>

					<div className="d-flex gap-4 justify-content-center">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleResetForm}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={isLoading}
						>
							{isLoading ? <Spinner animation="border" size="sm" /> : "Gerar"}
						</button>
					</div>
				</Form>
			</Modal.Body>

			<div className="d-none">
				<NarrativeCertificateSheet ref={narrativeCertificateRef} values={values} />
			</div>
		</Modal>
	);
}
