import { ReactElement } from "react";
import {
	AsyncPaginate,
	AsyncPaginateProps,
	withAsyncPaginate,
	ComponentProps,
	UseAsyncPaginateParams,
} from "react-select-async-paginate";
import Select, {
	CSSObjectWithLabel,
	GroupBase,
	MenuPlacement,
	Props,
	StylesConfig,
	Theme,
} from "react-select";
import { CreatableAdditionalProps } from "react-select/dist/declarations/src/useCreatable";
import CreatableSelect, { CreatableProps } from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect, { AsyncProps } from "react-select/async";

import Skeleton, { SkeletonProps } from "react-loading-skeleton";

export type Additional = {
	page: number;
};

type AsyncPaginateCreatableProps<
	OptionType,
	Group extends GroupBase<OptionType>,
	Additional,
	IsMulti extends boolean
> = CreatableProps<OptionType, IsMulti, Group> &
	UseAsyncPaginateParams<OptionType, Group, Additional> &
	ComponentProps<OptionType, Group, IsMulti>;

type AsyncPaginateCreatableType = <
	OptionType,
	Group extends GroupBase<OptionType>,
	Additional,
	IsMulti extends boolean = false
>(
	props: AsyncPaginateCreatableProps<OptionType, Group, Additional, IsMulti>
) => ReactElement;

type AsyncSelectProps = AsyncProps<unknown, boolean, GroupBase<unknown>>;

type AsyncPaginateSelectProps = AsyncPaginateProps<
	unknown,
	GroupBase<unknown>,
	Additional,
	boolean
>;

export type ReactSelectProps = SkeletonProps &
	(
		| Props
		| AsyncProps<unknown, boolean, GroupBase<unknown>>
		| CreatableAdditionalProps<unknown, GroupBase<unknown>>
		| AsyncPaginateSelectProps
	) & {
		styles?: StylesConfig<any>;
		isAsync?: boolean;
		isCreatable?: boolean;
		isAsyncPaginate?: boolean;
		isSolid?: boolean;
		isInvalid?: boolean;
		skeleton?: boolean;
	};

type ReactSelectStyles = {
	styles: StylesConfig<unknown, boolean, GroupBase<unknown>> | undefined;
};

export function ReactSelect({
	styles,
	isAsync,
	isCreatable,
	isAsyncPaginate,
	isSolid,
	isInvalid = false,
	skeleton = false,
	width,
	height,
	...props
}: ReactSelectProps) {
	function verifyColorOption(isSelected: boolean, isFocused: boolean) {
		return isSelected ? "#b6c3d3" : isFocused ? "#e0ecfc" : undefined;
	}

	const solidStyle: ReactSelectStyles = isSolid
		? {
				styles: {
					input: (baseStyles: CSSObjectWithLabel) => ({
						...baseStyles,
						color: "#5e6278",
					}),
					placeholder: (baseStyles: CSSObjectWithLabel) => ({
						...baseStyles,
						color: "#5e6278",
					}),
					option: (baseStyles: CSSObjectWithLabel, { isSelected, isFocused }) => ({
						...baseStyles,
						backgroundColor: verifyColorOption(isSelected, isFocused),
					}),
					singleValue: (baseStyles: CSSObjectWithLabel) => ({
						...baseStyles,
						color: "#5e6278",
					}),
					menu: (baseStyles: CSSObjectWithLabel) => ({
						...baseStyles,
						backgroundColor: "#f9f9f9",
					}),
					control: (baseStyles: CSSObjectWithLabel) => ({
						...baseStyles,
						borderColor: "transparent",
						backgroundColor: "#f9f9f9",
					}),
					multiValue: (baseStyles: CSSObjectWithLabel) => ({
						...baseStyles,
						backgroundColor: "#E6E6E6",
					}),
					...styles,
				},
		  }
		: {
				styles: {
					control: (baseStyles: CSSObjectWithLabel) => ({
						...baseStyles,
						border: isInvalid ? "1px solid #f1416c" : undefined,
					}),
					option: (baseStyles: CSSObjectWithLabel, { isDisabled }) => ({
						...baseStyles,
						color: isDisabled ? "#ccc" : "#1b1b29",
					}),
					...styles,
				},
		  };

	const defaultProps = {
		placeholder: "",
		closeMenuOnSelect: true,
		menuPlacement: "auto" as MenuPlacement,
		noOptionsMessage: () => "Nenhum resultado encontrado",
		components: {
			IndicatorSeparator: null,
		},
		theme: (theme: Theme) => ({
			...theme,
			colors: {
				...theme.colors,
				primary: isSolid ? "transparent" : "#b9b9b969",
			},
		}),
	};

	if (isAsyncPaginate && isCreatable) {
		const CreatableAsyncPaginateSelect = withAsyncPaginate(
			CreatableSelect
		) as AsyncPaginateCreatableType;

		return (
			<>
				{skeleton ? (
					<Skeleton width={width} height={height} />
				) : (
					<CreatableAsyncPaginateSelect
						loadingMessage={() => "Carregando..."}
						{...defaultProps}
						{...solidStyle}
						{...(props as AsyncPaginateSelectProps)}
					/>
				)}
			</>
		);
	} else if (isAsync && isCreatable) {
		return (
			<>
				{skeleton ? (
					<Skeleton width={width} height={height} />
				) : (
					<AsyncCreatableSelect
						loadingMessage={() => "Carregando..."}
						{...defaultProps}
						{...solidStyle}
						{...(props as AsyncSelectProps)}
					/>
				)}
			</>
		);
	} else if (isAsync) {
		return (
			<>
				{skeleton ? (
					<Skeleton width={width} height={height} />
				) : (
					<AsyncSelect
						loadingMessage={() => "Carregando..."}
						{...defaultProps}
						{...solidStyle}
						{...(props as AsyncSelectProps)}
					/>
				)}
			</>
		);
	} else if (isCreatable) {
		return (
			<>
				{skeleton ? (
					<Skeleton width={width} height={height} />
				) : (
					<CreatableSelect {...defaultProps} {...solidStyle} {...props} />
				)}
			</>
		);
	} else if (isAsyncPaginate) {
		return (
			<AsyncPaginate
				loadingMessage={() => "Carregando..."}
				{...defaultProps}
				{...solidStyle}
				{...(props as AsyncPaginateSelectProps)}
			/>
		);
	} else {
		return (
			<>
				{skeleton ? (
					<Skeleton width={width} height={height} />
				) : (
					<Select {...defaultProps} {...solidStyle} {...props} />
				)}
			</>
		);
	}
}
