import { createFilter } from "react-select";

import { medicalRegulationInitialValues } from "./initialValues/medicalRegulation";
import { sceneRegisterInitialValues } from "./initialValues/sceneRegister";

export function filterSelectedDiagnosticHypotheses(
	candidate: ReactSelectFilterOption<unknown>,
	input: string,
	values: typeof medicalRegulationInitialValues | typeof sceneRegisterInitialValues
) {
	const { value } = candidate;

	const selectedDiagnosticHypothesis = values.diagnostic_hypotheses.find(
		(hypothesis) => hypothesis.diagnostic_hypothesis_id === value
	);

	const filteredDiagnosticHypotheses = values.diagnostic_hypotheses.filter(
		(hypothesis) => hypothesis.diagnostic_hypothesis_id !== value
	);

	const hasSameNature = filteredDiagnosticHypotheses.filter(
		(hypothesis) => hypothesis.nature_type_id === selectedDiagnosticHypothesis?.nature_type_id
	);

	if (selectedDiagnosticHypothesis && hasSameNature.length) {
		return false;
	}

	const defaultFilter = createFilter();

	return defaultFilter(candidate, input);
}
