import { createContext, ReactNode, useState } from "react";

import { attendanceConsultationInitialValues } from "@/utils/initialValues/attendanceConsultation";

type ActiveContent = "filter" | "results";

type TypeOfAttendanceConsultationInitialValues = typeof attendanceConsultationInitialValues;

type AttendanceConsultationContextProps = {
	activeContent: ActiveContent;
	setActiveContent: (activeContent: ActiveContent) => void;
	paginatedAttendanceConsultation: PaginatedAttendanceConsultation;
	setPaginatedAttendanceConsultation: (
		paginatedAttendanceConsultation: PaginatedAttendanceConsultation
	) => void;
	attendanceConsultationValues: TypeOfAttendanceConsultationInitialValues | null;
	setAttendanceConsultationValues: (
		attendanceConsultationValues: TypeOfAttendanceConsultationInitialValues | null
	) => void;
};

type AttendanceConsultationProviderProps = {
	children: ReactNode;
};

export const AttendanceConsultationContext = createContext({
	activeContent: "filter",
	setActiveContent: () => {},
	paginatedAttendanceConsultation: {} as PaginatedAttendanceConsultation,
	setPaginatedAttendanceConsultation: () => {},
	attendanceConsultationValues: null,
	setAttendanceConsultationValues: () => {},
} as AttendanceConsultationContextProps);

export function AttendanceConsultationProvider({ children }: AttendanceConsultationProviderProps) {
	const [activeContent, setActiveContent] = useState<ActiveContent>("filter");
	const [paginatedAttendanceConsultation, setPaginatedAttendanceConsultation] =
		useState<PaginatedAttendanceConsultation>({} as PaginatedAttendanceConsultation);
	const [attendanceConsultationValues, setAttendanceConsultationValues] =
		useState<TypeOfAttendanceConsultationInitialValues | null>(null);

	return (
		<AttendanceConsultationContext.Provider
			value={{
				activeContent,
				setActiveContent,
				paginatedAttendanceConsultation,
				setPaginatedAttendanceConsultation,
				attendanceConsultationValues,
				setAttendanceConsultationValues,
			}}
		>
			{children}
		</AttendanceConsultationContext.Provider>
	);
}
