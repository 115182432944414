import { LocationType, LocationTypeMessages } from "@/enums/LocationType";

export const locationTypeOptions = [
	{
		label: LocationTypeMessages[LocationType.PublicRoad],
		value: LocationType.PublicRoad,
	},
	{
		label: LocationTypeMessages[LocationType.Residence],
		value: LocationType.Residence,
	},
	{
		label: LocationTypeMessages[LocationType.School],
		value: LocationType.School,
	},
	{
		label: LocationTypeMessages[LocationType.Company],
		value: LocationType.Company,
	},
	{
		label: LocationTypeMessages[LocationType.RestHome],
		value: LocationType.RestHome,
	},
	{
		label: LocationTypeMessages[LocationType.EmergencyCareUnit],
		value: LocationType.EmergencyCareUnit,
	},
	{
		label: LocationTypeMessages[LocationType.Hospital],
		value: LocationType.Hospital,
	},
	{
		label: LocationTypeMessages[LocationType.BasicSupportUnit],
		value: LocationType.BasicSupportUnit,
	},
	{
		label: LocationTypeMessages[LocationType.RuralArea],
		value: LocationType.RuralArea,
	},
	{
		label: LocationTypeMessages[LocationType.PoliceStationOrPrison],
		value: LocationType.PoliceStationOrPrison,
	},
	{
		label: LocationTypeMessages[LocationType.Others],
		value: LocationType.Others,
	},
];
