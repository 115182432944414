import { ButtonHTMLAttributes, ReactNode } from "react";
import classNames from "clsx";

import "./styles.scss";

type LateralToolbarRootProps = {
	children: ReactNode;
};

type LateralToolbarButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	reverse?: boolean;
	showNotificationIndicator?: boolean;
};

const LateralToolbarRoot = ({ children }: LateralToolbarRootProps) => (
	<div className="d-flex engage-toolbar position-fixed fw-bolder z-index-2 top-50 end-0 transform-90 gap-2 px-5 mt-10rem">
		{children}
	</div>
);

const LateralToolbarButton = ({
	children,
	reverse = false,
	showNotificationIndicator = false,
	...rest
}: LateralToolbarButtonProps) => (
	<button
		className={classNames("btn button-bg-orange-color-white lh-1", !reverse && "transform-180")}
		{...rest}
	>
		{showNotificationIndicator && (
			<div className="rounded-circle bg-navy-blue position-absolute h-1.25rem w-1.25rem left-92.5 bottom-82.5" />
		)}
		<span className="fw-normal">{children}</span>
	</button>
);

export const LateralToolbar = LateralToolbarRoot as typeof LateralToolbarRoot & {
	Button: typeof LateralToolbarButton;
};

LateralToolbar.Button = LateralToolbarButton;
