import { useEffect, useRef, useState } from "react";

import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { useSwal } from "@/hooks/useSwal";
import { useTokenApi } from "@/hooks/useTokenApi";
import { VehicleType, VehicleTypeMessages } from "@/enums/VehicleType";

import { InsertTokenModal } from "@/components/Modals/InsertTokenModal";
import { PercentageBadge } from "@/components/Badges/PercentageBadge";
import { CardWithIcon } from "@/components/Cards/CardWithIcon";
import { ChartCard } from "@/components/Cards/ChartCard";

import "./styles.scss";

type VehicleTypeProps = {
	active?: number;
	committed?: number;
	in_maintenance?: number;
	solicited?: number;
	unavailable?: number;
	total?: number;
	vehicle_type_id: VehicleType;
};

type FleetByType = {
	fleet: VehicleTypeProps[];
	percentage_available: string;
};

export function TotemDashboard() {
	const [occurrencePerDiagnosticHypothesis, setOccurrencePerDiagnosticHypothesis] =
		useState<OccurrencePerDiagnosticHypothesis>({} as OccurrencePerDiagnosticHypothesis);
	const [averageResponseTime, setAverageResponseTime] = useState<AverageResponseTime>(
		{} as AverageResponseTime
	);
	const [attendancesResults, setAttendancesResults] = useState<AttendancesResult>(
		{} as AttendancesResult
	);
	const [attendancesByType, setAttendancesByType] = useState<AttendanceByType>(
		{} as AttendanceByType
	);
	const [fleetsByType, setFleetsByType] = useState<FleetByType>({} as FleetByType);
	const [showInsertTokenModal, setShowInsertTokenModal] = useState(false);
	const [accessToken, setAccessToken] = useState("");
	const [prankCalls, setPrankCalls] = useState(0);

	const tokenIsValidRef = useRef(false);
	const { tokenApi } = useTokenApi();
	const { currentAuth } = useAuth();
	const { Toast } = useSwal();
	const { api } = useApi();

	const { totemAccessToken } = localStorage;

	const handleShowInsertTokenModal = () => setShowInsertTokenModal(true);
	const handleCloseInsertTokenModal = () => setShowInsertTokenModal(false);

	async function getAverageResponseTime() {
		try {
			const { data } = await tokenApi.get("unauthenticated/dashboard/average-response-time");

			setAverageResponseTime(data);
			tokenIsValidRef.current = true;
		} catch (error: any) {
			console.log(error);

			const { status } = error.response;

			if (status === 401 && currentAuth) {
				await getToken();

				return;
			}

			if (status === 401) {
				tokenIsValidRef.current = false;

				Toast.fire({
					icon: "error",
					title: "Token de acesso inválido!",
				});

				return;
			}

			Toast.fire({
				icon: "error",
				title: "Erro ao buscar dados!",
			});
		}
	}

	const getAverageResponseTimePerURC = (URCName: string) => {
		const averageResponseTimePerURC = averageResponseTime.per_urc?.find(
			(URC) => URC.urc_name === URCName
		);

		return averageResponseTimePerURC;
	};

	async function fetchAttendances() {
		try {
			const { data } = await tokenApi.get("unauthenticated/dashboard/occurrences");

			setAttendancesResults(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function getToken() {
		try {
			const { data } = await api.post("/unauthenticated-access-tokens");

			localStorage.setItem("totemAccessToken", data.access_token);

			setAccessToken(data.access_token);
		} catch (error) {
			console.error(error);

			Toast.fire({
				icon: "error",
				title: "Erro ao gerar token!",
			});
		}
	}

	const getAttendancesPerURC = (URCName: string) => {
		const totalOccurrencesPerURC = attendancesResults?.total_occurrences_per_urc?.find(
			(URC) => URC.urc_name === URCName
		);

		return totalOccurrencesPerURC;
	};

	// primary attendance

	const [periodPrimaryAttendanceChart, setPeriodPrimaryAttendanceChart] =
		useState<PeriodType>("current");

	const primaryAttendance = attendancesResults?.primary;
	const thePrimaryAttendancePeriodIsCurrent = periodPrimaryAttendanceChart === "current";
	const currentPeriodPrimaryAttendanceData = primaryAttendance?.current_period?.map(
		(attendance) => attendance.last_status_updated_at
	);
	const lastPeriodPrimaryAttendanceData = primaryAttendance?.last_period?.map(
		(attendance) => attendance.last_status_updated_at
	);
	const primaryAttendanceCount = thePrimaryAttendancePeriodIsCurrent
		? primaryAttendance?.current_period_count
		: primaryAttendance?.last_period_count;

	function handleChangePeriodPrimaryAttendanceChartFunction() {
		if (thePrimaryAttendancePeriodIsCurrent) {
			setPeriodPrimaryAttendanceChart("last");

			return;
		}

		setPeriodPrimaryAttendanceChart("current");
	}

	// secondary attendance

	const [periodSecondaryAttendanceChart, setPeriodSecondaryAttendanceChart] =
		useState<PeriodType>("current");

	const secondaryAttendance = attendancesResults?.secondary;
	const theSecondaryAttendancePeriodIsCurrent = periodSecondaryAttendanceChart === "current";
	const currentPeriodSecondaryAttendanceData = secondaryAttendance?.current_period?.map(
		(attendance) => attendance.last_status_updated_at
	);
	const lastPeriodSecondaryAttendanceData = secondaryAttendance?.last_period?.map(
		(attendance) => attendance.last_status_updated_at
	);
	const secondaryAttendanceCount = theSecondaryAttendancePeriodIsCurrent
		? secondaryAttendance?.current_period_count
		: secondaryAttendance?.last_period_count;

	function handleChangePeriodSecondaryAttendanceChartFunction() {
		if (theSecondaryAttendancePeriodIsCurrent) {
			setPeriodSecondaryAttendanceChart("last");

			return;
		}

		setPeriodSecondaryAttendanceChart("current");
	}

	//  other functions

	async function fetchAttendancesBaseStatus() {
		try {
			const { data } = await tokenApi.get(
				"unauthenticated/dashboard/attendance-base-statuses",
				{
					params: {
						totem_dashboard: 1,
					},
				}
			);

			setAttendancesByType(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function getPrankCalls() {
		try {
			const { data } = await tokenApi.get("unauthenticated/dashboard/prank-calls");

			setPrankCalls(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function getFleetsByType() {
		try {
			const { data } = await tokenApi.get("unauthenticated/dashboard/fleet");

			setFleetsByType(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function getOccurrencePerDiagnosticHypothesis() {
		try {
			const { data } = await tokenApi.get(
				"unauthenticated/dashboard/occurrences-per-diagnostic-hypothesis"
			);

			setOccurrencePerDiagnosticHypothesis(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function dashboardFetches() {
		getAverageResponseTime();
		fetchAttendances();
		fetchAttendancesBaseStatus();
		getPrankCalls();
		getFleetsByType();
		getOccurrencePerDiagnosticHypothesis();
	}

	async function checkThatTheTokenIsValid() {
		try {
			if (!totemAccessToken && !currentAuth) {
				handleShowInsertTokenModal();

				return;
			}

			if (!totemAccessToken && currentAuth) {
				await getToken();

				return;
			}

			await getAverageResponseTime();

			if (!tokenIsValidRef.current && !currentAuth) {
				handleShowInsertTokenModal();

				return;
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		checkThatTheTokenIsValid();
	}, [accessToken]);

	useEffect(() => {
		if (!tokenIsValidRef.current) {
			return;
		}

		dashboardFetches();

		const interval = setInterval(() => {
			dashboardFetches();
		}, 30000);

		return () => {
			clearInterval(interval);
		};
	}, [tokenIsValidRef.current]);

	return (
		<>
			<div className="d-flex gap-4">
				<Card className="w-100">
					<Card.Body className="px-6 py-5">
						<Row>
							<Col sm={8}>
								<h1 className="mb-0">
									{averageResponseTime.primary_attendance_average}
								</h1>
							</Col>

							<Col className="d-flex flex-center">
								<PercentageBadge
									percentage={averageResponseTime.primary_attendance_change}
								/>
							</Col>
						</Row>

						<span className="fs-7">
							Total do tempo médio de resposta ocorrência primária
						</span>
					</Card.Body>
				</Card>

				<Card className="w-100">
					<Card.Body className="px-6 py-5">
						<Row>
							<Col sm={8}>
								<h1 className="mb-0">
									{averageResponseTime.secondary_attendance_average}
								</h1>
							</Col>

							<Col className="d-flex flex-center">
								<PercentageBadge
									percentage={averageResponseTime.secondary_attendance_change}
								/>
							</Col>
						</Row>

						<span className="fs-7">
							Total do tempo médio de resposta ocorrência secundária
						</span>
					</Card.Body>
				</Card>
			</div>

			<h2 className="text-white my-4">Tempo de resposta por CRU - últimas 12 horas</h2>

			<div className="d-flex flex-column gap-5">
				<div className="d-flex gap-4">
					<CardWithIcon
						cardBodyClassName="px-6 py-5"
						icon={
							<img
								src="media/gifs/time1-clock.gif"
								alt="ícone de relógio"
								width={75}
								height={75}
							/>
						}
					>
						<Row>
							<Col>
								<h5 className="mb-0">Eusébio</h5>
							</Col>

							<Col className="d-flex flex-column gap-2">
								<div>
									<div>Primária</div>
									<h6 className="mb-0">
										{getAverageResponseTimePerURC("CRU Eusébio")
											?.primary_attendance_average_response_time || 0}
									</h6>
								</div>

								<div>
									<div>Secundária</div>
									<h6 className="mb-0">
										{getAverageResponseTimePerURC("CRU Eusébio")
											?.secondary_attendance_average_response_time || 0}
									</h6>
								</div>
							</Col>
						</Row>
					</CardWithIcon>

					<CardWithIcon
						cardBodyClassName="px-6 py-5"
						icon={
							<img
								src="media/gifs/time2-clock.gif"
								alt="ícone de relógio"
								width={75}
								height={75}
							/>
						}
					>
						<Row>
							<Col>
								<h5 className="mb-0">Sobral</h5>
							</Col>

							<Col className="d-flex flex-column gap-2">
								<div>
									<div>Primária</div>
									<h6 className="mb-0">
										{getAverageResponseTimePerURC("CRU Sobral")
											?.primary_attendance_average_response_time || 0}
									</h6>
								</div>

								<div>
									<div>Secundária</div>
									<h6 className="mb-0">
										{getAverageResponseTimePerURC("CRU Sobral")
											?.secondary_attendance_average_response_time || 0}
									</h6>
								</div>
							</Col>
						</Row>
					</CardWithIcon>

					<CardWithIcon
						cardBodyClassName="px-6 py-5"
						icon={
							<img
								src="media/gifs/time3-clock.gif"
								alt="ícone de relógio"
								width={75}
								height={75}
							/>
						}
					>
						<Row>
							<Col>
								<h5 className="mb-0">Juazeiro do Norte</h5>
							</Col>

							<Col className="d-flex flex-column gap-2">
								<div>
									<div>Primária</div>
									<h6 className="mb-0">
										{getAverageResponseTimePerURC("CRU Juazeiro do Norte")
											?.primary_attendance_average_response_time || 0}
									</h6>
								</div>

								<div>
									<div>Secundária</div>
									<h6 className="mb-0">
										{getAverageResponseTimePerURC("CRU Juazeiro do Norte")
											?.secondary_attendance_average_response_time || 0}
									</h6>
								</div>
							</Col>
						</Row>
					</CardWithIcon>
				</div>

				{/* total occurrences in the last 12 hours */}

				<div className="d-flex gap-4">
					<ChartCard
						id="primary-attendances-chart-card"
						className="w-100"
						cardBodyClassName="px-6 pt-5 pb-0"
						title="Total de ocorrências primárias nas últimas 12 horas"
						number={primaryAttendanceCount}
						period={periodPrimaryAttendanceChart}
						change={attendancesResults?.primary?.change}
						data={
							thePrimaryAttendancePeriodIsCurrent
								? currentPeriodPrimaryAttendanceData
								: lastPeriodPrimaryAttendanceData
						}
						handleChangePeriodChartFunction={() =>
							handleChangePeriodPrimaryAttendanceChartFunction()
						}
					/>

					<ChartCard
						id="secondary-attendances-chart-card"
						className="w-100"
						cardBodyClassName="px-6 pt-5 pb-0"
						title="Total de ocorrências secundária nas últimas 12 horas"
						number={secondaryAttendanceCount}
						period={periodSecondaryAttendanceChart}
						change={attendancesResults?.secondary?.change}
						data={
							theSecondaryAttendancePeriodIsCurrent
								? currentPeriodSecondaryAttendanceData
								: lastPeriodSecondaryAttendanceData
						}
						handleChangePeriodChartFunction={() =>
							handleChangePeriodSecondaryAttendanceChartFunction()
						}
					/>
				</div>
			</div>

			<h2 className="text-samu my-4">Total de ocorrências por CRU - últimas 12 horas</h2>

			<div className="d-flex flex-column gap-5">
				<div className="d-flex gap-4">
					<Card className="w-100">
						<Card.Body className="px-6 py-5">
							<h5>Eusébio</h5>

							<div className="d-flex">
								<Col className="d-flex flex-column">
									<div>Primária</div>
									<h1 className="mb-0">
										{getAttendancesPerURC("CRU Eusébio")
											?.primary_attendance_count || 0}
									</h1>
								</Col>

								<Col className="d-flex flex-column">
									<div>Secundária</div>
									<h1 className="mb-0">
										{getAttendancesPerURC("CRU Eusébio")
											?.secondary_attendance_count || 0}
									</h1>
								</Col>
							</div>
						</Card.Body>
					</Card>

					<Card className="w-100">
						<Card.Body className="px-6 py-5">
							<h5>Sobral</h5>

							<div className="d-flex">
								<Col className="d-flex flex-column">
									<div>Primária</div>
									<h1 className="mb-0">
										{getAttendancesPerURC("CRU Sobral")
											?.primary_attendance_count || 0}
									</h1>
								</Col>

								<Col className="d-flex flex-column">
									<div>Secundária</div>
									<h1 className="mb-0">
										{getAttendancesPerURC("CRU Sobral")
											?.secondary_attendance_count || 0}
									</h1>
								</Col>
							</div>
						</Card.Body>
					</Card>

					<Card className="w-100">
						<Card.Body className="px-6 py-5">
							<h5>Juazeiro do Norte</h5>

							<div className="d-flex">
								<Col className="d-flex flex-column">
									<div>
										<div>Primária</div>
										<h1 className="mb-0">
											{getAttendancesPerURC("CRU Juazeiro do Norte")
												?.primary_attendance_count || 0}
										</h1>
									</div>
								</Col>

								<Col className="d-flex flex-column">
									<div>
										<div>Secundária</div>
										<h1 className="mb-0">
											{getAttendancesPerURC("CRU Juazeiro do Norte")
												?.secondary_attendance_count || 0}
										</h1>
									</div>
								</Col>
							</div>
						</Card.Body>
					</Card>
				</div>

				{/* statistics */}

				<div className="d-flex gap-4">
					<Col>
						<Card className="bg-yellow">
							<Card.Body className="d-flex flex-column px-6 py-5">
								<div className="d-flex gap-4">
									<h3 className="d-flex align-items-center text-white mb-0">
										Aguardando regulação
									</h3>

									<div className="d-flex flex-grow justify-content-end text-white fs-3rem">
										{attendancesByType.awaiting_medical_regulation || 0}
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>

					<Col>
						<Card className="bg-blue">
							<Card.Body className="d-flex flex-column px-6 py-5">
								<div className="d-flex gap-4">
									<h3 className="d-flex align-items-center text-white mb-0">
										Aguardando empenho da viatura
									</h3>

									<div className="text-white fs-3rem">
										{attendancesByType.awaiting_vehicle_commitment || 0}
									</div>
								</div>
							</Card.Body>
						</Card>
					</Col>

					<Col>
						<Card className="bg-pink">
							<Card.Body className="d-flex flex-column px-6 py-5">
								<div className="d-flex gap-4">
									<h3 className="d-flex align-items-center text-white mb-0">
										Quantidade de trote nas últimas 12 horas
									</h3>

									<div className="text-white fs-3rem">{prankCalls}</div>
								</div>
							</Card.Body>
						</Card>
					</Col>
				</div>

				{/* fleet in real time */}

				<Card>
					<Card.Body className="p-0">
						<div className="px-6 pt-5">
							<h3 className="mb-0">Frota em tempo real</h3>

							<Table responsive className="table-row-dashed table-row-gray-300">
								<thead>
									<tr className="fw-bold fs-6">
										<th>Tipo</th>
										<th className="text-center">Qtd. total</th>
										<th className="text-center">Manut.</th>
										<th className="text-center">Indis. temp.</th>
										<th className="text-center">Solic.</th>
										<th className="text-center">Empen.</th>
										<th className="text-center">Ativa</th>
									</tr>
								</thead>

								<tbody>
									{fleetsByType.fleet?.map((fleet, index) => (
										<tr
											key={`vehicle-type-option-${index}`}
											className="fw-bold"
										>
											<td>
												{
													VehicleTypeMessages[
														Number(
															fleet.vehicle_type_id || 0
														) as keyof typeof VehicleTypeMessages
													]
												}
											</td>
											<td className="text-center">{fleet.total || 0}</td>
											<td className="text-center">
												{fleet.in_maintenance || 0}
											</td>
											<td className="text-center">
												{fleet.unavailable || 0}
											</td>
											<td className="text-center">{fleet.solicited || 0}</td>
											<td className="text-center">{fleet.committed || 0}</td>
											<td className="text-center">{fleet.active || 0}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>

						<div className="d-flex gap-2 pe-6 pb-5 ps-4">
							<img
								src="media/gifs/ambulance.gif"
								alt="ícone de relógio"
								width={40}
								height={40}
							/>

							<div className="d-flex flex-column justify-content-center w-100">
								<h6>
									Frota disponível:{" "}
									{Number(fleetsByType.percentage_available) || 0}%
								</h6>
								<div className="bg-ice rounded w-100 h-10px">
									<div
										className="bg-light-blue rounded h-10px"
										style={{ width: `${fleetsByType.percentage_available}%` }}
									/>
								</div>
							</div>
						</div>
					</Card.Body>
				</Card>
			</div>

			<h2 className="text-samu my-4">Hipóteses diagnósticas - últimas 24 horas</h2>

			<div className="d-flex gap-4">
				<CardWithIcon
					cardBodyClassName="px-6 py-5"
					icon={
						<img
							src="media/gifs/brain.gif"
							alt="ícone de cérebro"
							width={90}
							height={90}
						/>
					}
					bigAnimatedCard
				>
					<h3 className="mb-0">AVC - Acidente vascular cerebral</h3>
					<div className="fs-3rem text-end">
						{occurrencePerDiagnosticHypothesis.cva || 0}
					</div>
				</CardWithIcon>

				<CardWithIcon
					cardBodyClassName="px-6 py-5"
					icon={
						<img
							src="media/gifs/heart.gif"
							alt="ícone de coração"
							width={90}
							height={90}
						/>
					}
					bigAnimatedCard
				>
					<h3 className="mb-0">IAM - Infarto agudo do miocárdio</h3>
					<div className="fs-3rem text-end">
						{occurrencePerDiagnosticHypothesis.ami || 0}
					</div>
				</CardWithIcon>

				<CardWithIcon
					cardBodyClassName="d-flex flex-column px-6 py-5"
					icon={
						<img
							src="media/gifs/prank-call.gif"
							alt="ícone de telefone"
							width={90}
							height={90}
						/>
					}
					bigAnimatedCard
				>
					<h3 className="mb-0">Outros</h3>
					<div className="d-flex flex-1 flex-end fs-3rem">
						{occurrencePerDiagnosticHypothesis.others || 0}
					</div>
				</CardWithIcon>
			</div>

			<InsertTokenModal
				showInsertTokenModal={showInsertTokenModal}
				handleCloseInsertTokenModal={handleCloseInsertTokenModal}
				setAccessToken={setAccessToken}
			/>
		</>
	);
}
