import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useAttendanceConsultation } from "@/hooks/useAttendanceConsultation";

import { DrawerMessenger, ActivityDrawer, InviteUsers, UpgradePlan } from "../partials";

import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { AsideDefault } from "./components/aside/AsideDefault";
import { RightToolbar } from "../partials/layout/RightToolbar";
import { MenuComponent } from "../assets/ts/components";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";

import "./styles.scss";

const MasterLayout = () => {
	const { setAttendanceConsultationValues, setActiveContent } = useAttendanceConsultation();
	const location = useLocation();

	useEffect(() => {
		setTimeout(() => {
			MenuComponent.reinitialization();
		}, 500);
	}, [location.key]);

	useEffect(() => {
		const routesWhereAttendanceConsultationDataShouldBeKept = [
			"/relatorios/consultas",
			"/ocorrencias/resumo",
		];

		const attendanceConsultationDataShouldBeKept =
			routesWhereAttendanceConsultationDataShouldBeKept.some((route) =>
				location.pathname.includes(route)
			);

		if (!attendanceConsultationDataShouldBeKept) {
			setAttendanceConsultationValues(null);
			setActiveContent("filter");
		}
	}, [location.pathname]);

	return (
		<>
			<div className="page d-flex flex-row flex-column-fluid">
				<AsideDefault />
				<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
					<HeaderWrapper />

					<div
						id="kt_content"
						className="content d-flex flex-column flex-column-fluid bg-opaque-white m-0 p-0"
					>
						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="bg-samu py-4 w-100 h-190px">
								<Content>
									<Outlet />
								</Content>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ActivityDrawer />
			<RightToolbar />
			<DrawerMessenger />

			<InviteUsers />
			<UpgradePlan />
			<ScrollTop />
		</>
	);
};

export { MasterLayout };
