import { useEffect, useState } from "react";

import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { AttendanceStatus } from "@/enums/AttendanceStatus";
import { VehicleType, VehicleTypeMessages } from "@/enums/VehicleType";

import { ColoredCard } from "@/components/Dashboards/AdminDashboard/ColoredCard";
import { CardWithIcon } from "@/components/Cards/CardWithIcon";
import { ChartCard } from "@/components/Cards/ChartCard";

import "./styles.scss";

type VehicleTypeProps = {
	active?: number;
	committed?: number;
	in_maintenance?: number;
	inactive?: number;
	solicited?: number;
	unavailable?: number;
	total?: number;
	vehicle_type_id: VehicleType;
};

type FleetByType = {
	fleet: VehicleTypeProps[];
	percentage_available: string;
};

type BaseAttendanceStatusesResult = {
	attendance_status_id: AttendanceStatus;
	total: number;
};

export function AdminDashboard() {
	const { api } = useApi();
	const { currentAuth } = useAuth();

	const [availableVehiclesByType, setAvailableVehiclesByType] = useState<
		VehicleTypeProps[] | null
	>(null);

	const [baseAttendanceStatuses, setBaseAttendanceStatuses] = useState<
		BaseAttendanceStatusesResult[] | null
	>(null);

	const [removalsResults, setRemovalsResults] = useState<AttendancePeriod>(
		{} as AttendancePeriod
	);

	const [attendancesResults, setAttendancesResults] = useState<AttendancesResult>(
		{} as AttendancesResult
	);

	const [retainedPatrimoniesCount, setRetainedPatrimoniesCount] = useState(0);
	const [committedVehicles, setCommittedVehicles] = useState({} as CommittedVehiclesResult);

	const URCId = currentAuth?.current_urgency_regulation_center.id;
	const config = { params: { urc_id: URCId } };

	async function fetchAvailableVehicles() {
		try {
			const { data } = await api.get<FleetByType>("/dashboard/fleet", {
				params: { urc_id: URCId, only_active: 1 },
			});

			setAvailableVehiclesByType(data.fleet);
		} catch (error) {
			console.error(error);
		}
	}

	async function fetchAttendancesBaseStatus() {
		try {
			const { data } = await api.get<BaseAttendanceStatusesResult[]>(
				"/dashboard/attendance-base-statuses",
				config
			);

			setBaseAttendanceStatuses(data);
		} catch (error) {
			console.error(error);
		}
	}

	async function fetchRetainedPatrimonies() {
		try {
			const { data } = await api.get("dashboard/retained-patrimonies", config);

			setRetainedPatrimoniesCount(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function fetchRemovals() {
		try {
			const { data } = await api.get("dashboard/removals", config);

			setRemovalsResults(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function fetchCommittedVehicles() {
		try {
			const { data } = await api.get("dashboard/committed-vehicles", config);

			setCommittedVehicles(data);
		} catch (error) {
			console.log(error);
		}
	}

	async function fetchAttendances() {
		try {
			const { data } = await api.get("dashboard/occurrences", config);

			setAttendancesResults(data);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		fetchAvailableVehicles();
		fetchAttendancesBaseStatus();
		fetchRetainedPatrimonies();
		fetchCommittedVehicles();
		fetchRemovals();
		fetchAttendances();
	}, []);

	const [periodRemovalsChart, setPeriodRemovalsChart] = useState<PeriodType>("current");
	const [periodPrimaryAttendanceChart, setPeriodPrimaryAttendanceChart] =
		useState<PeriodType>("current");
	const [periodSecondaryAttendanceChart, setPeriodSecondaryAttendanceChart] =
		useState<PeriodType>("current");

	const currentPeriodRemovalsData = removalsResults?.current_period?.map(
		(attendance) => attendance.last_status_updated_at
	);
	const lastPeriodRemovalsData = removalsResults?.last_period?.map(
		(attendance) => attendance.last_status_updated_at
	);

	const currentPeriodPrimaryAttendanceData = attendancesResults?.primary?.current_period?.map(
		(attendance) => attendance.last_status_updated_at
	);
	const lastPeriodPrimaryAttendanceData = attendancesResults?.primary?.last_period?.map(
		(attendance) => attendance.last_status_updated_at
	);

	const currentPeriodSecondaryAttendanceData = attendancesResults?.secondary?.current_period?.map(
		(attendance) => attendance.last_status_updated_at
	);
	const lastPeriodSecondaryAttendanceData = attendancesResults?.secondary?.last_period?.map(
		(attendance) => attendance.last_status_updated_at
	);

	const removalsCount =
		periodRemovalsChart === "current"
			? removalsResults.current_period_count
			: removalsResults.last_period_count;
	const primaryAttendanceCount =
		periodPrimaryAttendanceChart === "current"
			? attendancesResults?.primary?.current_period_count
			: attendancesResults?.primary?.last_period_count;
	const secondaryAttendanceCount =
		periodSecondaryAttendanceChart === "current"
			? attendancesResults?.secondary?.current_period_count
			: attendancesResults?.secondary?.last_period_count;

	function handleChangePeriodRemovalsChartFunction() {
		if (periodRemovalsChart === "current") {
			setPeriodRemovalsChart("last");

			return;
		}

		setPeriodRemovalsChart("current");
	}

	function handleChangePeriodPrimaryAttendanceChartFunction() {
		if (periodPrimaryAttendanceChart === "current") {
			setPeriodPrimaryAttendanceChart("last");

			return;
		}

		setPeriodPrimaryAttendanceChart("current");
	}

	function handleChangePeriodSecondaryAttendanceChartFunction() {
		if (periodSecondaryAttendanceChart === "current") {
			setPeriodSecondaryAttendanceChart("last");

			return;
		}

		setPeriodSecondaryAttendanceChart("current");
	}

	return (
		<div className="d-flex gap-5 flex-column">
			<Row>
				<Col xs={6}>
					<ChartCard
						id="removals-chart-card"
						title="Remoção dos pacientes das últimas 12 horas"
						number={removalsCount}
						period={periodRemovalsChart}
						change={removalsResults?.change}
						data={
							periodRemovalsChart === "current"
								? currentPeriodRemovalsData
								: lastPeriodRemovalsData
						}
						handleChangePeriodChartFunction={() =>
							handleChangePeriodRemovalsChartFunction()
						}
					/>
				</Col>

				<Col xs={12} md={6}>
					<Row className="align-items-center">
						<Col xs={12} md={4}>
							<CardWithIcon
								className="h-21.85rem"
								icon={
									<img
										alt="ícone de prancheta"
										src="/media/icons/clipboard.svg"
									/>
								}
							>
								<Card.Title className="fs-3rem">
									{baseAttendanceStatuses?.find(
										(result) =>
											result.attendance_status_id ===
											AttendanceStatus.AwaitingMedicalRegulation
									)?.total || 0}
								</Card.Title>

								<Card.Subtitle className="text-muted">
									Aguardando regulação
								</Card.Subtitle>
							</CardWithIcon>
						</Col>

						<Col xs={12} md={4}>
							<CardWithIcon
								className="h-21.85rem"
								icon={
									<img
										alt="ícone de espera"
										src="/media/icons/waiting-room-sign.svg"
									/>
								}
							>
								<Card.Title className="fs-3rem">
									{baseAttendanceStatuses?.find(
										(result) =>
											result.attendance_status_id ===
											AttendanceStatus.AwaitingVehicleCommitment
									)?.total || 0}
								</Card.Title>

								<Card.Subtitle className="text-muted">
									Aguardando empenho da viatura
								</Card.Subtitle>
							</CardWithIcon>
						</Col>

						<Col xs={12} md={4}>
							<CardWithIcon
								className="h-21.85rem"
								icon={
									<img alt="ícone de condutor" src="/media/icons/conductor.svg" />
								}
							>
								<Card.Title className="fs-3rem">
									{baseAttendanceStatuses?.find(
										(result) =>
											result.attendance_status_id ===
											AttendanceStatus.VehicleSent
									)?.total || 0}
								</Card.Title>

								<Card.Subtitle className="text-muted">
									Viatura enviada
								</Card.Subtitle>
							</CardWithIcon>
						</Col>
					</Row>
				</Col>
			</Row>

			<div className="d-flex gap-4">
				<ColoredCard
					cardSubtitle="Registro de cena"
					background="bg-warning"
					className="w-20"
					value={
						baseAttendanceStatuses?.find(
							(result) =>
								result.attendance_status_id ===
								AttendanceStatus.InAttendanceSceneRecord
						)?.total || 0
					}
				/>
				<ColoredCard
					cardSubtitle="Viatura no destino"
					background="bg-primary"
					className="w-20"
					value={committedVehicles.vehicles_at_destination}
				/>
				<ColoredCard
					cardSubtitle="Aguardando liberação da VTR"
					background="bg-danger"
					className="w-20"
					value={committedVehicles.vehicles_awaiting_release}
				/>
				<ColoredCard
					cardSubtitle="Aguardando conduta"
					background="bg-orange"
					className="w-20"
					value={
						baseAttendanceStatuses?.find(
							(result) =>
								result.attendance_status_id === AttendanceStatus.AwaitingConduct
						)?.total || 0
					}
				/>
				<ColoredCard
					cardSubtitle="Equipamentos retidos"
					background="bg-success"
					className="w-20"
					value={retainedPatrimoniesCount}
				/>
			</div>

			<Row>
				<Col xs={6}>
					<ChartCard
						id="primary-attendances-chart-card"
						title="Total de ocorrências primárias nas últimas 12 horas"
						number={primaryAttendanceCount}
						period={periodPrimaryAttendanceChart}
						change={attendancesResults?.primary?.change}
						data={
							periodPrimaryAttendanceChart === "current"
								? currentPeriodPrimaryAttendanceData
								: lastPeriodPrimaryAttendanceData
						}
						handleChangePeriodChartFunction={() =>
							handleChangePeriodPrimaryAttendanceChartFunction()
						}
					/>
				</Col>

				<Col xs={6}>
					<ChartCard
						id="secondary-attendances-chart-card"
						title="Total de ocorrências secundária nas últimas 12 horas"
						number={secondaryAttendanceCount}
						period={periodSecondaryAttendanceChart}
						change={attendancesResults?.secondary?.change}
						data={
							periodSecondaryAttendanceChart === "current"
								? currentPeriodSecondaryAttendanceData
								: lastPeriodSecondaryAttendanceData
						}
						handleChangePeriodChartFunction={() =>
							handleChangePeriodSecondaryAttendanceChartFunction()
						}
					/>
				</Col>
			</Row>

			<Row>
				<Card>
					<Card.Body>
						<Card.Title className="fs-1">Quantidade de viaturas disponíveis</Card.Title>
						<Table responsive className="table-row-dashed table-row-gray-300">
							<thead>
								<tr className="fw-bold fs-4">
									<th>Tipo de Viatura</th>
									<th className="text-center">Quantidade</th>
								</tr>
							</thead>

							<tbody>
								{availableVehiclesByType?.map((fleet, index) => (
									<tr
										key={`vehicle-${index}`}
										className="fw-bold fs-6 text-muted"
									>
										<td>
											{
												VehicleTypeMessages[
													Number(
														fleet.vehicle_type_id || 0
													) as keyof typeof VehicleTypeMessages
												]
											}
										</td>
										<td className="text-center">{fleet.active || 0}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Card.Body>
				</Card>
			</Row>
		</div>
	);
}
