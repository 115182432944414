import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";

import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { unitTypeOptions } from "@/utils/options/unitType";
import { baseTypeOptions } from "@/utils/options/baseType";
import { vehicleTypeOptions } from "@/utils/options/vehicleType";
import {
	filterObjectExcluding,
	filterObjectReplacingNullWithEmptyString,
} from "@/utils/filterObject";
import { baseOrUnitInitialValues } from "@/utils/initialValues/baseOrUnit";
import { basesAndUnitsSchema } from "@/utils/validation/basesAndUnitsSchema";

import { SearchInputGroupFormControl } from "@/components/FormControl/SearchInputGroupFormControl";
import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { FederalUnitsSelect } from "@/components/Selects/FederalUnitsSelect";
import { CitiesSelect } from "@/components/Selects/CitiesSelect";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { RedAsterisk } from "@/components/RedAsterisk";
import { UrgencyRegulationCentersSelect } from "@/components/Selects/UrgencyRegulationCentersSelect";

type Props = {
	baseOrUnit?: BaseOrUnit;
	handleCloseEditModal?: () => void;
	fetchPaginatedBasesOrUnits?: (pageToUse?: number) => void;
};

export function RegistrationForm({
	baseOrUnit,
	handleCloseEditModal,
	fetchPaginatedBasesOrUnits,
}: Props) {
	const [isSearchingUnit, setIsSearchingUnit] = useState(false);
	const [search, setSearch] = useState("");
	const [error, setError] = useState("");
	const { Toast, toastRequestErrors } = useSwal();
	const location = useLocation();
	const { api } = useApi();

	const formik = useFormik({
		initialValues: baseOrUnitInitialValues,
		validationSchema: basesAndUnitsSchema,
		async onSubmit(values, { setSubmitting }) {
			const method = baseOrUnit ? "put" : "post";

			try {
				if (baseDataType) {
					await api[method](`/bases${baseOrUnit ? "/" + baseOrUnit.id : ""}`, values);
				} else {
					await api[method](`/units${baseOrUnit ? "/" + baseOrUnit.id : ""}`, values);
				}

				Toast.fire({
					icon: "success",
					title: `${baseDataType ? "Base" : "Unidade"} ${
						baseOrUnit ? "atualizada" : "cadastrada"
					} com sucesso!`,
				});

				handleResetForm();
				fetchPaginatedBasesOrUnits && fetchPaginatedBasesOrUnits();
				handleCloseEditModal && handleCloseEditModal();
			} catch (error: any) {
				console.log(error);

				if (error.isAxiosError && error.response.status === 422) {
					return toastRequestErrors(error.response.data.errors);
				}

				Toast.fire({
					icon: "error",
					title: "Erro ao realizar as alterações",
				});

				setSubmitting(false);
			}
		},
	});

	const { errors, values } = formik;

	const baseDataType = values.dataType === "base";

	const selectedUnitTypeOption = unitTypeOptions.find(
		(option) => option.value === values.unit_type_id
	);

	function handleResetForm() {
		formik.resetForm({
			values: { ...baseOrUnitInitialValues, dataType: values.dataType },
		});
		setSearch("");
	}

	async function handleSearchUnitByRegistration() {
		if (!search) {
			return;
		}

		try {
			setIsSearchingUnit(true);

			const { data } = await api.get<HealthUnitSearchResult>(
				`/units/fetch-by-registration/${search}`
			);

			formik.resetForm({
				values: { ...baseOrUnitInitialValues, dataType: values.dataType },
			});
			setError("");

			const simpleValues = filterObjectReplacingNullWithEmptyString({
				...values,
				...(filterObjectExcluding(data, [
					"federal_unit",
					"city",
					"unit_type",
				]) as typeof baseOrUnitInitialValues),
			});

			formik.setValues({ ...values, ...simpleValues });

			formik.setFieldValue("federal_unit_id", data.federal_unit?.id);
			formik.setFieldValue("federal_unit_label", data.federal_unit?.uf);
			formik.setFieldValue("city_id", data.city?.id, true);
			formik.setFieldValue("city_label", data.city?.name);
		} catch (error: any) {
			console.error(error);

			if (error.isAxiosError && error.response.status === 422) {
				setError("Nenhum registro encontrado");
				return;
			}

			setError("");

			Toast.fire({
				icon: "error",
				title: "Erro ao buscar a unidade",
			});
		} finally {
			setIsSearchingUnit(false);
			setSearch("");
		}
	}

	function handleChangeFederalUnit(option: ReactSelectOption) {
		formik.setFieldValue("federal_unit_id", String(option.value));
		formik.setFieldValue("federal_unit_label", option.label);
		formik.setFieldValue("city_id", "");
		formik.setFieldValue("city_label", "");
	}

	function handleChangeCity(option: ReactSelectOption) {
		formik.setFieldValue("city_id", String(option.value));
		formik.setFieldValue("city_label", option.label);
	}

	function handleChangeVehicleType(option: ReactSelectOption) {
		formik.setFieldValue("vehicle_type_id", String(option.value));
		formik.setFieldValue("vehicle_type_label", option.label);
	}

	useEffect(() => {
		if (values.dataType && baseDataType) {
			values.unit_type_id = String(baseTypeOptions[0].value);
		}
	}, [values.dataType]);

	useEffect(() => {
		formik.validateForm();
	}, [
		values.federal_unit_id,
		values.city_id,
		values.vehicle_type_id,
		values.urc_id,
		values.dataType,
	]);

	useEffect(() => {
		if (baseOrUnit) {
			const baseOrUnitWithSelectValues = {
				...baseOrUnit,
				city_label: baseOrUnit.city?.name ?? "",
				federal_unit_id: String(baseOrUnit.city?.federal_unit_id),
				federal_unit_label: baseOrUnit.city?.federal_unit?.uf ?? "",
				vehicle_type_label: baseOrUnit.vehicle_type?.name ?? "",
				vehicle_type_id: String(baseOrUnit.vehicle_type_id ?? ""),
			};
			formik.setValues(baseOrUnitWithSelectValues);
		}

		formik.validateForm();

		if (location.pathname === "/bases" || location.pathname === "/bases/cadastro") {
			formik.setFieldValue("dataType", "base");

			return;
		}

		formik.setFieldValue("dataType", "unidade");
	}, []);

	return (
		<Form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-4">
			<Row>
				<Col lg={5}>
					<SearchInputGroupFormControl
						id="registration-code-input"
						label="CNES"
						labelClassName="fw-bold"
						placeholder="Pesquisar CNES"
						mask={Number}
						onClick={handleSearchUnitByRegistration}
						validationMessage={error}
						isLoading={isSearchingUnit}
						disabled={isSearchingUnit}
						onChange={(event) => setSearch(event.currentTarget.value)}
						value={search}
						isMaskedInput
					/>
				</Col>
			</Row>

			<Row>
				<Col lg={5}>
					<FormattedFormControl
						id="national-health-registration"
						label="CNES"
						labelClassName="fw-bold"
						mask={Number}
						validationMessage={errors.national_health_registration}
						isInvalid={!!errors.national_health_registration}
						{...formik.getFieldProps("national_health_registration")}
						isMaskedInput
						isRequired
					/>
				</Col>

				<Col>
					<FormattedFormControl
						id="name"
						label="Nome"
						labelClassName="fw-bold"
						validationMessage={errors.name}
						isInvalid={!!errors.name}
						{...formik.getFieldProps("name")}
						isRequired
					/>
				</Col>
			</Row>

			<Row>
				<Col lg={7}>
					<FormattedFormControl
						id="street"
						label="Logradouro"
						labelClassName="fw-bold"
						{...formik.getFieldProps("street")}
					/>
				</Col>

				<Col>
					<FormattedFormControl
						id="house-number"
						label="Nº"
						labelClassName="fw-bold"
						{...formik.getFieldProps("house_number")}
					/>
				</Col>

				<Col>
					<FormattedFormControl
						id="zip-code"
						label="CEP"
						labelClassName="fw-bold"
						placeholder="00000-000"
						mask="00000-000"
						{...formik.getFieldProps("zip_code")}
						isMaskedInput
					/>
				</Col>
			</Row>

			<Row>
				<Col lg={7}>
					<FormattedFormControl
						id="neighborhood"
						label="Bairro"
						labelClassName="fw-bold"
						{...formik.getFieldProps("neighborhood")}
					/>
				</Col>

				<Col>
					<FormattedFormControl
						id="complement"
						label="Complemento"
						labelClassName="fw-bold"
						{...formik.getFieldProps("complement")}
					/>
				</Col>
			</Row>

			<Row>
				<Col lg={3}>
					<Form.Label className="fw-bold" htmlFor="federal-unit">
						UF <RedAsterisk />
					</Form.Label>

					<FederalUnitsSelect
						inputId="federal-unit"
						value={
							values.federal_unit_id
								? {
										value: values.federal_unit_id,
										label: values.federal_unit_label,
								  }
								: ""
						}
						isInvalid={!!errors.federal_unit_id}
						placeholder="Selecione"
						onChange={(option) => handleChangeFederalUnit(option as ReactSelectOption)}
					/>

					{!!errors.federal_unit_id && (
						<span className="text-danger">{errors.federal_unit_id}</span>
					)}
				</Col>

				<Col lg={4}>
					<Form.Label className="fw-bold" htmlFor="city">
						Cidade <RedAsterisk />
					</Form.Label>

					<CitiesSelect
						inputId="city"
						federalUnitId={values.federal_unit_id}
						value={
							values.city_id
								? {
										value: values.city_id,
										label: values.city_label,
								  }
								: ""
						}
						isInvalid={!!errors.city_id}
						placeholder="Selecione uma cidade"
						onChange={(option) => handleChangeCity(option as ReactSelectOption)}
					/>

					{!!errors.city_id && <span className="text-danger">{errors.city_id}</span>}
				</Col>

				<Col>
					<FormattedFormControl
						id="latitude"
						label="Latitude"
						labelClassName="fw-bold"
						{...formik.getFieldProps("latitude")}
					/>
				</Col>

				<Col>
					<FormattedFormControl
						id="longitude"
						label="Longitude"
						labelClassName="fw-bold"
						{...formik.getFieldProps("longitude")}
					/>
				</Col>
			</Row>

			<Row>
				<Col lg={7}>
					<Form.Label className="fw-bold" htmlFor="unit-type-id">
						Tipo da unidade <RedAsterisk />
					</Form.Label>

					<ReactSelect
						inputId="unit-type-id"
						placeholder="Selecione um tipo de unidade"
						options={baseDataType ? baseTypeOptions : unitTypeOptions}
						value={baseDataType ? baseTypeOptions[0] : selectedUnitTypeOption || ""}
						isInvalid={!baseDataType && !!errors.unit_type_id}
						onChange={(option) =>
							formik.setFieldValue(
								"unit_type_id",
								(option as ReactSelectOption).value
							)
						}
					/>

					{!baseDataType && errors.unit_type_id && (
						<span className="text-danger">{errors.unit_type_id}</span>
					)}
				</Col>

				<Col>
					<FormattedFormControl
						id="company-name"
						label="Razão social da unidade"
						labelClassName="fw-bold"
						{...formik.getFieldProps("company_name")}
					/>
				</Col>
			</Row>

			<Row>
				<Col lg={2}>
					<FormattedFormControl
						id="phone"
						label="Telefone"
						labelClassName="fw-bold"
						placeholder="(00) 00000-0000"
						mask="(00) 00000-0000"
						{...formik.getFieldProps("telephone")}
						isMaskedInput
					/>
				</Col>

				<Col lg={3}>
					<FormattedFormControl
						id="company-registration-number"
						label="CNPJ mantenedor"
						labelClassName="fw-bold"
						mask="00.000.000/0000-00"
						{...formik.getFieldProps("company_registration_number")}
						isMaskedInput
					/>
				</Col>

				{baseDataType && (
					<>
						<Col>
							<Form.Label className="fw-bold" htmlFor="unit-type-id">
								CRU vinculada <RedAsterisk />
							</Form.Label>

							<UrgencyRegulationCentersSelect
								urgencyRegulationCenterId={values.urc_id}
								isInvalid={!!errors.urc_id}
								onChange={(option) => {
									formik.setFieldValue(
										"urc_id",
										(option as ReactSelectOption).value
									);
								}}
							/>

							{errors.urc_id && <span className="text-danger">{errors.urc_id}</span>}
						</Col>

						<Col>
							<Form.Label className="fw-bold" htmlFor="unit-type-id">
								Tipo <RedAsterisk />
							</Form.Label>

							<ReactSelect
								options={vehicleTypeOptions}
								value={{
									value: values.vehicle_type_id,
									label: values.vehicle_type_label,
								}}
								isInvalid={!!errors.vehicle_type_id}
								onChange={(option) =>
									handleChangeVehicleType(option as ReactSelectOption)
								}
							/>

							{errors.vehicle_type_id && (
								<span className="text-danger">{errors.vehicle_type_id}</span>
							)}
						</Col>
					</>
				)}
			</Row>

			<div className="d-flex justify-content-center gap-4 mt-12">
				{handleCloseEditModal ? (
					<button
						type="button"
						className="btn button-bg-light-color-gray-400"
						onClick={handleCloseEditModal}
					>
						Fechar
					</button>
				) : (
					<button
						type="button"
						className="btn button-bg-light-color-gray-400"
						onClick={handleResetForm}
					>
						Limpar
					</button>
				)}

				<button
					type="submit"
					className="btn button-bg-samu-color-white"
					disabled={formik.isSubmitting || !formik.isValid}
				>
					{formik.isSubmitting ? (
						<Spinner animation="border" size="sm" className="mx-8" />
					) : baseOrUnit ? (
						"Salvar"
					) : (
						"Cadastrar"
					)}
				</button>
			</div>
		</Form>
	);
}
