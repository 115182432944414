import { FormikProps } from "formik";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { AttendanceType } from "@/enums/AttendanceType";
import { attendanceInitialValues } from "@/utils/initialValues/attendance";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { CitiesSelect } from "@/components/Selects/CitiesSelect";
import { FormRadio } from "@/components/Radios/FormRadio";
import { RedAsterisk } from "@/components/RedAsterisk";
import { Victims } from "./Victims";

type Props = {
	formik: FormikProps<typeof attendanceInitialValues>;
	initialVictimData: Victim;
	victims: Victim[];
	setVictims: (victims: Victim[]) => void;
};

export function RequesterAndPatientData({ formik, initialVictimData, victims, setVictims }: Props) {
	const { values, errors } = formik;

	const selectedCity = {
		value: values.city_id,
		label: values.city_label,
	};

	function handleChangeCity(option: ReactSelectOption) {
		formik.setFieldValue("city_id", String(option.value));
		formik.setFieldValue("city_label", option.label);
	}

	return (
		<Row>
			<Col lg={5} className="pe-0">
				<h3 className="mb-4">Dados do solicitante</h3>

				{values.ticket_type_id === String(AttendanceType.SecondaryOccurrence) && (
					<Row className="gap-4 mb-4">
						<div>
							<FormGroupRadio
								label="Quem solicitou?"
								labelClassName="fw-bold"
								requestedValue={values.requester.requester_type_id}
								{...formik.getFieldProps("requester.requester_type_id")}
								inline
							>
								<FormRadio id="medical" label="Médico" value="1" />
								<FormRadio
									id="other-professional"
									label="Outro profissional"
									value="2"
								/>
							</FormGroupRadio>
						</div>

						<Col lg={8}>
							<FormattedFormControl
								id="professional-name"
								label="Profissional solicitante"
								labelClassName="fw-bold"
								isInvalid={!!errors.requester?.name}
								validationMessage={errors.requester?.name}
								{...formik.getFieldProps("requester.name")}
								isRequired
							/>
						</Col>

						<Col>
							<FormattedFormControl
								id="council-number"
								label="Número do conselho"
								labelClassName="fw-bold"
								{...formik.getFieldProps("requester.council_number")}
							/>
						</Col>
					</Row>
				)}

				<Row>
					<Col lg={9} className="d-flex flex-column gap-4">
						{values.ticket_type_id !== String(AttendanceType.SecondaryOccurrence) && (
							<FormattedFormControl
								id="requester-name"
								label="Nome do solicitante"
								labelClassName="fw-bold"
								isInvalid={!!errors.requester?.name}
								validationMessage={errors.requester?.name}
								{...formik.getFieldProps("requester.name")}
								isRequired
							/>
						)}

						<div>
							<>
								<Form.Label className="fw-bold" htmlFor="requester-city">
									Cidade
								</Form.Label>{" "}
								<RedAsterisk />
							</>

							<CitiesSelect
								inputId="requester-city"
								federalUnitId={6}
								value={values.city_id && selectedCity}
								isInvalid={!!errors.city_id}
								placeholder="Selecione uma cidade"
								onChange={(option) => handleChangeCity(option as ReactSelectOption)}
							/>

							{errors.city_id && (
								<span className="text-danger">{errors.city_id}</span>
							)}
						</div>

						<Row>
							<div>
								<Form.Label className="fw-bold" htmlFor="requester-primary-phone">
									Telefone
								</Form.Label>{" "}
								<RedAsterisk />
							</div>

							<Col lg={6}>
								<FormattedFormControl
									id="requester-primary-phone"
									placeholder="(00) 00000-0000"
									mask={[{ mask: "(00) 0000-0000" }, { mask: "(00) 00000-0000" }]}
									isInvalid={!!errors.requester?.primary_phone}
									validationMessage={errors.requester?.primary_phone}
									{...formik.getFieldProps("requester.primary_phone")}
									onAccept={(value) =>
										formik.setFieldValue("requester.primary_phone", value)
									}
									isMaskedInput
								/>
							</Col>

							<Col>
								<FormattedFormControl
									placeholder="(00) 00000-0000"
									mask="(00) 00000-0000"
									{...formik.getFieldProps("requester.secondary_phone")}
									isMaskedInput
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>

			<Col lg="auto" className="border-end-dashed border-secondary ps-20" />

			<Col className="ps-20">
				<h3 className="mb-4">Dados do paciente</h3>

				{values.ticket_type_id === String(AttendanceType.PrimaryOccurrence) && (
					<Col lg={9} className="d-flex align-items-center h-3rem mb-4">
						<Col className="d-flex align-items-center gap-2">
							<input
								type="checkbox"
								id="multiple-victims"
								{...formik.getFieldProps("multiple_victims")}
							/>

							<Form.Label className="fw-bold m-0" htmlFor="multiple-victims">
								Múltiplas vítimas
							</Form.Label>
						</Col>

						{values.multiple_victims && (
							<Col>
								<div className="d-flex align-items-center gap-2">
									<Form.Label className="fw-bold m-0" htmlFor="number-of-victims">
										Qtd. vítimas
									</Form.Label>

									<Col lg={5}>
										<FormattedFormControl
											type="number"
											id="number-of-victims"
											isInvalid={!!errors.number_of_victims}
											validationMessage={errors.number_of_victims}
											{...formik.getFieldProps("number_of_victims")}
										/>
									</Col>
								</div>
							</Col>
						)}
					</Col>
				)}

				<Victims
					victims={victims}
					setVictims={setVictims}
					initialVictimData={initialVictimData}
					multipleVictims={values.multiple_victims}
					ticketType={values.ticket_type_id}
				/>
			</Col>
		</Row>
	);
}
