import { ReactElement } from "react";
import { FormControlProps } from "react-bootstrap";
import classNames from "clsx";

import Card from "react-bootstrap/Card";

import "./styles.scss";

type CardWithIconProps = FormControlProps & {
	icon: ReactElement;
	bigAnimatedCard?: boolean;
	cardBodyClassName?: string;
};

export function CardWithIcon({
	icon,
	bigAnimatedCard,
	cardBodyClassName,
	className,
	children,
}: CardWithIconProps) {
	return (
		<Card
			className={classNames(
				"overflow-hidden w-100",
				bigAnimatedCard ? "big-animated-card" : "animated-card",
				className
			)}
		>
			<Card.Body className={cardBodyClassName}>
				{children}

				<div className="position-absolute">{icon}</div>
			</Card.Body>
		</Card>
	);
}
