export const patrimonyTypeOptions: ReactSelectOption[] = [
	{ value: 1, label: "Maca" },
	{ value: 2, label: "Aparelho celular siemens c/ carregador" },
	{ value: 3, label: "Aspirador portátil" },
	{ value: 4, label: "Fluxômetro de oxigênio" },
	{ value: 5, label: "Monitor desf. cardíaco" },
	{ value: 6, label: "Prancha curta com tirantes" },
	{ value: 7, label: "Prancha longa com tirantes" },
	{ value: 8, label: "Umidificadores" },
	{ value: 9, label: "Ventilador mecânico / circuito" },
	{ value: 10, label: "Vidro de aspirador c/ tampa" },
	{ value: 11, label: "Aparelho celular com carregador" },
	{ value: 12, label: "DEA (Desfibrilador Externo Automático)" },
	{ value: 13, label: "Carregador de oxímetro" },
];
