import Yup from "@/utils/mixins/yup";

export const userRegistrationSchema = Yup.object().shape({
	name: Yup.string().required("Campo obrigatório"),
	gender_code: Yup.string().required("Campo obrigatório"),
	phone: Yup.string()
		.length(15, "O telefone deve ter conter todos os caracteres")
		.required("Campo obrigatório"),
	identifier: Yup.string().cpf(),
	birthdate: Yup.date()
		.max(new Date(), "Data de nascimento inválida")
		.required("Campo obrigatório"),
	email: Yup.string().email("Esse email não possui formato válido").required("Campo obrigatório"),
	federal_unit: Yup.string().required("Campo obrigatório"),
	city_id: Yup.string().required("Campo obrigatório"),
	neighborhood: Yup.string().required("Campo obrigatório"),
	street: Yup.string().required("Campo obrigatório"),
	street_type: Yup.string().required("Campo obrigatório"),
	house_number: Yup.string().required("Campo obrigatório"),
	council_number: Yup.string().max(50, "Tamanho máximo de 50 caracteres").nullable(),
	cbo: Yup.string().required("Campo obrigatório"),
	password: Yup.string()
		.when("user_id", {
			is: (val: string) => !val,
			then: Yup.string().required("Campo obrigatório"),
		})
		.min(8, "A senha deve ter no mínimo 8 caracteres.")
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/,
			"A senha deve conter pelo menos uma letra maiúscula, um número e um caractere especial."
		),
	password_confirmation: Yup.string()
		.when("user_id", {
			is: (val: string) => !val,
			then: Yup.string().required("Campo obrigatório"),
		})
		.min(8, "A senha deve ter no mínimo 8 caracteres.")
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/,
			"A senha deve conter pelo menos uma letra maiúscula, um número e um caractere especial."
		)
		.oneOf([Yup.ref("password"), null], "As senhas não conferem"),
});
