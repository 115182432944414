import { Fragment } from "react";
import dayjs from "dayjs";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { VehicleType } from "@/enums/VehicleType";
import { OccupationType } from "@/enums/OccupationType";
import { createSentence } from "@/utils/createSentence";

import { Separator } from "@/components/Separator";
import { Timeline } from "@/components/Timeline";

type Props = {
	radioOperation: RadioOperation;
};

export function RadioOperationView({ radioOperation }: Props) {
	const OccupationAndUser = ({
		label,
		occupations,
		fleet,
	}: {
		label: string;
		occupations: string[];
		fleet: Fleet;
	}) => {
		const MEDIC_OCCUPATION_INITIAL_CODE = "2251";
		const isMedic = (occupationId: string | undefined) =>
			occupations.includes(MEDIC_OCCUPATION_INITIAL_CODE) &&
			occupationId?.includes(MEDIC_OCCUPATION_INITIAL_CODE);

		return (
			<>
				<span className="fw-bold">{label}: </span>
				<span>
					{(fleet.users?.find(
						(user) =>
							occupations.includes(user.pivot?.occupation_id as OccupationType) ||
							isMedic(user?.pivot?.occupation_id)
					)?.name ||
						fleet.external_professionals?.find(
							(externalProfessional) =>
								occupations.includes(
									externalProfessional?.occupation_id as OccupationType
								) || isMedic(externalProfessional?.occupation_id)
						)?.external_professional) ??
						"-"}
				</span>
			</>
		);
	};

	const radioOperationCreatedAt = dayjs(radioOperation?.created_at).format(
		"DD/MM/YYYY [às] HH:mm"
	);

	return (
		<>
			<div>
				<h2 className="mb-4">Controle de frota</h2>

				{radioOperation?.fleets?.map((fleet, index, array) => {
					const vehicleHistory = fleet.vehicle.latest_vehicle_status_history;
					const vehicleTypeId = vehicleHistory.vehicle_type_id;
					const vehicleTypeName = vehicleHistory.vehicle_type?.name || "";
					const vehicleCode = vehicleHistory.vehicle?.code;
					const vehicleCityName = vehicleHistory.city_id
						? vehicleHistory.vehicle_city?.name
						: "";

					return (
						<Fragment key={`radio-operation-fleet-${index}`}>
							<Col className="mb-2">
								<span className="fw-bold">Veículo enviado: </span>
								<span>
									{`${vehicleTypeName} ${vehicleCode} ${vehicleCityName}`}
								</span>
							</Col>

							<div className="d-flex flex-column gap-1">
								<h4>Equipe VTR</h4>

								<Row>
									{(Number(
										vehicleHistory.vehicle_type_id || 0
									) as VehicleType) !== VehicleType.motorizedAmbulance && (
										<Col lg="auto">
											<OccupationAndUser
												label="Condutor"
												occupations={[OccupationType.Conductor]}
												fleet={fleet}
											/>
										</Col>
									)}

									{[
										VehicleType.AHU,
										VehicleType.aeromedical,
										VehicleType.RTV,
									].includes(vehicleTypeId as VehicleType) && (
										<Col lg="auto">
											<OccupationAndUser
												label="Médico"
												occupations={[OccupationType.Doctor]}
												fleet={fleet}
											/>
										</Col>
									)}

									{![
										VehicleType.motorizedAmbulance,
										VehicleType.transportAmbulance,
									].includes(vehicleTypeId as VehicleType) && (
										<Col lg="auto">
											<OccupationAndUser
												label="Enfermeiro"
												occupations={[OccupationType.Nurse]}
												fleet={fleet}
											/>
										</Col>
									)}

									{[
										VehicleType.transportAmbulance,
										VehicleType.motorizedAmbulance,
									].includes(vehicleTypeId as VehicleType) && (
										<Col lg="auto">
											<OccupationAndUser
												label="Auxiliar de enfermagem / Técnico de enfermagem / Enfermeiro"
												occupations={[
													OccupationType.Nurse,
													OccupationType.NursingAssistant,
													OccupationType.NursingTechnician,
												]}
												fleet={fleet}
											/>
										</Col>
									)}

									{[VehicleType.BHU, VehicleType.craft].includes(
										vehicleTypeId as VehicleType
									) && (
										<Col lg="auto">
											<OccupationAndUser
												label="Auxiliar de enfermagem / Técnico de enfermagem"
												occupations={[
													OccupationType.NursingAssistant,
													OccupationType.NursingTechnician,
												]}
												fleet={fleet}
											/>
										</Col>
									)}
								</Row>
							</div>

							{index !== array.length - 1 && <Separator className="py-4 w-75" />}
						</Fragment>
					);
				})}

				{Boolean(radioOperation?.fleet_histories?.length) && (
					<>
						<h4 className="my-4">Histórico de viaturas empenhadas</h4>

						<div className="ms-1">
							<Timeline>
								{radioOperation.fleet_histories?.map((result, index, array) => {
									const vehicle = `${
										result.fleet.vehicle.vehicle_type?.name || ""
									} ${result.fleet.vehicle.code} - ${
										result.fleet.vehicle.base?.city?.name
									}`;

									const dateTimeOfCommitment =
										index === 0
											? radioOperationCreatedAt
											: dayjs(array[index - 1].created_at).format(
													"DD/MM/YYYY [às] HH:mm"
											  );

									return (
										<Timeline.Item key={`history-${index}`}>
											<Timeline.VerticalLine />

											<Timeline.Content>
												{index > 0 && <Separator className="pb-8 pt-3" />}

												<Row>
													<Col lg={5}>
														<Row>
															<Col lg={5}>
																<span className="fw-bold">
																	VTR:{" "}
																</span>
																{vehicle}
															</Col>

															<Col>
																<span className="fw-bold">
																	Data e hora do empenho:{" "}
																</span>
																{dateTimeOfCommitment}
															</Col>
														</Row>

														<div>
															<span className="fw-bold">
																Profissional responsável pelo
																empenho:{" "}
															</span>
															{result.creator.name}
														</div>

														<div>
															<span className="fw-bold">
																Justificativa:{" "}
															</span>
															{result.change_reason}
														</div>

														<div>
															<span className="fw-bold">
																Equipe:{" "}
															</span>
															{createSentence(
																result.fleet.users.map(
																	(user) => user.name
																) ?? []
															)}
														</div>
													</Col>

													<Col>
														<div>
															<span className="fw-bold">
																Solicitação VTR:{" "}
															</span>
															{dayjs(
																result.previous_vehicle_requested_at
															).format("DD/MM/YYYY [às] HH:mm")}
														</div>

														<div>
															<span className="fw-bold">
																Saída VTR:{" "}
															</span>
															{dayjs(
																result.previous_vehicle_dispatched_at
															).format("DD/MM/YYYY [às] HH:mm")}
														</div>
													</Col>
												</Row>
											</Timeline.Content>
										</Timeline.Item>
									);
								})}
							</Timeline>
						</div>
					</>
				)}

				<Separator />

				<div>
					<h2 className="mb-4">Horários</h2>

					<Row>
						<Col sm={3}>
							<span className="fw-bold">Solicitação VTR: </span>
							<span>
								{radioOperation?.vehicle_requested_at
									? dayjs(radioOperation?.vehicle_requested_at).format(
											"DD/MM/YYYY [às] HH:mm"
									  )
									: "-"}
							</span>
						</Col>

						<Col sm={3}>
							<span className="fw-bold">Saída VTR: </span>
							<span>
								{radioOperation?.vehicle_dispatched_at
									? dayjs(radioOperation?.vehicle_dispatched_at).format(
											"DD/MM/YYYY [às] HH:mm"
									  )
									: "-"}
							</span>
						</Col>

						<Col sm={3}>
							<span className="fw-bold">Chegada local: </span>
							<span>
								{radioOperation?.arrived_to_site_at
									? dayjs(radioOperation?.arrived_to_site_at).format(
											"DD/MM/YYYY [às] HH:mm"
									  )
									: "-"}
							</span>
						</Col>

						<Col sm={3}>
							<span className="fw-bold">Saída local: </span>
							<span>
								{radioOperation?.left_from_site_at
									? dayjs(radioOperation?.left_from_site_at).format(
											"DD/MM/YYYY [às] HH:mm"
									  )
									: "-"}
							</span>
						</Col>
					</Row>

					<Row>
						<Col sm={3}>
							<span className="fw-bold">Chegada destino: </span>
							<span>
								{radioOperation?.arrived_to_destination_at
									? dayjs(radioOperation?.arrived_to_destination_at).format(
											"DD/MM/YYYY [às] HH:mm"
									  )
									: "-"}
							</span>
						</Col>

						<Col lg={3}>
							<span className="fw-bold">Liberação destino: </span>
							<span>
								{radioOperation?.release_from_destination_at
									? dayjs(radioOperation?.release_from_destination_at).format(
											"DD/MM/YYYY [às] HH:mm"
									  )
									: "-"}
							</span>
						</Col>

						<Col sm={3}>
							<span className="fw-bold">Liberação VTR: </span>
							<span>
								{radioOperation?.vehicle_released_at
									? dayjs(radioOperation?.vehicle_released_at).format(
											"DD/MM/YYYY [às] HH:mm"
									  )
									: "-"}
							</span>
						</Col>
					</Row>
				</div>

				<Separator />

				<div>
					<h2 className="mb-4">Observações</h2>

					{radioOperation?.notes?.map((note, index, array) => (
						<div key={`radio-operation-observation-${index}`}>
							<Row>
								<Col lg={3}>
									<span className="fw-bold">Data e hora: </span>
									<span>
										{note.datetime
											? dayjs(note.datetime).format("DD/MM/YYYY [às] HH:mm")
											: "-"}
									</span>
								</Col>

								<Col>
									<span className="fw-bold">Profissional responsável: </span>
									<span>{note.responsible_professional}</span>
								</Col>
							</Row>

							<Row>
								<Col>
									<span className="fw-bold">Observação: </span>
									<span>{note.observation}</span>
								</Col>
							</Row>

							{index !== array.length - 1 && <Separator className="py-4 w-75" />}
						</div>
					))}
				</div>
			</div>
		</>
	);
}
