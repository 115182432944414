export enum PriorityType {
	Blue = 1,
	Green = 2,
	Yellow = 3,
	Orange = 4,
	Red = 5,
}

export const PriorityTypeMessages = {
	[PriorityType.Blue]: "Azul",
	[PriorityType.Green]: "Verde",
	[PriorityType.Yellow]: "Amarelo",
	[PriorityType.Orange]: "Laranja",
	[PriorityType.Red]: "Vermelho",
};
