import { MutableRefObject } from "react";
import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { AttendanceStatus, AttendanceStatusMessages } from "@/enums/AttendanceStatus";
import { AttendanceType, AttendanceTypeMessages } from "@/enums/AttendanceType";

type Props = {
	attendances: AttendanceConsultation[];
	tableRef?: MutableRefObject<HTMLTableElement | null>;
};

export function ExportAttendancesTable({ tableRef, attendances }: Props) {
	return (
		<Table responsive ref={tableRef}>
			<thead>
				<tr>
					<th>Data do chamado</th>
					<th>N° da ocorrência</th>
					<th>Nome do paciente</th>
					<th>Idade do paciente</th>
					<th>Nome do solicitante</th>
					<th>Tipo de ocorrência</th>
					<th>Status da ocorrência</th>
					<th>Cidade de origem</th>
					<th>Unidade de destino</th>
				</tr>
			</thead>

			<tbody>
				{attendances?.map((attendance, index) => {
					const sceneRecording = attendance?.scene_recording;

					const unitDestination =
						sceneRecording?.latest_counter_referral_destination_name ||
						sceneRecording?.scene_recording_destination_name ||
						attendance.secondary_attendance_destination_name;

					const attendanceType =
						AttendanceTypeMessages[attendance.ticket_type_id as AttendanceType];

					const attendanceStatus =
						AttendanceStatusMessages[
							attendance.attendance_status_id as AttendanceStatus
						];

					return (
						<tr key={`attendance-${index}`}>
							<td>{dayjs(attendance.opening_at).format("DD/MM/YYYY HH:mm")}</td>
							<td>{attendance.attendance_number}</td>
							<td>{attendance.patient.name}</td>
							<td>{attendance.patient.age}</td>
							<td>{attendance.requesting.name}</td>
							<td>{attendanceType}</td>
							<td>{attendanceStatus}</td>
							<td>{attendance.origin.city}</td>
							<td>{unitDestination}</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
}
