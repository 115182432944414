import { useEffect, useRef, useState } from "react";
import classNames from "clsx";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";

import { FaPersonWalkingArrowRight } from "react-icons/fa6";
import { FaPowerOff, FaPlus } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { useDebounce } from "@/hooks/useDebounce";
import { PlaceType, PlaceTypeMessages } from "@/enums/Place";

import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { PlaceModal } from "@/components/Configurations/Places/PlaceModal";
import { PaginationLinks } from "@/components/PaginationLinks";

type Props = {
	activeTab: string;
};

export function Places({ activeTab }: Props) {
	const [places, setPlaces] = useState<PaginatedPlace>({} as PaginatedPlace);
	const [currentPlace, setCurrentPlace] = useState<Place>({} as Place);
	const [showPlaceModal, setShowPlaceModal] = useState(false);
	const [skeleton, setSkeleton] = useState(true);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);

	const isFirstRender = useRef(true);
	const forcePage = useRef(1);

	const debouncedSearch = useDebounce(search, 500);
	const { Toast } = useSwal();
	const { api } = useApi();

	const { results } = places;

	async function fetchPaginatedPlaces(pageToUse?: number) {
		setSkeleton(true);

		try {
			const { data } = await api.get("/place", {
				params: {
					page: pageToUse || page,
					per_page: 10,
					search: debouncedSearch,
				},
			});
			setPlaces(data);
		} catch (error) {
			console.error(error);
		} finally {
			setSkeleton(false);
		}
	}

	async function handleChangeStatusVacate(id: string) {
		setLoading(true);

		try {
			await api.put<Place>(`/place/vacate/${id}`);

			Toast.fire({
				icon: "success",
				title: "Local desocupado com sucesso!",
			});

			fetchPaginatedPlaces();
		} catch (error: any) {
			Toast.fire({
				icon: "error",
				title: error.response.data.message,
			});
		} finally {
			setLoading(false);
		}
	}

	async function handleChangeStatus(id: string) {
		setLoading(true);

		try {
			const { data } = await api.put<Place>(`/place/activate-or-inactivate/${id}`);

			const isActivate = data.place_status_id === PlaceType.Free;

			Toast.fire({
				icon: "success",
				title: `Local ${isActivate ? "ativado" : "desativado"} com sucesso!`,
			});

			fetchPaginatedPlaces();
		} catch (error: any) {
			console.log(error);

			Toast.fire({
				icon: "error",
				title: error.response.data.message,
			});
		} finally {
			setLoading(false);
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchPaginatedPlaces(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	function handleShowPlaceModal(result?: Place) {
		setShowPlaceModal(true);

		if (result) {
			setCurrentPlace(result);

			return;
		}

		setCurrentPlace({} as Place);
	}

	function handleClosePlaceModal() {
		setShowPlaceModal(false);
		setCurrentPlace({} as Place);
	}

	useEffect(() => {
		forcePage.current = page;
		fetchPaginatedPlaces();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		if (activeTab === "places") {
			fetchPaginatedPlaces();
		}
	}, [debouncedSearch, activeTab]);

	return (
		<>
			<div className="d-flex justify-content-between align-items-center my-6">
				<button
					className="d-flex align-items-center btn button-bg-samu-color-white gap-4"
					onClick={() => handleShowPlaceModal()}
				>
					<FaPlus /> Cadastrar
				</button>

				<SearchFormControl
					placeholder="Pesquisar local"
					onChange={(event) => setSearch(event.target.value)}
				/>
			</div>

			<Table className="table-row-dashed table-row-gray-300">
				<thead>
					<tr className="fw-bold fs-6">
						<th>Nome do local</th>
						<th>Profissional</th>
						<th>Status</th>
						<th>Ações</th>
					</tr>
				</thead>
				<tbody>
					{skeleton && <TableBodySkeleton columns={4} />}

					{results?.map((result, index) => {
						const status = result.place_status_id as keyof typeof PlaceTypeMessages;

						const isActive = status === PlaceType.Free;
						const isOccupied = status === PlaceType.Occupied;
						const isDisabled = status === PlaceType.Disabled;

						return (
							<tr key={`places-${index}`}>
								<td className="align-middle">{result?.name}</td>
								<td className="align-middle">{result?.user?.name}</td>
								<td className="align-middle">
									<Badge
										className={classNames("formatted-badge", {
											"badge-bg-green": isActive,
											"bg-samu": isOccupied,
											"badge-bg-gray": isDisabled,
										})}
										pill
									>
										{PlaceTypeMessages[status]}
									</Badge>
								</td>
								<td className="align-middle w-1px">
									<div className="d-flex gap-2">
										<OverlayTrigger overlay={<Tooltip>Editar</Tooltip>}>
											<Button
												variant="secondary"
												className="btn-icon btn-sm"
												onClick={() => handleShowPlaceModal(result)}
											>
												<MdEdit className="text-gray-700" />
											</Button>
										</OverlayTrigger>

										<OverlayTrigger overlay={<Tooltip>Desocupar</Tooltip>}>
											<Button
												variant="secondary"
												className="btn-icon btn-sm"
												onClick={() => handleChangeStatusVacate(result.id)}
											>
												{loading ? (
													<Spinner
														variant="samu"
														animation="border"
														size="sm"
													/>
												) : (
													<FaPersonWalkingArrowRight className="text-gray-700 fs-5" />
												)}
											</Button>
										</OverlayTrigger>

										<OverlayTrigger
											overlay={
												<Tooltip>
													{isActive ? "Desativar" : "Ativar"}
												</Tooltip>
											}
										>
											<Button
												variant="secondary"
												className="btn-icon btn-sm"
												onClick={() => handleChangeStatus(result.id)}
											>
												{loading ? (
													<Spinner
														variant="samu"
														animation="border"
														size="sm"
													/>
												) : (
													<FaPowerOff className="text-gray-700" />
												)}
											</Button>
										</OverlayTrigger>
									</div>
								</td>
							</tr>
						);
					})}

					{!results?.length && (
						<tr>
							<td className="text-center" colSpan={12}>
								<h2 className="mt-12 mb-0">Nenhum local encontrado</h2>
							</td>
						</tr>
					)}
				</tbody>
			</Table>

			{Boolean(results?.length) && (
				<div className="d-flex justify-content-end mt-8">
					<PaginationLinks
						itemsPerPage={10}
						totalItems={places.meta?.total}
						forcePage={forcePage.current - 1}
						changeSelectedPage={handleChangeSelectedPage}
					/>
				</div>
			)}

			<PlaceModal
				showPlaceModal={showPlaceModal}
				handleClosePlaceModal={handleClosePlaceModal}
				fetchPaginatedPlaces={fetchPaginatedPlaces}
				currentPlace={currentPlace}
			/>
		</>
	);
}
