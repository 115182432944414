import { FormikProps } from "formik";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { attendanceInitialValues } from "@/utils/initialValues/attendance";

import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";
import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { UnitsSelect } from "@/components/Selects/UnitsSelect";
import { FormRadio } from "@/components/Radios/FormRadio";
import { RedAsterisk } from "@/components/RedAsterisk";
import { Separator } from "@/components/Separator";

type Props = {
	formik: FormikProps<typeof attendanceInitialValues>;
};

export function SecondaryAttendance({ formik }: Props) {
	const { values, errors } = formik;

	const selectedUnitOrigin = {
		value: values.unit_origin_id,
		label: values.unit_origin_label,
	};

	const selectedUnitDestination = {
		value: values.unit_destination_id,
		label: values.unit_destination_label,
	};

	function handleChangeUnitOrigin(option: ReactSelectOption) {
		formik.setFieldValue("unit_origin_id", String(option.value));
		formik.setFieldValue("unit_origin_label", option.label);
	}

	function handleChangeUnitDestinations(option: ReactSelectOption) {
		formik.setFieldValue("unit_destination_id", String(option.value));
		formik.setFieldValue("unit_destination_label", option.label);
	}

	return (
		<div className="d-flex flex-column gap-4 mt-4">
			<FormControlWithCharactersCount
				id="observations"
				as="textarea"
				placeholder="Descreva"
				label="Observações"
				labelClassName="fw-bold"
				maxLength={3000}
				{...formik.getFieldProps("observations")}
			/>

			<Separator />

			<h3 className="mb-0">Dados clínicos</h3>

			<Row>
				<Col lg="auto">
					<FormGroupRadio
						label="Motivo de transferência"
						labelClassName="fw-bold"
						requestedValue={values.transfer_reason_id}
						{...formik.getFieldProps("transfer_reason_id")}
						inline
					>
						<FormRadio id="zero-vacancy" label="Vaga zero" value="1" />
						<FormRadio
							id="vacancy-released-by-bed-center"
							label="Vaga liberada pela central de leitos"
							value="2"
						/>
					</FormGroupRadio>
				</Col>

				<Col lg="auto">
					<FormGroupRadio
						label="Está na central de leitos?"
						labelClassName="fw-bold"
						requestedValue={values.in_central_bed}
						{...formik.getFieldProps("in_central_bed")}
						inline
					>
						<FormRadio id="yes" label="Não" value="0" />
						<FormRadio id="not" label="Sim" value="1" />
					</FormGroupRadio>
				</Col>

				<Col lg={2}>
					{values.in_central_bed === "1" && (
						<FormattedFormControl
							id="protocol"
							label="Nº do protocolo"
							labelClassName="fw-bold"
							isMaskedInput
							mask={Number}
							{...formik.getFieldProps("protocol")}
						/>
					)}
				</Col>
			</Row>

			<Row>
				<FormattedFormControl
					isRequired
					id="diagnostic-hypothesis"
					label="Hipótese diagnóstica (HD)"
					labelClassName="fw-bold"
					isInvalid={!!errors.diagnostic_hypothesis}
					validationMessage={errors.diagnostic_hypothesis}
					{...formik.getFieldProps("diagnostic_hypothesis")}
				/>
			</Row>

			<Row>
				<Col>
					<Form.Label className="fw-bold" htmlFor="unit-origin">
						Unidade de origem <RedAsterisk />
					</Form.Label>

					<UnitsSelect
						inputId="unit-origin"
						value={values.unit_origin_id && selectedUnitOrigin}
						isInvalid={!!errors.unit_origin_id}
						placeholder="Selecione uma unidade"
						onChange={(option) => handleChangeUnitOrigin(option as ReactSelectOption)}
					/>

					{!!errors.unit_origin_id && (
						<span className="text-danger">{errors.unit_origin_id}</span>
					)}
				</Col>

				<Col>
					<Form.Label className="fw-bold" htmlFor="unit-destination">
						Unidade de destino <RedAsterisk />
					</Form.Label>

					<UnitsSelect
						inputId="unit-destination"
						isInvalid={!!errors.unit_destination_id}
						value={values.unit_destination_id && selectedUnitDestination}
						placeholder="Selecione uma unidade"
						onChange={(option) =>
							handleChangeUnitDestinations(option as ReactSelectOption)
						}
					/>

					{!!errors.unit_destination_id && (
						<span className="text-danger">{errors.unit_destination_id}</span>
					)}
				</Col>
			</Row>

			<Row>
				<Col>
					<FormattedFormControl
						id="complement-origin"
						label="Complemento/Setor origem"
						labelClassName="fw-bold"
						{...formik.getFieldProps("complement_origin")}
					/>
				</Col>

				<Col>
					<FormattedFormControl
						id="complement_destination"
						label="Complemento/Setor destino"
						labelClassName="fw-bold"
						{...formik.getFieldProps("complement_destination")}
					/>
				</Col>
			</Row>

			<Separator />

			<h3 className="mb-0">Recurso solicitado</h3>

			<div className="d-flex flex-column gap-4">
				<FormGroupRadio
					requestedValue={values.requested_resource_id}
					{...formik.getFieldProps("requested_resource_id")}
					inline
				>
					<FormRadio id="basic-support-unit" label="USB" value="0" />
					<FormRadio id="advanced-support-unit" label="USA" value="1" />
					<FormRadio id="aeromedical" label="Aeromédico" value="2" />
				</FormGroupRadio>

				<FormControlWithCharactersCount
					id="transfer_observation"
					as="textarea"
					label="Observação da transferência"
					labelClassName="fw-bold"
					placeholder="Descreva"
					maxLength={1000}
					{...formik.getFieldProps("transfer_observation")}
				/>
			</div>
		</div>
	);
}
