import { useEffect, useState } from "react";

import { useApi } from "@/hooks/useApi";
import { RoleMessages } from "@/enums/Role";

import { ReactSelect, ReactSelectProps } from "../ReactSelect";

export function RolesSelect({ ...rest }: ReactSelectProps) {
	const [profiles, setProfiles] = useState<ReactSelectOption[]>([]);
	const { api } = useApi();

	async function fetchProfilesByURC() {
		try {
			const { data } = await api.get("/profiles");

			const transformedData = data?.results.map((result: Role) => ({
				value: result.id,
				label: RoleMessages[result.name as keyof typeof RoleMessages],
			}));

			setProfiles(transformedData);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		fetchProfilesByURC();
	}, []);

	return <ReactSelect options={profiles} isMulti {...rest} />;
}
