export enum Gender {
	Male = "M",
	Female = "F",
	Other = "O",
}

export const GenderMessages = {
	[Gender.Male]: "Masculino",
	[Gender.Female]: "Feminino",
	[Gender.Other]: "Outro",
};
