import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import clsx from "clsx";

import { FiLogOut } from "react-icons/fi";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";

import { AsideMenuMain } from "./AsideMenuMain";
import { DrawerComponent, ScrollComponent, ToggleComponent } from "../../../assets/ts/components";

type Props = {
	asideMenuCSSClasses: string[];
};

const AsideMenu: React.FC<Props> = ({ asideMenuCSSClasses }) => {
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { logout } = useAuth();
	const { api } = useApi();

	async function handleLogout() {
		try {
			await api.post("/auth/logout");

			logout();

			navigate("/login");
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		setTimeout(() => {
			DrawerComponent.reinitialization();
			ToggleComponent.reinitialization();
			ScrollComponent.reinitialization();
			if (scrollRef.current) {
				scrollRef.current.scrollTop = 0;
			}
		}, 50);
	}, [pathname]);

	return (
		<>
			<div className="aside-menu flex-column-fluid">
				<div
					id="kt_aside_menu_wrapper"
					ref={scrollRef}
					className="hover-scroll-overlay-y px-2 my-5 my-lg-5"
					data-kt-scroll="true"
					data-kt-scroll-height="auto"
					data-kt-scroll-dependencies="{default: '#kt_aside_toolbar, #kt_aside_footer', lg: '#kt_header, #kt_aside_toolbar, #kt_aside_footer'}"
					data-kt-scroll-wrappers="#kt_aside_menu"
					data-kt-scroll-offset="5px"
				>
					<div
						id="#kt_aside_menu"
						data-kt-menu="true"
						className={clsx(
							"menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
							asideMenuCSSClasses.join(" ")
						)}
					>
						<AsideMenuMain />
					</div>
				</div>
			</div>

			<div className="aside-footer flex-column-auto py-5" id="kt_aside_footer">
				<button
					className="btn bg-white-with-hover border border-secondary fw-bold w-100"
					onClick={handleLogout}
				>
					<FiLogOut /> <span>Sair</span>
				</button>
			</div>
		</>
	);
};

export { AsideMenu };
