import { useEffect, useRef, useState } from "react";
import classNames from "clsx";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";

import { FaEye, FaPlus, FaPowerOff } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { Link } from "react-router-dom";

import { useApi } from "@/hooks/useApi";
import { RoleMessages } from "@/enums/Role";
import { UserStatus } from "@/enums/UserStatus";
import { formatPhone } from "@/utils/formatPhone";
import { useDebounce } from "@/hooks/useDebounce";
import { formatIdentifier } from "@/utils/formatIdentifier";

import { ChangeUserStatusModal } from "@/components/Modals/ChangeUserStatusModal";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { ViewModal } from "@/components/Users/Modals/ViewModal";
import { EditModal } from "@/components/Users/Modals/EditModal";
import { PaginationLinks } from "@/components/PaginationLinks";

export function Users() {
	const [users, setUsers] = useState<PaginatedUser>({} as PaginatedUser);
	const [showChangeUserStatusModal, setShowChangeUserStatusModal] = useState(false);
	const [showViewUserModal, setShowViewUserModal] = useState(false);
	const [showEditUserModal, setShowEditUserModal] = useState(false);
	const [currentUser, setCurrentUser] = useState<User>({} as User);
	const [skeleton, setSkeleton] = useState(true);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);

	const isFirstRender = useRef(true);
	const forcePage = useRef(1);
	const debouncedSearch = useDebounce(search, 500);
	const { api } = useApi();

	const { results } = users;

	const handleShowViewUserModal = (currentUser: User) => {
		setShowViewUserModal(true);
		setCurrentUser(currentUser);
	};
	const handleCloseViewUserModal = () => setShowViewUserModal(false);

	const handleShowEditUserModal = (currentUser: User) => {
		setShowEditUserModal(true);
		setCurrentUser(currentUser);
	};
	const handleCloseEditUserModal = () => setShowEditUserModal(false);

	const handleShowChangeUserStatusModal = (user: User) => {
		setShowChangeUserStatusModal(true);
		setCurrentUser(user);
	};

	const handleCloseChangeUserStatusModal = () => {
		setShowChangeUserStatusModal(false);
		setCurrentUser({} as User);
	};

	async function fetchPaginatedUsers(pageToUse?: number) {
		setSkeleton(true);

		try {
			const { data } = await api.get("/users", {
				params: {
					page: pageToUse || page,
					search: debouncedSearch,
				},
			});

			setUsers(data);
			setSkeleton(false);
		} catch (error) {
			console.error(error);
			setSkeleton(false);
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchPaginatedUsers(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	useEffect(() => {
		forcePage.current = page;
		fetchPaginatedUsers();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		forcePage.current = 1;
		fetchPaginatedUsers(1);
	}, [debouncedSearch]);

	return (
		<>
			<Link to="cadastro">
				<button className="d-flex align-items-center btn button-bg-white-color-samu mb-6 gap-4">
					<FaPlus /> Cadastrar usuário
				</button>
			</Link>

			<Card>
				<Card.Header className="d-flex justify-content-between align-items-center">
					<h3 className="fw-normal m-0">Usuários cadastrados</h3>

					<SearchFormControl
						placeholder="Pesquisar usuário"
						onChange={(event) => setSearch(event.target.value)}
					/>
				</Card.Header>

				<Card.Body className="pt-0">
					<Table responsive className="table-row-dashed table-row-gray-300">
						<thead>
							<tr className="fw-bold fs-6">
								<th>Nome</th>
								<th>Perfil</th>
								<th>CPF</th>
								<th>Telefone</th>
								<th>Status</th>
								<th>Ações</th>
							</tr>
						</thead>

						<tbody>
							{skeleton ? (
								<TableBodySkeleton columns={6} />
							) : (
								<>
									{results?.map((user, index) => {
										const isActive =
											user.latest_user_status_history?.status_id ===
											UserStatus.Active;

										return (
											<tr key={`user-${index}`}>
												<td className="align-middle">{user.name}</td>

												<td className="align-middle">
													{user.roles.map((role, index) => (
														<div key={`role-${index}`}>
															{
																RoleMessages[
																	role.name as keyof typeof RoleMessages
																]
															}
														</div>
													))}

													{!user.roles.length && (
														<span>Sem perfil nesta unidade</span>
													)}
												</td>

												<td className="align-middle">
													{formatIdentifier(user?.identifier)}
												</td>

												<td className="align-middle">
													{formatPhone(user.phone)}
												</td>

												<td className="align-middle">
													<Badge
														className={classNames(
															"formatted-badge",
															isActive ? "badge-bg-green" : "bg-samu"
														)}
														pill
													>
														{isActive ? "Ativado" : "Desativado"}
													</Badge>
												</td>

												<td className="align-middle w-1px">
													<div className="d-flex gap-2">
														<OverlayTrigger
															overlay={
																<Tooltip>Visualizar perfil</Tooltip>
															}
														>
															<Button
																variant="secondary"
																className="btn-icon btn-sm"
																onClick={() =>
																	handleShowViewUserModal(user)
																}
															>
																<FaEye className="text-gray-700" />
															</Button>
														</OverlayTrigger>

														<OverlayTrigger
															overlay={<Tooltip>Editar</Tooltip>}
														>
															<Button
																variant="secondary"
																className="btn-icon btn-sm"
																onClick={() =>
																	handleShowEditUserModal(user)
																}
															>
																<MdEdit className="text-gray-700" />
															</Button>
														</OverlayTrigger>

														<OverlayTrigger
															overlay={
																<Tooltip>
																	{isActive
																		? "Desativar"
																		: "Ativar"}
																</Tooltip>
															}
														>
															<Button
																variant="secondary"
																className="btn-icon btn-sm"
																disabled={skeleton}
																onClick={() =>
																	handleShowChangeUserStatusModal(
																		user
																	)
																}
															>
																{skeleton ? (
																	<Spinner
																		variant="samu"
																		animation="border"
																		size="sm"
																	/>
																) : (
																	<FaPowerOff className="text-gray-700" />
																)}
															</Button>
														</OverlayTrigger>
													</div>
												</td>
											</tr>
										);
									})}

									{!results?.length && (
										<tr>
											<td className="text-center" colSpan={12}>
												<h2 className="mt-12 mb-0">
													Não há usuários cadastrados
												</h2>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</Table>

					{!!results?.length && (
						<div className="d-flex justify-content-end mt-8">
							<PaginationLinks
								itemsPerPage={10}
								totalItems={users.meta?.total}
								forcePage={forcePage.current - 1}
								changeSelectedPage={handleChangeSelectedPage}
							/>
						</div>
					)}
				</Card.Body>
			</Card>

			<ViewModal
				showViewUserModal={showViewUserModal}
				handleCloseViewUserModal={handleCloseViewUserModal}
				user={currentUser}
			/>

			<EditModal
				showEditUserModal={showEditUserModal}
				handleCloseEditUserModal={handleCloseEditUserModal}
				currentUser={currentUser}
				fetchPaginatedUsers={fetchPaginatedUsers}
			/>

			<ChangeUserStatusModal
				showChangeUserStatusModal={showChangeUserStatusModal}
				handleCloseChangeUserStatusModal={handleCloseChangeUserStatusModal}
				currentUser={currentUser}
				fetchPaginatedUsers={fetchPaginatedUsers}
			/>
		</>
	);
}
