import classNames from "clsx";

type Props = {
	totalTickets: number;
	totalTicketsWithMedicalOrientation: number;
	totalAttendancesWithBasicSupportUnits: number;
	totalAttendancesWithAdvancedSupportUnits: number;
	totalTicketsWithMultipleAttendances: number;
	showOccupationCodeColumn?: boolean;
};

export function ProceduresRows({
	totalTickets,
	totalTicketsWithMedicalOrientation,
	totalAttendancesWithBasicSupportUnits,
	totalAttendancesWithAdvancedSupportUnits,
	totalTicketsWithMultipleAttendances,
	showOccupationCodeColumn = false,
}: Props) {
	return (
		<>
			<tr>
				<td>Total de chamadas recebidas pela CRU (TARM)</td>
				<td>0301030014</td>
				<td className={classNames(!showOccupationCodeColumn && "d-none")}>422205</td>
				<td className="text-center">{totalTickets}</td>
			</tr>

			<tr>
				<td>Total de chamadas recebidas pela CRU com orientação médica</td>
				<td>0301030146</td>
				<td className={classNames(!showOccupationCodeColumn && "d-none")}>225125</td>
				<td className="text-center">{totalTicketsWithMedicalOrientation}</td>
			</tr>

			<tr>
				<td>Chamados com envio de Suporte Básico (Motolância e USB)</td>
				<td>0301030138</td>
				<td className={classNames(!showOccupationCodeColumn && "d-none")}>225125</td>
				<td className="text-center">{totalAttendancesWithBasicSupportUnits}</td>
			</tr>

			<tr>
				<td>Chamados com envio de Suporte Avançado (Aeromédico e USA)</td>
				<td>0301030120</td>
				<td className={classNames(!showOccupationCodeColumn && "d-none")}>225125</td>
				<td className="text-center">{totalAttendancesWithAdvancedSupportUnits}</td>
			</tr>

			<tr>
				<td>Acionamento de múltiplos meios</td>
				<td>0301030111</td>
				<td className={classNames(!showOccupationCodeColumn && "d-none")}>225125</td>
				<td className="text-center">{totalTicketsWithMultipleAttendances}</td>
			</tr>
		</>
	);
}
