export const baseOrUnitInitialValues = {
	id: "",
	name: "",
	urc_id: "",
	national_health_registration: "",
	street: "",
	house_number: "",
	zip_code: "",
	neighborhood: "",
	complement: "",
	latitude: "",
	longitude: "",
	telephone: "",
	unit_type_id: "",
	company_registration_number: "",
	company_name: "",
	federal_unit_id: "",
	federal_unit_label: "",
	city_id: "",
	city_label: "",
	vehicle_type_id: "",
	vehicle_type_label: "",
	dataType: "",
};
