import { FC } from "react";

import { Link } from "react-router-dom";

import { useAuth } from "@/modules/auth";
import { routesPerRole } from "@/helpers/routesPerRole";
import { toAbsoluteUrl } from "@/../_metronic/helpers";

const Error404: FC = () => {
	const { currentAuth } = useAuth();

	const currentRole = currentAuth?.current_role.name as keyof typeof routesPerRole;
	const mainRoutePerRole = routesPerRole[currentRole]?.[0];

	return (
		<div className="d-flex flex-column flex-root">
			<div className="d-flex flex-column flex-center flex-column-fluid p-10">
				<img
					src={toAbsoluteUrl("/media/errors/404.png")}
					alt="error 404"
					className="mw-100 mb-10 h-lg-450px"
				/>

				<h1 className="text-gray-500 fw-bold mb-10">Parece que não há nada aqui</h1>

				<Link
					to={mainRoutePerRole}
					className="btn text-white fs-3 fw-bold bg-samu login-button"
				>
					Voltar
				</Link>
			</div>
		</div>
	);
};

export { Error404 };
