import { useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { version } from "@/../version";
import { toAbsoluteUrl } from "@/../_metronic/helpers";

import { SelectURCOrRole } from "./components/SelectURCOrRole";
import { ForgotPassword } from "./components/ForgotPassword";
import { Login } from "./components/Login";

import "./styles.scss";

const AuthLayout = () => {
	return (
		<div
			className="w-100 h-100 bgi-no-repeat bgi-size-cover bgi-attachment-fixed"
			style={{
				backgroundImage: `url(${toAbsoluteUrl("/media/logos/fundo-samu.png")})`,
			}}
		>
			<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 h-100">
				<div className="w-lg-450px bg-body rounded shadow-sm mx-auto pt-15 px-15 pb-8">
					<div className="d-flex justify-content-center">
						<span className="mb-5">
							<img
								alt="Logo do SAMU"
								src={toAbsoluteUrl("/media/logos/samu-logo.png")}
								className="h-60px scale-2.1"
							/>
						</span>
					</div>

					<Outlet />

					<div className="text-end fw-bold color-samu-with-hover pt-12">
						VERSÃO: {version}
					</div>
				</div>
			</div>
		</div>
	);
};

export function AuthPage() {
	const [userData, setUserData] = useState<UserData>({} as UserData);

	return (
		<Routes>
			<Route element={<AuthLayout />}>
				<Route
					path="login"
					element={<Login userData={userData} setUserData={setUserData} />}
				/>
				<Route
					path="selecionar-unidade-ou-perfil"
					element={<SelectURCOrRole userData={userData} />}
				/>
				<Route path="recuperar-senha" element={<ForgotPassword />} />
				<Route index element={<Login userData={userData} setUserData={setUserData} />} />
			</Route>
		</Routes>
	);
}
