import { Demos } from "./Demos";

export function DemosDrawer() {
	return (
		<>
			{/* begin::Demos drawer */}
			<div
				id="kt_engage_demos"
				className="bg-body"
				data-kt-drawer="true"
				data-kt-drawer-name="explore"
				data-kt-drawer-activate="true"
				data-kt-drawer-overlay="true"
				data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
				data-kt-drawer-direction="end"
				data-kt-drawer-toggle="#kt_engage_demos_toggle"
				data-kt-drawer-close="#kt_engage_demos_close"
			>
				{/* begin::Card  */}
				<div className="card shadow-none w-100">
					{/* begin::Header */}
					<div className="card-header" id="kt_explore_header">
						<div className="card-toolbar">
							<button
								type="button"
								className="btn btn-sm btn-icon explore-btn-dismiss me-n5"
								id="kt_engage_demos_close"
							></button>
						</div>
					</div>
					{/* end::Header */}
					{/* begin::Body */}
					<div className="card-body" id="kt_explore_body">
						{/* begin::Content */}
						<div
							id="kt_explore_scroll"
							className="scroll-y me-n5 pe-5"
							data-kt-scroll="true"
							data-kt-scroll-height="auto"
							data-kt-scroll-wrappers="#kt_engage_demos_body"
							data-kt-scroll-dependencies="#kt_engage_demos_header"
							data-kt-scroll-offset="5px"
						>
							<div className="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
								<div className="d-flex flex-stack">
									<div className="d-flex flex-column">
										<div className="d-flex align-items-center mb-1">
											<div className="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">
												Regular License
											</div>

											<i
												className="text-gray-400 fas fa-exclamation-circle ms-1 fs-7"
												data-bs-toggle="popover"
												data-bs-trigger="hover"
												data-bs-placement="top"
												data-bs-content="Use, by you or one client in a single end product which end users are not charged for."
											></i>
										</div>
										<div className="fs-7 text-muted">
											For single end product used by you or one client
										</div>
									</div>

									<div className="text-nowrap">
										<span className="text-muted fs-7 fw-bold">$</span>
										<span className="text-dark fs-1 fw-bolder">39</span>
									</div>
								</div>
							</div>

							<div className="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
								<div className="d-flex flex-stack">
									<div className="d-flex flex-column">
										<div className="d-flex align-items-center mb-1">
											<div className="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">
												Extended License
											</div>

											<i
												className="text-gray-400 fas fa-exclamation-circle ms-1 fs-7"
												data-bs-toggle="popover"
												data-bs-trigger="hover"
												data-bs-placement="top"
												data-bs-content="Use, by you or one client, in a single end product which end users can be charged for."
											></i>
										</div>
										<div className="fs-7 text-muted">
											For single end product with paying users.
										</div>
									</div>

									<div className="text-nowrap">
										<span className="text-muted fs-7 fw-bold">$</span>
										<span className="text-dark fs-1 fw-bolder">939</span>
									</div>
								</div>
							</div>

							<div className="rounded border border-dashed border-gray-300 py-4 px-6 mb-5">
								<div className="d-flex flex-stack">
									<div className="d-flex flex-column">
										<div className="d-flex align-items-center mb-1">
											<div className="fs-6 fw-bold text-gray-800 fw-bold mb-0 me-1">
												Custom License
											</div>
										</div>
										<div className="fs-7 text-muted">
											Reach us for custom license offers.
										</div>
									</div>

									<div className="text-nowrap">
										<a
											href="https://keenthemes.com/contact/"
											className="btn btn-sm btn-success"
										>
											Contact Us
										</a>
									</div>
								</div>
							</div>

							<Demos />
						</div>
						{/* end::Content */}
					</div>
					{/* end::Body */}
				</div>
				{/* begin::Card */}
			</div>
			{/* end::Demos drawer */}
		</>
	);
}
