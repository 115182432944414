type Props = {
	motorcycleAmbulances: OutpatientProductionBulletinVehicle[];
};

export function MotorcycleAmbulanceRows({ motorcycleAmbulances }: Props) {
	return (
		<>
			{motorcycleAmbulances?.map((motorcycleAmbulance, index) => (
				<tr key={`motorcycle-ambulance-${index}`}>
					<td>{index + 1}</td>
					<td>{motorcycleAmbulance.name}</td>
					<td>{motorcycleAmbulance.national_health_registration}</td>
					<td>0301030197</td>
					<td className="text-center">{motorcycleAmbulance.total_attendance_count}</td>
				</tr>
			))}
		</>
	);
}
