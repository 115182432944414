import { Route, Routes } from "react-router-dom";

import { RegistrationCard } from "@/components/BasesAndUnits/RegistrationCard";
import { BasesAndUnits } from "@/pages/BasesAndUnits";

export function BaseRoutes() {
	return (
		<Routes>
			<Route path="/" element={<BasesAndUnits />} />
			<Route path="cadastro" element={<RegistrationCard />} />
		</Routes>
	);
}
