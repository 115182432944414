import { useNavigate } from "react-router-dom";

import { ImArrowLeft2 } from "react-icons/im";
import Card from "react-bootstrap/Card";

import { useAuth } from "@/modules/auth";

import { ViewPassword } from "@/components/Users/Forms/ViewPassword";
import { ViewForm } from "@/components/Users/Forms/ViewForm";

export function ViewProfile() {
	const { currentAuth } = useAuth();
	const navigate = useNavigate();

	return (
		<>
			<Card className="mb-8">
				<Card.Header className="d-flex justify-content-between align-items-center">
					<div className="w-100px" />

					<h2 className="m-0">Meu perfil</h2>

					<button
						className="d-flex align-items-center btn button-bg-light-color-gray-400 gap-2 w-100px"
						onClick={() => navigate(-1)}
					>
						<ImArrowLeft2 />
						Voltar
					</button>
				</Card.Header>

				<Card.Body>
					<ViewForm user={currentAuth as User} isTheProfileView />
				</Card.Body>
			</Card>

			<Card>
				<Card.Body>
					<ViewPassword user={currentAuth as User} isTheProfileView />
				</Card.Body>
			</Card>
		</>
	);
}
