export enum WoundType {
	Amputation = 1,
	Contusion = 2,
	Cutting = 3,
	CuttingContusion = 4,
	Excoriation = 5,
	StabWounds = 6,
	FirearmWounds = 7,
	ExposedFracture = 8,
	ClosedFracture = 9,
	Bleeding = 10,
	Perforating = 11,
	Burn = 12,
	Others = 13,
}

export const WoundTypeMessages = {
	[WoundType.Amputation]: "Amputação/Avulsão",
	[WoundType.Contusion]: "Contusão",
	[WoundType.Cutting]: "Cortante",
	[WoundType.CuttingContusion]: "Corto-contuso",
	[WoundType.Excoriation]: "Escoriação",
	[WoundType.StabWounds]: "FAB",
	[WoundType.FirearmWounds]: "FAF",
	[WoundType.ExposedFracture]: "Fratura exposta",
	[WoundType.ClosedFracture]: "Fratura fechada",
	[WoundType.Bleeding]: "Hemorragia",
	[WoundType.Perforating]: "Perfurante",
	[WoundType.Burn]: "Queimadura",
	[WoundType.Others]: "Outros",
};
