import { ChangeEvent, FormEvent, KeyboardEvent, useState } from "react";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";

import { SearchInputGroupFormControl } from "@/components/FormControl/SearchInputGroupFormControl";

type searchProps = {
	search: string;
	setSearch: (search: string) => void;
	handleFetchedData: (cadsusConsult: CadsusResponse) => void;
};

export function CpfSearch({ search, setSearch, handleFetchedData }: searchProps) {
	const [loading, setLoading] = useState(false);
	const { Toast } = useSwal();
	const { api } = useApi();

	async function handleSearchIdentifier() {
		setLoading(true);

		try {
			const { data } = await api.get(`/cadsus-consultation/${search}`);

			handleFetchedData(data);
		} catch (error) {
			Toast.fire({
				icon: "error",
				title: "Erro ao buscar dados",
			});

			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	function handleKeyUp(event: KeyboardEvent) {
		if (event.key === "Enter") {
			handleSearchIdentifier();
		}
	}

	function formatCPF(input: string) {
		return input.replace(/[^\d]/g, "");
	}

	function handleChange(event: ChangeEvent<HTMLInputElement> & FormEvent<HTMLButtonElement>) {
		const formattedValue = formatCPF(event.target.value);

		setSearch(formattedValue);
	}

	return (
		<SearchInputGroupFormControl
			id="search-by-CPF"
			label="Digite o CPF"
			labelClassName="fw-bold"
			placeholder="Pesquisar CPF"
			value={search}
			onChange={handleChange}
			onClick={handleSearchIdentifier}
			isLoading={loading}
			isMaskedInput
			onKeyUp={handleKeyUp}
		/>
	);
}
