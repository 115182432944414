export enum VehicleType {
	noType = 0,
	transportAmbulance = 1,
	BHU = 2,
	AHU = 3,
	craft = 4,
	aeromedical = 5,
	RTV = 6,
	motorizedAmbulance = 7,
}

export const VehicleTypeMessages = {
	[VehicleType.noType]: "Sem base",
	[VehicleType.transportAmbulance]: "Ambulância de transporte",
	[VehicleType.BHU]: "USB",
	[VehicleType.AHU]: "USA",
	[VehicleType.craft]: "Embarcação",
	[VehicleType.aeromedical]: "Aeromédico",
	[VehicleType.RTV]: "VIR",
	[VehicleType.motorizedAmbulance]: "Motolância",
};
