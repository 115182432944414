import { Routes, Route } from "react-router-dom";

import { MedicalRegulation } from "@/pages/Attendance/MedicalRegulation";
import { NewAttendance } from "@/components/Attendance/NewAttendance";
import { SceneRegister } from "@/pages/Attendance/SceneRegister";
import { AttendanceSummary } from "@/pages/AttendanceSummary";
import { AttendanceViewing } from "@/pages/AttendanceViewing";
import { Attendance } from "@/pages/Attendance";

export function AttendanceRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Attendance />} />
			<Route path="nova-ocorrencia" element={<NewAttendance />} />
			<Route path="regulacao-medica/:attendanceId" element={<MedicalRegulation />} />
			<Route path="registro-de-cena/:attendanceId" element={<SceneRegister />} />
			<Route path="resumo/:attendanceId" element={<AttendanceSummary />} />
			<Route
				path="visualizar-chamado/:attendanceId/:attendanceType"
				element={<AttendanceViewing />}
			/>
		</Routes>
	);
}
