import axios, { AxiosError, AxiosRequestConfig } from "axios";

export function useTokenApi() {
	const baseURL = process.env.REACT_APP_API_URL || "api";
	const { totemAccessToken } = localStorage;
	const controller = new AbortController();

	function requestInterceptor(config: AxiosRequestConfig): AxiosRequestConfig {
		if (!config.headers) {
			config.headers = {};
		}

		config.headers.Authorization = totemAccessToken;
		config.signal = controller.signal;

		return config;
	}

	function responseInterceptor(error: AxiosError) {
		return Promise.reject(error);
	}

	function createApi(config?: AxiosRequestConfig) {
		const api = axios.create(config);

		api.interceptors.request.use(requestInterceptor, (err: any) => Promise.reject(err));
		api.interceptors.response.use((response) => response, responseInterceptor);

		return api;
	}

	const tokenApi = createApi({ baseURL });

	return { tokenApi };
}
