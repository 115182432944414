import { useEffect, useRef, useState } from "react";
import classNames from "clsx";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";

import { Link, useLocation } from "react-router-dom";
import { FaEye, FaPowerOff } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

import { Role } from "@/enums/Role";
import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { formatPhone } from "@/utils/formatPhone";
import { useDebounce } from "@/hooks/useDebounce";
import { useCanAccess } from "@/hooks/useCanAccess";

import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { ViewModal } from "@/components/BasesAndUnits/Modals/ViewModal";
import { EditModal } from "@/components/BasesAndUnits/Modals/EditModal";
import { PaginationLinks } from "@/components/PaginationLinks";

export function BasesAndUnits() {
	const [basesOrUnits, setBasesOrUnits] = useState<PaginatedBaseOrUnit>(
		{} as PaginatedBaseOrUnit
	);
	const [selectedBaseOrUnit, setSelectedBaseOrUnit] = useState<BaseOrUnit>({} as BaseOrUnit);
	const [dataType, setDataType] = useState<"base" | "unidade">("base");
	const [showViewModal, setShowViewModal] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [skeleton, setSkeleton] = useState(true);
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);
	const { can } = useCanAccess();

	const isFirstRender = useRef(true);
	const forcePage = useRef(1);
	const debouncedSearch = useDebounce(search, 500);
	const location = useLocation();
	const { api } = useApi();
	const { Toast, toastRequestErrors } = useSwal();

	const { results } = basesOrUnits;
	const { pathname } = location;

	const baseDataType = dataType === "base";

	const { admin } = Role;

	const handleShowViewModal = () => setShowViewModal(true);
	const handleCloseViewModal = () => setShowViewModal(false);

	const handleShowEditModal = () => setShowEditModal(true);
	const handleCloseEditModal = () => setShowEditModal(false);

	async function fetchPaginatedBasesOrUnits(pageToUse?: number) {
		setSkeleton(true);

		const route = pathname === "/bases" ? "/bases" : "/units";

		try {
			const { data } = await api.get(route, {
				params: {
					page: pageToUse || page,
					search: debouncedSearch,
				},
			});

			setBasesOrUnits(data);
		} catch (error) {
			console.error(error);
		} finally {
			setSkeleton(false);
		}
	}

	async function handleChangeStatus(id: string) {
		setLoading(true);

		try {
			if (baseDataType) {
				await api.put(`/bases/change-status/${id}`);
			} else {
				await api.put(`/units/change-status/${id}`);
			}

			fetchPaginatedBasesOrUnits();
		} catch (error: any) {
			console.error(error);

			if (error.isAxiosError && error.response.status === 422) {
				return toastRequestErrors(error.response.data.errors);
			}

			Toast.fire({
				icon: "error",
				title: "Erro ao mudar status",
			});
		} finally {
			setLoading(false);
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchPaginatedBasesOrUnits(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	useEffect(() => {
		forcePage.current = page;
		fetchPaginatedBasesOrUnits();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		forcePage.current = 1;
		fetchPaginatedBasesOrUnits(1);
	}, [debouncedSearch]);

	useEffect(() => {
		pathname === "/bases" ? setDataType("base") : setDataType("unidade");
	}, []);

	return (
		<>
			{can([admin]) && (
				<Link to="cadastro">
					<button className="d-flex align-items-center btn button-bg-white-color-samu mb-6 gap-4">
						<FaPlus /> Cadastrar {baseDataType ? "base" : "unidade"}
					</button>
				</Link>
			)}

			<Card>
				<Card.Header className="d-flex justify-content-between align-items-center">
					<h3 className="fw-normal m-0">
						{baseDataType ? "Bases" : "Unidades"} cadastradas
					</h3>

					<SearchFormControl
						placeholder={`Pesquisar ${dataType}`}
						onChange={(event) => setSearch(event.target.value)}
					/>
				</Card.Header>

				<Card.Body className="pt-0">
					<Table responsive className="table-row-dashed table-row-gray-300">
						<thead>
							<tr className="fw-bold fs-6 ">
								<th>Nome da {dataType}</th>
								{can([admin]) && (
									<>
										<th>CNES</th>
										<th>Endereço</th>
									</>
								)}
								<th>Telefone</th>
								<th>Tipo de unidade</th>
								<th>Status</th>
								<th>Ações</th>
							</tr>
						</thead>

						<tbody>
							{skeleton ? (
								<TableBodySkeleton columns={7} />
							) : (
								<>
									{results?.map((baseOrUnit, index) => {
										const isActive = baseOrUnit.is_active;

										const address = [
											baseOrUnit.street,
											baseOrUnit.house_number,
											baseOrUnit.neighborhood,
											baseOrUnit.city?.name,
										];
										const formattedAddress = address.filter(Boolean).join(" ");

										return (
											<tr key={`base-or-unit-${index}`}>
												<td className="align-middle">{baseOrUnit.name}</td>

												{can([admin]) && (
													<>
														<td className="align-middle">
															{
																baseOrUnit.national_health_registration
															}
														</td>

														<td className="align-middle">
															{formattedAddress}
														</td>
													</>
												)}

												<td className="align-middle">
													{formatPhone(baseOrUnit.telephone)}
												</td>

												<td className="align-middle">
													{baseOrUnit.unit_type?.name}
												</td>

												<td className="align-middle">
													<Badge
														className={classNames(
															"formatted-badge",
															isActive ? "badge-bg-green" : "bg-samu"
														)}
														pill
													>
														{isActive ? "Ativado" : "Desativado"}
													</Badge>
												</td>

												<td className="align-middle w-1px">
													<div className="d-flex gap-2">
														<OverlayTrigger
															overlay={<Tooltip>Visualizar</Tooltip>}
														>
															<Button
																variant="secondary"
																className="btn-icon btn-sm"
																onClick={() => {
																	setSelectedBaseOrUnit(
																		baseOrUnit
																	);
																	handleShowViewModal();
																}}
															>
																<FaEye className="text-gray-700" />
															</Button>
														</OverlayTrigger>

														{can([admin]) && (
															<>
																<OverlayTrigger
																	overlay={
																		<Tooltip>Editar</Tooltip>
																	}
																>
																	<Button
																		variant="secondary"
																		className="btn-icon btn-sm"
																		onClick={() => {
																			setSelectedBaseOrUnit(
																				baseOrUnit
																			);
																			handleShowEditModal();
																		}}
																	>
																		<MdEdit className="text-gray-700" />
																	</Button>
																</OverlayTrigger>

																<OverlayTrigger
																	overlay={
																		<Tooltip>
																			{isActive
																				? "Desativar"
																				: "Ativar"}
																		</Tooltip>
																	}
																>
																	<Button
																		variant="secondary"
																		className="btn-icon btn-sm"
																		disabled={loading}
																		onClick={() =>
																			handleChangeStatus(
																				baseOrUnit.id
																			)
																		}
																	>
																		{loading ? (
																			<Spinner
																				variant="samu"
																				animation="border"
																				size="sm"
																			/>
																		) : (
																			<FaPowerOff className="text-gray-700" />
																		)}
																	</Button>
																</OverlayTrigger>
															</>
														)}
													</div>
												</td>
											</tr>
										);
									})}

									{!results?.length && (
										<tr>
											<td className="text-center" colSpan={12}>
												<h2 className="mt-12 mb-0">
													Não há {dataType}s cadastradas
												</h2>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</Table>

					{!!results?.length && (
						<div className="d-flex justify-content-end mt-8">
							<PaginationLinks
								itemsPerPage={10}
								totalItems={basesOrUnits.meta?.total}
								forcePage={forcePage.current - 1}
								changeSelectedPage={handleChangeSelectedPage}
							/>
						</div>
					)}
				</Card.Body>
			</Card>

			<ViewModal
				baseOrUnit={selectedBaseOrUnit}
				showViewModal={showViewModal}
				handleCloseViewModal={handleCloseViewModal}
				dataType={dataType}
			/>

			<EditModal
				baseOrUnit={selectedBaseOrUnit}
				showEditModal={showEditModal}
				handleCloseEditModal={handleCloseEditModal}
				dataType={dataType}
				fetchPaginatedBasesOrUnits={fetchPaginatedBasesOrUnits}
			/>
		</>
	);
}
