import { PriorityType, PriorityTypeMessages } from "@/enums/PritorityType";

export const priorityTypeOptions = [
	{
		label: PriorityTypeMessages[PriorityType.Blue],
		value: PriorityType.Blue,
		background: "#DDEEFF",
		color: "#3699FF",
	},
	{
		label: PriorityTypeMessages[PriorityType.Green],
		value: PriorityType.Green,
		background: "#DFFFEA",
		color: "#17C690",
	},
	{
		label: PriorityTypeMessages[PriorityType.Orange],
		value: PriorityType.Orange,
		background: "#FFEADE",
		color: "#FF5C00",
	},
	{
		label: PriorityTypeMessages[PriorityType.Red],
		value: PriorityType.Red,
		background: "#FFE1E1",
		color: "#FF0000",
	},
	{
		label: PriorityTypeMessages[PriorityType.Yellow],
		value: PriorityType.Yellow,
		background: "#FFF5D2",
		color: "#FFC700",
	},
];
