import { useEffect } from "react";
import { useFormik } from "formik";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { AttendanceType } from "@/enums/AttendanceType";
import { attendanceLinkSchema } from "@/utils/validation/attendanceLinkSchema";

import { Additional, ReactSelect } from "@/components/Selects/ReactSelect";
import { RedAsterisk } from "@/components/RedAsterisk";

type Props = {
	showAttendanceLinkModal: boolean;
	handleCloseAttendanceLinkModal: () => void;
	fetchAttendances?: () => Promise<void>;
	selectedAttendance?: Attendance;
	selectedAttendanceConsultation?: AttendanceConsultation;
};

export function AttendanceLinkModal({
	showAttendanceLinkModal,
	handleCloseAttendanceLinkModal,
	fetchAttendances,
	selectedAttendance,
	selectedAttendanceConsultation,
}: Props) {
	const { api } = useApi();
	const { Toast } = useSwal();

	const attendance = selectedAttendance || selectedAttendanceConsultation;

	const formik = useFormik({
		initialValues: {
			father_link_id: "",
		},
		validationSchema: attendanceLinkSchema,
		async onSubmit(values, { setSubmitting, resetForm }) {
			try {
				await api.post("/attendance/link", {
					children_links: [attendance?.id],
					...values,
				});

				if (fetchAttendances) {
					await fetchAttendances();
				}

				Toast.fire({
					icon: "success",
					title: "Ocorrência vinculada com sucesso!",
				});

				resetForm();
				handleCloseAttendanceLinkModal();
			} catch (error: any) {
				console.log(error);

				Toast.fire({
					icon: "error",
					title: error.response.data.message,
				});

				setSubmitting(false);
			}
		},
	});

	const { values } = formik;

	async function handleSearchAttendances(page?: number, search?: string) {
		try {
			const ticketType =
				selectedAttendance?.ticket?.ticket_type_id ||
				selectedAttendanceConsultation?.ticket_type_id;
			const isPrimaryOcurrence = ticketType === AttendanceType.PrimaryOccurrence;
			const url = isPrimaryOcurrence
				? "/ticket/primary-attendance"
				: "/ticket/secondary-attendance";

			const { data } = await api.get(url, {
				params: {
					search,
					exclude_finished_attendances: 1,
					page: page || 1,
				},
			});

			return data as PaginatedAttendable;
		} catch (error: any) {
			console.log(error);

			return {} as PaginatedAttendable;
		}
	}

	const loadAsyncAttendancesOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, __: Attendable, { page }: Additional) {
			const attendables = await handleSearchAttendances(page, search);

			return {
				options:
					attendables.results?.map((attendable) => {
						const attendanceTicket =
							attendable.attendance?.ticket
								?.ticket_sequence_per_urgency_regulation_center;
						const attendanceSequencePerTicket =
							attendable.attendance?.attendance_sequence_per_ticket;
						const patientName = attendable.attendance?.patient?.name;

						return {
							value: attendable.attendance?.id,
							label: `${attendanceTicket}/${attendanceSequencePerTicket} - ${
								patientName ?? "Vítima sem identificação"
							}`,
							isDisabled: attendable.attendance?.id === attendance?.id,
						};
					}) || [],
				hasMore: attendables.meta?.current_page < attendables.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	useEffect(() => {
		formik.validateForm();
	}, [values.father_link_id]);

	return (
		<Modal
			show={showAttendanceLinkModal}
			onHide={handleCloseAttendanceLinkModal}
			size="lg"
			centered
		>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Vincular ocorrência</h2>
			</Modal.Header>

			<Modal.Body>
				<Form className="d-flex flex-column gap-4" onSubmit={formik.handleSubmit}>
					<Row className="fw-bold">
						<Col lg={2}>
							N°:{" "}
							{selectedAttendance?.number ||
								selectedAttendanceConsultation?.attendance_number}
						</Col>

						<Col>Paciente: {attendance?.patient?.name ?? "-"}</Col>
					</Row>

					<Form.Label className="fw-bold m-0" htmlFor="father_link_id">
						Vincular ocorrência
						<RedAsterisk />
					</Form.Label>

					<ReactSelect
						inputId="father_link_id"
						placeholder="Selecione uma ocorrência para vincular"
						{...loadAsyncAttendancesOptionsProps}
						onChange={(option) =>
							formik.setFieldValue(
								"father_link_id",
								(option as ReactSelectOption).value
							)
						}
					/>

					<div className="d-flex justify-content-center gap-2 mt-4">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseAttendanceLinkModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							Vincular
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
