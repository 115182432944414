import { useEffect } from "react";
import { useFormik } from "formik";

import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { natureTypesOptions } from "@/utils/options/natureTypes";
import { diagnosticHypothesisInitialValues } from "@/utils/initialValues/diagnosticHypothesis";
import { diagnosticHypothesisValidationSchema } from "@/utils/validation/diagnosticHypothesisValidationSchema";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { RedAsterisk } from "@/components/RedAsterisk";

type Props = {
	showDiagnosticHypothesisModal: boolean;
	handleCloseDiagnosticHypothesisModal: () => void;
	fetchPaginatedDiagnosticHypotheses: () => void;
	currentDiagnosticHypothesis?: DiagnosticHypothesis;
};

export function DiagnosticHypothesisModal({
	showDiagnosticHypothesisModal,
	handleCloseDiagnosticHypothesisModal,
	fetchPaginatedDiagnosticHypotheses,
	currentDiagnosticHypothesis,
}: Props) {
	const { Toast, toastRequestErrors } = useSwal();
	const { api } = useApi();

	const isEdit =
		currentDiagnosticHypothesis && Object.entries(currentDiagnosticHypothesis).length;

	const formik = useFormik({
		initialValues: diagnosticHypothesisInitialValues,
		validationSchema: diagnosticHypothesisValidationSchema,
		async onSubmit(values, { setSubmitting }) {
			try {
				const method = isEdit ? "put" : "post";
				const route = isEdit
					? `/diagnostic-hypothesis/${currentDiagnosticHypothesis?.id}`
					: "/diagnostic-hypothesis";

				await api[method](route, {
					name: values.name,
					nature_types_id: values.nature_types_id.map((natureType) => natureType.value),
				});

				handleCloseModal();
				fetchPaginatedDiagnosticHypotheses();

				Toast.fire({
					icon: "success",
					title: `Hipótese diagnóstica ${
						currentDiagnosticHypothesis ? "atualizada" : "cadastrada"
					} com sucesso!`,
				});
			} catch (error: any) {
				console.log(error);

				toastRequestErrors(error.response.data?.errors);

				setSubmitting(false);
			}
		},
	});

	function handleCloseModal() {
		formik.resetForm();

		handleCloseDiagnosticHypothesisModal();
	}

	const { errors, values } = formik;

	useEffect(() => {
		if (isEdit) {
			const natureTypesSelected = currentDiagnosticHypothesis?.nature_types?.map(
				(natureType) => ({
					label: natureType.name,
					value: natureType.id,
				})
			);

			formik.setFieldValue("name", currentDiagnosticHypothesis?.name);
			formik.setFieldValue("nature_types_id", natureTypesSelected);
		}
	}, [currentDiagnosticHypothesis]);

	useEffect(() => {
		if (showDiagnosticHypothesisModal) {
			formik.validateForm();
		}
	}, [values.nature_types_id, values.name, showDiagnosticHypothesisModal]);

	return (
		<Modal size="lg" show={showDiagnosticHypothesisModal} onHide={handleCloseModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">{isEdit ? "Editar" : "Cadastrar"} hipótese diagnóstica</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-4">
					<FormattedFormControl
						id="name"
						label="Hipótese diagnóstica"
						labelClassName="fw-bold"
						validationMessage={errors.name}
						isInvalid={!!errors.name}
						{...formik.getFieldProps("name")}
						isRequired
					/>

					<div>
						<Form.Label htmlFor="nature-type" className="fw-bold">
							Natureza <RedAsterisk />
						</Form.Label>

						<ReactSelect
							isMulti
							isInvalid={!!errors.nature_types_id}
							inputId="nature-types"
							placeholder="Selecione a natureza vinculada"
							options={natureTypesOptions}
							value={values.nature_types_id}
							onChange={(options) => formik.setFieldValue("nature_types_id", options)}
						/>

						{errors.nature_types_id && (
							<span className="text-danger">
								{errors.nature_types_id as string[]}
							</span>
						)}
					</div>

					<div className="d-flex justify-content-center gap-4 mt-5">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : (
								"Salvar"
							)}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
