import classNames from "clsx";

import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";

import "./styles.scss";

type Props = {
	percentage: string;
};

export function PercentageBadge({ percentage }: Props) {
	const change = Number(percentage?.replace("%", ""));

	return (
		<span
			className={classNames(
				"d-flex align-items-center fs-5 fw-bold gap-1 rounded-1 py-1 px-3",
				change > 0 ? "badge-yellow" : "percentage-badge-blue"
			)}
		>
			{change > 0 ? <FaArrowUpLong className="fs-8" /> : <FaArrowDownLong className="fs-8" />}
			{percentage}
		</span>
	);
}
