import { Route, Routes } from "react-router-dom";

import { RegistrationForm } from "@/components/Users/Forms/RegistrationForm";
import { Users } from "@/pages/Users";

export function UserRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Users />} />
			<Route path="cadastro" element={<RegistrationForm />} />
		</Routes>
	);
}
