import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Spinner from "react-bootstrap/Spinner";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";

export function AuthorizeLogin() {
	const { saveAuth, currentAuth } = useAuth();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { api } = useApi();

	async function handleNewToken() {
		try {
			const { data } = await api.post("/oauth/token", {
				grant_type: "authorization_code",
				code: searchParams.get("code"),
			});

			saveAuth(data);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		if (currentAuth) {
			navigate("/");

			return;
		}

		handleNewToken();
	}, [currentAuth]);

	return (
		<div id="splash-screen" className="splash-screen">
			<Spinner animation="border" variant="primary" />
			<span className="mt-3">Carregando...</span>
		</div>
	);
}
