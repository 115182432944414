import Card from "react-bootstrap/Card";

import { useLocation, useNavigate } from "react-router-dom";
import { ImArrowLeft2 } from "react-icons/im";

import { RegistrationForm } from "@/components/BasesAndUnits/RegistrationForm";

export function RegistrationCard() {
	const navigate = useNavigate();

	const location = useLocation();

	const dataType = location.pathname === "/bases/cadastro" ? "base" : "unidade";

	return (
		<Card>
			<Card.Header>
				<div className="d-flex justify-content-between align-items-center w-100">
					<div className="w-100px" />

					<h2 className="m-0">Cadastrar {dataType}</h2>

					<button
						className="d-flex align-items-center btn button-bg-light-color-gray-400 gap-2 w-100px"
						onClick={() => navigate(-1)}
					>
						<ImArrowLeft2 />
						Voltar
					</button>
				</div>
			</Card.Header>

			<Card.Body>
				<RegistrationForm />
			</Card.Body>
		</Card>
	);
}
