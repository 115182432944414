import { FormikProps } from "formik";

import { attendanceInitialValues } from "@/utils/initialValues/attendance";

import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";

type Props = {
	formik: FormikProps<typeof attendanceInitialValues>;
};

export function OthersAttendances({ formik }: Props) {
	return (
		<div className="mt-4">
			<FormControlWithCharactersCount
				id="description"
				as="textarea"
				placeholder="Descreva"
				label="Descrição do chamado"
				labelClassName="fw-bold"
				maxLength={3000}
				{...formik.getFieldProps("description")}
			/>
		</div>
	);
}
