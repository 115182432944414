export enum ActionType {
	WithIntervention = 1,
	WithoutIntervention = 2,
	AwaitingForReturn = 3,
	AwaintingForVacancy = 4,
}

export const ActionTypeMessages = {
	[ActionType.WithIntervention]: "Com intervenção",
	[ActionType.WithoutIntervention]: "Sem intervenção",
	[ActionType.AwaitingForReturn]: "Aguardando retorno",
	[ActionType.AwaintingForVacancy]: "Aguardando vaga",
};
