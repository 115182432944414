import {
	CounterReferralReasonType,
	CounterReferralReasonTypeMessages,
} from "@/enums/CounterReferralReasonType";

export const counterReferralReasonTypesOptions = [
	{
		label: CounterReferralReasonTypeMessages[CounterReferralReasonType.OutOfProfile],
		value: CounterReferralReasonType.OutOfProfile,
	},
	{
		label: CounterReferralReasonTypeMessages[CounterReferralReasonType.BedUnavailable],
		value: CounterReferralReasonType.BedUnavailable,
	},
	{
		label: CounterReferralReasonTypeMessages[
			CounterReferralReasonType.UnavailabilityOfNecessaryResources
		],
		value: CounterReferralReasonType.UnavailabilityOfNecessaryResources,
	},
	{
		label: CounterReferralReasonTypeMessages[CounterReferralReasonType.TheOnCallDoctorsRefusal],
		value: CounterReferralReasonType.TheOnCallDoctorsRefusal,
	},
	{
		label: CounterReferralReasonTypeMessages[CounterReferralReasonType.Others],
		value: CounterReferralReasonType.Others,
	},
];
