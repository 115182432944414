import { Route, Routes, Navigate } from "react-router-dom";

import { AttendanceConsultationProvider } from "@/contexts/AttendanceConsultation";

import { ConfigurationRoutes } from "./TabRoutes/ConfigurationRoutes";
import { ProfessionalRoutes } from "./TabRoutes/ProfessionalRoutes";
import { FleetControlRoutes } from "./TabRoutes/FleetControlRoutes";
import { MasterLayout } from "@/../_metronic/layout/MasterLayout";
import { AttendanceRoutes } from "./TabRoutes/AttendanceRoutes";
import { DashboardRoutes } from "./TabRoutes/DashboardRoutes";
import { ViewProfile } from "@/components/Users/ViewProfile";
import { TrackingRoutes } from "./TabRoutes/TrackingRoutes";
import { ReportRoutes } from "./TabRoutes/ReportRoutes";
import { UserRoutes } from "./TabRoutes/UserRoutes";
import { BaseRoutes } from "./TabRoutes/BaseRoutes";
import { UnitRoutes } from "./TabRoutes/UnitRoutes";

export function PrivateRoutes() {
	return (
		<AttendanceConsultationProvider>
			<Routes>
				<Route element={<MasterLayout />}>
					<Route path="/login/*" element={<Navigate to="/dashboard" />} />
					<Route path="/ver-perfil" element={<ViewProfile />} />

					{/* aside menu */}
					<Route path="/dashboard/*" element={<DashboardRoutes />} />
					<Route path="/ocorrencias/*" element={<AttendanceRoutes />} />
					<Route path="/controle-de-frota/*" element={<FleetControlRoutes />} />
					<Route path="/rastreamento/*" element={<TrackingRoutes />} />
					<Route path="/usuarios/*" element={<UserRoutes />} />
					<Route path="/profissionais/*" element={<ProfessionalRoutes />} />
					<Route path="/bases/*" element={<BaseRoutes />} />
					<Route path="/unidades/*" element={<UnitRoutes />} />
					<Route path="/relatorios/*" element={<ReportRoutes />} />
					<Route path="/configuracoes/*" element={<ConfigurationRoutes />} />

					{/* default page */}
					<Route path="/" element={<Navigate to="/dashboard" />} />

					{/* Page Not Found */}
					<Route path="*" element={<Navigate to="/error/404" />} />
				</Route>
			</Routes>
		</AttendanceConsultationProvider>
	);
}
