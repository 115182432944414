import { FormikProps } from "formik";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { userInitialValues } from "@/utils/initialValues/user";
import { genderCodesOptions } from "@/utils/options/genderCodes";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { RedAsterisk } from "@/components/RedAsterisk";

type Props = {
	formik: FormikProps<typeof userInitialValues>;
};

export function PatientData({ formik }: Props) {
	const { values, errors } = formik;

	const selectedGender =
		genderCodesOptions.find((option) => option.value === values.gender_code) || null;

	return (
		<>
			<Row className="mb-4">
				<Col lg={4}>
					<FormattedFormControl
						id="name"
						label="Nome completo"
						labelClassName="fw-bold"
						isInvalid={!!errors.name}
						validationMessage={errors.name}
						{...formik.getFieldProps("name")}
						isRequired
					/>
				</Col>

				<Col lg={4}>
					<FormattedFormControl
						id="national-health-card"
						label="CNS"
						labelClassName="fw-bold"
						mask={"000 0000 0000 0000"}
						{...formik.getFieldProps("national_health_card")}
						isMaskedInput
					/>
				</Col>

				<Col lg={2}>
					<FormattedFormControl
						type="date"
						id="birthdate"
						label="Data de nascimento"
						labelClassName="fw-bold"
						isInvalid={!!errors.birthdate}
						validationMessage={errors.birthdate}
						{...formik.getFieldProps("birthdate")}
						isRequired
					/>
				</Col>

				<Col>
					<Form.Label className="fw-bold" htmlFor="gender-code">
						Sexo <RedAsterisk />
					</Form.Label>

					<ReactSelect
						inputId="gender-code"
						options={genderCodesOptions}
						isInvalid={!!errors.gender_code}
						value={selectedGender}
						placeholder="Selecione"
						onChange={(option) =>
							formik.setFieldValue("gender_code", (option as ReactSelectOption).value)
						}
					/>

					{errors.gender_code && (
						<span className="text-danger">{errors.gender_code}</span>
					)}
				</Col>
			</Row>

			<Row>
				<Col lg={4}>
					<FormattedFormControl
						id="email"
						label="Email"
						labelClassName="fw-bold"
						isInvalid={!!errors.email}
						validationMessage={errors.email}
						{...formik.getFieldProps("email")}
						isRequired
					/>
				</Col>

				<Col lg={2}>
					<FormattedFormControl
						id="identifier"
						label="CPF"
						labelClassName="fw-bold"
						placeholder="000.000.000-00"
						mask="000.000.000-00"
						isInvalid={!!errors.identifier}
						validationMessage={errors.identifier}
						{...formik.getFieldProps("identifier")}
						isMaskedInput
						isRequired
					/>
				</Col>

				<Col lg={2}>
					<FormattedFormControl
						id="phone"
						label="Telefone"
						labelClassName="fw-bold"
						placeholder="(00) 00000-0000"
						mask="(00) 00000-0000"
						isInvalid={!!errors.phone}
						validationMessage={errors.phone}
						{...formik.getFieldProps("phone")}
						isMaskedInput
						isRequired
					/>
				</Col>

				<Col lg={2}>
					<FormattedFormControl
						id="whatsapp"
						label="WhatsApp"
						labelClassName="fw-bold"
						placeholder="(00) 00000-0000"
						mask="(00) 00000-0000"
						{...formik.getFieldProps("whatsapp")}
						isMaskedInput
					/>
				</Col>
			</Row>
		</>
	);
}
