import { ButtonHTMLAttributes, ReactNode } from "react";

import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import { IoSearchSharp } from "react-icons/io5";

import { MaskedFormControl } from "../MaskedFormControl";

type Props = FormControlProps &
	ButtonHTMLAttributes<HTMLButtonElement> & {
		label?: string;
		labelClassName?: string;
		validationMessage?: string;
		isMaskedInput?: boolean;
		mask?: string | NumberConstructor;
		isLoading?: boolean;
		buttonLabel?: ReactNode;
	};

export function SearchInputGroupFormControl({
	id,
	placeholder,
	label,
	labelClassName,
	validationMessage,
	isMaskedInput,
	mask,
	onChange,
	isLoading = false,
	buttonLabel,
	onKeyUp,
	value,
	disabled,
	...rest
}: Props) {
	return (
		<Form.Group className="mb-4" controlId={id}>
			{label && <Form.Label className={labelClassName}>{label}</Form.Label>}

			<InputGroup>
				{isMaskedInput ? (
					<MaskedFormControl
						placeholder={placeholder}
						mask={mask}
						onChange={onChange}
						onKeyUp={onKeyUp}
						value={value}
						disabled={disabled}
					/>
				) : (
					<Form.Control
						placeholder={placeholder}
						onChange={onChange}
						onKeyUp={onKeyUp}
						value={value}
						disabled={disabled}
					/>
				)}

				<button
					type="button"
					className="d-flex align-items-center btn bg-dark-samu-with-hover text-white gap-2 h-38px"
					disabled={isLoading}
					{...rest}
				>
					{isLoading ? (
						<Spinner animation="border" size="sm" />
					) : buttonLabel ? (
						buttonLabel
					) : (
						<>
							<IoSearchSharp />
							Buscar
						</>
					)}
				</button>
			</InputGroup>

			{validationMessage && <span className="text-danger">{validationMessage}</span>}
		</Form.Group>
	);
}
