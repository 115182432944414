import { useEffect } from "react";
import { FormikProps } from "formik";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { AttendanceStatus } from "@/enums/AttendanceStatus";
import { attendanceStatusOptions } from "@/utils/options/attendanceStatus";
import { filterAttendanceInitialValues } from "@/utils/initialValues/filterAttendance";

import { ReactSelect } from "@/components/Selects/ReactSelect";

type Props = {
	showFilterAttendanceModal: boolean;
	handleCLoseFilterAttendanceModal: () => void;
	formik: FormikProps<typeof filterAttendanceInitialValues>;
};

export function AttendanceStatusFilterModal({
	showFilterAttendanceModal,
	handleCLoseFilterAttendanceModal,
	formik,
}: Props) {
	const { values, errors } = formik;

	const options = attendanceStatusOptions.filter(
		(option) =>
			option.value !== AttendanceStatus.Completed &&
			option.value !== AttendanceStatus.Canceled
	);

	const selectedAttendanceStatus = options.find(
		(option) => option.value === values.attendance_status_id
	);

	function handleChangeAttendanceStatus(option: ReactSelectOption) {
		formik.setFieldValue("attendanceStatusLabel", option.label);
		formik.setFieldValue("attendance_status_id", option.value);
	}

	function handleResetForm() {
		handleCLoseFilterAttendanceModal();

		formik.resetForm();
		formik.handleSubmit();
	}

	useEffect(() => {
		formik.validateForm();
	}, []);

	return (
		<Modal show={showFilterAttendanceModal} onHide={handleCLoseFilterAttendanceModal} centered>
			<Modal.Header closeButton>
				<span className="btn-close invisible" />
				<h2 className="m-0">Filtros</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<Form.Label className="fw-bold" htmlFor="attendance-status-filter">
						Selecione o status
					</Form.Label>

					<ReactSelect
						inputId="attendance-status-filter"
						options={options}
						isInvalid={!!errors.attendance_status_id}
						onChange={(option) =>
							handleChangeAttendanceStatus(option as ReactSelectOption)
						}
						value={selectedAttendanceStatus}
					/>

					{errors.attendance_status_id && (
						<span className="text-danger">{errors.attendance_status_id}</span>
					)}

					<div className="mt-8 d-flex align-items-center justify-content-center gap-2">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleResetForm}
						>
							Limpar
						</button>

						<button
							type="submit"
							className="d-flex align-items-center btn button-bg-samu-color-white gap-4"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							Filtrar
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
