import { KeyboardEvent } from "react";

import Modal from "react-bootstrap/Modal";

import { useSwal } from "@/hooks/useSwal";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";

type Props = {
	showInsertTokenModal: boolean;
	handleCloseInsertTokenModal: () => void;
	setAccessToken: (value: string) => void;
};

export function InsertTokenModal({
	showInsertTokenModal,
	handleCloseInsertTokenModal,
	setAccessToken,
}: Props) {
	const { Toast } = useSwal();

	function handleInsetAccessToken() {
		const token = (document.querySelector("#insert-access-token") as HTMLInputElement)?.value;

		if (!token) {
			Toast.fire({
				icon: "error",
				title: "Token de acesso não pode ser vazio!",
			});

			return;
		}

		localStorage.setItem("totemAccessToken", token);
		setAccessToken(token);

		handleCloseInsertTokenModal();
	}

	function handleKeyUp(event: KeyboardEvent<HTMLInputElement>) {
		if (event.key === "Enter") {
			handleInsetAccessToken();
		}
	}

	return (
		<Modal
			backdrop="static"
			show={showInsertTokenModal}
			onHide={handleCloseInsertTokenModal}
			centered
		>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Adicionar token</h2>
			</Modal.Header>

			<Modal.Body>
				<FormattedFormControl
					id="insert-access-token"
					label="Adicionar token de acesso"
					labelClassName="fw-bold"
					onKeyUp={(event) => handleKeyUp(event as KeyboardEvent<HTMLInputElement>)}
				/>

				<div className="text-center mt-8">
					<button
						className="btn button-bg-samu-color-white"
						onClick={handleInsetAccessToken}
					>
						Salvar
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
