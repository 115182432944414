/* eslint-disable eqeqeq */
import { FormCheckProps } from "react-bootstrap/FormCheck";
import Form from "react-bootstrap/Form";

import "./styles.scss";

export type Props = FormCheckProps & {
	label: string | JSX.Element;
	currentvalue?: string | number | boolean | undefined;
};

export function FormRadio({ label, currentvalue, value, inline, id, ...rest }: Props) {
	return (
		<Form.Check
			type="radio"
			className="d-flex align-items-center gap-2"
			id={id}
			label={label}
			value={value}
			inline={inline}
			checked={value == currentvalue}
			onChange={() => {}}
			{...rest}
		/>
	);
}
