import { useEffect } from "react";
import { useFormik } from "formik";

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { useSwal } from "@/hooks/useSwal";
import { cancelAttendanceSchema } from "@/utils/validation/cancelAttendanceSchema";

import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";
import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";

type Props = {
	showCancelAttendanceModal: boolean;
	handleCloseCancelAttendanceModal: () => void;
	fetchAttendances?: () => Promise<void>;
	selectedAttendance?: Attendance;
	selectedAttendanceConsultation?: AttendanceConsultation;
};

export function CancelAttendanceModal({
	showCancelAttendanceModal,
	handleCloseCancelAttendanceModal,
	fetchAttendances,
	selectedAttendance,
	selectedAttendanceConsultation,
}: Props) {
	const { currentAuth } = useAuth();
	const { Toast } = useSwal();
	const { api } = useApi();

	const attendance = selectedAttendance || selectedAttendanceConsultation;

	const formik = useFormik({
		initialValues: {
			reason: "",
			requester: "",
		},
		validationSchema: cancelAttendanceSchema,
		async onSubmit(values, { setSubmitting, resetForm, validateForm }) {
			try {
				await api.post("/attendance/cancellation", {
					...values,
					attendance_id: attendance?.id,
				});

				handleCloseCancelAttendanceModal();

				if (fetchAttendances) {
					await fetchAttendances();
				}

				Toast.fire({
					icon: "success",
					title: "Ocorrência cancelada com sucesso!",
				});

				resetForm();

				setTimeout(() => {
					validateForm();
				});
			} catch (error: any) {
				console.log(error);

				Toast.fire({
					icon: "error",
					title: error.response.data.message,
				});

				setSubmitting(false);
			}
		},
	});

	const { errors } = formik;

	useEffect(() => {
		if (!showCancelAttendanceModal) {
			formik.resetForm();
		}
	}, [showCancelAttendanceModal]);

	useEffect(() => {
		formik.validateForm();
	}, []);

	return (
		<Modal
			show={showCancelAttendanceModal}
			onHide={handleCloseCancelAttendanceModal}
			size="lg"
			centered
		>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Cancelar ocorrência</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-4">
					<Row className="fw-bold">
						<Col lg={2}>
							N°:{" "}
							{selectedAttendance?.number ||
								selectedAttendanceConsultation?.attendance_number}
						</Col>

						<Col>Paciente: {attendance?.patient?.name || "-"}</Col>
					</Row>

					<FormattedFormControl
						label="Médico regulador"
						labelClassName="fw-bold"
						value={currentAuth?.name}
						disabled
					/>

					<FormControlWithCharactersCount
						id="requester"
						label="Solicitante"
						labelClassName="fw-bold"
						maxLength={100}
						isInvalid={!!errors.requester}
						validationMessage={errors.requester}
						{...formik.getFieldProps("requester")}
						isRequired
					/>

					<FormControlWithCharactersCount
						id="observations"
						as="textarea"
						label="Motivo do cancelamento"
						labelClassName="fw-bold"
						placeholder="Descreva"
						maxLength={500}
						isInvalid={!!errors.reason}
						validationMessage={errors.reason}
						{...formik.getFieldProps("reason")}
						isRequired
					/>

					<div className="d-flex justify-content-center gap-2 mt-4">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseCancelAttendanceModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : (
								"Registrar"
							)}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
