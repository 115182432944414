import { textStyles } from "@/utils/configs/excelOptions";

type Props = {
	advancedSupportUnits: OutpatientProductionBulletinVehicle[];
	isExcel?: boolean;
};

export function AdvancedSupportUnitRows({ advancedSupportUnits, isExcel = false }: Props) {
	return (
		<>
			{advancedSupportUnits?.map((advancedSupportUnit, index) => (
				<tr key={`advanced-support-unit-${index}`} style={isExcel ? textStyles : {}}>
					<td>{index + 1}</td>
					<td>{advancedSupportUnit.name}</td>
					<td>{advancedSupportUnit.national_health_registration}</td>
					<td className="text-center">
						{advancedSupportUnit.primary_attendance_count || "0"}
					</td>
					<td className="text-center">
						{advancedSupportUnit.secondary_attendance_count || "0"}
					</td>
					<td className="text-center">{advancedSupportUnit.total_attendance_count}</td>
				</tr>
			))}
		</>
	);
}
