import Skeleton from "react-loading-skeleton";

type TableBodySkeletonProps = {
	rows?: number;
	columns?: number;
};

export function TableBodySkeleton({ rows = 10, columns = 5 }: TableBodySkeletonProps) {
	return (
		<>
			{Array.from({ length: rows }, (_, index) => (
				<tr key={`tr-${index}`}>
					{Array.from({ length: columns }, (_, index) => (
						<td key={`td-${index}`}>
							<Skeleton height={25} />
						</td>
					))}
				</tr>
			))}
		</>
	);
}
