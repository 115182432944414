import { useEffect, useState } from "react";
import dayjs from "dayjs";

import InfiniteScroll from "react-infinite-scroll-component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { LuCalendarDays } from "react-icons/lu";
import { FaEye, FaPlus } from "react-icons/fa";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { MdEdit } from "react-icons/md";

import { Role } from "@/enums/Role";
import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { useCanAccess } from "@/hooks/useCanAccess";
import { PeriodTypeMessages } from "@/enums/PeriodType";
import { DutyReportTypeOptions } from "@/utils/options/dutyReportTypeOptions";
import { DutyReportType, DutyReportTypeMessages } from "@/enums/DutyReportType";

import { DutyReportFormModal } from "@/components/Reports/DutyReport/Modals/DutyReportFormModal";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { DutyReportViewModal } from "@/components/Reports/DutyReport/Modals/DutyReportViewModal";

type KeyOfPeriodTypeMessages = keyof typeof PeriodTypeMessages;
type KeyOfDutyReportTypeMessages = keyof typeof DutyReportTypeMessages;

export function DutyReport() {
	const [dutyReports, setDutyReports] = useState<PaginatedDutyReport>({} as PaginatedDutyReport);
	const [currentDutyReport, setCurrentDutyReport] = useState<DutyReport>({} as DutyReport);
	const [showDutyReportViewModal, setShowDutyReportViewModal] = useState<boolean>(false);
	const [showDutyReportFormModal, setShowDutyReportFormModal] = useState(false);
	const [dutyReportTypeFilter, setDutyReportTypeFilter] = useState<number | null>();
	const [dutyReportTypeToModal, setDutyReportTypeToModal] = useState<DutyReportType>();
	const [startDate, setStartDate] = useState<Date>(
		new Date(new Date().setDate(new Date().getDate() - 1))
	);
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [skeleton, setSkeleton] = useState(true);

	const { currentAuth } = useAuth();
	const { can } = useCanAccess();
	const { api } = useApi();

	const { results } = dutyReports;

	const dataLength = results?.length;
	const hasMore = Boolean(dataLength) && dataLength !== dutyReports?.meta?.total;

	const { admin, radioOperator, teamLeader } = Role;

	function handleShowDutyReportFormModal(dutyReportType: DutyReportType) {
		setShowDutyReportFormModal(true);
		setDutyReportTypeToModal(dutyReportType);
	}

	function handleCloseDutyReportFormModal() {
		setShowDutyReportFormModal(false);
		setCurrentDutyReport({} as DutyReport);
	}

	function handleShowDutyReportViewModal(dutyReport: DutyReport) {
		setCurrentDutyReport(dutyReport);
		setShowDutyReportViewModal(true);
	}

	function handleCloseDutyReportViewModal() {
		setShowDutyReportViewModal(false);
		setCurrentDutyReport({} as DutyReport);
	}

	function handleEditDutyReport(dutyReport: DutyReport) {
		setCurrentDutyReport(dutyReport);
		handleShowDutyReportFormModal(dutyReport.duty_report_type_id);
	}

	async function fetchDutyReports(increasePage = false) {
		if (!increasePage) {
			setSkeleton(true);
		}

		try {
			const page = dutyReports.meta?.current_page || 1;

			const { data } = await api.get("/report/duty", {
				params: {
					duty_report_type_id: dutyReportTypeFilter,
					start_date: dayjs(startDate).format("YYYY-MM-DD"),
					end_date: dayjs(endDate).format("YYYY-MM-DD"),
					page: increasePage ? page + 1 : 1,
				},
			});

			if (!increasePage) {
				return setDutyReports(data);
			}

			return setDutyReports({
				...data,
				results: [...dutyReports.results, ...data.results],
			});
		} catch (error) {
			console.log(error);

			return {} as PaginatedDutyReport;
		} finally {
			setSkeleton(false);
		}
	}

	useEffect(() => {
		if ((dutyReportTypeFilter || (startDate && endDate)) && currentAuth) {
			fetchDutyReports();
		}
	}, [dutyReportTypeFilter, startDate, endDate, currentAuth]);

	return (
		<>
			<div className="d-flex gap-4 mb-6">
				{can([admin, teamLeader]) && (
					<button
						className="d-flex align-items-center btn button-bg-white-color-samu gap-4"
						onClick={() => {
							setCurrentDutyReport({} as DutyReport);
							handleShowDutyReportFormModal(DutyReportType.teamLeader);
						}}
					>
						<FaPlus /> Chefe de equipe
					</button>
				)}

				{can([admin, radioOperator]) && (
					<button
						className="d-flex align-items-center btn button-bg-white-color-samu gap-4"
						onClick={() => {
							setCurrentDutyReport({} as DutyReport);
							handleShowDutyReportFormModal(DutyReportType.fleetManager);
						}}
					>
						<FaPlus /> Gerente de frota
					</button>
				)}
			</div>

			<Card>
				<Card.Header className="d-flex align-items-center">
					<h3 className="fw-normal m-0">Relatório de plantão</h3>

					{can([admin]) && (
						<div className="d-flex gap-4 justify-content-end">
							<ReactSelect
								name="duty_report_type"
								placeholder="Selecione um tipo"
								options={DutyReportTypeOptions}
								isClearable={true}
								onChange={(option) =>
									setDutyReportTypeFilter(
										Number((option as ReactSelectOption)?.value) || null
									)
								}
							/>

							<DatePicker
								placeholderText="Selecione um período"
								showIcon
								selectsRange
								dateFormat="dd/MM/yyyy"
								className="form-control ps-10"
								calendarIconClassname="h-100 position-absolute py-0 px-4"
								showPopperArrow={false}
								selected={startDate}
								startDate={startDate}
								endDate={endDate}
								icon={<LuCalendarDays />}
								onChange={(dates) => {
									setStartDate(dates[0] as Date);
									setEndDate(dates[1] as Date);
								}}
							/>
						</div>
					)}
				</Card.Header>

				<Card.Body id="duty-reports" className="overflow-y-scroll max-h-610px pt-0">
					<InfiniteScroll
						style={{ overflow: "hidden" }}
						dataLength={dataLength || 0}
						hasMore={hasMore}
						next={() => fetchDutyReports(true)}
						scrollableTarget="duty-reports"
						loader={
							<div className="d-flex flex-center">
								<Spinner animation="border" variant="samu" />
							</div>
						}
					>
						<Table className="table-row-dashed table-row-gray-300">
							<thead>
								<tr className="fw-bold fs-6">
									<th>Data</th>
									<th>Turno</th>
									<th>Profissional</th>
									<th>Tipo</th>
									{can([admin, radioOperator]) && <th>CRU</th>}
									<th>Ações</th>
								</tr>
							</thead>

							<tbody>
								{skeleton ? (
									<TableBodySkeleton columns={6} rows={10} />
								) : (
									results?.map((dutyReport, index) => {
										const dutyReportTypeMessage =
											DutyReportTypeMessages[
												dutyReport.duty_report_type_id as KeyOfDutyReportTypeMessages
											];

										const periodTypeMessage =
											PeriodTypeMessages[
												dutyReport.period_type_id as KeyOfPeriodTypeMessages
											];

										return (
											<tr key={`duty-report-${index}`}>
												<td className="align-middle">
													{dayjs(dutyReport.record_at).format(
														"DD/MM/YYYY"
													)}
												</td>
												<td className="align-middle">
													{periodTypeMessage}
												</td>
												<td className="align-middle">
													{dutyReport.creator?.name}
												</td>
												<td className="align-middle">
													{dutyReportTypeMessage}
												</td>
												{can([admin, radioOperator]) && (
													<td className="align-middle">
														{dutyReport.urgency_regulation_center?.name}
													</td>
												)}
												<td className="align-middle w-1px">
													<div className="d-flex gap-2">
														{can([admin]) && (
															<OverlayTrigger
																overlay={
																	<Tooltip>Visualizar</Tooltip>
																}
															>
																<Button
																	variant="secondary"
																	className="btn-icon btn-sm"
																	onClick={() =>
																		handleShowDutyReportViewModal(
																			dutyReport
																		)
																	}
																>
																	<FaEye className="text-gray-700" />
																</Button>
															</OverlayTrigger>
														)}

														{can([teamLeader, radioOperator]) && (
															<OverlayTrigger
																overlay={<Tooltip>Editar</Tooltip>}
															>
																<Button
																	variant="secondary"
																	className="btn-icon btn-sm"
																	onClick={() =>
																		handleEditDutyReport(
																			dutyReport
																		)
																	}
																>
																	<MdEdit className="text-gray-700" />
																</Button>
															</OverlayTrigger>
														)}
													</div>
												</td>
											</tr>
										);
									})
								)}

								{!results?.length && !skeleton && (
									<tr>
										<td colSpan={6} className="text-center">
											<h2 className="mt-12 mb-0">
												Não há relatórios de plantão cadastrados
											</h2>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</InfiniteScroll>
				</Card.Body>
			</Card>

			<DutyReportViewModal
				dutyReport={currentDutyReport}
				showDutyReportViewModal={showDutyReportViewModal}
				handleCloseDutyReportViewModal={handleCloseDutyReportViewModal}
			/>

			<DutyReportFormModal
				dutyReport={currentDutyReport}
				showDutyReportFormModal={showDutyReportFormModal}
				handleCloseDutyReportFormModal={handleCloseDutyReportFormModal}
				dutyReportType={dutyReportTypeToModal ?? null}
				fetchDutyReports={fetchDutyReports}
			/>
		</>
	);
}
