import { useEffect } from "react";

import dayjs from "dayjs";
import classNames from "clsx";
import { FormikProps } from "formik";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { FaPlus } from "react-icons/fa6";
import { LuCalendarDays } from "react-icons/lu";
import DatePicker from "react-datepicker";

import { ScheduleType } from "@/enums/ScheduleType";
import { daysOfWeek } from "@/utils/options/daysOfWeek";
import { userInitialValues } from "@/utils/initialValues/user";
import { scheduleInitialValues } from "@/utils/initialValues/schedule";

import { FormRadio } from "@/components/Radios/FormRadio";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { WorkplacesSelect } from "@/components/Selects/WorkplacesSelect";

type ScheduleInitialValues = typeof scheduleInitialValues;
type UserInitialValues = typeof userInitialValues;

type Props = {
	isForm?: boolean;
	formik: FormikProps<ScheduleInitialValues> | FormikProps<UserInitialValues>;
	handleOpenNewScheduleTab?: () => void;
	schedulesLength?: number;
};

export function SchedulesRegistration({
	isForm,
	formik,
	handleOpenNewScheduleTab,
	schedulesLength,
}: Props) {
	const { values, errors } = formik;

	const currentDate = dayjs().format("YYYY-MM-DD");

	const validFrom = values.valid_from ? values.valid_from.toDate() : null;
	const validThrough = values.valid_through ? values.valid_through.toDate() : null;

	const periodErrors = errors.valid_from || errors.valid_through;
	const periodEnded = currentDate > dayjs(values.valid_through).format("YYYY-MM-DD");

	function handleChangeDates(dates: [Date | null, Date | null]) {
		const firstDate = dates[0] as Date;
		const secondDate = dates[1] as Date;

		if (dayjs(firstDate).isValid()) {
			formik.setFieldValue("valid_from", dayjs(firstDate));
		} else {
			formik.setFieldValue("valid_from", null);
		}

		if (dayjs(secondDate).isValid()) {
			formik.setFieldValue("valid_through", dayjs(secondDate));
		} else {
			formik.setFieldValue("valid_through", null);
		}
	}

	useEffect(() => {
		formik.validateForm();
	}, [values.schedulable_id]);

	return (
		<>
			<Row className="mb-4">
				<Col lg={isForm ? 5 : 8}>
					<Form.Label className="fw-bold">Local de trabalho</Form.Label>

					<WorkplacesSelect
						placeholder="Selecione um local de trabalho"
						value={{ value: values.schedulable_id, label: values.schedulable_label }}
						onChange={(option) => {
							formik.setFieldValue(
								"schedulable_id",
								(option as ReactSelectOption).value
							);
							formik.setFieldValue(
								"schedulable_label",
								(option as ReactSelectOption).label
							);
						}}
						isInvalid={!!errors.schedulable_id}
						isDisabled={periodEnded}
					/>

					{errors.schedulable_id && (
						<span className="text-danger">{errors.schedulable_id}</span>
					)}
				</Col>

				<Col lg={isForm ? 2 : 4}>
					<Form.Label className="fw-bold">Período</Form.Label>

					<DatePicker
						showIcon
						selectsRange
						dateFormat="dd/MM/yyyy"
						selected={validFrom}
						startDate={validFrom}
						endDate={validThrough}
						showPopperArrow={false}
						className={classNames(
							Boolean(periodErrors) && "border border-danger border-1",
							"form-control pe-10"
						)}
						calendarIconClassname="h-100 position-absolute py-0 px-4 end-0 fw-bold"
						icon={<LuCalendarDays />}
						onChange={handleChangeDates}
						disabled={periodEnded}
						minDate={dayjs().toDate()}
					/>

					{Boolean(periodErrors) && <span className="text-danger">{periodErrors}</span>}
				</Col>
			</Row>

			<Col>
				<FormGroupRadio
					label="Tipo de escala"
					labelClassName="fw-bold"
					className="mb-4"
					requestedValue={values.schedule_type_id}
					isInvalid={!!errors.schedule_type_id}
					{...formik.getFieldProps("schedule_type_id")}
					inline
					disabled={periodEnded}
				>
					<FormRadio id="default" label="Padrão" value={String(ScheduleType.Default)} />
				</FormGroupRadio>

				{errors.schedule_type_id && (
					<span className="text-danger">{errors.schedule_type_id}</span>
				)}
			</Col>

			<Col className="mb-4">
				<Form.Label className="fw-bold">Dias da semana</Form.Label>

				<Row>
					{daysOfWeek.map((day, index) => (
						<Col
							lg="auto"
							className="d-flex text-center gap-2"
							key={`checkbox-day-of-week-${index}`}
						>
							<input
								type="checkbox"
								id={`checkbox-day-of-week-${index}`}
								value={day.value}
								name="days_of_week"
								onChange={formik.handleChange}
								checked={values.days_of_week.some(
									(value) => Number(value) === day.value
								)}
								disabled={periodEnded}
							/>

							<Form.Label
								className="fw-bold"
								htmlFor={`checkbox-day-of-week-${index}`}
							>
								{day.label}
							</Form.Label>
						</Col>
					))}
				</Row>

				{errors.days_of_week && <span className="text-danger">{errors.days_of_week}</span>}
			</Col>

			<Form.Label className="fw-bold" htmlFor="start-time">
				Horário
			</Form.Label>

			<Row className="mb-5">
				<Col lg={isForm ? 2 : 3}>
					<FormattedFormControl
						type="time"
						id="start-time"
						isInvalid={!!errors.clock_in}
						validationMessage={errors.clock_in}
						disabled={periodEnded}
						{...formik.getFieldProps("clock_in")}
					/>
				</Col>

				<Col lg={isForm ? 2 : 3}>
					<FormattedFormControl
						type="time"
						id="end-time"
						isInvalid={!!errors.clock_out}
						validationMessage={errors.clock_out}
						disabled={periodEnded}
						{...formik.getFieldProps("clock_out")}
					/>
				</Col>
			</Row>

			{!isForm && Number(schedulesLength) >= 1 && (
				<Button
					className="d-flex align-items-center gap-4 btn-sm button-bg-light-color-gray-400"
					onClick={handleOpenNewScheduleTab}
				>
					<FaPlus /> Nova escala
				</Button>
			)}
		</>
	);
}
