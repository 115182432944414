import { useEffect, useState } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useFormik } from "formik";
import dayjs from "dayjs";

import { ImArrowLeft2 } from "react-icons/im";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useSwal } from "@/hooks/useSwal";
import { useApi } from "@/hooks/useApi";
import { formatPhone } from "@/utils/formatPhone";
import { onlyNumbers } from "@/utils/onlyNumbers";
import { formatIdentifier } from "@/utils/formatIdentifier";
import { removeCountryCode } from "@/utils/removeCountryCode";
import { userInitialValues } from "@/utils/initialValues/user";
import { userRegistrationSchema } from "@/utils/validation/userRegistrationSchema";

import { PatientData } from "@/components/Users/Components/PatientData";
import { Credentials } from "@/components/Users/Components/Credentials";
import { SchedulesRegistration } from "@/components/SchedulesRegistration";
import { Address } from "@/components/Users/Components/Address";
import { CpfSearch } from "@/components/FormControl/CpfSearch";
import { Separator } from "@/components/Separator";

type RegistrationFormProps = {
	isProfessionalRegistration?: boolean;
};

export function RegistrationForm({ isProfessionalRegistration }: RegistrationFormProps) {
	dayjs.extend(customParseFormat);

	const urgencyRegulationCenterAndRolesInitialData = {
		id: "",
		name: "",
		user_roles: [],
	};

	const [urgencyRegulationCenterAndRoles, setUrgencyRegulationCenterAndRoles] = useState<
		UrgencyRegulationCenterAndRoles[]
	>([urgencyRegulationCenterAndRolesInitialData]);
	const [identifierSearch, setIdentifierSearch] = useState("");

	const { Toast, toastRequestErrors } = useSwal();
	const { api } = useApi();

	const isFieldsInvalid = urgencyRegulationCenterAndRoles.some(({ id, user_roles }) => {
		return !id || !user_roles.length;
	});

	const formik = useFormik({
		initialValues: userInitialValues,
		validationSchema: userRegistrationSchema,
		async onSubmit(values, { setSubmitting }) {
			const url = isProfessionalRegistration ? "/professionals" : "/users";
			let saveUserId = "";

			try {
				const { data } = await api.post(url, {
					...values,
					identifier: onlyNumbers(values.identifier),
					phone: onlyNumbers(values.phone),
					whatsapp: onlyNumbers(values.whatsapp),
					national_health_card: onlyNumbers(values.national_health_card),
				});

				const user = data.original as User;

				saveUserId = user.id;

				if (isProfessionalRegistration && values.schedulable_id) {
					const scheduleData = {
						schedulable_id: values.schedulable_id,
						valid_from: values.valid_from,
						valid_through: values.valid_through,
						clock_in: values.clock_in,
						clock_out: values.clock_out,
					};

					const hasLeastOneScheduleValue = Object.values(scheduleData).some(
						(value) => value
					);

					if (hasLeastOneScheduleValue || values.days_of_week.length) {
						await api.post("/schedules-schema", {
							...scheduleData,
							user_id: saveUserId,
							schedule_type_id: values.schedule_type_id,
							days_of_week: values.days_of_week,
						});
					}
				}

				Toast.fire({
					icon: "success",
					title: "Cadastro realizado com sucesso!",
				});

				formik.resetForm();
			} catch (error: any) {
				console.log(error);

				toastRequestErrors(error.response.data?.errors);

				setSubmitting(false);
			}
		},
		async onReset() {
			setUrgencyRegulationCenterAndRoles([
				{
					id: "",
					name: "",
					user_roles: [],
				},
			]);
			setIdentifierSearch("");
		},
	});

	const { values } = formik;

	function handleResetForm() {
		formik.resetForm();

		setTimeout(() => {
			formik.validateForm();
		});
	}

	function handleUpdateFormWithCadsus(data: CadsusResponse) {
		const formattedPhone = onlyNumbers(removeCountryCode(data.phone));

		formik.setValues({
			...userInitialValues,
			name: data.fullName || values.name,
			identifier: formatIdentifier(data.cpf) || values.identifier,
			national_health_card: data.cns || values.national_health_card,
			gender_code: data.genderCode || values.gender_code,
			phone: formatPhone(formattedPhone) || values.phone,
			email: data.email || values.email,
			street: data.street || values.street,
			house_number: data.houseNumber || values.house_number,
			neighborhood: data.neighborhood || values.neighborhood,
			city_id: data.cityId || values.city_id,
			city_label: data.cityName || values.city_label,
			federal_unit: data.stateId || values.federal_unit,
			federal_unit_label: data.stateUf || values.federal_unit_label,
			birthdate: dayjs(data.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD") || values.birthdate,
		});
	}

	useEffect(() => {
		formik.validateForm();
	}, [values.federal_unit, values.city_id]);

	return (
		<Card>
			<Card.Header>
				<div className="d-flex justify-content-between align-items-center w-100">
					<div className="w-100px" />

					<h2 className="m-0">
						Cadastrar {isProfessionalRegistration ? "profissional" : "usuário"}
					</h2>

					<Link
						className="d-flex align-items-center btn button-bg-light-color-gray-400 gap-2 w-100px"
						to={isProfessionalRegistration ? "/profissionais" : "/usuarios"}
					>
						<ImArrowLeft2 />
						Voltar
					</Link>
				</div>
			</Card.Header>

			<Card.Body>
				<Form onSubmit={formik.handleSubmit}>
					{/* PatientData */}

					<Row>
						<Col lg={4}>
							<CpfSearch
								search={identifierSearch}
								setSearch={setIdentifierSearch}
								handleFetchedData={(data) => handleUpdateFormWithCadsus(data)}
							/>
						</Col>
					</Row>

					<PatientData formik={formik} />

					<Separator />

					{/* Address */}
					<h3 className="mb-4">Endereço</h3>
					<Address formik={formik} />

					<Separator />

					{/* Credentials */}
					<h3 className="mb-4">Credenciais</h3>
					<Credentials
						isRegister
						formik={formik}
						urgencyRegulationCenterAndRoles={urgencyRegulationCenterAndRoles}
						setUrgencyRegulationCenterAndRoles={setUrgencyRegulationCenterAndRoles}
						isProfessionalRegistration={isProfessionalRegistration}
					/>

					{isProfessionalRegistration && (
						<>
							<Separator />

							<h3 className="mb-4">Escala</h3>

							<SchedulesRegistration isForm formik={formik} />
						</>
					)}

					<div className="d-flex justify-content-center gap-4 mt-16">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleResetForm}
						>
							Limpar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid || isFieldsInvalid}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : (
								"Registrar"
							)}
						</button>
					</div>
				</Form>
			</Card.Body>
		</Card>
	);
}
