import { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import ptBR from "date-fns/locale/pt-BR";
import { useFormik } from "formik";
import dayjs from "dayjs";

import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import DatePicker, { registerLocale } from "react-datepicker";
import { RiFileExcel2Fill } from "react-icons/ri";
import { LuCalendarDays } from "react-icons/lu";
import { BiSolidFilePdf } from "react-icons/bi";

import { useApi } from "@/hooks/useApi";
import { usePusher } from "@/hooks/usePusher";
import { outpatientProductionBulletinSchema } from "@/utils/validation/outpatientProductionBulletinSchema";
import { outpatientProductionBulletinInitialValues } from "@/utils/initialValues/outpatientProductionBulletin";

import { MotorcycleAmbulanceRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/MotorcycleAmbulanceRows";
import { AdvancedSupportUnitRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/AdvancedSupportUnitRows";
import { BasicSupportUnitRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/BasicSupportUnitRows";
import { OutpatientProductionBulletinPrint } from "@/components/Prints/OutpatientProductionBulletinPrint";
import { AeromedicalRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/AeromedicalRows";
import { ProceduresRows } from "@/components/Tables/OutpatientProductionBulletin/Rows/ProceduresRows";
import { UrgencyRegulationCentersSelect } from "@/components/Selects/UrgencyRegulationCentersSelect";
import { ExportTable } from "@/components/Reports/OutpatientProductionBulletin/ExportTable";
import { NoRecordsFoundRow } from "@/components/Tables/NoRecordsFoundRow";

type OutpatientProductionBulletinValues = {
	startDate: string;
	endDate: string;
	urcId: string;
	urcLabel: string;
};

registerLocale("ptBR", ptBR);

export function OutpatientProductionBulletin() {
	const [outpatientProductionBulletin, setOutpatientProductionBulletin] =
		useState<OutpatientProductionBulletin>({} as OutpatientProductionBulletin);
	const [currentUrgencyRegulationCenterName, setCurrentUrgencyRegulationCenterName] =
		useState("");
	const [isPrintLoading, setIsPrintLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const outpatientProductionBulletinPrintRef = useRef<HTMLTableElement>(null);
	const outpatientProductionBulletinTableRef = useRef<HTMLTableElement>(null);

	const pusher = usePusher();
	const { api } = useApi();

	const print = useReactToPrint({
		content: () => outpatientProductionBulletinPrintRef.current,
		onAfterPrint: () => setIsPrintLoading(false),
	});

	const { onDownload } = useDownloadExcel({
		filename: "bpa",
		sheet: "Outpatient Production Bulletin",
		currentTableRef: outpatientProductionBulletinTableRef.current,
	});

	const formattedCurrentUrgencyRegulationCenterName = currentUrgencyRegulationCenterName
		?.slice(4)
		.toUpperCase();

	const eventName = "BPAGenerated";

	const outpatientProductionBulletinData = outpatientProductionBulletin?.data;

	const totalTickets = outpatientProductionBulletinData?.total_tickets || 0;
	const totalTicketsWithMedicalOrientation =
		outpatientProductionBulletinData?.total_tickets_with_medical_orientation || 0;
	const totalAttendancesWithBasicSupportUnits =
		outpatientProductionBulletinData?.total_attendances_with_basic_support_units || 0;
	const totalAttendancesWithAdvancedSupportUnits =
		outpatientProductionBulletinData?.total_attendances_with_advanced_support_units || 0;
	const totalTicketsWithMultipleAttendances =
		outpatientProductionBulletinData?.total_tickets_with_multiple_attendances || 0;

	const vehicleTypes = outpatientProductionBulletinData?.total_attendances_per_base_and_type;
	const advancedSupportUnits = vehicleTypes?.advanced_support_unit?.data;
	const basicSupportUnits = vehicleTypes?.basic_support_unit?.data;
	const motorcycleAmbulances = vehicleTypes?.motorcycle_ambulance?.data;
	const aeromedicals = vehicleTypes?.aeromedical?.data;

	function handlePrint() {
		setIsPrintLoading(true);
		print();
	}

	function changeCurrentUrgencyRegulationCenterName() {
		setCurrentUrgencyRegulationCenterName(selectedUrcOption.label);
	}

	function listenOutpatientProductionBulletinGeneratedEvent() {
		const startDate = dayjs(values.start_date).format("YYYYMMDD");
		const endDate = dayjs(values.end_date).format("YYYYMMDD");

		const key = `${values.urc_id}${startDate}${endDate}`;

		const channelName = `private-bpa-reports.${key}`;

		pusher.unsubscribe(channelName);
		pusher.unbind(eventName);

		const channel = pusher.subscribe(channelName);

		channel.bind(eventName, () => getOutpatientProductionBulletinData());
	}

	async function generateOutpatientProductionBulletin(
		values: typeof outpatientProductionBulletinInitialValues
	) {
		try {
			listenOutpatientProductionBulletinGeneratedEvent();

			const outpatientProductionBulletinValues = {
				startDate: values.start_date.toDateString(),
				endDate: values.end_date.toDateString(),
				urcId: values.urc_id,
				urcLabel: values.urc_label,
			};

			localStorage.setItem(
				"outpatientProductionBulletinValues",
				JSON.stringify(outpatientProductionBulletinValues)
			);

			setIsLoading(true);

			const { data: bulletin } = await api.post<OutpatientProductionBulletin>(
				"/report/bpa",
				values
			);

			if (bulletin.data) {
				setOutpatientProductionBulletin(bulletin);
				changeCurrentUrgencyRegulationCenterName();

				localStorage.removeItem("outpatientProductionBulletinValues");
				setIsLoading(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	const formik = useFormik({
		initialValues: outpatientProductionBulletinInitialValues,
		validationSchema: outpatientProductionBulletinSchema,
		async onSubmit(values) {
			await generateOutpatientProductionBulletin(values);
		},
	});

	const { values, errors } = formik;

	const periodFieldIsNull = !values.start_date || !values.end_date;
	const differencePeriodDays = dayjs(values.end_date).diff(values.start_date, "day");
	const differencePeriodDaysIsInvalid = differencePeriodDays > 31;
	const periodFieldIsInvalid = periodFieldIsNull || differencePeriodDaysIsInvalid;

	const selectedUrcOption = {
		value: values.urc_id,
		label: values.urc_label,
	};

	function handleChangeUrc(option: ReactSelectOption) {
		formik.setFieldValue("urc_id", option.value);
		formik.setFieldValue("urc_label", option.label);
	}

	function getOutpatientProductionBulletinData() {
		const outpatientProductionBulletinFieldValues = localStorage.getItem(
			"outpatientProductionBulletinValues"
		) as string;

		const transformedOutpatientProductionBulletinValues = JSON.parse(
			outpatientProductionBulletinFieldValues
		) as OutpatientProductionBulletinValues;

		if (!transformedOutpatientProductionBulletinValues) {
			return;
		}

		formik.setFieldValue(
			"start_date",
			new Date(transformedOutpatientProductionBulletinValues?.startDate)
		);
		formik.setFieldValue(
			"end_date",
			new Date(transformedOutpatientProductionBulletinValues?.endDate)
		);
		formik.setFieldValue("urc_id", transformedOutpatientProductionBulletinValues?.urcId);
		formik.setFieldValue("urc_label", transformedOutpatientProductionBulletinValues?.urcLabel);

		const outpatientProductionBulletinValues = {
			start_date: new Date(transformedOutpatientProductionBulletinValues.startDate),
			end_date: new Date(transformedOutpatientProductionBulletinValues.endDate),
			urc_id: transformedOutpatientProductionBulletinValues.urcId,
			urc_label: transformedOutpatientProductionBulletinValues.urcLabel,
		};

		generateOutpatientProductionBulletin(outpatientProductionBulletinValues);
	}

	useEffect(() => {
		formik.validateForm();
	}, [values.urc_id]);

	useEffect(() => {
		getOutpatientProductionBulletinData();
	}, []);

	return (
		<>
			<Card>
				<Card.Header className="d-flex align-items-center">
					<h3 className="fw-normal m-0">Relatório de produção</h3>
				</Card.Header>

				<Card.Body>
					<Form onSubmit={formik.handleSubmit}>
						<Row>
							<Col lg={3} className="d-flex flex-column">
								<Form.Label className="fw-bold" htmlFor="period">
									Data do relatório
								</Form.Label>

								<DatePicker
									id="period"
									locale="ptBR"
									showIcon
									selectsRange
									dateFormat="dd/MM/yyyy"
									className="form-control ps-10"
									calendarIconClassname="h-100 position-absolute py-0 px-4"
									showPopperArrow={false}
									icon={<LuCalendarDays />}
									startDate={values.start_date}
									endDate={values.end_date}
									onChange={(date) => {
										formik.setFieldValue("start_date", date[0]);
										formik.setFieldValue("end_date", date[1]);
									}}
								/>

								{periodFieldIsNull && (
									<span className="text-danger">Campo obrigatório</span>
								)}

								{!periodFieldIsNull && differencePeriodDaysIsInvalid && (
									<span className="text-danger">
										A diferença entre as datas deve ser de no máximo 31 dias
									</span>
								)}
							</Col>

							<Col lg={5}>
								<Form.Label className="fw-bold" htmlFor="urc">
									CRU
								</Form.Label>

								<UrgencyRegulationCentersSelect
									inputId="urc"
									placeholder="Selecione uma CRU"
									urgencyRegulationCenterId={values.urc_id}
									value={selectedUrcOption}
									onChange={(option) =>
										handleChangeUrc(option as ReactSelectOption)
									}
								/>

								{!!errors.urc_id && (
									<span className="text-danger">{errors.urc_id}</span>
								)}
							</Col>
						</Row>

						<div className="mt-10 d-flex justify-content-center">
							<button
								type="submit"
								className="btn button-bg-samu-color-white"
								disabled={!formik.isValid || isLoading || periodFieldIsInvalid}
							>
								{isLoading ? (
									<div className="d-flex align-items-center gap-2">
										<Spinner
											animation="border"
											style={{ height: "1.5rem", width: "1.5rem" }}
										/>
										Por favor, aguarde...
									</div>
								) : (
									"Gerar"
								)}
							</button>
						</div>
					</Form>
				</Card.Body>
			</Card>

			{outpatientProductionBulletinData && (
				<>
					<div className="my-8 d-flex gap-4">
						<button
							className="d-flex align-items-center btn button-bg-samu-color-white gap-4"
							onClick={handlePrint}
							disabled={isPrintLoading}
						>
							{isPrintLoading ? (
								<div className="d-flex align-items-center gap-2">
									<Spinner
										animation="border"
										style={{ height: "1.5rem", width: "1.5rem" }}
									/>
									Por favor, aguarde...
								</div>
							) : (
								<>
									<BiSolidFilePdf className="fs-2" /> Gerar PDF
								</>
							)}
						</button>

						<button
							className="d-flex align-items-center btn button-bg-orange-color-white gap-4"
							onClick={onDownload}
						>
							<RiFileExcel2Fill className="fs-2" /> Exportar excel
						</button>
					</div>

					<Card>
						<Card.Header className="d-flex justify-content-between align-items-center">
							<h3 className="fw-normal text-uppercase m-0">
								{currentUrgencyRegulationCenterName}
							</h3>
						</Card.Header>

						<Card.Body className="pt-0">
							<Table className="table-row-dashed table-row-gray-300">
								<thead>
									<tr className="fs-6 fw-bold">
										<th>Procedimento</th>
										<th>Código</th>
										<th className="text-center">Total</th>
									</tr>
								</thead>

								<tbody>
									<ProceduresRows
										totalTickets={totalTickets}
										totalTicketsWithMedicalOrientation={
											totalTicketsWithMedicalOrientation
										}
										totalAttendancesWithBasicSupportUnits={
											totalAttendancesWithBasicSupportUnits
										}
										totalAttendancesWithAdvancedSupportUnits={
											totalAttendancesWithAdvancedSupportUnits
										}
										totalTicketsWithMultipleAttendances={
											totalTicketsWithMultipleAttendances
										}
									/>
								</tbody>
							</Table>
						</Card.Body>
					</Card>

					<Card className="my-8">
						<Card.Header className="d-flex justify-content-between align-items-center">
							<h3 className="fw-normal m-0">UNIDADE DE SUPORTE AVANÇADO - USA</h3>
						</Card.Header>

						<Card.Body className="pt-0">
							<Table className="table-row-dashed table-row-gray-300">
								<thead>
									<tr className="fs-6 fw-bold">
										<th>N°</th>
										<th>Base</th>
										<th>CNES</th>
										<th className="text-center">Primário</th>
										<th className="text-center">Secundário</th>
										<th className="text-center">Total</th>
									</tr>
								</thead>

								<tbody>
									<AdvancedSupportUnitRows
										advancedSupportUnits={advancedSupportUnits}
									/>

									{!advancedSupportUnits?.length && (
										<NoRecordsFoundRow colSpanColumn={6} isFormatted />
									)}
								</tbody>
							</Table>
						</Card.Body>
					</Card>

					<Card className="my-8">
						<Card.Header className="d-flex justify-content-between align-items-center">
							<h3 className="fw-normal m-0">UNIDADES DE SUPORTE BÁSICO - USB</h3>
						</Card.Header>

						<Card.Body className="pt-0">
							<Table className="table-row-dashed table-row-gray-300">
								<thead>
									<tr className="fs-6 fw-bold">
										<th>N°</th>
										<th>Base</th>
										<th>CNES</th>
										<th className="text-center">Primário</th>
										<th className="text-center">Secundário</th>
										<th className="text-center">Total</th>
									</tr>
								</thead>

								<tbody>
									<BasicSupportUnitRows basicSupportUnits={basicSupportUnits} />

									{!basicSupportUnits?.length && (
										<NoRecordsFoundRow colSpanColumn={6} isFormatted />
									)}
								</tbody>
							</Table>
						</Card.Body>
					</Card>

					<Card className="my-8">
						<Card.Header className="d-flex justify-content-between align-items-center">
							<h3 className="fw-normal m-0">
								UNIDADES DE SUPORTE BÁSICO - MOTOLÂNCIAS
							</h3>
						</Card.Header>

						<Card.Body className="pt-0">
							<Table className="table-row-dashed table-row-gray-300">
								<thead>
									<tr className="fs-6 fw-bold">
										<th>N°</th>
										<th>Base</th>
										<th>CNES</th>
										<th>Código</th>
										<th className="text-center">Total</th>
									</tr>
								</thead>

								<tbody>
									<MotorcycleAmbulanceRows
										motorcycleAmbulances={motorcycleAmbulances}
									/>

									{!motorcycleAmbulances?.length && (
										<NoRecordsFoundRow colSpanColumn={5} isFormatted />
									)}
								</tbody>
							</Table>
						</Card.Body>
					</Card>

					<Card className="my-8">
						<Card.Header className="d-flex justify-content-between align-items-center">
							<h3 className="fw-normal m-0">
								UNIDADES DE SUPORTE AVANÇADO - AEROMÉDICO
							</h3>
						</Card.Header>

						<Card.Body className="pt-0">
							<Table className="table-row-dashed table-row-gray-300">
								<thead>
									<tr className="fs-6 fw-bold">
										<th>N°</th>
										<th>Base</th>
										<th>CNES</th>
										<th>Código</th>
										<th className="text-center">Primário</th>
										<th className="text-center">Secundário</th>
										<th className="text-center">Total</th>
									</tr>
								</thead>

								<tbody>
									<AeromedicalRows aeromedicals={aeromedicals} />

									{!aeromedicals?.length && (
										<NoRecordsFoundRow colSpanColumn={7} isFormatted />
									)}
								</tbody>
							</Table>
						</Card.Body>
					</Card>

					<div className="d-none">
						<OutpatientProductionBulletinPrint
							ref={outpatientProductionBulletinPrintRef}
							urgencyRegulationCenterName={
								formattedCurrentUrgencyRegulationCenterName
							}
							startDate={values.start_date}
							endDate={values.end_date}
							totalTickets={totalTickets}
							totalTicketsWithMedicalOrientation={totalTicketsWithMedicalOrientation}
							totalAttendancesWithAdvancedSupportUnits={
								totalAttendancesWithAdvancedSupportUnits
							}
							totalAttendancesWithBasicSupportUnits={
								totalAttendancesWithBasicSupportUnits
							}
							totalTicketsWithMultipleAttendances={
								totalTicketsWithMultipleAttendances
							}
							vehicleTypes={vehicleTypes}
						/>

						<ExportTable
							ref={outpatientProductionBulletinTableRef}
							urgencyRegulationCenterName={
								formattedCurrentUrgencyRegulationCenterName
							}
							outpatientProductionBulletin={outpatientProductionBulletin}
							startDate={values.start_date}
							endDate={values.end_date}
							totalTickets={totalTickets}
							totalTicketsWithMedicalOrientation={totalTicketsWithMedicalOrientation}
							totalAttendancesWithAdvancedSupportUnits={
								totalAttendancesWithAdvancedSupportUnits
							}
							totalAttendancesWithBasicSupportUnits={
								totalAttendancesWithBasicSupportUnits
							}
							totalTicketsWithMultipleAttendances={
								totalTicketsWithMultipleAttendances
							}
							vehicleTypes={vehicleTypes}
						/>
					</div>
				</>
			)}
		</>
	);
}
