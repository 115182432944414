import { useEffect, useState } from "react";

import { useApi } from "@/hooks/useApi";

import { ReactSelect, ReactSelectProps } from "@/components/Selects/ReactSelect";

export function FederalUnitsSelect({ ...rest }: ReactSelectProps) {
	const [federalUnitOptions, setFederalUnitOptions] = useState<ReactSelectOption[]>([]);

	const { api } = useApi();

	async function fetchFederalUnits() {
		try {
			const { data } = await api.get("/federal-units");

			const options = data?.map((federalUnit: FederalUnit) => ({
				value: federalUnit.id,
				label: federalUnit.uf,
			}));

			setFederalUnitOptions(options);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		fetchFederalUnits();
	}, []);

	return <ReactSelect options={federalUnitOptions} {...rest} />;
}
