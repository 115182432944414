import { useEffect, useRef, useState } from "react";
import classNames from "clsx";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import { FaEye, FaStethoscope } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

import { Role } from "@/enums/Role";
import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { useSwal } from "@/hooks/useSwal";
import { useDebounce } from "@/hooks/useDebounce";
import { useCanAccess } from "@/hooks/useCanAccess";
import { useWindowDimensions } from "@/hooks/useWindowDimensions";
import { vehicleStatusOptions } from "@/utils/options/vehicleStatus";
import {
	VehicleStatus,
	VehicleStatusMessages,
	unmodifiableVehicleStatuses,
} from "@/enums/VehicleStatus";

import { VehicleRegistrationModal } from "@/components/FleetControl/Vehicles/Modals/VehicleRegistrationModal";
import { VehicleViewingModal } from "@/components/FleetControl/Vehicles/Modals/VehicleViewingModal";
import { LinkEquipmentModal } from "@/components/FleetControl/Vehicles/Modals/LinkEquipmentModal";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { PaginationLinks } from "@/components/PaginationLinks";
import { ReactSelect } from "@/components/Selects/ReactSelect";

export function Vehicles() {
	const [showVehicleRegistrationModal, setShowVehicleRegistrationModal] = useState(false);
	const [vehicles, setVehicles] = useState<PaginatedVehicles>({} as PaginatedVehicles);
	const [showVehicleViewingModal, setShowVehicleViewingModal] = useState(false);
	const [showEquipmentModal, setShowEquipmentModal] = useState(false);
	const [vehicle, setVehicle] = useState<Vehicle>({} as Vehicle);
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const [skeleton, setSkeleton] = useState(true);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);

	const { Swal, Toast, toastRequestErrors } = useSwal();
	const debouncedSearch = useDebounce(search, 500);
	const { width } = useWindowDimensions();
	const { currentAuth } = useAuth();
	const { can } = useCanAccess();
	const { api } = useApi();

	const isFirstRender = useRef(true);
	const forcePage = useRef(1);

	const { results } = vehicles;

	// VehicleRegistrationModal
	const handleShowVehicleRegistrationModal = (vehicle?: Vehicle) => {
		setShowVehicleRegistrationModal(true);

		if (vehicle) {
			setVehicle(vehicle);

			return;
		}

		setVehicle({} as Vehicle);
	};
	const handleCloseVehicleRegistrationModal = () => {
		setShowVehicleRegistrationModal(false);
		setVehicle({} as Vehicle);
	};

	// VehicleViewingModal
	const handleShowVehicleViewingModal = (vehicle: Vehicle) => {
		setVehicle(vehicle);
		setShowVehicleViewingModal(true);
	};
	const handleCloseVehicleViewingModal = () => setShowVehicleViewingModal(false);

	// EquipmentModal
	const handleShowEquipmentModal = (vehicle: Vehicle) => {
		setVehicle(vehicle);
		setShowEquipmentModal(true);
	};
	const handleCloseEquipmentModal = () => setShowEquipmentModal(false);

	async function fetchVehicles(pageToUse?: number) {
		setSkeleton(true);

		try {
			const { data } = await api.get("/vehicles", {
				params: {
					page: pageToUse || page,
					search: debouncedSearch,
					show_all: true,
				},
			});

			setVehicles(data);
			setSkeleton(false);
		} catch (error) {
			console.error(error);
			setSkeleton(false);
		}
	}

	async function handleChangeVehicleStatus(vehicleStatusId: VehicleStatus, vehicle: Vehicle) {
		const isUnavailable = vehicleStatusId === VehicleStatus.Unavailable;
		const isInactive = vehicleStatusId === VehicleStatus.Inactive;

		let description;
		let base_id;

		if (isInactive) {
			base_id = null;
		}

		if (isUnavailable) {
			const result = await Swal.fire({
				title: "Insira o motivo:",
				input: "textarea",
				inputAttributes: {
					maxlength: "1000",
					placeholder: "Insira o motivo com no maximo 1000 caracteres.",
				},
				showCancelButton: true,
			});

			if (result.isDismissed) {
				return;
			}

			if (!result.value) {
				Toast.fire({
					icon: "error",
					title: "Insira o motivo",
				});

				return;
			}

			description = result.value;
		}

		try {
			await api.post(`/vehicles/${vehicle.id}/status`, {
				vehicle_status_id: vehicleStatusId,
				base_id,
				description,
			});

			Toast.fire({
				icon: "success",
				title: "Status alterado com sucesso",
			});

			fetchVehicles(forcePage.current);
		} catch (error: any) {
			if (error.isAxiosError && error.response.status === 422) {
				return toastRequestErrors(error.response.data.errors);
			}

			Toast.fire({
				icon: "error",
				title: "Erro ao alterar status",
			});
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchVehicles(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	useEffect(() => {
		forcePage.current = page;
		fetchVehicles();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		forcePage.current = 1;
		fetchVehicles(1);
	}, [debouncedSearch]);

	return (
		<>
			{can([Role.admin, Role.fleetControl]) && (
				<button
					className="d-flex align-items-center btn button-bg-white-color-samu mb-6 gap-4"
					onClick={() => handleShowVehicleRegistrationModal()}
				>
					<FaPlus /> Cadastrar viatura
				</button>
			)}

			<Card>
				<Card.Header className="d-flex justify-content-between align-items-center">
					<h3 className="fw-normal m-0">Viaturas cadastradas</h3>

					<SearchFormControl
						placeholder="Pesquisar viatura"
						onChange={(event) => setSearch(event.target.value)}
					/>
				</Card.Header>

				<Card.Body className="pt-0">
					<div
						className={classNames(
							!menuIsOpen && "table-responsive",
							menuIsOpen && width <= 637 && "pb-5"
						)}
					>
						<Table className="table-row-dashed table-row-gray-300 mb-0">
							<thead>
								<tr className="fw-bold fs-6">
									<th>Tipo</th>
									<th>Código</th>
									<th>Placa</th>
									<th>Base</th>
									<th>Status</th>
									<th>Ações</th>
								</tr>
							</thead>

							<tbody>
								{skeleton ? (
									<TableBodySkeleton columns={6} />
								) : (
									<>
										{results?.map((vehicle, index) => {
											const latestVehicleStatusHistory =
												vehicle.latest_vehicle_status_history;

											const vehicleStatusId =
												latestVehicleStatusHistory?.vehicle_status_id;

											const selectedVehicleStatusOption =
												vehicleStatusOptions.find(
													(option) => option.value === vehicleStatusId
												);

											const selectedOptionWithDescription = {
												...selectedVehicleStatusOption,
												label: `${selectedVehicleStatusOption?.label} - ${latestVehicleStatusHistory?.description}`,
											};

											const vehicleType = vehicle?.vehicle_type?.name;

											function verifyVehicleStatus() {
												if (
													unmodifiableVehicleStatuses.includes(
														vehicleStatusId
													)
												) {
													return VehicleStatusMessages[
														vehicleStatusId as VehicleStatus
													];
												}

												return (
													<ReactSelect
														options={vehicleStatusOptions}
														value={
															vehicleStatusId ===
															VehicleStatus.Unavailable
																? selectedOptionWithDescription
																: selectedVehicleStatusOption
														}
														onMenuOpen={() => setMenuIsOpen(true)}
														onMenuClose={() => setMenuIsOpen(false)}
														onChange={(option) =>
															handleChangeVehicleStatus(
																(option as ReactSelectOption)
																	.value as VehicleStatus,
																vehicle
															)
														}
													/>
												);
											}

											const vehicleStatus = verifyVehicleStatus();

											return (
												<tr key={`vehicle-${index}`}>
													<td className="align-middle">{vehicleType}</td>

													<td className="align-middle">
														{vehicle?.code}
													</td>

													<td className="align-middle">
														{vehicle?.license_plate}
													</td>

													<td className="align-middle">
														{vehicle.base?.name ?? "Sem base"}
													</td>

													<td className="align-middle min-width-10rem">
														{vehicleStatus}
													</td>

													<td className="align-middle w-1px">
														<div className="d-flex gap-2">
															<OverlayTrigger
																overlay={
																	<Tooltip>Visualizar</Tooltip>
																}
															>
																<Button
																	variant="secondary"
																	className="btn-icon btn-sm"
																	onClick={() =>
																		handleShowVehicleViewingModal(
																			vehicle
																		)
																	}
																>
																	<FaEye className="text-gray-700" />
																</Button>
															</OverlayTrigger>

															{currentAuth?.current_role.name !==
																Role.fleetControl && (
																<OverlayTrigger
																	overlay={
																		<Tooltip>Editar</Tooltip>
																	}
																>
																	<Button
																		variant="secondary"
																		className="btn-icon btn-sm"
																		onClick={() =>
																			handleShowVehicleRegistrationModal(
																				vehicle
																			)
																		}
																	>
																		<MdEdit className="text-gray-700" />
																	</Button>
																</OverlayTrigger>
															)}

															{can([]) && (
																<OverlayTrigger
																	overlay={
																		<Tooltip>
																			Equipamentos
																		</Tooltip>
																	}
																>
																	<Button
																		variant="secondary"
																		className="btn-icon btn-sm"
																		onClick={() =>
																			handleShowEquipmentModal(
																				vehicle
																			)
																		}
																	>
																		<FaStethoscope className="text-gray-700" />
																	</Button>
																</OverlayTrigger>
															)}
														</div>
													</td>
												</tr>
											);
										})}

										{!results?.length && (
											<tr>
												<td className="text-center" colSpan={12}>
													<h2 className="mt-12 mb-0">
														Não há viaturas cadastradas
													</h2>
												</td>
											</tr>
										)}
									</>
								)}
							</tbody>
						</Table>
					</div>

					{!!results?.length && (
						<div className="d-flex justify-content-end mt-8">
							<PaginationLinks
								activeLinkClassName="z-index-auto"
								itemsPerPage={10}
								totalItems={vehicles.meta?.total}
								forcePage={forcePage.current - 1}
								changeSelectedPage={handleChangeSelectedPage}
							/>
						</div>
					)}
				</Card.Body>
			</Card>

			<VehicleRegistrationModal
				showVehicleRegistrationModal={showVehicleRegistrationModal}
				handleCloseVehicleRegistrationModal={handleCloseVehicleRegistrationModal}
				vehicle={vehicle}
				fetchVehicles={fetchVehicles}
			/>

			<VehicleViewingModal
				showVehicleViewingModal={showVehicleViewingModal}
				handleCloseVehicleViewingModal={handleCloseVehicleViewingModal}
				vehicle={vehicle}
			/>

			<LinkEquipmentModal
				showEquipmentModal={showEquipmentModal}
				handleCloseEquipmentModal={handleCloseEquipmentModal}
				vehicle={vehicle}
			/>
		</>
	);
}
