import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Role } from "@/enums/Role";
import { useAuth } from "@/modules/auth";
import { routesPerRole } from "@/helpers/routesPerRole";

export function useCanAccess() {
	const { currentAuth } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	function canAccessRoute() {
		const currentRole = currentAuth?.current_role.name as keyof typeof routesPerRole;
		const mainRoutePerRole = routesPerRole[currentRole]?.[0];

		const { pathname, state } = location;

		function getFormattedPathname() {
			if (pathname.includes("/ocorrencias/resumo")) {
				return "/resumo";
			}

			return pathname;
		}

		const formattedPathname = getFormattedPathname();

		if (
			!routesPerRole[currentRole]?.includes(
				(state?.pathname || (state as string)) ?? formattedPathname
			) &&
			pathname !== "/error/404"
		) {
			navigate(mainRoutePerRole);
		}
	}

	function can(roles: string[]) {
		roles.push(Role.superAdmin);

		return roles.includes(currentAuth?.current_role?.name as string);
	}

	function roleIs(roles: string[]) {
		return roles.includes(currentAuth?.current_role?.name as string);
	}

	useEffect(() => {
		if (currentAuth) {
			canAccessRoute();
		}
	}, [location, currentAuth]);

	return { can, roleIs };
}
