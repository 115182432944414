import { RequesterType, RequesterTypeMessages } from "@/enums/RequesterType";

export const requesterTypeOptions = [
	{
		label: RequesterTypeMessages[RequesterType.Medical],
		value: RequesterType.Medical,
	},
	{
		label: RequesterTypeMessages[RequesterType.OtherProfessional],
		value: RequesterType.OtherProfessional,
	},
	{
		label: RequesterTypeMessages[RequesterType.Himself],
		value: RequesterType.Himself,
	},
	{
		label: RequesterTypeMessages[RequesterType.Family],
		value: RequesterType.Family,
	},
	{
		label: RequesterTypeMessages[RequesterType.Friend],
		value: RequesterType.Friend,
	},
	{
		label: RequesterTypeMessages[RequesterType.Passerby],
		value: RequesterType.Passerby,
	},
	{
		label: RequesterTypeMessages[RequesterType.HealthProfessional],
		value: RequesterType.HealthProfessional,
	},
	{
		label: RequesterTypeMessages[RequesterType.MunicipalGuard],
		value: RequesterType.MunicipalGuard,
	},
	{
		label: RequesterTypeMessages[RequesterType.Firefighters],
		value: RequesterType.Firefighters,
	},
	{
		label: RequesterTypeMessages[RequesterType.MilitaryPolice],
		value: RequesterType.MilitaryPolice,
	},
	{
		label: RequesterTypeMessages[RequesterType.StateHighwayPolice],
		value: RequesterType.StateHighwayPolice,
	},
	{
		label: RequesterTypeMessages[RequesterType.FederalHighwayPolice],
		value: RequesterType.FederalHighwayPolice,
	},
	{
		label: RequesterTypeMessages[RequesterType.MunicipalAuthority],
		value: RequesterType.MunicipalAuthority,
	},
];
