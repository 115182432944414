import { useRef } from "react";

import { useApi } from "@/hooks/useApi";

import { ReactSelect, ReactSelectProps, Additional } from "../ReactSelect";

type UsersSelectProps = ReactSelectProps & {
	occupations: string[];
};

export function UsersSelect({ occupations, ...rest }: UsersSelectProps) {
	const abortControllerRef = useRef(new AbortController());
	const { api } = useApi();

	async function fetchUsers(page: number, search: string) {
		try {
			abortControllerRef.current.abort();
			abortControllerRef.current = new AbortController();

			const { data } = await api.get("/radio-operation/fleet/able-professionals", {
				params: {
					page,
					occupations,
					search,
				},
				signal: abortControllerRef.current.signal,
			});

			return data as PaginatedUser;
		} catch (error) {
			console.log(error);
			return {} as PaginatedUser;
		}
	}

	const loadAsyncUsersOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, _: User, { page }: Additional) {
			const users = await fetchUsers(page, search);

			return {
				options: users?.results.map((user) => ({
					label: user.name,
					value: {
						userId: user.id,
						occupationId: user.cbo,
					},
				})),
				hasMore: users.meta?.current_page < users.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	return <ReactSelect {...loadAsyncUsersOptionsProps} {...rest} />;
}
