import { useFormik } from "formik";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useAuth } from "@/modules/auth";
import { useApi } from "@/hooks/useApi";

import { ReactSelect } from "@/components/Selects/ReactSelect";

type Props = {
	showChangeUrcModal: boolean;
	handleCloseChangeUrcModal: () => void;
};

export function ChangeUrcModal({ showChangeUrcModal, handleCloseChangeUrcModal }: Props) {
	const { currentAuth, setCurrentAuth } = useAuth();
	const { api } = useApi();

	const formik = useFormik({
		initialValues: {
			urc_id: currentAuth?.urc_id,
		},
		async onSubmit(values) {
			if (values.urc_id !== currentAuth?.urc_id) {
				try {
					const { data } = await api.put("change-urgency-regulation-center", values);

					setCurrentAuth(data);
					window.location.reload();
				} catch (error) {
					console.error(error);
				}
			}

			handleCloseChangeUrcModal();
		},
	});

	const urgencyRegulationCenters = currentAuth?.urgency_regulation_centers;

	const urcOptions = urgencyRegulationCenters?.map((urc) => ({
		value: urc.id,
		label: urc.name,
	}));

	const currentUrc = currentAuth?.current_urgency_regulation_center;

	const defaultOption = {
		label: currentUrc?.name,
		value: currentUrc?.id,
	};

	const updatedOptions = urcOptions?.map((option) => {
		if (option.value === currentAuth?.urc_id) {
			return { ...option, isDisabled: true };
		}

		return option;
	});

	return (
		<Modal show={showChangeUrcModal} onHide={handleCloseChangeUrcModal} centered>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Selecione uma CRU</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<ReactSelect
						options={updatedOptions}
						defaultValue={defaultOption}
						onChange={(option) =>
							formik.setFieldValue("urc_id", (option as ReactSelectOption).value)
						}
					/>

					<div className="d-flex justify-content-center gap-2 mt-8">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseChangeUrcModal}
						>
							Fechar
						</button>

						<button type="submit" className="btn button-bg-samu-color-white">
							Mudar
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
