import { WoundPlaceType, WoundPlaceTypeMessages } from "@/enums/WoundPlaceType";

export const woundPlaceTypesOptions: ReactSelectOption[] = [
	{ value: WoundPlaceType.Abdomen, label: WoundPlaceTypeMessages[WoundPlaceType.Abdomen] },
	{ value: WoundPlaceType.Forearm, label: WoundPlaceTypeMessages[WoundPlaceType.Forearm] },
	{
		value: WoundPlaceType.MouthTongue,
		label: WoundPlaceTypeMessages[WoundPlaceType.MouthTongue],
	},
	{ value: WoundPlaceType.Arm, label: WoundPlaceTypeMessages[WoundPlaceType.Arm] },
	{ value: WoundPlaceType.Head, label: WoundPlaceTypeMessages[WoundPlaceType.Head] },
	{ value: WoundPlaceType.Thigh, label: WoundPlaceTypeMessages[WoundPlaceType.Thigh] },
	{
		value: WoundPlaceType.HandFingers,
		label: WoundPlaceTypeMessages[WoundPlaceType.HandFingers],
	},
	{
		value: WoundPlaceType.FootFingers,
		label: WoundPlaceTypeMessages[WoundPlaceType.FootFingers],
	},
	{ value: WoundPlaceType.Back, label: WoundPlaceTypeMessages[WoundPlaceType.Back] },
	{ value: WoundPlaceType.Face, label: WoundPlaceTypeMessages[WoundPlaceType.Face] },
	{ value: WoundPlaceType.Knee, label: WoundPlaceTypeMessages[WoundPlaceType.Knee] },
	{ value: WoundPlaceType.Hand, label: WoundPlaceTypeMessages[WoundPlaceType.Hand] },
	{ value: WoundPlaceType.Eye, label: WoundPlaceTypeMessages[WoundPlaceType.Eye] },
	{ value: WoundPlaceType.Shoulder, label: WoundPlaceTypeMessages[WoundPlaceType.Shoulder] },
	{ value: WoundPlaceType.Foot, label: WoundPlaceTypeMessages[WoundPlaceType.Foot] },
	{
		value: WoundPlaceType.PelvisBasin,
		label: WoundPlaceTypeMessages[WoundPlaceType.PelvisBasin],
	},
	{ value: WoundPlaceType.Perineum, label: WoundPlaceTypeMessages[WoundPlaceType.Perineum] },
	{ value: WoundPlaceType.Leg, label: WoundPlaceTypeMessages[WoundPlaceType.Leg] },
	{ value: WoundPlaceType.Neck, label: WoundPlaceTypeMessages[WoundPlaceType.Neck] },
	{ value: WoundPlaceType.Chest, label: WoundPlaceTypeMessages[WoundPlaceType.Chest] },
];
