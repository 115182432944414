import { useEffect, useState } from "react";
import { useFormik } from "formik";

import Form from "react-bootstrap/Form";

import { useAuth } from "../core/Auth";
import { useApi } from "@/hooks/useApi";
import { RoleMessages } from "@/enums/Role";
import { selectURCOrRoleInitialValues } from "@/utils/initialValues/selectURCOrRole";

import { ReactSelect } from "@/components/Selects/ReactSelect";

type Props = {
	userData: UserData;
};

export function SelectURCOrRole({ userData }: Props) {
	const { urgencyRegulationCenters, roles } = userData;

	const urgencyRegulationCenterOptions = urgencyRegulationCenters?.map(
		(urgencyRegulationCenter) => ({
			value: urgencyRegulationCenter.id,
			label: urgencyRegulationCenter.name,
		})
	);

	const initialRoleOptions =
		roles &&
		roles.map((role) => ({
			value: role.id,
			label: RoleMessages[role.name as keyof typeof RoleMessages],
		}));

	const [roleOptions, setRoleOptions] = useState<ReactSelectOption[]>(initialRoleOptions);
	const [selectedRole, setSelectedRole] = useState<ReactSelectOption>({} as ReactSelectOption);
	const { saveAuth } = useAuth();
	const { api } = useApi();

	const formik = useFormik({
		initialValues: selectURCOrRoleInitialValues,
		async onSubmit(values, { setSubmitting }) {
			if (!values.urc_id) {
				values.urc_id = userData.urgencyRegulationCenters?.[0].id;
			}

			if (!values.role_id) {
				values.role_id = String(userData.roles?.[0].id);
			}

			values.identifier = userData.identifier;
			values.password = userData.password;

			try {
				const { data: token } = await api.post("/auth/login", {
					...values,
					latitude: userData.latitude,
					longitude: userData.longitude,
				});

				saveAuth(token);
			} catch (error) {
				console.error(error);

				setSubmitting(false);
			}
		},
	});

	const { values } = formik;

	async function handleRolesByUnit(urgencyRegulationCenterId: string) {
		const { data } = await api.get<Role[]>("/user/roles-by-unit", {
			params: {
				user_id: userData.user_id,
				urc_id: urgencyRegulationCenterId,
			},
		});

		const roleOptions = data?.map((role) => ({
			value: role.id,
			label: RoleMessages[role.name as keyof typeof RoleMessages],
		}));

		formik.setFieldValue("role_id", roleOptions?.[0].value);
		setRoleOptions(roleOptions);
	}

	useEffect(() => {
		if (userData.urgencyRegulationCenters?.length > 1) {
			handleRolesByUnit(values.urc_id || userData.urgencyRegulationCenters?.[0].id);
		}
	}, [values.urc_id]);

	useEffect(() => {
		const selectedRole = values.role_id
			? roleOptions.find((role) => role.value === values.role_id)
			: roleOptions?.[0];

		setSelectedRole(selectedRole || ({} as ReactSelectOption));
	}, [values.role_id, roleOptions]);

	return (
		<Form onSubmit={formik.handleSubmit}>
			<div className="text-center text-dark mt-4 mb-10">
				<h2>BEM VINDO (A)</h2>
				<h2>{userData.user_name}</h2>
			</div>

			{urgencyRegulationCenterOptions?.length > 1 && (
				<div className="fv-row mb-10">
					<Form.Label className="fw-bold" htmlFor="urc_id">
						SELECIONE UMA UNIDADE
					</Form.Label>

					<ReactSelect
						inputId="urc_id"
						defaultValue={urgencyRegulationCenterOptions[0]}
						options={urgencyRegulationCenterOptions}
						onChange={(option) =>
							formik.setFieldValue("urc_id", (option as ReactSelectOption).value)
						}
						isSolid
					/>
				</div>
			)}

			{roleOptions?.length > 1 && (
				<div className="fv-row mb-10">
					<Form.Label className="fw-bold" htmlFor="role_id">
						SELECIONE UM PERFIL
					</Form.Label>
					<ReactSelect
						inputId="role_id"
						value={selectedRole}
						options={roleOptions}
						onChange={(option) =>
							formik.setFieldValue("role_id", (option as ReactSelectOption).value)
						}
						isSolid
					/>
				</div>
			)}

			<div className="text-center">
				<button
					type="submit"
					className="btn text-white fs-3 w-75 bg-samu login-button mb-5 py-3"
					disabled={formik.isSubmitting || !formik.isValid}
				>
					{!formik.isSubmitting && (
						<span className="indicator-label text-white">ENTRAR</span>
					)}

					{formik.isSubmitting && (
						<span className="d-block indicator-progress">
							Por favor, aguarde...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
						</span>
					)}
				</button>
			</div>
		</Form>
	);
}
