import { FC, ReactNode } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import classNames from "clsx";

import { checkIsActive, WithChildren } from "../../../helpers";

import "./styles.scss";

type Props = {
	to: string;
	title: string;
	icon?: ReactNode;
	fontIcon?: string;
	hasBullet?: boolean;
};

const AsideMenuItem: FC<Props & WithChildren> = ({
	children,
	to,
	title,
	icon,
	fontIcon,
	hasBullet = false,
}) => {
	const { pathname } = useLocation();
	const isActive = checkIsActive(pathname, to);

	return (
		<div className="menu-item">
			<Link className={classNames("menu-link without-sub", { active: isActive })} to={to}>
				{hasBullet && (
					<span className="menu-bullet">
						<span className="bullet bullet-dot"></span>
					</span>
				)}

				{icon && <span className="menu-icon scale-1.25">{icon}</span>}

				{fontIcon && <i className={classNames("bi fs-3", fontIcon)}></i>}
				<span className="menu-title">{title}</span>
			</Link>
			{children}
		</div>
	);
};

export { AsideMenuItem };
