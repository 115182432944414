import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
// Axios
import { Chart, registerables } from "chart.js";
import { QueryClient, QueryClientProvider } from "react-query";
// Apps
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
import dayjs from "dayjs";
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "react-datepicker/dist/react-datepicker.css";
import "react-loading-skeleton/dist/skeleton.css";
import "nouislider/dist/nouislider.css";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.react.scss";
import "./app/global.scss";
import "./app/badges.scss";
import "./app/vehicleStatus.scss";
import { AppRoutes } from "./app/routes/AppRoutes";
import { AuthProvider } from "./app/modules/auth";
import "dayjs/locale/pt-br";
dayjs.locale("pt-br");

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
Chart.register(...registerables);

const queryClient = new QueryClient();
const container = document.getElementById("root");
if (container) {
	createRoot(container).render(
		<StrictMode>
			<QueryClientProvider client={queryClient}>
				<MetronicI18nProvider>
					<AuthProvider>
						<AppRoutes />
					</AuthProvider>
				</MetronicI18nProvider>
			</QueryClientProvider>
		</StrictMode>
	);
}
