export enum ClosingType {
	Death = 1,
	AddressNotFound = 2,
	Evading = 3,
	FalseOcurrence = 4,
	RefusalAttendance = 5,
	RefusalRemoval = 6,
	RemovedByThirdsParties = 7,
	RemovedByOtherVehicle = 8,
}

export const ClosingTypeMessages = {
	[ClosingType.Death]: "Óbito",
	[ClosingType.AddressNotFound]: "Endereço não localizado",
	[ClosingType.Evading]: "Evasão do local",
	[ClosingType.FalseOcurrence]: "Falsa ocorrência",
	[ClosingType.RefusalAttendance]: "Recusa atendimento",
	[ClosingType.RefusalRemoval]: "Recusa remoção",
	[ClosingType.RemovedByThirdsParties]: "Removido por terceiros",
	[ClosingType.RemovedByOtherVehicle]: "Remoção por outra VTR",
};
