import { useEffect, useState } from "react";

import { useFormik } from "formik";
import dayjs from "dayjs";

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { scheduleSchema } from "@/utils/validation/scheduleSchema";
import { scheduleInitialValues } from "@/utils/initialValues/schedule";

import { SchedulesRegistration } from "@/components/SchedulesRegistration";

type SchedulesModalProps = {
	showSchedulesModal: boolean;
	handleCloseSchedulesModal: () => void;
	user: User;
	fetchProfessionals?: () => void;
};

export function SchedulesModal({
	showSchedulesModal,
	handleCloseSchedulesModal,
	user,
	fetchProfessionals,
}: SchedulesModalProps) {
	const [scheduleId, setScheduleId] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [alreadyHasSchedule, setAlreadyHasSchedule] = useState(false);
	const [showNewScheduleTab, setShowNewScheduleTab] = useState(false);

	const { api } = useApi();
	const { Toast, toastRequestErrors } = useSwal();

	const schedulesLength = Object.entries(user.schedule_schemas || [])?.length;
	const hasMultipleSchedules = schedulesLength > 1;

	const showTabs = hasMultipleSchedules || (schedulesLength === 1 && showNewScheduleTab);

	const formik = useFormik({
		initialValues: scheduleInitialValues,
		validationSchema: scheduleSchema,
		async onSubmit(values) {
			try {
				const url = alreadyHasSchedule
					? `/schedules-schema/${scheduleId}`
					: "/schedules-schema";
				const method = alreadyHasSchedule ? "put" : "post";

				await api[method](url, values);

				Toast.fire({
					icon: "success",
					title: `Escala ${alreadyHasSchedule ? "editada" : "cadastrada"} com sucesso`,
				});

				fetchProfessionals && fetchProfessionals();
				setShowNewScheduleTab(false);

				handleCloseSchedulesModal();
			} catch (error: any) {
				if (error.isAxiosError && error.response.status === 422) {
					return toastRequestErrors(error.response.data.errors);
				}

				Toast.fire({
					icon: "error",
					title: "Erro ao cadastrar escala",
				});
			}
		},
	});

	const { values } = formik;

	const currentDate = dayjs().format("YYYY-MM-DD");

	const periodEnded = currentDate > dayjs(values.valid_through).format("YYYY-MM-DD");

	async function handleFetchSchedule() {
		try {
			setIsLoading(true);

			if (!scheduleId || scheduleId === "newTab") {
				setAlreadyHasSchedule(false);

				return;
			}

			const { data } = await api.get<ScheduleSchema>(`/schedules-schema/${scheduleId}`);

			const formattedData = {
				schedulable_id: data?.schedulable?.id,
				schedulable_label: data?.schedulable?.name,
				user_id: user?.id,
				valid_from: dayjs(data?.valid_from),
				valid_through: dayjs(data?.valid_through),
				schedule_type_id: data?.schedule_type_id,
				days_of_week: data?.days_of_week,
				clock_in: data?.clock_in,
				clock_out: data?.clock_out,
				created_at: data?.created_at,
			};

			formik.setValues(formattedData);

			setAlreadyHasSchedule(true);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}

	const SchedulesRegistrationSpinner = () => (
		<div className="d-flex align-items-center justify-content-center">
			<Spinner variant="samu" animation="border" />
		</div>
	);

	function handleResetForm() {
		formik.setValues({
			...scheduleInitialValues,
			user_id: values.user_id,
			schedule_type_id: values.schedule_type_id,
		});
	}

	const handleOpenNewScheduleTab = () => {
		setScheduleId("newTab");
		setShowNewScheduleTab(true);

		handleResetForm();
	};

	function handleCloseModal() {
		setShowNewScheduleTab(false);

		handleCloseSchedulesModal();
	}

	const schedulesRegistrationProps = {
		formik,
		handleOpenNewScheduleTab,
		schedulesLength,
	};

	const ScheduleRegistrationComponent = isLoading ? (
		<SchedulesRegistrationSpinner />
	) : (
		<SchedulesRegistration {...schedulesRegistrationProps} />
	);

	useEffect(() => {
		const firstScheduleId = user?.schedule_schemas?.at(0)?.id ?? "";

		setScheduleId(firstScheduleId);
		formik.setFieldValue("user_id", user?.id);
	}, [user]);

	useEffect(() => {
		handleFetchSchedule();
	}, [scheduleId]);

	useEffect(() => {
		if (!showSchedulesModal) {
			formik.resetForm();
		}
	}, [showSchedulesModal]);

	return (
		<Modal show={showSchedulesModal} onHide={handleCloseSchedulesModal} size="lg">
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Escala</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					{showTabs ? (
						<Tabs
							unmountOnExit
							activeKey={scheduleId}
							onSelect={(selectedKey) => {
								if (!selectedKey || selectedKey === "newTab") {
									handleResetForm();
								}

								setScheduleId(String(selectedKey));
							}}
							className="nav-line-tabs nav-line-tabs-2x fs-5 fw-bolder flex-nowrap w-fit-content mb-6"
						>
							{user?.schedule_schemas?.map((scheduleSchema, index) => (
								<Tab
									key={`schedule-${index}`}
									eventKey={scheduleSchema?.id}
									title={
										<span className="text-center px-10">
											Escala {index + 1}
										</span>
									}
								>
									{ScheduleRegistrationComponent}
								</Tab>
							))}

							{showNewScheduleTab && (
								<Tab
									eventKey="newTab"
									title={
										<span className="text-center px-10">
											Escala {schedulesLength + 1}
										</span>
									}
								>
									{ScheduleRegistrationComponent}
								</Tab>
							)}
						</Tabs>
					) : (
						ScheduleRegistrationComponent
					)}

					<div className="d-flex justify-content-center gap-3 mt-10">
						<button
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseModal}
							type="button"
						>
							Fechar
						</button>

						<button
							className="btn button-bg-samu-color-white"
							disabled={!formik.isValid || periodEnded}
							type="submit"
						>
							{alreadyHasSchedule ? "Editar" : "Salvar"}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
