import { useEffect, useRef, useState } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { FaPlus } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import { useApi } from "@/hooks/useApi";
import { useDebounce } from "@/hooks/useDebounce";
import { PatrimonyTypeMessages } from "@/enums/PatrimonyType";

import { PatrimonyModal } from "@/components/FleetControl/Patrimonies/PatrimonyModal";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";
import { PaginationLinks } from "@/components/PaginationLinks";
import { VehicleTypeMessages } from "@/enums/VehicleType";

type EnumPatrimonyTypeMessages = keyof typeof PatrimonyTypeMessages;
type EnumVehicleTypeMessages = keyof typeof VehicleTypeMessages;

export function Patrimonies() {
	const [patrimonies, setPatrimonies] = useState<PaginatedPatrimony>({} as PaginatedPatrimony);
	const [showPatrimonyModal, setShowPatrimonyModal] = useState(false);
	const [patrimony, setPatrimony] = useState<Patrimony>({} as Patrimony);
	const [skeleton, setSkeleton] = useState(true);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);

	const isFirstRender = useRef(true);
	const forcePageRef = useRef(1);
	const debouncedSearch = useDebounce(search, 500);
	const { api } = useApi();

	const { results } = patrimonies;

	const handleShowPatrimonyModal = (patrimony?: Patrimony) => {
		setShowPatrimonyModal(true);

		if (patrimony) {
			setPatrimony(patrimony);

			return;
		}

		setPatrimony({} as Patrimony);
	};
	const handleClosePatrimonyModal = () => setShowPatrimonyModal(false);

	async function fetchPatrimonies(pageToUse?: number) {
		setSkeleton(true);

		try {
			const { data } = await api.get("/patrimonies", {
				params: {
					page: pageToUse || page,
					search: debouncedSearch,
				},
			});

			setPatrimonies(data);
		} catch (error) {
			console.error(error);
		} finally {
			setSkeleton(false);
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePageRef.current) {
			fetchPatrimonies(selected);
			forcePageRef.current = selected;

			return;
		}

		setPage(selected);
	}

	useEffect(() => {
		forcePageRef.current = page;
		fetchPatrimonies();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		forcePageRef.current = 1;
		fetchPatrimonies(1);
	}, [debouncedSearch]);

	return (
		<>
			<div className="w-fit-content">
				<button
					className="d-flex align-items-center btn button-bg-white-color-samu mb-6 gap-4"
					onClick={() => handleShowPatrimonyModal()}
				>
					<FaPlus /> Cadastrar equipamento
				</button>
			</div>

			<Card className="mb-8">
				<Card.Header className="d-flex justify-content-between align-items-center">
					<h3 className="fw-normal m-0">Equipamentos cadastrados</h3>

					<SearchFormControl
						placeholder="Pesquisar equipamento"
						onChange={(event) => setSearch(event.target.value)}
					/>
				</Card.Header>

				<Card.Body className="pt-0">
					<Table responsive className="table-row-dashed table-row-gray-300">
						<thead>
							<tr className="fw-bold fs-6">
								<th>Tipo</th>
								<th>Identificador</th>
								<th>VTR vinculada</th>
								<th>Status</th>
								<th>Ações</th>
							</tr>
						</thead>

						<tbody>
							{skeleton ? (
								<TableBodySkeleton />
							) : (
								<>
									{results?.map((patrimony) => {
										const patrimonyTypeMessage =
											PatrimonyTypeMessages[
												patrimony.patrimony_type_id as EnumPatrimonyTypeMessages
											];

										const vehicleTypeId = Number(
											patrimony.vehicle?.vehicle_type?.id || 0
										);

										const vehicleTypeMessage =
											VehicleTypeMessages[
												vehicleTypeId as EnumVehicleTypeMessages
											] || "";

										const vehicleDescription = vehicleTypeId
											? `${vehicleTypeMessage} ${patrimony.vehicle?.description}`
											: "Sem viatura";

										return (
											<tr key={`patrimony-${patrimony.id}`}>
												<td className="align-middle">
													{patrimonyTypeMessage}
												</td>
												<td className="align-middle">
													{patrimony.identifier}
												</td>
												<td className="align-middle">
													{vehicleDescription}
												</td>
												<td className="align-middle">
													{patrimony.patrimony_status_id
														? "Disponível"
														: "Indisponível"}
												</td>
												<td className="align-middle w-1px">
													<OverlayTrigger
														overlay={<Tooltip>Editar</Tooltip>}
													>
														<Button
															variant="secondary"
															className="btn-icon btn-sm"
															onClick={() =>
																handleShowPatrimonyModal(patrimony)
															}
														>
															<MdEdit className="text-gray-700" />
														</Button>
													</OverlayTrigger>
												</td>
											</tr>
										);
									})}

									{!results?.length && (
										<tr>
											<td className="text-center" colSpan={12}>
												<h2 className="mt-12 mb-0">
													Não há equipamentos cadastrados
												</h2>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</Table>

					{!!results?.length && (
						<div className="d-flex justify-content-end mt-8">
							<PaginationLinks
								itemsPerPage={10}
								totalItems={patrimonies.meta?.total}
								forcePage={forcePageRef.current - 1}
								changeSelectedPage={handleChangeSelectedPage}
							/>
						</div>
					)}
				</Card.Body>
			</Card>

			<PatrimonyModal
				showPatrimonyModal={showPatrimonyModal}
				handleClosePatrimonyModal={handleClosePatrimonyModal}
				patrimony={patrimony}
				fetchPatrimonies={fetchPatrimonies}
			/>
		</>
	);
}
