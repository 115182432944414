export const supportingOrganizationsOptions = [
	{
		label: "Agente de trânsito",
		value: "Agente de trânsito",
	},
	{
		label: "Bombeiros",
		value: "Bombeiros",
	},
	{
		label: "CIOPAER",
		value: "CIOPAER",
	},
	{
		label: "Guarda municipal",
		value: "Guarda municipal",
	},
	{
		label: "Perícia forense",
		value: "Perícia forense",
	},
	{
		label: "PM",
		value: "PM",
	},
	{
		label: "PRE",
		value: "PRE",
	},
	{
		label: "PRF",
		value: "PRF",
	},
];
