export enum AttendanceStatus {
	AwaitingMedicalRegulation = 1,
	AwaitingReturn = 12,
	AwaitingVehicleCommitment = 2,
	VehicleSent = 3,
	AwaitingVacancy = 4,
	AwaitingConduct = 5,
	Conduct = 6,
	Completed = 7,
	Canceled = 8,
	InAttendanceMedicalRegulation = 9,
	InAttendanceSceneRecord = 10,
	InAttendanceRadioOperation = 11,
	Linked = 13,
}

export const AttendanceStatusMessages = {
	[AttendanceStatus.AwaitingMedicalRegulation]: "Ag. reg",
	[AttendanceStatus.AwaitingReturn]: "Ag. retorno",
	[AttendanceStatus.AwaitingVehicleCommitment]: "Ag. VTR",
	[AttendanceStatus.VehicleSent]: "VTR enviada",
	[AttendanceStatus.AwaitingVacancy]: "Ag. vaga",
	[AttendanceStatus.AwaitingConduct]: "Ag. conduta",
	[AttendanceStatus.Conduct]: "Conduta",
	[AttendanceStatus.Completed]: "Concluído",
	[AttendanceStatus.Canceled]: "Cancelado",
	[AttendanceStatus.InAttendanceMedicalRegulation]: "Em atendimento - regulação médica",
	[AttendanceStatus.InAttendanceSceneRecord]: "Em atendimento - registro de cena",
	[AttendanceStatus.InAttendanceRadioOperation]: "Em atendimento - rádio operação",
	[AttendanceStatus.Linked]: "Vinculado",
};
