import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useApi } from "@/hooks/useApi";

import { ReactSelect, ReactSelectProps, Additional } from "../ReactSelect";

type CitiesSelectProps = ReactSelectProps & {
	federalUnitId?: string | number | null;
};

export function CitiesSelect({ federalUnitId = null, ...rest }: CitiesSelectProps) {
	const [key, setKey] = useState(uuidv4());
	const { api } = useApi();

	async function fetchCities(page?: number, search?: string) {
		try {
			const { data } = await api.get("/cities", {
				params: {
					federal_unit_id: federalUnitId,
					page: page || 1,
					search,
				},
			});

			return data as PaginatedCity;
		} catch (error) {
			console.error(error);

			return {} as PaginatedCity;
		}
	}

	const loadAsyncCitiesOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, __: City, { page }: Additional) {
			const cities = await fetchCities(page, search);

			return {
				options:
					cities.results?.map((city) => ({
						value: city.id,
						label: city.name,
					})) || [],
				hasMore: cities.meta?.current_page < cities.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	useEffect(() => {
		setKey(uuidv4()); // remount component, running load options again
	}, [federalUnitId]);

	return <ReactSelect key={key} {...loadAsyncCitiesOptionsProps} {...rest} />;
}
