import { FC } from "react";
import { useLayout } from "../../core";
import { AsideMenu } from "./AsideMenu";

const AsideDefault: FC = () => {
	const { classes } = useLayout();

	return (
		<div
			id="kt_aside"
			className="aside"
			data-kt-drawer="true"
			data-kt-drawer-name="aside"
			data-kt-drawer-activate="{default: true, lg: false}"
			data-kt-drawer-overlay="true"
			data-kt-drawer-width="{default:'200px', '300px': '250px'}"
			data-kt-drawer-direction="start"
			data-kt-drawer-toggle="#kt_aside_mobile_toggle"
		>
			<AsideMenu asideMenuCSSClasses={classes.asideMenu} />
		</div>
	);
};

export { AsideDefault };
