import { Route, Routes } from "react-router-dom";

import { RadioOperationForm } from "@/components/FleetControl/RadioOperation/RadioOperationForm";
import { RadioOperation } from "@/pages/FleetControl/RadioOperation";
import { Patrimonies } from "@/pages/FleetControl/Patrimonies";
import { Vehicles } from "@/pages/FleetControl/Vehicles";

export function FleetControlRoutes() {
	return (
		<Routes>
			<Route path="viaturas" element={<Vehicles />} />
			<Route path="equipamentos" element={<Patrimonies />} />
			<Route path="ocorrencias" element={<RadioOperation />} />
			<Route path="radio-operador/:attendanceId" element={<RadioOperationForm />} />
		</Routes>
	);
}
