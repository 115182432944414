import { AttendanceStatus, AttendanceStatusMessages } from "@/enums/AttendanceStatus";

export const attendanceStatusOptions: ReactSelectOption[] = [
	{
		label: AttendanceStatusMessages[AttendanceStatus.AwaitingMedicalRegulation],
		value: AttendanceStatus.AwaitingMedicalRegulation,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.AwaitingReturn],
		value: AttendanceStatus.AwaitingReturn,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.AwaitingVehicleCommitment],
		value: AttendanceStatus.AwaitingVehicleCommitment,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.VehicleSent],
		value: AttendanceStatus.VehicleSent,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.AwaitingVacancy],
		value: AttendanceStatus.AwaitingVacancy,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.AwaitingConduct],
		value: AttendanceStatus.AwaitingConduct,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.Conduct],
		value: AttendanceStatus.Conduct,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.Completed],
		value: AttendanceStatus.Completed,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.Canceled],
		value: AttendanceStatus.Canceled,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.InAttendanceMedicalRegulation],
		value: AttendanceStatus.InAttendanceMedicalRegulation,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.InAttendanceSceneRecord],
		value: AttendanceStatus.InAttendanceSceneRecord,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.InAttendanceRadioOperation],
		value: AttendanceStatus.InAttendanceRadioOperation,
	},
	{
		label: AttendanceStatusMessages[AttendanceStatus.Linked],
		value: AttendanceStatus.Linked,
	},
];
