import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaPlus, FaTrash } from "react-icons/fa";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { AttendanceType } from "@/enums/AttendanceType";
import { timeUnitsOptions } from "@/utils/options/timeUnits";
import { genderCodesOptions } from "@/utils/options/genderCodes";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { RedAsterisk } from "@/components/RedAsterisk";

type Props = {
	initialVictimData: Victim;
	multipleVictims: boolean;
	victims: Victim[];
	setVictims: (victims: Victim[]) => void;
	ticketType: string;
};

type VictimWithoutId = Omit<Victim, "id" | "noIdentification">;

export function Victims({
	initialVictimData,
	multipleVictims,
	victims,
	setVictims,
	ticketType,
}: Props) {
	const verifyAttendanceType =
		ticketType === String(AttendanceType.PrimaryOccurrence) ||
		ticketType === String(AttendanceType.SecondaryOccurrence);

	function handleAddVictim() {
		const updatedVictims = [...victims];

		setVictims([...updatedVictims, initialVictimData]);
	}

	function handleUpdateVictim(index: number, field: string, value: string) {
		const updatedVictims = [...victims];

		updatedVictims[index][field as keyof VictimWithoutId] = value;

		setVictims(updatedVictims);
	}

	function handleUpdateVictimNoIdentification(index: number, value: boolean) {
		const updatedVictims = [...victims];

		updatedVictims[index].noIdentification = value;

		setVictims(updatedVictims);
	}

	function handleDeleteVictim(index: number) {
		const updatedVictims = [...victims];
		updatedVictims.splice(index, 1);

		setVictims(updatedVictims);
	}

	function isInvalid(victimAge: string, index: number) {
		const hasNotVictimAge = verifyAttendanceType && !victimAge;
		const isOlderThanPermittedAge =
			victims[index].timeUnit === "3" && Number(victims[index].age) > 140;

		if (hasNotVictimAge || isOlderThanPermittedAge) {
			return true;
		}
	}

	return (
		<>
			{victims.map((victim, index) => (
				<Row key={`victim-${index}`} className="gap-4 mb-10">
					<Col lg={9}>
						<FormattedFormControl
							id={`victim-name-${index}`}
							label="Nome do paciente"
							labelClassName="fw-bold"
							value={victim.name}
							isMaskedInput
							mask={/^[\p{L}\s]*$/u}
							disabled={victim.noIdentification}
							onChange={(event) =>
								handleUpdateVictim(index, "name", event.currentTarget.value)
							}
						/>
					</Col>

					<Col className="d-flex flex-column gap-2">
						<input
							type="checkbox"
							id={`no-identification-${index}`}
							checked={victim.noIdentification}
							onChange={() => {
								handleUpdateVictim(index, "name", "Vítima sem identificação");
								handleUpdateVictimNoIdentification(index, !victim.noIdentification);
							}}
						/>

						<Form.Label className="fw-bold m-0" htmlFor={`no-identification-${index}`}>
							Sem identif.
						</Form.Label>
					</Col>

					<Col lg={9} className="d-flex gap-4">
						<Col lg={4}>
							<>
								<Form.Label className="fw-bold" htmlFor={`victim-sex-${index}`}>
									Sexo
								</Form.Label>{" "}
								<RedAsterisk />
							</>

							<ReactSelect
								inputId={`victim-sex-${index}`}
								isInvalid={verifyAttendanceType && !victim.sex}
								options={genderCodesOptions}
								value={genderCodesOptions.filter(
									(option) => option.value === victim.sex
								)}
								placeholder="Selecione"
								onChange={(option) =>
									handleUpdateVictim(
										index,
										"sex",
										String((option as ReactSelectOption).value)
									)
								}
							/>
						</Col>

						<Col lg={3}>
							<FormattedFormControl
								id={`victim-age-${index}`}
								label="Idade"
								labelClassName="fw-bold"
								mask={Number}
								isMaskedInput
								isInvalid={isInvalid(victim.age, index)}
								value={victim.age}
								onChange={(event) =>
									handleUpdateVictim(index, "age", event.currentTarget.value)
								}
								isRequired
							/>
						</Col>

						<Col className="d-flex align-items-end">
							<ReactSelect
								className="w-100"
								options={timeUnitsOptions}
								isInvalid={verifyAttendanceType && !victim.timeUnit}
								value={timeUnitsOptions.filter(
									(option) => option.value === victim.timeUnit
								)}
								onChange={(option) =>
									handleUpdateVictim(
										index,
										"timeUnit",
										String((option as ReactSelectOption).value)
									)
								}
							/>
						</Col>
					</Col>

					<Col className="d-flex align-items-end gap-2 p-0">
						{multipleVictims && (
							<OverlayTrigger overlay={<Tooltip>Adicionar</Tooltip>}>
								<Button
									type="button"
									variant="secondary"
									className="btn-icon btn-sm h-38px w-38px"
									onClick={handleAddVictim}
								>
									<FaPlus className="text-gray-700" />
								</Button>
							</OverlayTrigger>
						)}

						{index !== 0 && (
							<OverlayTrigger overlay={<Tooltip>Remover</Tooltip>}>
								<Button
									type="button"
									variant="secondary"
									className="btn-icon btn-sm h-38px w-38px"
									onClick={() => handleDeleteVictim(index)}
								>
									<FaTrash className="text-gray-700" />
								</Button>
							</OverlayTrigger>
						)}
					</Col>
				</Row>
			))}
		</>
	);
}
