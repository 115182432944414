import { useEffect, useRef, useState } from "react";

import Card from "react-bootstrap/Card";
import { FaFilter } from "react-icons/fa6";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { useCanAccess } from "@/hooks/useCanAccess";
import { VehicleStatus } from "@/enums/VehicleStatus";
import { Role } from "@/enums/Role";

import { GoogleMaps } from "@/components/Tracking/Maps/GoogleMaps";
import { OpenStreetMap } from "@/components/Tracking/Maps/OpenStreetMap";
import { VehicleStatusesFilterModal } from "@/components/Modals/VehicleStatusesFilterModal";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export function Tracking() {
	const { api } = useApi();
	const { currentAuth } = useAuth();
	const { can, roleIs } = useCanAccess();

	const interval = useRef<NodeJS.Timeout | null>(null);
	const [trackingData, setTrackingData] = useState<VehicleTrackingData[]>([]);
	const [bases, setBases] = useState<BaseOrUnit[]>([]);
	const [vehicleStatusesToFilter, setVehicleStatusesToFilter] = useState<number[]>([]);
	const [showVehicleStatusesFilterModal, setShowVehicleStatusesFilterModal] = useState(false);

	const handleShowVehicleStatusesFilterModal = () => setShowVehicleStatusesFilterModal(true);
	const handleCloseVehicleStatusesFilterModal = () => setShowVehicleStatusesFilterModal(false);

	const DEFAULT_LATITUDE = Number(-4.0911583333333335);
	const DEFAULT_LONGITUDE = Number(-38.49744);
	const DEFAULT_ZOOM = Number(10);
	const DEFAULT_REMAINING_SECONDS = 30;

	const defaultOptions = {
		latitude: DEFAULT_LATITUDE,
		longitude: DEFAULT_LONGITUDE,
		zoom: DEFAULT_ZOOM,
	};

	const vehicleStatusesToFilterLength = vehicleStatusesToFilter.length;

	const { manager, radioOperator } = Role;
	const needsToFilterVehicles = roleIs([manager, radioOperator]) || vehicleStatusesToFilterLength;

	const managerAndRadioOperationStatuses = [
		VehicleStatus.Active,
		VehicleStatus.Solicited,
		VehicleStatus.Committed,
		VehicleStatus.Unavailable,
	];

	function getStatuses(): MapVehicleStatuses {
		if (needsToFilterVehicles) {
			if (vehicleStatusesToFilterLength) {
				return vehicleStatusesToFilter;
			}

			return managerAndRadioOperationStatuses;
		}

		return "all";
	}

	const showNoCommunicationIndicator = false;
	const statuses = getStatuses();

	const defaultProps = {
		defaultOptions,
		trackingData,
		requestTrackingData,
		showNoCommunicationIndicator,
		statuses,
		bases,
		fetchAllBases,
	};

	async function requestTrackingData() {
		try {
			const url = "/vehicles-tracking/all-vehicles-current-location";

			const { data } = await api.get<VehicleTrackingData[]>(url, {
				params: {
					...(needsToFilterVehicles && {
						vehicle_statuses_to_search: vehicleStatusesToFilterLength
							? vehicleStatusesToFilter
							: managerAndRadioOperationStatuses,
					}),
				},
			});

			setTrackingData(data);
		} catch (error) {
			console.error(error);
		}
	}

	async function fetchAllBases() {
		try {
			const { data } = await api.get("/bases/tracking-bases");

			setBases(data.results);
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		if (interval.current) {
			clearInterval(interval.current);
		}

		interval.current = setInterval(() => {
			requestTrackingData();
		}, DEFAULT_REMAINING_SECONDS * 1000);

		return () => {
			if (interval.current) {
				clearInterval(interval.current);
			}
		};
	}, [currentAuth, vehicleStatusesToFilter]);

	useEffect(() => {
		fetchAllBases();
	}, [currentAuth]);

	const mapKey = `${currentAuth?.current_role?.id}${vehicleStatusesToFilter.toString()}`;

	return (
		<Card className="bg-transparent">
			<div className="mb-4 d-flex justify-content-end">
				<button
					className="d-flex align-items-center gap-4 btn button-bg-white-color-samu"
					onClick={handleShowVehicleStatusesFilterModal}
				>
					<FaFilter /> Filtrar
				</button>
			</div>

			<Card.Body className="p-0" style={{ height: "75vh" }}>
				{can([manager]) && GOOGLE_MAPS_API_KEY ? (
					<GoogleMaps key={mapKey} {...defaultProps} />
				) : (
					<OpenStreetMap key={mapKey} {...defaultProps} />
				)}
			</Card.Body>

			<VehicleStatusesFilterModal
				showVehicleStatusesFilterModal={showVehicleStatusesFilterModal}
				handleCloseVehicleStatusesFilterModal={handleCloseVehicleStatusesFilterModal}
				setVehicleStatusesToFilter={setVehicleStatusesToFilter}
				vehicleStatusesToFilter={vehicleStatusesToFilter}
			/>
		</Card>
	);
}
