import { ClosingType, ClosingTypeMessages } from "@/enums/ClosingType";

export const closingTypeOptions = [
	{
		label: ClosingTypeMessages[ClosingType.FalseOcurrence],
		value: ClosingType.FalseOcurrence,
	},
	{
		label: ClosingTypeMessages[ClosingType.RemovedByThirdsParties],
		value: ClosingType.RemovedByThirdsParties,
	},
	{
		label: ClosingTypeMessages[ClosingType.RefusalAttendance],
		value: ClosingType.RefusalAttendance,
	},
	{
		label: ClosingTypeMessages[ClosingType.RefusalRemoval],
		value: ClosingType.RefusalRemoval,
	},
	{
		label: ClosingTypeMessages[ClosingType.Evading],
		value: ClosingType.Evading,
	},
	{
		label: ClosingTypeMessages[ClosingType.AddressNotFound],
		value: ClosingType.AddressNotFound,
	},
	{
		label: ClosingTypeMessages[ClosingType.Death],
		value: ClosingType.Death,
	},
	{
		label: ClosingTypeMessages[ClosingType.RemovedByOtherVehicle],
		value: ClosingType.RemovedByOtherVehicle,
	},
];
