import dayjs from "dayjs";

import Yup from "@/utils/mixins/yup";

const defaultDatetimeRules = Yup.date().test(
	"is-before-now",
	"A data e hora não podem ser superiores ao horário atual",
	(value) => {
		if (!value) {
			return true;
		}

		return dayjs(value).isBefore(dayjs());
	}
);

const afterPreviousFieldDatetimeRule = (
	previousFieldKey: string,
	previousFieldLabel: string,
	schema: Yup.DateSchema
) => {
	return schema.when(previousFieldKey, (value, schema) => {
		if (value) {
			return schema.min(
				value,
				`A data e hora devem ser posteriores a do campo ${previousFieldLabel}`
			);
		}

		return schema;
	});
};

export const radioOperationSchema = Yup.object().shape({
	awaiting_fleet_confirmation: Yup.boolean().required("Campo obrigatório"),
	vehicle_requested_at: defaultDatetimeRules,
	vehicle_dispatched_at: afterPreviousFieldDatetimeRule(
		"vehicle_requested_at",
		"Solicitação VTR",
		defaultDatetimeRules
	),
	arrived_to_site_at: afterPreviousFieldDatetimeRule(
		"vehicle_dispatched_at",
		"Saída VTR",
		defaultDatetimeRules
	),
	left_from_site_at: afterPreviousFieldDatetimeRule(
		"arrived_to_site_at",
		"Chegada local",
		defaultDatetimeRules
	),
	arrived_to_destination_at: afterPreviousFieldDatetimeRule(
		"left_from_site_at",
		"Saída local",
		defaultDatetimeRules
	),
	release_from_destination_at: afterPreviousFieldDatetimeRule(
		"arrived_to_destination_at",
		"Chegada destino",
		defaultDatetimeRules
	),
	vehicle_released_at: afterPreviousFieldDatetimeRule(
		"arrived_to_destination_at",
		"Chegada destino",
		defaultDatetimeRules
	),
});
