import { AuthModel } from "./_models";

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";
const getAuth = (): AuthModel | undefined => {
	if (!localStorage) {
		return;
	}

	const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
	if (!lsValue) {
		return;
	}

	try {
		const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
		if (auth) {
			// You can easily check auth_token expiration also
			return auth;
		}
	} catch (error) {
		console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
	}
};

const setAuth = (auth: AuthModel) => {
	if (!localStorage) {
		return;
	}

	try {
		const lsValue = JSON.stringify(auth);
		localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
	} catch (error) {
		console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
	}
};

const removeAuth = () => {
	if (!localStorage) {
		return;
	}

	try {
		localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
	} catch (error) {
		console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
	}
};

const AUTH_DATA_LOCAL_STORAGE_KEY = "kt-auth-data-react-v";

const setAuthData = (authData: User) => {
	if (!localStorage) {
		return;
	}

	try {
		const lsValue = JSON.stringify(authData);
		localStorage.setItem(AUTH_DATA_LOCAL_STORAGE_KEY, lsValue);
	} catch (error) {
		console.error("AUTH DATA LOCAL STORAGE SAVE ERROR", error);
	}
};

const removeAuthData = () => {
	if (!localStorage) {
		return;
	}

	try {
		localStorage.removeItem(AUTH_DATA_LOCAL_STORAGE_KEY);
	} catch (error) {
		console.error("AUTH DATA LOCAL STORAGE REMOVE ERROR", error);
	}
};

export {
	getAuth,
	setAuth,
	removeAuth,
	setAuthData,
	removeAuthData,
	AUTH_LOCAL_STORAGE_KEY,
	AUTH_DATA_LOCAL_STORAGE_KEY,
};
