import clsx from "clsx";

import Card, { CardProps } from "react-bootstrap/Card";

type ColoredCardProps = CardProps & {
	value?: number;
	cardSubtitle: string;
	background: string;
};

export function ColoredCard({
	value,
	cardSubtitle,
	background,
	className,
	...rest
}: ColoredCardProps) {
	return (
		<Card className={clsx("w-fit-content p-6", background, className)} {...rest}>
			<Card.Body className="ps-0 pe-0">
				<Card.Title className="fs-3rem text-white">{value || 0}</Card.Title>
				<Card.Subtitle className="text-white w-10rem">{cardSubtitle}</Card.Subtitle>
			</Card.Body>
		</Card>
	);
}
