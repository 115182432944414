import dayjs from "dayjs";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { createSentence } from "@/utils/createSentence";

import { OccurrenceData } from "@/components/OccurrenceData";
import { Separator } from "@/components/Separator";

type Props = {
	attendance: Attendance;
};

export function AttendanceData({ attendance }: Props) {
	const ticket = attendance?.ticket;
	const latestMedicalRegulation = attendance?.latest_medical_regulation;
	const radioOperation = attendance?.radio_operation;
	const vehicleHistory = attendance?.latest_vehicle_status_history;

	const internalTeam = radioOperation?.fleets[0]?.users.map((user) => user.name);
	const externalTeam = radioOperation?.fleets[0]?.external_professionals.map(
		(external) => external.external_professional
	);
	const vehicleTeam = internalTeam?.concat(externalTeam) || externalTeam;

	function CommittedVehicle() {
		if (!vehicleHistory) {
			return <></>;
		}

		const vehicle = `${vehicleHistory.vehicle_type?.name || ""} ${
			vehicleHistory.vehicle?.code
		}`;

		if (!vehicleHistory.city_id) {
			return <>{vehicle}</>;
		}

		return (
			<>
				{vehicle} - {vehicleHistory.vehicle_city?.name}
			</>
		);
	}

	return (
		<>
			<OccurrenceData
				attendanceType={ticket?.ticket_type_id}
				attendance={attendance}
				attendable={attendance.attendable}
				showFatherLink={true}
			/>

			<Separator />

			<Row className="gap-8 mb-4">
				<Col
					xs={5}
					className="d-flex flex-column gap-2 border-block-end border-end border-end-dashed border-gray-500"
				>
					<h3>Chamado</h3>

					<div>
						<span className="fw-bold">Rádio operador: </span>
						<span>{radioOperation?.creator?.name ?? "-"}</span>
					</div>

					<div>
						<span className="fw-bold">VTR: </span>
						<span>
							<CommittedVehicle />
						</span>
					</div>

					<div>
						<span className="fw-bold">Equipe VTR: </span>
						<span>{createSentence(vehicleTeam || []) ?? "-"}</span>
					</div>

					<div>
						<span className="fw-bold">Médico regulador: </span>
						<span>{latestMedicalRegulation?.createdBy?.name ?? "-"}</span>
					</div>
				</Col>

				<Col className="d-flex flex-column gap-2">
					<h3>Horários</h3>

					<div>
						<span className="fw-bold">Chamado: </span>
						<span>
							{ticket?.opening_at
								? dayjs(ticket?.opening_at).format("DD/MM/YYYY [às] HH:mm")
								: "-"}
						</span>
					</div>

					<div>
						<span className="fw-bold">Regulação médica: </span>
						<span>
							{latestMedicalRegulation?.created_at
								? dayjs(latestMedicalRegulation?.created_at).format(
										"DD/MM/YYYY [às] HH:mm"
								  )
								: "-"}
						</span>
					</div>

					<div>
						<span className="fw-bold">Solicitação VTR: </span>
						<span>
							{radioOperation?.vehicle_requested_at
								? dayjs(radioOperation?.vehicle_requested_at).format(
										"DD/MM/YYYY [às] HH:mm"
								  )
								: "-"}
						</span>
					</div>

					<div>
						<span className="fw-bold">Saída VTR: </span>
						<span>
							{radioOperation?.vehicle_dispatched_at
								? dayjs(radioOperation?.vehicle_dispatched_at).format(
										"DD/MM/YYYY [às] HH:mm"
								  )
								: "-"}
						</span>
					</div>
				</Col>
			</Row>

			{attendance.cancellation && (
				<>
					<Separator />

					<h2 className="mb-4">Ocorrência cancelada</h2>

					<Row>
						<Col lg={6}>
							<div>
								<span className="fw-bold">Médico regulador: </span>
								{attendance.cancellation.creator.name}
							</div>
							<div>
								<span className="fw-bold">Solicitante: </span>
								{attendance.cancellation.requester}
							</div>
							<div>
								<span className="fw-bold">Motivo do cancelamento: </span>
								{attendance.cancellation.reason}
							</div>
						</Col>

						<Col>
							<div>
								<span className="fw-bold">Data e hora: </span>
								{dayjs(attendance.cancellation.created_at).format(
									"DD/MM/YYYY [às] HH:mm"
								)}
							</div>
						</Col>
					</Row>
				</>
			)}
		</>
	);
}
