import { useEffect } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";

import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Role } from "@/enums/Role";
import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { useSwal } from "@/hooks/useSwal";
import { PeriodType } from "@/enums/PeriodType";
import { DutyReportType } from "@/enums/DutyReportType";
import { createSentence } from "@/utils/createSentence";
import { dutyReportSchema } from "@/utils/validation/dutyReportSchema";
import { dutyReportInitialValues } from "@/utils/initialValues/dutyReport";

import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";
import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { AbleUsersSelect } from "@/components/Selects/AbleUsersSelect";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { FormRadio } from "@/components/Radios/FormRadio";
import { RedAsterisk } from "@/components/RedAsterisk";

type Props = {
	showDutyReportFormModal: boolean;
	handleCloseDutyReportFormModal: () => void;
	dutyReport: DutyReport;
	fetchDutyReports: () => void;
	dutyReportType: DutyReportType | null;
};

export function DutyReportFormModal({
	showDutyReportFormModal,
	handleCloseDutyReportFormModal,
	dutyReport,
	fetchDutyReports,
	dutyReportType,
}: Props) {
	const { Swal, Toast, toastRequestErrors } = useSwal();
	const { currentAuth } = useAuth();
	const { api } = useApi();

	const isEdit = dutyReport?.id ? true : false;
	const isFleetManager = dutyReportType === DutyReportType.fleetManager;

	const formik = useFormik({
		validationSchema: dutyReportSchema,
		initialValues: dutyReportInitialValues,
		async onSubmit(values, { setSubmitting, resetForm }) {
			const method = isEdit ? "put" : "post";
			const route = isEdit ? `/report/duty/${dutyReport?.id}` : "/report/duty";

			try {
				await api[method](route, values);

				Toast.fire({
					icon: "success",
					title: `Reatório de plantão ${
						isEdit ? "atualizado" : "cadastrado"
					} com sucesso!`,
				});

				fetchDutyReports();
				handleCloseDutyReportFormModal();
				resetForm();
			} catch (error: any) {
				console.log(error);

				setSubmitting(false);
				toastRequestErrors(error.response.data.errors);
			}
		},
	});

	const { values, errors } = formik;

	const selectedMedicalRegulators = values.medical_regulators?.map(
		(user: DutyReportProfessional) => ({
			value: user.user_id,
			label: user.name,
		})
	);

	const selectedRadioOperators = values.radio_operators?.map((user: DutyReportProfessional) => ({
		value: user.user_id,
		label: user.name,
	}));

	const selectedTarms = values.tarms?.map((user: DutyReportProfessional) => ({
		value: user.user_id,
		label: user.name,
	}));

	async function verifyExistenceOfPreviousReport() {
		try {
			const { data } = await api.get(`/report/duty/verify`, {
				params: {
					record_at: values.record_at,
					period_type_id: values.period_type_id,
					duty_report_type_id: values.duty_report_type_id,
				},
			});

			if (!data.exists) {
				return;
			}

			Swal.fire({
				title: "Já existe um relatório de plantão registrado na data e período selecionado",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Fechar",
				showConfirmButton: false,
				timerProgressBar: true,
				timer: 5000,
			});
		} catch (error: any) {
			console.log(error);

			toastRequestErrors(error.response.data.errors);
		}
	}

	function handleCloseModal() {
		handleCloseDutyReportFormModal();

		formik.resetForm();
	}

	function formatUsername(user: User) {
		return `${user.name} - ${createSentence(
			user.urgency_regulation_centers?.map((urc) => urc.name) || []
		)}`;
	}

	function handleSelectProfessionals(options: ReactSelectOption[], field: string) {
		const rolePerField: { [key: string]: string } = {
			medical_regulators: Role.medic,
			radio_operators: Role.radioOperator,
			tarms: Role.tarm,
		};

		const formattedOptions = (options as ReactSelectOption[])?.map((option: any) => ({
			user_id: option.value,
			name: option.label,
			current_role_slug: rolePerField[field],
		}));

		formik.setFieldValue(field, formattedOptions);
	}

	useEffect(() => {
		const recordAtIsBeforeToday = dayjs(values.record_at).isBefore(dayjs());

		if (
			values.record_at &&
			values.period_type_id &&
			!isEdit &&
			showDutyReportFormModal &&
			recordAtIsBeforeToday
		) {
			verifyExistenceOfPreviousReport();
		}
	}, [values.record_at, values.period_type_id]);

	useEffect(() => {
		if (showDutyReportFormModal) {
			!values.duty_report_type_id &&
				formik.setFieldValue("duty_report_type_id", dutyReportType);
		}
	}, [showDutyReportFormModal]);

	useEffect(() => {
		if (dutyReport?.id && showDutyReportFormModal) {
			const medicalRegulators = dutyReport.medical_regulators?.map((user: User) => ({
				user_id: String(user.id),
				name: formatUsername(user),
				current_role_slug: Role.medic,
			}));

			const radioOperators = dutyReport.radio_operators?.map((user: User) => ({
				user_id: String(user.id),
				name: formatUsername(user),
				current_role_slug: Role.radioOperator,
			}));

			const tarms = dutyReport.tarms?.map((user: User) => ({
				user_id: String(user.id),
				name: formatUsername(user),
				current_role_slug: Role.tarm,
			}));

			formik.setValues({
				events: dutyReport.events ?? "",
				compliments: dutyReport.compliments ?? "",
				tarms: tarms as DutyReportProfessional[],
				period_type_id: String(dutyReport.period_type_id),
				record_at: dayjs(dutyReport.record_at).format("YYYY-MM-DD"),
				duty_report_type_id: String(dutyReport.duty_report_type_id),
				external_complications: dutyReport.external_complications ?? "",
				internal_complications: dutyReport.internal_complications ?? "",
				radio_operators: radioOperators as DutyReportProfessional[],
				medical_regulators: medicalRegulators as DutyReportProfessional[],
			});
		}
	}, [dutyReport]);

	return (
		<Modal size="xl" show={showDutyReportFormModal} onHide={handleCloseModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Relatório de plantão</h2>
			</Modal.Header>

			<Modal.Body>
				{isEdit && (
					<div>
						<span className="fw-bold">Última alteração: </span>
						{dayjs(dutyReport?.updated_at).format("DD/MM/YYYY [às] HH:mm")}
					</div>
				)}

				<Form onSubmit={formik.handleSubmit}>
					<Row>
						<Col lg={3}>
							<FormattedFormControl
								id="record-at"
								label="Data do registro"
								labelClassName="fw-bold"
								isRequired
								isInvalid={!!errors.record_at}
								validationMessage={errors.record_at}
								type="date"
								disabled={isEdit}
								{...formik.getFieldProps("record_at")}
							/>
						</Col>

						<Col lg={3}>
							<FormGroupRadio
								label="Turno"
								labelClassName="fw-bold mb-4"
								requestedValue={values.period_type_id}
								{...formik.getFieldProps("period_type_id")}
								inline
								isRequired
								disabled={isEdit}
								isInvalid={!!errors.period_type_id}
							>
								<FormRadio
									id="daytime"
									label={
										<OverlayTrigger
											overlay={
												<Tooltip>
													Referente ao início do plantão às 07h
												</Tooltip>
											}
										>
											<span>Diurno</span>
										</OverlayTrigger>
									}
									value={PeriodType.Daytime}
								/>

								<FormRadio
									id="nocturnal"
									label={
										<OverlayTrigger
											overlay={
												<Tooltip>
													Referente ao início do plantão às 19h
												</Tooltip>
											}
										>
											<span>Noturno</span>
										</OverlayTrigger>
									}
									value={PeriodType.Nocturnal}
								/>
							</FormGroupRadio>

							{errors.period_type_id && (
								<span className="text-danger">{errors.period_type_id}</span>
							)}
						</Col>

						<Col lg={3}>
							<FormattedFormControl
								label="Profissional"
								labelClassName="fw-bold"
								value={currentAuth?.name}
								disabled
							/>
						</Col>
					</Row>

					<Col lg={9} className="mt-5">
						<Form.Label htmlFor="medical-regulators" className="fw-bold">
							Médicos reguladores <RedAsterisk />
						</Form.Label>

						<AbleUsersSelect
							isMulti
							inputId="medical-regulators"
							isInvalid={!values.medical_regulators?.length}
							placeholder="Selecione pelo menos um usuário que possua o perfil de Médico"
							filterUrc={isFleetManager}
							noOptionsMessage={({ inputValue }) =>
								inputValue
									? "Nenhum resultado encontrado"
									: "Sem usuários cadastrados com esse perfil"
							}
							roles={["medic"]}
							value={selectedMedicalRegulators}
							onChange={(options) =>
								handleSelectProfessionals(
									options as ReactSelectOption[],
									"medical_regulators"
								)
							}
						/>

						{errors.medical_regulators && (
							<span className="text-danger">
								{errors.medical_regulators as string[]}
							</span>
						)}
					</Col>

					<Col lg={9} className="mt-5">
						<Form.Label htmlFor="radio-operators" className="fw-bold">
							{isFleetManager ? "Operador" : "Gerente"} de frota <RedAsterisk />
						</Form.Label>

						<AbleUsersSelect
							inputId="radio-operators"
							isInvalid={!values.radio_operators?.length}
							placeholder="Selecione pelo menos um usuário que possua o perfil de Rádio Operador"
							isMulti
							noOptionsMessage={({ inputValue }) =>
								inputValue
									? "Nenhum resultado encontrado"
									: "Sem usuários cadastrados com esse perfil"
							}
							roles={["radio-operator"]}
							onChange={(options) =>
								handleSelectProfessionals(
									options as ReactSelectOption[],
									"radio_operators"
								)
							}
							value={selectedRadioOperators}
						/>

						{errors.radio_operators && (
							<span className="text-danger">
								{errors.radio_operators as string[]}
							</span>
						)}
					</Col>

					{isFleetManager && (
						<Col lg={9} className="mt-5">
							<Form.Label htmlFor="tarms" className="fw-bold">
								TARM <RedAsterisk />
							</Form.Label>

							<AbleUsersSelect
								inputId="tarms"
								isMulti
								isInvalid={!values.tarms?.length}
								placeholder="Selecione pelo menos um usuário que possua o perfil de TARM"
								noOptionsMessage={({ inputValue }) =>
									inputValue
										? "Nenhum resultado encontrado"
										: "Sem usuários cadastrados com esse perfil"
								}
								roles={["TARM"]}
								onChange={(options) =>
									handleSelectProfessionals(
										options as ReactSelectOption[],
										"tarms"
									)
								}
								value={selectedTarms}
							/>

							{errors.tarms && (
								<span className="text-danger">{errors.tarms as string[]}</span>
							)}
						</Col>
					)}

					<FormControlWithCharactersCount
						id="internal-complications"
						as="textarea"
						label="Intercorrências internas"
						labelClassName="fw-bold mt-5"
						placeholder="Descreva"
						maxLength={5000}
						isInvalid={!!errors.internal_complications}
						validationMessage={errors.internal_complications}
						{...formik.getFieldProps("internal_complications")}
					/>

					<FormControlWithCharactersCount
						id="external-complications"
						as="textarea"
						label="Intercorrências externas"
						labelClassName="fw-bold mt-5"
						placeholder="Descreva"
						maxLength={5000}
						isInvalid={!!errors.external_complications}
						validationMessage={errors.external_complications}
						{...formik.getFieldProps("external_complications")}
					/>

					<FormControlWithCharactersCount
						id="compliments"
						as="textarea"
						label="Elogios"
						labelClassName="fw-bold mt-5"
						placeholder="Descreva"
						maxLength={5000}
						isInvalid={!!errors.compliments}
						validationMessage={errors.compliments}
						{...formik.getFieldProps("compliments")}
					/>

					<FormControlWithCharactersCount
						id="events"
						as="textarea"
						label="Eventos"
						labelClassName="fw-bold mt-5"
						placeholder="Descreva"
						maxLength={5000}
						isInvalid={!!errors.events}
						validationMessage={errors.events}
						{...formik.getFieldProps("events")}
					/>

					<div className="d-flex justify-content-center gap-2 mt-8">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{isEdit ? "Salvar" : "Cadastrar"}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
