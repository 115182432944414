export const vehicleStatusColors = [
	"badge-color-green",
	"badge-color-yellow",
	"badge-color-blue",
	"badge-color-red",
	"badge-color-orange",
];

export const vehicleStatusHovers = [
	"hover-bg-green",
	"hover-bg-yellow",
	"hover-bg-blue",
	"",
	"",
	"hover-bg-grey",
];
