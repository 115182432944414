export enum DistanceType {
	WithPatient = 1,
	Near = 2,
	Away = 3,
	NotWithPatient = 4,
}

export const DistanceTypeMessages = {
	[DistanceType.WithPatient]: "Com paciente",
	[DistanceType.Near]: "Próximo",
	[DistanceType.Away]: "Longe",
	[DistanceType.NotWithPatient]: "Não está com o paciente",
};
