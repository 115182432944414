import classNames from "clsx";

type Props = {
	attribute: number;
	isVehicleStatus?: boolean;
};

export function Bullet({ attribute, isVehicleStatus }: Props) {
	const priorityAttributes = [
		"bg-transparent",
		"badge-bg-blue",
		"badge-bg-green",
		"badge-bg-yellow",
		"badge-bg-orange",
		"badge-bg-red",
	];

	const vehicleStatusAttributes = [
		"badge-bg-green",
		"badge-bg-yellow",
		"badge-bg-blue",
		"badge-bg-red",
		"badge-bg-orange",
	];

	const getBadgeAttributes = isVehicleStatus ? vehicleStatusAttributes : priorityAttributes;
	const getAttribute = Number(`${isVehicleStatus ? attribute - 1 : attribute}`);

	return (
		<span
			className={classNames(
				"bullet bullet-dot h-15px w-15px",
				getBadgeAttributes[getAttribute]
			)}
		/>
	);
}
