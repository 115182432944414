export enum TimeUnit {
	Days = 1,
	Months = 2,
	Years = 3,
}

export const TimeUnitMessages = {
	[TimeUnit.Days]: "Dia(s)",
	[TimeUnit.Months]: "Mês(es)",
	[TimeUnit.Years]: "Ano(s)",
};
