import { FormEvent, Fragment, useEffect, useState } from "react";
import { createFilter } from "react-select";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FaTrash } from "react-icons/fa";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";

import { PatrimoniesSelect } from "@/components/Selects/PatrimoniesSelect";
import { Separator } from "@/components/Separator";

type Props = {
	showEquipmentModal: boolean;
	handleCloseEquipmentModal: () => void;
	vehicle: Vehicle;
};

type PatrimonyOption = {
	extraData: Patrimony;
};

export function LinkEquipmentModal({
	showEquipmentModal,
	handleCloseEquipmentModal,
	vehicle,
}: Props) {
	const [linkedPatrimonies, setLinkedPatrimonies] = useState<Patrimony[]>([]);
	const { Toast, toastRequestErrors } = useSwal();
	const { api } = useApi();

	async function handleSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();

		try {
			await api.patch(`/vehicles/${vehicle.id}/patrimonies`, {
				patrimonies: linkedPatrimonies.map((patrimony) => patrimony.id),
			});

			Toast.fire({
				icon: "success",
				title: "Equipamentos vinculados com sucesso",
			});

			handleCloseEquipmentModal();
		} catch (error: any) {
			if (error.isAxiosError && error.response.status === 422) {
				return toastRequestErrors(error.response.data.errors);
			}

			Toast.fire({
				icon: "error",
				title: "Erro ao vincular equipamentos",
			});
		}
	}

	function handleLinkPatrimony(patrimony: Patrimony) {
		setLinkedPatrimonies([...linkedPatrimonies, patrimony]);
	}

	function handleUnlinkPatrimony(index: number) {
		const updatedPatrimonies = [...linkedPatrimonies];
		updatedPatrimonies.splice(index, 1);

		setLinkedPatrimonies(updatedPatrimonies);
	}

	function filterSelected(candidate: ReactSelectFilterOption<unknown>, input: string) {
		if (linkedPatrimonies.some((patrimony) => patrimony.id === candidate.value)) {
			return false;
		}

		const defaultFilter = createFilter();

		return defaultFilter(candidate, input);
	}

	useEffect(() => {
		if (vehicle?.patrimonies) {
			setLinkedPatrimonies(vehicle.patrimonies);
		}
	}, [vehicle]);

	return (
		<Modal size="lg" show={showEquipmentModal} onHide={handleCloseEquipmentModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Equipamento</h2>
			</Modal.Header>

			<Modal.Body>
				<Form.Label className="fw-bold" htmlFor="patrimony">
					Vincular equipamento
				</Form.Label>

				<PatrimoniesSelect
					inputId="patrimony"
					filterOption={filterSelected}
					placeholder="Selecione um equipamento"
					onChange={(option) =>
						handleLinkPatrimony((option as PatrimonyOption).extraData)
					}
					onlyAvailable
				/>

				<Form onSubmit={handleSubmit} className="mt-8">
					<Form.Label className="fw-bold">Equipamentos vinculados</Form.Label>

					{linkedPatrimonies.map((patrimony, index) => (
						<Fragment key={`patrimony-${index}`}>
							<div className="d-flex justify-content-between align-items-center pb-4">
								<span>
									{patrimony?.identifier} - {patrimony.patrimony_type?.name}
								</span>

								<Button
									variant="secondary"
									className="btn-icon btn-sm"
									onClick={() => handleUnlinkPatrimony(index)}
								>
									<FaTrash className="text-gray-700" />
								</Button>
							</div>

							{linkedPatrimonies?.length - 1 !== index && (
								<Separator className="pb-4" />
							)}
						</Fragment>
					))}

					{!linkedPatrimonies?.length && (
						<div className="text-center">
							<h2 className="pt-8 pb-4">Sem equipamentos vinculados</h2>
						</div>
					)}

					<div className="d-flex justify-content-center gap-2 mt-8">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseEquipmentModal}
						>
							Fechar
						</button>

						<button type="submit" className="btn button-bg-samu-color-white">
							Atualizar
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
