export enum PatrimonyType {
	Bed = 1,
	SiemensCellPhoneWithCharger = 2,
	PortableVacuumCleaner = 3,
	OxygenFlowmeter = 4,
	CardiacDefibrillatorMonitor = 5,
	ShortPlankWithStraps = 6,
	LongBoardWithStraps = 7,
	Humidifiers = 8,
	MechanicalOrCircuitFan = 9,
	VacuumCleanerGlassWithLid = 10,
	CellPhoneWithCharger = 11,
	AutomaticExternalDefibrillator = 12,
	OximeterCharger = 13,
}

export const PatrimonyTypeMessages = {
	[PatrimonyType.Bed]: "Maca",
	[PatrimonyType.SiemensCellPhoneWithCharger]: "Aparelho celular Siemens c/ carregador",
	[PatrimonyType.PortableVacuumCleaner]: "Aspirador portátil",
	[PatrimonyType.OxygenFlowmeter]: "Fluxometro de oxigênio",
	[PatrimonyType.CardiacDefibrillatorMonitor]: "Monitor desf. cardíaco",
	[PatrimonyType.ShortPlankWithStraps]: "Prancha curta com tirantes",
	[PatrimonyType.LongBoardWithStraps]: "Prancha longa com tirantes",
	[PatrimonyType.Humidifiers]: "Umidificadores",
	[PatrimonyType.MechanicalOrCircuitFan]: "Ventilador mecânico / circuito",
	[PatrimonyType.VacuumCleanerGlassWithLid]: "Vidro de aspirador c/ tampa",
	[PatrimonyType.CellPhoneWithCharger]: "Aparelho celular com carregador",
	[PatrimonyType.AutomaticExternalDefibrillator]: "DEA (desfibrilador externo automático)",
	[PatrimonyType.OximeterCharger]: "Carregador de oxímetro",
};
