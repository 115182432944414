import { Dispatch, FormEvent, useState } from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useCanAccess } from "@/hooks/useCanAccess";
import {
	mapVehicleStatusesOptions,
	mapVehicleStatusesLimitedOptions,
} from "@/utils/options/vehicleStatus";
import { Role } from "@/enums/Role";

import { ReactSelect } from "@/components/Selects/ReactSelect";

type Props = {
	showVehicleStatusesFilterModal: boolean;
	handleCloseVehicleStatusesFilterModal: () => void;
	setVehicleStatusesToFilter: Dispatch<number[]>;
	vehicleStatusesToFilter: number[];
};

export function VehicleStatusesFilterModal({
	showVehicleStatusesFilterModal,
	handleCloseVehicleStatusesFilterModal,
	setVehicleStatusesToFilter,
	vehicleStatusesToFilter,
}: Props) {
	const [selectedVehicleStatuses, setSelectedVehicleStatuses] = useState<ReactSelectOption[]>([]);

	const { roleIs } = useCanAccess();
	const { manager, radioOperator } = Role;

	const vehicleStatusesLength = selectedVehicleStatuses.length;
	const needsToFilterOptions = roleIs([manager, radioOperator]);

	const vehicleStatusesFilteredOptions = needsToFilterOptions
		? mapVehicleStatusesLimitedOptions
		: mapVehicleStatusesOptions;

	function handleSubmit(event: FormEvent) {
		event.preventDefault();

		setVehicleStatusesToFilter(selectedVehicleStatuses.map((option) => Number(option.value)));
		handleCloseVehicleStatusesFilterModal();
	}

	function handleCleanModal() {
		if (vehicleStatusesToFilter.length) {
			setVehicleStatusesToFilter([]);
		}

		setSelectedVehicleStatuses([]);
		handleCloseVehicleStatusesFilterModal();
	}

	return (
		<Modal show={showVehicleStatusesFilterModal} onHide={handleCloseVehicleStatusesFilterModal}>
			<Modal.Header closeButton>
				<span className="btn-close invisible" />
				<h3 className="m-0">Filtros</h3>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={handleSubmit}>
					<Form.Label className="fw-bold">Selecione o status</Form.Label>

					<ReactSelect
						isMulti
						value={selectedVehicleStatuses}
						options={vehicleStatusesFilteredOptions}
						isInvalid={!vehicleStatusesLength}
						onChange={(options) =>
							setSelectedVehicleStatuses(options as ReactSelectOption[])
						}
					/>

					{!vehicleStatusesLength && (
						<span className="text-danger">Campo obrigatório</span>
					)}

					<div className="d-flex justify-content-center gap-4 mt-5">
						<button
							className="btn button-bg-light-color-gray-400"
							onClick={handleCleanModal}
							type="button"
						>
							{!vehicleStatusesLength ? "Fechar" : "Limpar"}
						</button>

						<button
							className="btn button-bg-samu-color-white"
							disabled={!vehicleStatusesLength}
							type="submit"
						>
							Filtrar
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
