import { TimeUnit, TimeUnitMessages } from "@/enums/TimeUnit";

export const attendanceConsultationInitialValues = {
	start_date: null,
	end_date: null,
	attendance_number: "",
	attendance_status: {},
	user: {},
	patient_name: "",
	initial_birth_date: "",
	final_birth_date: "",
	time_unit_id: { label: TimeUnitMessages[TimeUnit.Years], value: String(TimeUnit.Years) },
	gender: {},
	requesting_phone: "",
	cities: [],
	neighborhood: "",
	vehicles: [],
	vehicles_types: [],
	street: "",
	action_types: [],
	supporting_organizations_medical_regulation: [],
	supporting_organizations_scene_recording: [],
	priority_types: [],
	vehicle_movement_codes: [],
	nature_types: [],
	ticket_types: [],
	diagnostic_hypotheses: [],
	requester_types: [],
	requesting_name: "",
	distance_types: [],
	location_types: [],
	closing_types: [],
	units_origin: [],
	units_destination: [],
	antecedents: [],
	bases: [],
	transfer_reason: {},
	thrombolytic_applied: [],
	thrombolytic_recommended: [],
};
