import { useEffect, useState } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";

import { useCanAccess } from "@/hooks/useCanAccess";

import { DiagnosticHypotheses } from "@/components/Configurations/DiagnosticHypotheses";
import { CyclicScheduleTypes } from "@/components/Configurations/CyclicScheduleTypes";
import { Places } from "@/components/Configurations/Places";
import { PowerBIReports } from "@/components/Configurations/PowerBIReports";

export function Management() {
	const [defaultActiveKey, setDefaultActiveKey] = useState("");

	const { can } = useCanAccess();

	useEffect(() => {
		setDefaultActiveKey("places");
	}, []);

	return (
		<Card>
			<Card.Header className="d-flex align-items-center">
				<h3 className="fw-normal m-0">Gerenciamento</h3>
			</Card.Header>

			<Card.Body>
				<Tabs
					activeKey={defaultActiveKey}
					onSelect={(tab) => setDefaultActiveKey(String(tab))}
					fill
					className="nav-line-tabs nav-line-tabs-2x fs-5 fw-bolder flex-nowrap w-fit-content mb-6"
				>
					<Tab eventKey="places" title={<span className="text-center px-6">Locais</span>}>
						<Places activeTab={defaultActiveKey} />
					</Tab>

					<Tab
						eventKey="diagnostic-hypotheses"
						title={<span className="text-center px-6">Hipóteses diagnósticas</span>}
					>
						<DiagnosticHypotheses activeTab={defaultActiveKey} />
					</Tab>

					<Tab
						eventKey="cyclic-schedule-types"
						title={<span className="text-center px-6">Cadastro de escalas</span>}
					>
						<CyclicScheduleTypes activeTab={defaultActiveKey} />
					</Tab>

					{can([]) && (
						<Tab
							eventKey="power-bi-reports"
							title={<span className="text-center px-6">Relatórios do Power BI</span>}
						>
							<PowerBIReports activeTab={defaultActiveKey} />
						</Tab>
					)}
				</Tabs>
			</Card.Body>
		</Card>
	);
}
