import {
	ReactNode,
	Children,
	cloneElement,
	ReactElement,
	useState,
	DOMAttributes,
	useEffect,
} from "react";
import { FormControlProps } from "react-bootstrap/FormControl";
import classNames from "clsx";

import Skeleton, { SkeletonProps } from "react-loading-skeleton";
import Form from "react-bootstrap/Form";

import { RedAsterisk } from "@/components/RedAsterisk";

export type Props = DOMAttributes<HTMLDivElement> &
	FormControlProps &
	SkeletonProps & {
		label?: string;
		isRequired?: boolean;
		children: ReactNode;
		name: string;
		requestedValue?: string | number | boolean;
		labelClassName?: string;
		inline?: boolean;
		disabled?: boolean;
		readOnly?: boolean;
		skeleton?: boolean;
		containerClassName?: string;
	};

export function FormGroupRadio({
	label,
	children,
	name,
	isRequired = false,
	requestedValue,
	labelClassName,
	inline = false,
	disabled = false,
	readOnly = false,
	skeleton = false,
	width,
	height,
	containerClassName,
	onChange,
	className,
	isInvalid,
	...rest
}: Props) {
	const [currentvalue, setCurrentvalue] = useState<string | number | boolean | undefined>(
		requestedValue
	);

	useEffect(() => {
		setCurrentvalue(requestedValue);
	}, [requestedValue]);

	return (
		<>
			{skeleton ? (
				<Skeleton width={width} height={height} containerClassName={containerClassName} />
			) : (
				<>
					{label && (
						<Form.Label className={labelClassName}>
							{label} {isRequired && <RedAsterisk />}
						</Form.Label>
					)}

					<div
						className={classNames(className, inline && "d-flex flex-wrap")}
						onChange={(event) => onChange?.(event)}
						{...rest}
					>
						{Children.map(children, (child) => (
							<>
								{cloneElement(child as ReactElement, {
									name,
									currentvalue,
									inline,
									disabled,
									readOnly,
									isInvalid,
								})}
							</>
						))}
					</div>
				</>
			)}
		</>
	);
}
