import { useRef, useEffect } from "react";

import { Loader } from "@googlemaps/js-api-loader";

import "./styles.scss";

type MapsLibrary = google.maps.MapsLibrary;
type MarkerLibrary = google.maps.MarkerLibrary;
type Map = google.maps.Map;
type Marker = google.maps.Marker;
type MarkerOptions = google.maps.MarkerOptions;

type MarkerWithExtra = Marker & {
	accessHistory: AccessHistory;
};

type GoogleMapsProps = {
	accessHistory: AccessHistory;
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const loader = new Loader({
	apiKey: GOOGLE_MAPS_API_KEY ?? "",
	version: "weekly",
});

export function GoogleMapsAccessHistory({ accessHistory }: GoogleMapsProps) {
	const mapsLib = useRef<MapsLibrary | null>(null);
	const markerLib = useRef<MarkerLibrary | null>(null);
	const map = useRef<Map | null>(null);

	const defaultOptions = {
		latitude: Number(accessHistory.latitude),
		longitude: Number(accessHistory.longitude),
	};

	const currentMarkers = useRef<MarkerWithExtra[]>([]);

	async function initMap() {
		const [loadedMaps, loadedMarker] = await Promise.all([
			loader.importLibrary("maps"),
			loader.importLibrary("marker"),
		]);

		mapsLib.current = loadedMaps;
		markerLib.current = loadedMarker;

		const element = document.getElementById("google-maps-access-history") as HTMLElement;
		const coordinates = { lat: defaultOptions.latitude, lng: defaultOptions.longitude };

		map.current = new mapsLib.current.Map(element, {
			center: coordinates,
			zoom: 10,
		});
	}

	function createMarker(options: MarkerOptions) {
		const marker = new (markerLib.current as MarkerLibrary).Marker(options) as MarkerWithExtra;

		marker.setMap(map.current);

		currentMarkers.current = [...currentMarkers.current, marker];
	}

	function createMarkerWithExtra(data: AccessHistory) {
		const options = {
			position: {
				lat: Number(data.latitude),
				lng: Number(data.longitude),
			},
			title: data.name,
			icon: {
				url: `${window.location.origin}/media/icons/tracking/user-marker-icon.svg`,
				scaledSize: new google.maps.Size(50, 50),
			},
			extra: data,
		};
		createMarker(options);
	}

	useEffect(() => {
		initMap();

		return () => {
			if (map.current) {
				map.current = null;
			}

			if (currentMarkers.current.length) {
				currentMarkers.current = [];
			}
		};
	}, [accessHistory.latitude, accessHistory.longitude]);

	useEffect(() => {
		if (!mapsLib.current || !markerLib.current) {
			return;
		}

		createMarkerWithExtra(accessHistory);
	}, [mapsLib.current, markerLib.current]);

	return <div id="google-maps-access-history" className="rounded"></div>;
}
