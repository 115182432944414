export enum NatureType {
	Trauma = 1,
	Clinical = 2,
	GynecoObstetric = 3,
	Pediatric = 4,
	Psychiatric = 5,
	Neonatal = 6,
	Event = 7,
}

export const NatureTypeMessages = {
	[NatureType.Trauma]: "Trauma",
	[NatureType.Clinical]: "Clínico",
	[NatureType.GynecoObstetric]: "Gineco obstétrico",
	[NatureType.Pediatric]: "Pediátrico",
	[NatureType.Psychiatric]: "Psiquiátrico",
	[NatureType.Neonatal]: "Neonatal",
	[NatureType.Event]: "Evento",
};
