import dayjs from "dayjs";
import Yup from "@/utils/mixins/yup";

const currentDate = dayjs().format("YYYY-MM-DD");

export const scheduleSchema = Yup.object().shape({
	schedulable_id: Yup.string().required("Campo obrigatório"),
	user_id: Yup.string().nullable(),
	valid_from: Yup.date()
		.required("Campo obrigatório")
		.test(
			"is-after-or-equal-now",
			"A data não pode ser anterior a data atual",
			function (value) {
				const createdAt = this.parent.created_at;

				if (currentDate >= createdAt) {
					return true;
				}

				return dayjs(value).format("YYYY-MM-DD") >= currentDate;
			}
		)
		.nullable(),
	valid_through: Yup.date()
		.required("Campo obrigatório")
		.test(
			"is-after-or-equal-now",
			"A data não pode ser anterior a data atual",
			function (value) {
				const createdAt = this.parent.created_at;

				if (createdAt && dayjs() > dayjs(value)) {
					return true;
				}

				return dayjs(value).format("YYYY-MM-DD") >= currentDate;
			}
		)
		.nullable(),
	clock_in: Yup.string()
		.required("Campo obrigatório")
		.test(
			"is-before-clock-out",
			"O horário de entrada não pode ser maior que o de saída",
			function (value) {
				const clockOut = this.parent.clock_out;
				const validFrom = this.parent.valid_from;
				const validThrough = this.parent.valid_through;
				const periodInDays = dayjs(validThrough).diff(dayjs(validFrom), "day");

				if (periodInDays >= 1) {
					return true;
				}

				return (value as string) < clockOut;
			}
		),
	clock_out: Yup.string()
		.required("Campo obrigatório")
		.test(
			"is-after-clock-in",
			"O horário de saída não pode ser menor que o de entrada",
			function (value) {
				const clockIn = this.parent.clock_in;
				const validFrom = this.parent.valid_from;
				const validThrough = this.parent.valid_through;
				const periodInDays = dayjs(validThrough).diff(dayjs(validFrom), "day");

				if (periodInDays >= 1) {
					return true;
				}

				return (value as string) > clockIn;
			}
		),
	schedule_type_id: Yup.string().required("Campo obrigatório"),
	days_of_week: Yup.array().min(1, "Campo obrigatório"),
});
