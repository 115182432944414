export const titleStyles = {
	textAlign: "right" as "right",
	fontWeight: "bold",
};

export const textStyles = {
	fontFamily: "Calibri, sans-serif",
	fontSize: "12pt",
	textAlign: "center" as "center",
};
