export enum OccupationType {
	Conductor = "515135",
	Doctor = "2251",
	Nurse = "223505",
	NursingAssistant = "322230",
	NursingTechnician = "322205",
}

export const OccupationTypeMessages = {
	[OccupationType.Conductor]: "Condutor",
	[OccupationType.Doctor]: "Médico",
	[OccupationType.Nurse]: "Enfermeiro",
	[OccupationType.NursingAssistant]: "Auxiliar de enfermagem",
	[OccupationType.NursingTechnician]: "Técnico de enfermagem",
};
