import { useEffect } from "react";
import { useFormik } from "formik";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { updatePasswordSchema } from "@/utils/validation/updatePasswordSchema";
import { resetPasswordInitialValues } from "@/utils/initialValues/resetPassword";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";

type Props = {
	showEditPasswordModal: boolean;
	handleCloseEditPasswordModal: () => void;
	isTheProfileView: boolean;
	user: User;
};

export function EditPasswordModal({
	showEditPasswordModal,
	handleCloseEditPasswordModal,
	isTheProfileView,
	user,
}: Props) {
	const { Toast, toastRequestErrors } = useSwal();
	const { api } = useApi();

	const formik = useFormik({
		initialValues: resetPasswordInitialValues,
		validationSchema: updatePasswordSchema,
		async onSubmit(values, { setSubmitting }) {
			try {
				await api.put(`/users/password/reset`, {
					user_id: user.id,
					...values,
				});

				Toast.fire({
					icon: "success",
					title: "Senha atualizada com sucesso",
				});

				handleCloseModal();
			} catch (error: any) {
				console.log(error);

				toastRequestErrors(error.response.data?.errors);

				setSubmitting(false);
			}
		},
	});

	const { values, errors } = formik;

	function handleCloseModal() {
		handleCloseEditPasswordModal();
		formik.resetForm();
	}

	useEffect(() => {
		if (isTheProfileView) {
			values.has_password = true;
		} else {
			values.has_password = false;
		}

		setTimeout(() => {
			formik.validateForm();
		});
	}, []);

	return (
		<Modal centered show={showEditPasswordModal} onHide={handleCloseModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Alterar senha</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<div className="d-flex flex-column gap-4">
						{isTheProfileView && (
							<FormattedFormControl
								id="current_password"
								type="password"
								label="Senha atual"
								labelClassName="fw-bold"
								isInvalid={!!errors.current_password}
								validationMessage={errors.current_password}
								{...formik.getFieldProps("current_password")}
							/>
						)}

						<FormattedFormControl
							id="new_password"
							type="password"
							label="Nova senha"
							labelClassName="fw-bold"
							isInvalid={!!errors.new_password}
							validationMessage={errors.new_password}
							{...formik.getFieldProps("new_password")}
						/>

						<FormattedFormControl
							id="new_password_confirmation"
							type="password"
							label="Confirme a nova senha"
							labelClassName="fw-bold"
							isInvalid={!!errors.new_password_confirmation}
							validationMessage={errors.new_password_confirmation}
							{...formik.getFieldProps("new_password_confirmation")}
						/>
					</div>

					<div className="d-flex justify-content-center gap-4 mt-8">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseEditPasswordModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							Atualizar
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
