import { Outlet } from "react-router-dom";
import classNames from "clsx";

import { useWindowDimensions } from "@/hooks/useWindowDimensions";

export function DashboardLayout() {
	const { width } = useWindowDimensions();

	return (
		<>
			<div className="header-brand position-fixed z-index-1 w-100 bg-white">
				<img alt="Logo do SAMU" src="/media/logos/samu-logo.png" height={70} />
			</div>

			<div className="bg-opaque-white mt-17">
				<div className={classNames("bg-samu h-190px", width <= 992 ? "pt-4" : "pt-9")}>
					<div className="container-fluid pb-4">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}
