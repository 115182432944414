import { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";

import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useApi } from "@/hooks/useApi";

type Props = {
	showOccurrenceDataModal: boolean;
	handleCloseOccurrenceDataModal: () => void;
	attendanceId: string;
};

export function OccurrenceDataModal({
	showOccurrenceDataModal,
	handleCloseOccurrenceDataModal,
	attendanceId,
}: Props) {
	const [attendanceData, setAttendanceData] = useState<Attendance>({} as Attendance);
	const { api } = useApi();

	const { radio_operation, latest_medical_regulation } = attendanceData;

	const dontHasAttendanceData =
		!latest_medical_regulation?.createdBy?.name &&
		!radio_operation?.creator?.name &&
		!radio_operation?.fleets?.length;

	async function handleSearchAttendanceData() {
		try {
			const { data } = await api.get(`/attendance/${attendanceId}`);

			setAttendanceData(data);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (attendanceId) {
			handleSearchAttendanceData();
		}
	}, [attendanceId]);

	return (
		<Modal size="lg" show={showOccurrenceDataModal} onHide={handleCloseOccurrenceDataModal}>
			<Modal.Header closeButton>
				<span className="btn-close invisible" />
				<h2 className="m-0">Dados da ocorrência</h2>
			</Modal.Header>

			<Modal.Body>
				<Row className="gap-4">
					<Col className="border-end border-end-dashed border-gray-500">
						<h2 className="mb-4">Chamado</h2>

						{latest_medical_regulation?.createdBy?.name && (
							<div>
								<span className="fw-bold">Médico regulador: </span>
								{latest_medical_regulation?.createdBy?.name}
							</div>
						)}

						{radio_operation?.creator?.name && (
							<div>
								<span className="fw-bold">Rádio operador: </span>
								{radio_operation?.creator?.name}
							</div>
						)}

						{radio_operation?.fleets?.map((fleet, index) => (
							<Fragment key={`fleet-${index}`}>
								<div>
									<span className="fw-bold">VTR: </span>
									{fleet.vehicle?.description}
								</div>

								<div>
									<span className="fw-bold">Equipe VTR: </span>

									{fleet.users?.map((user, index) => (
										<span key={`user-${index}`}>
											{user.name}
											{fleet.users?.length - 1 !== index && ", "}
										</span>
									))}
								</div>
							</Fragment>
						))}

						{dontHasAttendanceData && <div>Nenhum registro encontrado.</div>}
					</Col>

					<Col>
						<h2 className="mb-4">Horários</h2>

						{attendanceData.ticket?.opening_at && (
							<div>
								<span className="fw-bold">Chamado: </span>
								{dayjs(attendanceData.ticket?.opening_at).format(
									"DD/MM/YY [às] H:m"
								)}
							</div>
						)}

						{latest_medical_regulation?.createdBy?.name && (
							<div>
								<span className="fw-bold">Regulação médica: </span>
								{dayjs(latest_medical_regulation?.created_at).format(
									"DD/MM/YY [às] H:mm"
								)}
							</div>
						)}
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
}
