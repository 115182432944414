import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

import { FaPlus, FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { useDebounce } from "@/hooks/useDebounce";

import { NewMessageModal } from "@/components/Configurations/Messages/Modals/NewMessageModal";
import { SearchFormControl } from "@/components/FormControl/SearchFormControl";
import { PaginationLinks } from "@/components/PaginationLinks";
import { TableBodySkeleton } from "@/components/Skeletons/TableBodySkeleton";

export function Messages() {
	const [messages, setMessages] = useState<PaginatedMessage>({} as PaginatedMessage);
	const [showNewMessageModal, setShowNewMessageModal] = useState(false);
	const [message, setMessage] = useState<Message>({} as Message);
	const [skeleton, setSkeleton] = useState(true);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(1);

	const isFirstRender = useRef(true);
	const forcePage = useRef(1);
	const debouncedSearch = useDebounce(search, 500);
	const { Swal } = useSwal();
	const { api } = useApi();

	const { results } = messages;

	const handleShowNewMessageModal = (message?: Message) => {
		setShowNewMessageModal(true);

		setTimeout(() => {
			document.querySelector("#note-show-modal")?.parentElement?.removeAttribute("tabindex");
		});

		if (message) {
			setMessage(message);

			return;
		}

		setMessage({} as Message);
	};
	const handleCloseNewMessageModal = () => setShowNewMessageModal(false);

	async function fetchMessages(pageToUse?: number) {
		setSkeleton(true);

		try {
			const { data } = await api.get("/coordination-notes", {
				params: {
					page: pageToUse || page,
					search: debouncedSearch,
				},
			});

			setMessages(data);
			setSkeleton(false);
		} catch (error) {
			console.log(error);
			setSkeleton(false);
		}
	}

	async function handleRemoveMessage(messageId: string) {
		const { isConfirmed } = await Swal.fire({
			title: "Tem certeza que deseja deletar o recado?",
			icon: "warning",
			showCancelButton: true,
		});

		if (isConfirmed) {
			try {
				await api.delete(`/coordination-notes/${messageId}`);

				fetchMessages();
			} catch (error) {
				console.log(error);
			}
		}
	}

	function handleChangeSelectedPage(selected: number) {
		if (page !== forcePage.current) {
			fetchMessages(selected);
			forcePage.current = selected;

			return;
		}

		setPage(selected);
	}

	useEffect(() => {
		forcePage.current = page;
		fetchMessages();
	}, [page]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;

			return;
		}

		forcePage.current = 1;
		fetchMessages(1);
	}, [debouncedSearch]);

	return (
		<>
			<button
				className="d-flex align-items-center btn button-bg-white-color-samu gap-4 mb-6"
				onClick={() => handleShowNewMessageModal()}
			>
				<FaPlus /> Novo recado
			</button>

			<Card>
				<Card.Header className="d-flex justify-content-between align-items-center">
					<h3 className="fw-normal m-0">Lista de recados cadastrados</h3>

					<SearchFormControl
						placeholder="Pesquisar recado"
						onChange={(event) => setSearch(event.target.value)}
					/>
				</Card.Header>

				<Card.Body className="pt-0">
					<Table responsive className="table-row-dashed table-row-gray-300">
						<thead>
							<tr className="fw-bold fs-6">
								<th>Recado</th>
								<th>Data e hora</th>
								<th>Ações</th>
							</tr>
						</thead>

						<tbody>
							{skeleton ? (
								<TableBodySkeleton columns={3} />
							) : (
								<>
									{results?.map((message, index) => (
										<tr key={`message-${index}`}>
											<td
												className="align-middle"
												dangerouslySetInnerHTML={{ __html: message.note }}
											></td>
											<td className="align-middle">
												{dayjs(message.created_at).format(
													"DD/MM/YYYY [às] HH:mm"
												)}
											</td>
											<td className="align-middle w-1px">
												<div className="d-flex gap-2">
													<OverlayTrigger
														overlay={<Tooltip>Editar</Tooltip>}
													>
														<Button
															variant="secondary"
															className="btn-icon btn-sm"
															onClick={() =>
																handleShowNewMessageModal(message)
															}
														>
															<MdEdit className="text-gray-700" />
														</Button>
													</OverlayTrigger>

													<OverlayTrigger
														overlay={<Tooltip>Deletar</Tooltip>}
													>
														<Button
															variant="secondary"
															className="btn-icon btn-sm"
															onClick={() =>
																handleRemoveMessage(message.id)
															}
														>
															<FaTrash className="text-gray-700" />
														</Button>
													</OverlayTrigger>
												</div>
											</td>
										</tr>
									))}

									{!results?.length && (
										<tr>
											<td className="text-center" colSpan={12}>
												<h2 className="mt-12 mb-0">
													Não há recados cadastrados
												</h2>
											</td>
										</tr>
									)}
								</>
							)}
						</tbody>
					</Table>

					{!!results?.length && (
						<div className="d-flex justify-content-end mt-8">
							<PaginationLinks
								itemsPerPage={10}
								totalItems={messages.meta?.total}
								forcePage={forcePage.current - 1}
								changeSelectedPage={handleChangeSelectedPage}
							/>
						</div>
					)}
				</Card.Body>
			</Card>

			<NewMessageModal
				showNewMessageModal={showNewMessageModal}
				handleCloseNewMessageModal={handleCloseNewMessageModal}
				message={message}
				fetchMessages={fetchMessages}
			/>
		</>
	);
}
