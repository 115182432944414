import { ScheduleType } from "@/enums/ScheduleType";
import { Dayjs } from "dayjs";

export const scheduleInitialValues = {
	schedulable_id: "",
	schedulable_label: "",
	user_id: "",
	valid_from: null as Dayjs | null,
	valid_through: null as Dayjs | null,
	clock_in: "",
	clock_out: "",
	schedule_type_id: String(ScheduleType.Default),
	days_of_week: [] as string[],
	created_at: "",
};
