import { useState } from "react";
import dayjs from "dayjs";

import { MdEdit } from "react-icons/md";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { RoleMessages } from "@/enums/Role";
import { GenderMessages } from "@/enums/Gender";
import { formatPhone } from "@/utils/formatPhone";
import { StreetTypeMessages } from "@/enums/StreetType";
import { createSentence } from "@/utils/createSentence";
import { formatIdentifier } from "@/utils/formatIdentifier";

import { EditModal } from "@/components/Users/Modals/EditModal";
import { Separator } from "@/components/Separator";

type Props = {
	isTheProfileView?: boolean;
	user: User;
};

export function ViewForm({ isTheProfileView = false, user }: Props) {
	const [showEditUserModal, setShowEditUserModal] = useState(false);

	const handleShowEditUserModal = () => setShowEditUserModal(true);
	const handleCloseEditUserModal = () => setShowEditUserModal(false);

	return (
		<>
			<div className="d-flex flex-column gap-4">
				<div className="d-flex justify-content-between align-items-center">
					<h3>Dados Pessoais</h3>

					{isTheProfileView && (
						<button
							className="btn btn-icon btn-sm text-white bg-orange-with-hover"
							onClick={handleShowEditUserModal}
						>
							<MdEdit />
						</button>
					)}
				</div>

				<Row>
					<Col>
						<span className="fw-bold">Nome completo: </span>
						{user?.name}
					</Col>
					<Col>
						<span className="fw-bold">CNS: </span>
						{user?.national_health_card}
					</Col>

					<Col>
						<span className="fw-bold">Data de nascimento: </span>
						{dayjs(user?.birthdate).format("DD/MM/YYYY")}
					</Col>

					<Col>
						<span className="fw-bold">Sexo: </span>
						{GenderMessages[user?.gender_code as keyof typeof GenderMessages]}
					</Col>
				</Row>

				<Row>
					<Col>
						<span className="fw-bold">Email: </span>
						{user?.email}
					</Col>

					<Col>
						<span className="fw-bold">CPF: </span>
						{formatIdentifier(user?.identifier)}
					</Col>

					<Col>
						<span className="fw-bold">Telefone: </span>
						{formatPhone(user?.phone)}
					</Col>

					<Col>
						<span className="fw-bold">WhatsApp: </span>
						{formatPhone(user?.whatsapp)}
					</Col>
				</Row>
			</div>

			<Separator />

			<div className="d-flex flex-column gap-4">
				<h3>Endereço</h3>
				<Row>
					<Col lg={3}>
						<span className="fw-bold">UF: </span>
						{user?.city?.federal_unit?.uf}
					</Col>

					<Col lg={3}>
						<span className="fw-bold">Cidade: </span>
						{user?.city?.name}
					</Col>

					<Col>
						<span className="fw-bold">Bairro: </span>
						{user?.neighborhood}
					</Col>
				</Row>

				<Row>
					<Col lg={3}>
						<span className="fw-bold">Tipo de logradouro: </span>
						{
							StreetTypeMessages[
								Number(user?.street_type) as keyof typeof StreetTypeMessages
							]
						}
					</Col>

					<Col lg={3}>
						<span className="fw-bold">Logradouro: </span>
						{user?.street}
					</Col>

					<Col>
						<span className="fw-bold">Nº: </span>
						{user?.house_number}
					</Col>
				</Row>

				<div>
					<span className="fw-bold">Complemento: </span>
					{user?.complement}
				</div>
			</div>

			<Separator />

			<div className="d-flex flex-column gap-4">
				<h3>Credenciais</h3>
				<Row>
					<Col>
						<span className="fw-bold">Número conselho:</span> {user?.council_number}
					</Col>

					<Col>
						<span className="fw-bold">CBO: </span>
						{user?.cbo}
					</Col>
				</Row>

				{user?.urgency_regulation_centers?.map((urc, index) => (
					<Row key={`urc-${index}`}>
						<Col>
							<span className="fw-bold">
								{urc?.user_roles.length === 1 ? "Perfil: " : "Perfis: "}
							</span>

							{createSentence(
								urc?.user_roles?.map(
									(role) => RoleMessages[role?.name as keyof typeof RoleMessages]
								)
							)}
						</Col>

						<Col>
							<span className="fw-bold">Unidade: </span>
							{urc?.name}
						</Col>
					</Row>
				))}
			</div>

			<EditModal
				showEditUserModal={showEditUserModal}
				handleCloseEditUserModal={handleCloseEditUserModal}
				currentUser={user}
			/>
		</>
	);
}
