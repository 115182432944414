import { AntecedentType, AntecedentTypeMessages } from "@/enums/AntecedentType";

export const antecedentTypesOptions: ReactSelectOption[] = [
	{
		value: AntecedentType.HeartDisease,
		label: AntecedentTypeMessages[AntecedentType.HeartDisease],
	},
	{ value: AntecedentType.Diabetes, label: AntecedentTypeMessages[AntecedentType.Diabetes] },
	{ value: AntecedentType.Epilepsy, label: AntecedentTypeMessages[AntecedentType.Epilepsy] },
	{ value: AntecedentType.Alcoholism, label: AntecedentTypeMessages[AntecedentType.Alcoholism] },
	{
		value: AntecedentType.Hypertension,
		label: AntecedentTypeMessages[AntecedentType.Hypertension],
	},
	{ value: AntecedentType.HIV, label: AntecedentTypeMessages[AntecedentType.HIV] },
	{
		value: AntecedentType.Nephropathy,
		label: AntecedentTypeMessages[AntecedentType.Nephropathy],
	},
	{ value: AntecedentType.Neoplasm, label: AntecedentTypeMessages[AntecedentType.Neoplasm] },
	{
		value: AntecedentType.Pneumopathy,
		label: AntecedentTypeMessages[AntecedentType.Pneumopathy],
	},
	{
		value: AntecedentType.StrokeSequel,
		label: AntecedentTypeMessages[AntecedentType.StrokeSequel],
	},
];
