import { AttendanceType } from "@/enums/AttendanceType";

export const attendanceInitialValues = {
	opening_at: "",
	ticket_type_id: String(AttendanceType.PrimaryOccurrence),
	city_id: "",
	city_label: "",
	multiple_victims: false,
	number_of_victims: "1",
	street: "",
	house_number: "",
	neighborhood: "",
	reference_place: "",
	primary_complaint: "",
	observations: "",
	distance_type_id: "",
	location_type_id: "",
	location_type_description: "",
	requester: {
		name: "",
		primary_phone: "",
		secondary_phone: "",
		requester_type_id: "",
		council_number: "",
	},
	transfer_reason_id: "",
	in_central_bed: "",
	protocol: "",
	diagnostic_hypothesis: "",
	unit_origin_id: "",
	unit_origin_label: "",
	unit_destination_id: "",
	unit_destination_label: "",
	complement_origin: "",
	complement_destination: "",
	transfer_observation: "",
	requested_resource_id: "",
	description: "",
};
