import { useAuth } from "@/modules/auth";

type Props = {
	title?: string;
};

export function Header({ title }: Props) {
	const { currentAuth } = useAuth();

	const unitName = currentAuth?.current_urgency_regulation_center?.name;
	const formattedCityName = unitName?.slice(4).toUpperCase();

	return (
		<div className="d-flex justify-content-between align-items-center">
			<img
				src="/media/logos/government-logo.png"
				className="h-35px"
				alt="Imagem do governo do estado"
			/>

			<h6 className="text-wrap text-center">
				{title ?? `CENTRAL DE REGULAÇÃO DE ${formattedCityName}`}
			</h6>

			<img
				src="/media/logos/samu-print-logo.png"
				className="h-50px"
				alt="Imagem da logo do samu"
			/>
		</div>
	);
}
