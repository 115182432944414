import { Route, Routes } from "react-router-dom";

import { TotemDashboard } from "@/pages/Dashboards/TotemDashboard";
import { AdminDashboard } from "@/pages/Dashboards/AdminDashboard";

export function DashboardRoutes() {
	return (
		<Routes>
			<Route path="/" element={<TotemDashboard />} />
			<Route path="/admin" element={<AdminDashboard />} />
		</Routes>
	);
}
