import { useRef } from "react";

import { useApi } from "@/hooks/useApi";

import { Additional, ReactSelect, ReactSelectProps } from "../ReactSelect";

type UnitsSelectProps = ReactSelectProps & {
	onlyActive?: boolean;
};

export function UnitsSelect({ onlyActive = true, ...rest }: UnitsSelectProps) {
	const { api } = useApi();
	const abortControllerRef = useRef(new AbortController());

	async function fetchUnits(page?: number, search?: string) {
		try {
			abortControllerRef.current.abort();
			abortControllerRef.current = new AbortController();

			const { data } = await api.get("/units", {
				params: {
					page: page || 1,
					search,
					per_page: 10,
					is_active: Number(onlyActive),
				},
				signal: abortControllerRef.current.signal,
			});

			return data as PaginatedBaseOrUnit;
		} catch (error) {
			console.log(error);

			return {} as PaginatedBaseOrUnit;
		}
	}

	const loadAsyncUnitsOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, __: BaseOrUnit, { page }: Additional) {
			const units = await fetchUnits(page, search);

			return {
				options:
					units.results?.map((unit) => ({
						value: unit.id,
						label: unit.city?.name ? `${unit.city?.name} - ${unit.name}` : unit.name,
					})) || [],
				hasMore: units.meta?.current_page < units.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	return <ReactSelect {...loadAsyncUnitsOptionsProps} {...rest} />;
}
