export function deepEqual(firstObject: GenericObject, secondObject: GenericObject): boolean {
	const ok = Object.keys,
		typeOfFirstObject = typeof firstObject,
		typeOfSecondyObject = typeof secondObject;

	return Boolean(
		firstObject &&
			secondObject &&
			typeOfFirstObject === "object" &&
			typeOfFirstObject === typeOfSecondyObject
			? ok(firstObject).length === ok(secondObject).length &&
					ok(firstObject).every((key) => deepEqual(firstObject[key], secondObject[key]))
			: firstObject === secondObject
	);
}
