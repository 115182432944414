import { VehicleStatus } from "@/enums/VehicleStatus";
import Yup from "@/utils/mixins/yup";

export const changeVehicleStatusAndBaseSchema = Yup.object({
	vehicle_status_id: Yup.string().required("Campo obrigatório"),
	base_id: Yup.string().required("Campo obrigatório"),
	description: Yup.string().when("vehicle_status_id", {
		is: (status: string) => Number(status) === VehicleStatus.Unavailable,
		then: Yup.string().required("Campo obrigatório"),
	}),
});
