import classNames from "clsx";
import { HTMLAttributes } from "react";

export function Separator({ className }: HTMLAttributes<HTMLElement>) {
	return (
		<div className={classNames(className || "py-8")}>
			<div className="separator separator-dashed border-gray-500" />
		</div>
	);
}
