import * as Yup from "yup";
import { validateCPF } from "validations-br";

const cpf = () => {
	return Yup.string().test("cpf", "Informe um CPF válido", (value) => validateCPF(value || ""));
};

Yup.addMethod(Yup.string, "cpf", cpf);

declare module "yup" {
	interface StringSchema extends Yup.BaseSchema {
		cpf(): StringSchema;
	}
}

export default Yup;
