export enum CounterReferralReasonType {
	OutOfProfile = 1,
	BedUnavailable = 2,
	UnavailabilityOfNecessaryResources = 3,
	TheOnCallDoctorsRefusal = 4,
	Others = 5,
}

export const CounterReferralReasonTypeMessages = {
	[CounterReferralReasonType.OutOfProfile]: "Fora do perfil",
	[CounterReferralReasonType.BedUnavailable]: "Indisponibilidade de leitos",
	[CounterReferralReasonType.UnavailabilityOfNecessaryResources]:
		"Indisponibilidade de recursos necessários",
	[CounterReferralReasonType.TheOnCallDoctorsRefusal]: "Negativa do plantonista",
	[CounterReferralReasonType.Others]: "Outros",
};
