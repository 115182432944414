import Card from "react-bootstrap/Card";

import { PowerBIReports } from "@/components/Configurations/PowerBIReports";

export function PowerBIReportsView() {
	return (
		<Card>
			<Card.Body id="power-bi-reports" className="overflow-y-scroll max-h-610px pt-0">
				<PowerBIReports onlyShow />
			</Card.Body>
		</Card>
	);
}
