export enum VehicleMovementCode {
	Code1 = 1,
	Code2 = 2,
	Code3 = 3,
}

export const VehicleMovementCodeMessages = {
	[VehicleMovementCode.Code1]: "Código 1",
	[VehicleMovementCode.Code2]: "Código 2",
	[VehicleMovementCode.Code3]: "Código 3",
};
