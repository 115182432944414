import classNames from "clsx";

import { AttendanceStatusMessages } from "@/enums/AttendanceStatus";

import "./statusBadge.scss";

type Props = {
	statusId: number;
};

type KeyOfAttendanceStatusMessages = keyof typeof AttendanceStatusMessages;

export function StatusBadge({ statusId }: Props) {
	const AttendanceStatusMessage =
		AttendanceStatusMessages[statusId as KeyOfAttendanceStatusMessages];

	const statusBadeAttributes = [
		"badge-awaiting-medical-regulation",
		"badge-awaiting-vehicle",
		"badge-vehicle-sent",
		"badge-awaiting-vacancy",
		"badge-awaiting-conduct",
		"badge-conduct",
		"badge-completed",
		"badge-canceled",
		"badge-in-attendance",
		"badge-in-attendance",
		"badge-in-attendance",
		"badge-awaiting-return",
		"badge-linked",
	];

	return (
		<span
			className={classNames("badge rounded-pill fw-bold", statusBadeAttributes[statusId - 1])}
			dangerouslySetInnerHTML={{
				__html: AttendanceStatusMessage?.replace(" - r", "<br>R"),
			}}
		/>
	);
}
