export enum VehicleStatus {
	Active = 1,
	UnderMaintenance = 2,
	Unavailable = 3,
	Committed = 4,
	Solicited = 5,
	Inactive = 6,
}

export const VehicleStatusMessages = {
	[VehicleStatus.Active]: "Ativa",
	[VehicleStatus.UnderMaintenance]: "Em manutenção",
	[VehicleStatus.Unavailable]: "Indisponível",
	[VehicleStatus.Committed]: "Empenhada",
	[VehicleStatus.Solicited]: "Solicitada",
	[VehicleStatus.Inactive]: "Baixada",
};

export const unmodifiableVehicleStatuses = [VehicleStatus.Committed, VehicleStatus.Solicited];

export const TrackingVehicleImagesMessages = {
	[VehicleStatus.Active]: "active",
	[VehicleStatus.UnderMaintenance]: "under-maintenance",
	[VehicleStatus.Unavailable]: "unavailable",
	[VehicleStatus.Committed]: "committed",
	[VehicleStatus.Solicited]: "solicited",
	[VehicleStatus.Inactive]: "inactive",
};

export const VehicleStatusesTextColorsClasses = {
	[VehicleStatus.Active]: "text-vehicle-status-active",
	[VehicleStatus.UnderMaintenance]: "text-vehicle-status-under-maintenance",
	[VehicleStatus.Unavailable]: "text-vehicle-status-unavailable",
	[VehicleStatus.Committed]: "text-vehicle-status-committed",
	[VehicleStatus.Solicited]: "text-vehicle-status-solicited",
	[VehicleStatus.Inactive]: "text-vehicle-status-inactive",
};
