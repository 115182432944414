import { BleedingType, BleedingTypeMessages } from "@/enums/BleedingType";

export const bleedingTypesOptions: ReactSelectOption[] = [
	{
		label: BleedingTypeMessages[BleedingType.ExternalActive],
		value: BleedingType.ExternalActive,
	},
	{ label: BleedingTypeMessages[BleedingType.Controlled], value: BleedingType.Controlled },
	{
		label: BleedingTypeMessages[BleedingType.SuspectedInternalBleeding],
		value: BleedingType.SuspectedInternalBleeding,
	},
	{
		label: BleedingTypeMessages[BleedingType.NoSuggestiveSigns],
		value: BleedingType.NoSuggestiveSigns,
	},
];
