export enum Conduct {
	MedicalRegulator = 1,
	MedicalInterventional = 2,
	NursingTeam = 3,
}

export const ConductMessages = {
	[Conduct.MedicalRegulator]: "Médico regulador",
	[Conduct.MedicalInterventional]: "Médico intervencionista",
	[Conduct.NursingTeam]: "Equipe de enfermagem",
};
