// @ts-nocheck
import { useEffect } from "react";
import { useLayout } from "../../core";
import noUiSlider from "nouislider";
import classNames from "clsx";

import { DefaultTitle } from "./page-title/DefaultTitle";

const HeaderToolbar = () => {
	const { classes } = useLayout();

	useEffect(() => {
		const rangeSlider = document.querySelector("#kt_toolbar_slider");
		const rangeSliderValueElement = document.querySelector("#kt_toolbar_slider_value");

		if (!rangeSlider || !rangeSliderValueElement) {
			return;
		}

		noUiSlider.create(rangeSlider, {
			start: [5],
			connect: [true, false],
			step: 1,
			format: {
				to: function (value) {
					const val = +value;
					return Math.round(val).toString();
				},
				from: function (value) {
					return value;
				},
			},
			range: {
				min: [1],
				max: [10],
			},
		});

		rangeSlider.noUiSlider.on("update", function (values, handle) {
			rangeSliderValueElement.innerHTML = values[handle];
		});

		const handle = rangeSlider.querySelector(".noUi-handle");
		if (handle) {
			handle.setAttribute("tabindex", "0");
		}

		handle.addEventListener("click", function () {
			this.focus();
		});

		handle.addEventListener("keydown", function (event) {
			const value = Number(rangeSlider.noUiSlider.get());

			switch (event.which) {
				case 37:
					rangeSlider.noUiSlider.set(value - 1);
					break;
				case 39:
					rangeSlider.noUiSlider.set(value + 1);
					break;
			}
		});
		return () => {
			rangeSlider.noUiSlider.destroy();
		};
	}, []);

	return (
		<div className="toolbar d-flex align-items-stretch">
			<div
				className={classNames(
					"py-6 py-lg-0 d-flex flex-column flex-lg-row align-items-lg-stretch justify-content-lg-between bg-samu",
					classes.headerContainer
				)}
			>
				<DefaultTitle />
			</div>
		</div>
	);
};

export { HeaderToolbar };
