import dayjs from "dayjs";

import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { PeriodTypeMessages } from "@/enums/PeriodType";
import { createSentence } from "@/utils/createSentence";
import { DutyReportType, DutyReportTypeMessages } from "@/enums/DutyReportType";

type Props = {
	dutyReport: DutyReport;
	showDutyReportViewModal: boolean;
	handleCloseDutyReportViewModal: () => void;
};

type KeyOfPeriodTypeMessages = keyof typeof PeriodTypeMessages;
type KeyOfDutyReportTypeMessages = keyof typeof DutyReportTypeMessages;

export function DutyReportViewModal({
	dutyReport,
	showDutyReportViewModal,
	handleCloseDutyReportViewModal,
}: Props) {
	const isFleetManager = dutyReport?.duty_report_type_id === DutyReportType.fleetManager;

	const dutyReportTypeMessage =
		DutyReportTypeMessages[dutyReport?.duty_report_type_id as KeyOfDutyReportTypeMessages];

	const periodTypeMessage =
		PeriodTypeMessages[dutyReport?.period_type_id as KeyOfPeriodTypeMessages];

	return (
		<Modal size="xl" show={showDutyReportViewModal} onHide={handleCloseDutyReportViewModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Relatório de plantão - visualização</h2>
			</Modal.Header>

			<Modal.Body className="d-flex flex-column gap-3">
				<Row>
					<Col lg={3}>
						<span className="fw-bold">Última alteração: </span>
						{dayjs(dutyReport?.updated_at).format("DD/MM/YYYY [às] HH:mm")}
					</Col>

					<Col>
						<span className="fw-bold">Tipo: </span>
						{dutyReportTypeMessage}
					</Col>
				</Row>

				<Row>
					<Col lg={3}>
						<span className="fw-bold">Data do registro: </span>
						{dayjs(dutyReport?.created_at).format("DD/MM/YYYY")}
					</Col>

					<Col lg={2}>
						<span className="fw-bold">Turno: </span>
						{periodTypeMessage}
					</Col>

					<Col>
						<span className="fw-bold">Profissional: </span>
						{dutyReport?.creator?.name}
					</Col>
				</Row>

				<Col lg={7}>
					<span className="fw-bold">Médicos reguladores: </span>

					<div className="border border-2 rounded overflow-y-scroll max-h-100px p-3 mt-2">
						{dutyReport?.medical_regulators?.map((medicalRegulator, index) => {
							return (
								<div className="fw-normal" key={`medical-regulator-${index}`}>
									{`${medicalRegulator.name} - ${createSentence(
										medicalRegulator.urgency_regulation_centers?.map(
											(urc) => urc.name
										) || []
									)}`}
								</div>
							);
						})}
					</div>
				</Col>

				<Col lg={7}>
					<span className="fw-bold">
						{isFleetManager ? "Operador" : "Gerente"} de frota:{" "}
					</span>

					<div className="border border-2 rounded overflow-y-scroll max-h-100px p-3 mt-2">
						{dutyReport?.radio_operators?.map((radioOperator, index) => {
							return (
								<div className="fw-normal" key={`radio-operator-${index}`}>
									{`${radioOperator.name} - ${createSentence(
										radioOperator.urgency_regulation_centers?.map(
											(urc) => urc.name
										) || []
									)}`}
								</div>
							);
						})}
					</div>
				</Col>

				{isFleetManager && (
					<Col lg={7}>
						<span className="fw-bold">TARM: </span>

						<div className="border border-2 rounded overflow-y-scroll max-h-100px p-3 mt-2">
							{dutyReport?.tarms?.map((tarm, index) => {
								return (
									<div className="fw-normal" key={`tarm-${index}`}>
										{`${tarm.name} - ${createSentence(
											tarm.urgency_regulation_centers?.map(
												(urc) => urc.name
											) || []
										)}`}
									</div>
								);
							})}
						</div>
					</Col>
				)}

				<Col>
					<span className="fw-bold">Intercorrências internas: </span>
					<div className="border border-2 rounded overflow-y-scroll max-h-100px p-3 mt-2">
						{dutyReport?.internal_complications}
					</div>
				</Col>

				<Col>
					<span className="fw-bold">Intercorrências externas: </span>
					<div className="border border-2 rounded overflow-y-scroll max-h-100px p-3 mt-2">
						{dutyReport?.external_complications}
					</div>
				</Col>

				<Col>
					<span className="fw-bold">Elogios: </span>
					<div className="border border-2 rounded overflow-y-scroll max-h-100px p-3 mt-2">
						{dutyReport?.compliments}
					</div>
				</Col>

				<Col>
					<span className="fw-bold">Eventos: </span>
					<div className="border border-2 rounded overflow-y-scroll max-h-100px p-3 mt-2">
						{dutyReport?.events}
					</div>
				</Col>

				<div className="mt-9 d-flex justify-content-center">
					<button
						className="btn button-bg-light-color-gray-400"
						onClick={handleCloseDutyReportViewModal}
					>
						Fechar
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
