import { useState } from "react";
import dayjs from "dayjs";

import { EditPasswordModal } from "@/components/Users/Modals/EditPasswordModal";

type Props = {
	isTheProfileView?: boolean;
	user: User;
};

export function ViewPassword({ isTheProfileView = false, user }: Props) {
	const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);

	const handleShowEditPasswordModal = () => setShowEditPasswordModal(true);
	const handleCloseEditPasswordModal = () => setShowEditPasswordModal(false);

	return (
		<div className="d-flex flex-column gap-4">
			<h3>Login</h3>

			<div className="d-flex align-items-center gap-20">
				<div>
					<span className="fw-bold">Senha: ************ </span>

					<span>
						{user?.last_password_history && (
							<>
								Última atualização da senha:{" "}
								{dayjs(user?.last_password_history?.created_at).format(
									"DD [de] MMMM"
								)}
							</>
						)}
					</span>
				</div>

				<button
					className="btn button-bg-orange-color-white py-2"
					onClick={handleShowEditPasswordModal}
				>
					Atualizar
				</button>
			</div>

			<EditPasswordModal
				showEditPasswordModal={showEditPasswordModal}
				handleCloseEditPasswordModal={handleCloseEditPasswordModal}
				isTheProfileView={isTheProfileView}
				user={user}
			/>
		</div>
	);
}
