import Modal from "react-bootstrap/Modal";

import { HistoryRegister } from "@/components/HistoryRegister";

type Props = {
	showHistoryRegisterModal: boolean;
	handleCloseHistoryRegisterModal: () => void;
	attendanceId: string;
};

export function HistoryRegisterModal({
	showHistoryRegisterModal,
	handleCloseHistoryRegisterModal,
	attendanceId,
}: Props) {
	return (
		<Modal size="xl" show={showHistoryRegisterModal} onHide={handleCloseHistoryRegisterModal}>
			<Modal.Header closeButton>
				<span className="btn-close invisible" />
				<h2 className="m-0">Histórico de registros</h2>
			</Modal.Header>

			<Modal.Body id="history-register-modal-body" className="overflow-y-scroll h-80vh">
				<HistoryRegister attendanceId={attendanceId} />
			</Modal.Body>
		</Modal>
	);
}
