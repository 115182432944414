import { AppliedMessages, RecommendedMessages } from "@/enums/UseOfThrombolytics";

type Param = string | number;

export function indicatedThrombolyticsMessage(recommended: Param, applied: Param) {
	const recommendedMessage =
		RecommendedMessages[Number(recommended) as keyof typeof RecommendedMessages];

	const appliedMessage = applied
		? `e ${AppliedMessages[Number(applied) as keyof typeof AppliedMessages]}`
		: "";

	return `${recommendedMessage} ${appliedMessage}`;
}
