import Yup from "@/utils/mixins/yup";

import { UserStatus } from "@/enums/UserStatus";

export const changeUserStatusSchema = Yup.object().shape({
	reason: Yup.string()
		.max(1000, "O campo deve conter no máximo 1000 caracteres")
		.when("status_id", {
			is: UserStatus.Inactive,
			then: Yup.string().required("Campo obrigatório"),
			otherwise: Yup.string().nullable(),
		}),
});
