import { useEffect } from "react";
import { useFormik } from "formik";

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { RoleMessages } from "@/enums/Role";
import { powerBIReportInitialValues } from "@/utils/initialValues/powerBIReport";
import { powerBIReportValidationSchema } from "@/utils/validation/powerBIReportSchema";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { RolesSelect } from "@/components/Selects/RolesSelect";
import { RedAsterisk } from "@/components/RedAsterisk";

type Props = {
	showPowerBIReportsModal: boolean;
	handleClosePowerBIReportsModal: () => void;
	fetchPaginatedPowerBIReports: () => void;
	currentPowerBIReport?: PowerBIReport;
};

export function PowerBIReportModal({
	showPowerBIReportsModal,
	handleClosePowerBIReportsModal,
	fetchPaginatedPowerBIReports,
	currentPowerBIReport,
}: Props) {
	const { Toast, toastRequestErrors } = useSwal();
	const { api } = useApi();

	const isEdit = currentPowerBIReport && Object.entries(currentPowerBIReport).length;

	const formik = useFormik({
		initialValues: powerBIReportInitialValues,
		validationSchema: powerBIReportValidationSchema,
		async onSubmit(values, { setSubmitting }) {
			try {
				const method = isEdit ? "put" : "post";
				const route = isEdit
					? `/power-bi-report/${currentPowerBIReport?.id}`
					: "/power-bi-report";

				await api[method](route, {
					...values,
					roles: values.roles.map((role) => role.value),
				});

				handleCloseModal();
				fetchPaginatedPowerBIReports();

				Toast.fire({
					icon: "success",
					title: `Relatório ${isEdit ? "atualizado" : "cadastrado"} com sucesso!`,
				});
			} catch (error: any) {
				console.log(error);

				toastRequestErrors(error.response.data?.errors);

				setSubmitting(false);
			}
		},
	});

	function handleCloseModal() {
		formik.resetForm();

		handleClosePowerBIReportsModal();
	}

	const { errors, values } = formik;

	useEffect(() => {
		if (isEdit) {
			const rolesSelected = currentPowerBIReport?.roles?.map((role) => ({
				label: RoleMessages[role.name as keyof typeof RoleMessages],
				value: role.id,
			}));

			const formattedCurrentPowerBIReport = {
				...currentPowerBIReport,
				roles: rolesSelected,
			};

			formik.setValues(formattedCurrentPowerBIReport);
		}
	}, [currentPowerBIReport]);

	useEffect(() => {
		if (showPowerBIReportsModal) {
			formik.validateForm();
		}
	}, [values.name, values.url, values.roles, showPowerBIReportsModal]);

	return (
		<Modal size="lg" show={showPowerBIReportsModal} onHide={handleCloseModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">{isEdit ? "Editar" : "Novo"} relatório</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-4">
					<FormattedFormControl
						id="name"
						label="Nome"
						labelClassName="fw-bold"
						isInvalid={!!errors.name}
						{...formik.getFieldProps("name")}
						validationMessage={errors.name}
						isRequired
					/>

					<FormattedFormControl
						id="description"
						label="Descrição"
						labelClassName="fw-bold"
						{...formik.getFieldProps("description")}
					/>

					<FormattedFormControl
						id="url"
						label="URL"
						labelClassName="fw-bold"
						isInvalid={!!errors.url}
						{...formik.getFieldProps("url")}
						validationMessage={errors.url}
						isRequired
					/>

					<div>
						<Form.Label htmlFor="roles" className="fw-bold">
							Perfil com acesso <RedAsterisk />
						</Form.Label>

						<RolesSelect
							inputId="roles"
							isInvalid={!!errors.roles}
							value={values.roles}
							placeholder="Selecione os perfis com acesso"
							onChange={(options) => formik.setFieldValue("roles", options)}
						/>

						{errors.roles && (
							<span className="text-danger">{errors.roles as string[]}</span>
						)}
					</div>

					<div className="d-flex justify-content-center gap-4 mt-5">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : isEdit ? (
								"Salvar"
							) : (
								"Cadastrar"
							)}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
