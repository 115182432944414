import { SkinColorationType, SkinColorationTypeMessages } from "@/enums/SkinColorationType";

export const skinColorationTypesOptions: ReactSelectOption[] = [
	{
		value: SkinColorationType.Cyanosis,
		label: SkinColorationTypeMessages[SkinColorationType.Cyanosis],
	},
	{
		value: SkinColorationType.Normal,
		label: SkinColorationTypeMessages[SkinColorationType.Normal],
	},
	{
		value: SkinColorationType.Pallor,
		label: SkinColorationTypeMessages[SkinColorationType.Pallor],
	},
];
