type Props = {
	aeromedicals: OutpatientProductionBulletinVehicle[];
};

export function AeromedicalRows({ aeromedicals }: Props) {
	return (
		<>
			{aeromedicals?.map((aeromedical, index) => (
				<tr key={`aeromedical-${index}`}>
					<td>{index + 1}</td>
					<td>{aeromedical.name}</td>
					<td>{aeromedical.national_health_registration}</td>
					<td>0301030049</td>
					<td className="text-center">{aeromedical.primary_attendance_count ?? 0}</td>
					<td className="text-center">{aeromedical.secondary_attendance_count ?? 0}</td>
					<td className="text-center">{aeromedical.total_attendance_count}</td>
				</tr>
			))}
		</>
	);
}
