import { NatureType, NatureTypeMessages } from "@/enums/NatureType";

export const natureTypesOptions: ReactSelectOption[] = [
	{ value: NatureType.Trauma, label: NatureTypeMessages[NatureType.Trauma] },
	{ value: NatureType.Clinical, label: NatureTypeMessages[NatureType.Clinical] },
	{ value: NatureType.GynecoObstetric, label: NatureTypeMessages[NatureType.GynecoObstetric] },
	{ value: NatureType.Pediatric, label: NatureTypeMessages[NatureType.Pediatric] },
	{ value: NatureType.Psychiatric, label: NatureTypeMessages[NatureType.Psychiatric] },
	{ value: NatureType.Neonatal, label: NatureTypeMessages[NatureType.Neonatal] },
	{ value: NatureType.Event, label: NatureTypeMessages[NatureType.Event] },
];
