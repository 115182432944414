import dayjs from "dayjs";

import Yup from "@/utils/mixins/yup";
import { NarrativeCertificate } from "@/enums/narrativeCertificate";

export const narrativeCertificateSchema = Yup.object().shape({
	date: Yup.date().when("default_or_free", {
		is: NarrativeCertificate.Default,
		then: Yup.date().test(
			"is-before-now",
			"A data e hora não podem ser superiores ao horário atual",
			(value) => {
				if (!value) {
					return true;
				}

				return dayjs(value).isBefore(dayjs());
			}
		),
	}),
});
