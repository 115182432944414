import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { FaPlay } from "react-icons/fa";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { usePusher } from "@/hooks/usePusher";
import { useDebounce } from "@/hooks/useDebounce";
import { AttendanceType } from "@/enums/AttendanceType";

import { AttendanceCard } from "@/components/FleetControl/RadioOperation/AttendanceCard";

type RefreshedRadioOperation = {
	results: {
		action: "created" | "updated";
		radio_operation: Omit<RadioOperationResponse, "notes" | "fleets"> & {
			attendance: Attendance;
		};
	};
};

export function RadioOperation() {
	const { api } = useApi();

	// awaiting vehicles

	const [attendancesAwaitingVehicles, setAttendancesAwaitingVehicles] =
		useState<PaginatedAttendance>({} as PaginatedAttendance);
	const [isLoadingAttendancesAwaitingVehicles, setIsLoadingAttendancesAwaitingVehicles] =
		useState(true);
	const [searchAttendancesAwaitingVehicles, setSearchAttendancesAwaitingVehicles] = useState("");
	const debouncedAttendancesAwaitingVehiclesSearch = useDebounce(
		searchAttendancesAwaitingVehicles,
		500
	);

	// primary attendances vehicles sent

	const [primaryAttendancesVehiclesSent, setPrimaryAttendancesVehiclesSent] =
		useState<PaginatedAttendance>({} as PaginatedAttendance);
	const [isLoadingPrimaryAttendancesVehiclesSent, setIsLoadingPrimaryAttendancesVehiclesSent] =
		useState(true);
	const [searchPrimaryAttendancesVehiclesSent, setSearchPrimaryAttendancesVehiclesSent] =
		useState("");
	const debouncedPrimaryAttendancesVehiclesSentSearch = useDebounce(
		searchPrimaryAttendancesVehiclesSent,
		500
	);

	// secondary attendances vehicles sent

	const [secondaryAttendancesVehiclesSent, setSecondaryAttendancesVehiclesSent] =
		useState<PaginatedAttendance>({} as PaginatedAttendance);
	const [
		isLoadingSecondaryAttendancesVehiclesSent,
		setIsLoadingSecondaryAttendancesVehiclesSent,
	] = useState(true);
	const [searchSecondaryAttendancesVehiclesSent, setSearchSecondaryAttendancesVehiclesSent] =
		useState("");
	const debouncedSecondaryAttendancesVehiclesSentSearch = useDebounce(
		searchSecondaryAttendancesVehiclesSent,
		500
	);

	// awaiting vehicles

	async function fetchAttendancesAwaitingVehicles(increasePage = false) {
		try {
			const page = attendancesAwaitingVehicles.meta?.current_page || 1;

			const { data } = await api.get("/radio-operation", {
				params: {
					page: increasePage ? page + 1 : 1,
					...(debouncedAttendancesAwaitingVehiclesSearch && {
						search: debouncedAttendancesAwaitingVehiclesSearch,
					}),
					filter_by_awaiting_vehicles: 1,
				},
			});

			return data as PaginatedAttendance;
		} catch (error) {
			console.log(error);

			return {} as PaginatedAttendance;
		}
	}

	async function loadMoreAttendancesAwaitingVehicles() {
		const newAttendances = await fetchAttendancesAwaitingVehicles(true);

		setAttendancesAwaitingVehicles((prevState) => ({
			...newAttendances,
			results: [...prevState.results, ...newAttendances.results],
		}));
	}

	async function handleSearchAttendancesAwaitingVehicles() {
		try {
			setIsLoadingAttendancesAwaitingVehicles(true);

			const attendances = await fetchAttendancesAwaitingVehicles();

			setAttendancesAwaitingVehicles(attendances);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingAttendancesAwaitingVehicles(false);
		}
	}

	useEffect(() => {
		handleSearchAttendancesAwaitingVehicles();
	}, [debouncedAttendancesAwaitingVehiclesSearch]);

	// vehicles sent

	async function fetchAttendancesVehiclesSent(ticketTypeId: number, increasePage = false) {
		try {
			const ticketTypeState =
				ticketTypeId === AttendanceType.PrimaryOccurrence
					? primaryAttendancesVehiclesSent
					: secondaryAttendancesVehiclesSent;

			const debouncedSearch =
				ticketTypeId === AttendanceType.PrimaryOccurrence
					? debouncedPrimaryAttendancesVehiclesSentSearch
					: debouncedSecondaryAttendancesVehiclesSentSearch;

			const page = ticketTypeState.meta?.current_page || 1;

			const { data } = await api.get("/radio-operation", {
				params: {
					page: increasePage ? page + 1 : page,
					...(debouncedSearch && {
						search: debouncedSearch,
					}),
					filter_by_vehicles_sent: 1,
					ticket_type_id: ticketTypeId,
				},
			});

			return data as PaginatedAttendance;
		} catch (error) {
			console.log(error);

			return {} as PaginatedAttendance;
		}
	}

	// primary attendances vehicles sent

	async function loadMorePrimaryAttendancesVehiclesSent() {
		const newPrimaryAttendances = await fetchAttendancesVehiclesSent(
			AttendanceType.PrimaryOccurrence,
			true
		);

		setPrimaryAttendancesVehiclesSent((prevState) => ({
			...newPrimaryAttendances,
			results: [...prevState.results, ...newPrimaryAttendances.results],
		}));
	}

	async function handleSearchPrimaryAttendancesVehiclesSent() {
		try {
			setIsLoadingPrimaryAttendancesVehiclesSent(true);

			const attendances = await fetchAttendancesVehiclesSent(
				AttendanceType.PrimaryOccurrence
			);

			setPrimaryAttendancesVehiclesSent(attendances);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingPrimaryAttendancesVehiclesSent(false);
		}
	}

	useEffect(() => {
		handleSearchPrimaryAttendancesVehiclesSent();
	}, [debouncedPrimaryAttendancesVehiclesSentSearch]);

	// secondary attendances vehicles sent

	async function loadMoreSecondaryAttendancesVehiclesSent() {
		const newSecondaryAttendances = await fetchAttendancesVehiclesSent(
			AttendanceType.SecondaryOccurrence,
			true
		);

		setSecondaryAttendancesVehiclesSent((prevState) => ({
			...newSecondaryAttendances,
			results: [...prevState.results, ...newSecondaryAttendances.results],
		}));
	}

	async function handleSearchSecondaryAttendancesVehiclesSent() {
		try {
			setIsLoadingSecondaryAttendancesVehiclesSent(true);

			const attendances = await fetchAttendancesVehiclesSent(
				AttendanceType.SecondaryOccurrence
			);

			setSecondaryAttendancesVehiclesSent(attendances);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingSecondaryAttendancesVehiclesSent(false);
		}
	}

	useEffect(() => {
		handleSearchSecondaryAttendancesVehiclesSent();
	}, [debouncedSecondaryAttendancesVehiclesSentSearch]);

	// pusher events

	const pusher = usePusher();
	const { auth, currentAuth } = useAuth();
	const currentURCId = String(currentAuth?.current_urgency_regulation_center.id);

	function handleRefreshRadioOperationAttendance(urcId: string) {
		const channelName = `private-radio-operation.refresh.${urcId}`;
		const eventName = "RefreshRadioOperationAttendance";

		const channel = pusher.subscribe(channelName);

		channel.bind(eventName, ({ results }: RefreshedRadioOperation) => {
			if (!results.radio_operation) {
				handleSearchAttendancesAwaitingVehicles();
				return;
			}

			handleSearchAttendancesAwaitingVehicles();
			handleSearchPrimaryAttendancesVehiclesSent();
			handleSearchSecondaryAttendancesVehiclesSent();
		});
	}

	useEffect(() => {
		if (currentURCId && auth) {
			handleRefreshRadioOperationAttendance(currentURCId);
		}

		return () => {
			pusher.unsubscribe(`private-radio-operation.refresh.${currentURCId}`);
		};
	}, [
		attendancesAwaitingVehicles,
		primaryAttendancesVehiclesSent,
		secondaryAttendancesVehiclesSent,
		currentURCId,
		auth,
	]);

	return (
		<>
			<div className="w-fit-content">
				<Link to="/ocorrencias/nova-ocorrencia">
					<button className="d-flex align-items-center btn button-bg-white-color-samu gap-4 mb-6">
						<FaPlay /> Nova ocorrência
					</button>
				</Link>
			</div>

			<AttendanceCard
				title="Aguardando empenho de viatura"
				isLoading={isLoadingAttendancesAwaitingVehicles}
				attendances={attendancesAwaitingVehicles}
				next={loadMoreAttendancesAwaitingVehicles}
				onSearch={setSearchAttendancesAwaitingVehicles}
				scrollableTarget="attendances-awaiting-vehicles-table"
				isTheAwaitingVehiclesTable
				fetchAttendances={handleSearchAttendancesAwaitingVehicles}
			/>

			<AttendanceCard
				title="Ocorrência primária"
				isLoading={isLoadingPrimaryAttendancesVehiclesSent}
				attendances={primaryAttendancesVehiclesSent}
				next={loadMorePrimaryAttendancesVehiclesSent}
				onSearch={setSearchPrimaryAttendancesVehiclesSent}
				scrollableTarget="primary-attendances-sent-vehicles-table"
				fetchAttendances={handleSearchPrimaryAttendancesVehiclesSent}
			/>

			<AttendanceCard
				title="Ocorrência secundária"
				isLoading={isLoadingSecondaryAttendancesVehiclesSent}
				attendances={secondaryAttendancesVehiclesSent}
				next={loadMoreSecondaryAttendancesVehiclesSent}
				onSearch={setSearchSecondaryAttendancesVehiclesSent}
				scrollableTarget="secondary-attendances-sent-vehicles-table"
				fetchAttendances={handleSearchSecondaryAttendancesVehiclesSent}
			/>
		</>
	);
}
