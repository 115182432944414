export const vehiclesInitialValues = {
	code: "",
	license_plate: "",
	base_id: "",
	base_label: "",
	chassis: "",
	general_availability: false,
	tracking_device_imei: "",
	tracking_system_id: "",
};
