import dayjs from "dayjs";
import { FormControlProps } from "react-bootstrap";
import isBetween from "dayjs/plugin/isBetween";
import duration from "dayjs/plugin/duration";
import { ApexOptions } from "apexcharts";

import Card from "react-bootstrap/Card";
import Chart from "react-apexcharts";

import { PercentageBadge } from "@/components/Badges/PercentageBadge";

dayjs.extend(isBetween);
dayjs.extend(duration);

type Props = FormControlProps & {
	title: string;
	number: number;
	data?: string[];
	period: "current" | "last";
	change: string;
	handleChangePeriodChartFunction: () => void;
	cardBodyClassName?: string;
};

export function ChartCard({
	id,
	title,
	number = 0,
	data,
	period,
	change,
	handleChangePeriodChartFunction,
	cardBodyClassName,
	className,
}: Props) {
	const periods = [
		...Array.from({ length: 13 }, (_, index) => {
			const currentPeriod = dayjs().subtract(index, "hour").set("minute", 0).set("second", 0);
			const lastPeriod = dayjs()
				.subtract(12, "hour")
				.subtract(index, "hour")
				.set("minute", 0)
				.set("second", 0);

			if (period === "current") {
				return currentPeriod;
			}

			return lastPeriod;
		}),
	].reverse();

	const chartOptions: ApexOptions = {
		chart: {
			id,
			type: "line",
			zoom: {
				enabled: false,
			},
		},
		yaxis: {
			labels: {
				formatter: function (val) {
					return val?.toFixed();
				},
			},
		},
	};

	const groupedData = periods.map((period) => {
		const periodStart = dayjs(period);
		const periodEnd = periodStart.add(1, "hour");

		return {
			y: data?.filter((period) => {
				return dayjs(period).isBetween(periodStart, periodEnd, "minute", "[)");
			}).length,
			x: periodStart.format("HH:mm"),
		};
	});

	const series = [
		{
			name: "Atendimentos",
			data: groupedData,
		},
	];

	return (
		<Card className={className}>
			<Card.Body className={cardBodyClassName}>
				<div className="d-flex flex-stack">
					<div className="d-flex flex-column w-fit-content">
						<h1 className="d-flex align-items-center fs-3rem gap-4 mb-0">
							{number}
							<PercentageBadge percentage={change} />
						</h1>
						<Card.Subtitle>{title}</Card.Subtitle>
					</div>

					<button
						className="btn btn-sm button-bg-light-color-gray-400"
						onClick={() => handleChangePeriodChartFunction()}
					>
						{period === "current" ? "-12" : "+12"}
					</button>
				</div>

				<Chart height={150} options={chartOptions} series={series} />
			</Card.Body>
		</Card>
	);
}
