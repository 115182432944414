import { FC } from "react";

import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

import { TotemDashboard } from "@/pages/Dashboards/TotemDashboard";
import { DashboardLayout } from "@/layout/DashboardLayout";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { AuthorizeLogin } from "@/pages/AuthorizeLogin";
import { AuthPage, useAuth } from "../modules/auth";
import { PrivateRoutes } from "./PrivateRoutes";
import { App } from "../App";

const AppRoutes: FC = () => {
	const { currentAuth } = useAuth();

	return (
		<BrowserRouter>
			<Routes>
				<Route element={<App />}>
					<Route path="error/*" element={<ErrorsPage />} />
					<Route path="authorize-login" element={<AuthorizeLogin />} />
					{currentAuth ? (
						<Route path="/*" element={<PrivateRoutes />} />
					) : (
						<>
							<Route path="login/*" element={<AuthPage />} />
							<Route element={<DashboardLayout />}>
								<Route path="dashboard" element={<TotemDashboard />} />
							</Route>
							<Route path="*" element={<Navigate to="/login" />} />
						</>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export { AppRoutes };
