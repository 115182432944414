import { Fragment } from "react";

import { Separator } from "@/components/Separator";

type Props = {
	diagnosticHypotheses: string[];
	diagnosticHypothesis: string;
	primaryComplaint: string;
};

export function DiagnosticHypothesesTooltipContent({
	diagnosticHypotheses,
	diagnosticHypothesis,
	primaryComplaint,
}: Props) {
	return (
		<>
			{Boolean(diagnosticHypotheses?.length)
				? diagnosticHypotheses?.map((diagnosticHypothesis, index) => {
						const latestDiagnosticHypothesis =
							index === diagnosticHypotheses?.length - 1;

						const showSeparator =
							diagnosticHypotheses?.length > 1 && !latestDiagnosticHypothesis;

						return (
							<Fragment key={`diagnostic-hypothesis-${index}`}>
								{diagnosticHypothesis}

								{showSeparator && <Separator className="py-2" />}
							</Fragment>
						);
				  })
				: diagnosticHypothesis || primaryComplaint}
		</>
	);
}
