import Modal from "react-bootstrap/Modal";

import { useAuth } from "@/modules/auth";

import { ViewPassword } from "@/components/Users/Forms/ViewPassword";
import { ViewForm } from "@/components/Users/Forms/ViewForm";
import { Separator } from "@/components/Separator";

type Props = {
	showViewUserModal: boolean;
	handleCloseViewUserModal: () => void;
	user: User;
};

export function ViewModal({ showViewUserModal, handleCloseViewUserModal, user }: Props) {
	const { currentAuth } = useAuth();

	return (
		<Modal size="xl" show={showViewUserModal} onHide={handleCloseViewUserModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Visualização do perfil</h2>
			</Modal.Header>

			<Modal.Body>
				<ViewForm user={user} />

				<Separator />

				<ViewPassword user={user} isTheProfileView={user.id === currentAuth?.id} />

				<div className="text-center mt-8">
					<button
						className="btn button-bg-light-color-gray-400"
						onClick={handleCloseViewUserModal}
					>
						Fechar
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
