import Modal from "react-bootstrap/Modal";

import { useCanAccess } from "@/hooks/useCanAccess";
import { Role } from "@/enums/Role";

import { GoogleMapsAccessHistory } from "@/components/Configurations/AccessHistory/Maps/GoogleMaps";
import { LocationAccessMap } from "@/components/Configurations/AccessHistory/Maps/LocationAccessMap";

type Props = {
	show: boolean;
	setShow: (show: boolean) => void;
	accessHistory: AccessHistory;
	setAccessHistory: (accessHistory: AccessHistory) => void;
	hasLocation: string;
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export function AccessHistoryModal({
	show,
	setShow,
	accessHistory,
	setAccessHistory,
	hasLocation,
}: Props) {
	const { can } = useCanAccess();

	const { manager } = Role;

	return (
		<Modal
			show={show}
			onHide={() => {
				setShow(false);
				setAccessHistory({} as AccessHistory);
			}}
			fullscreen={hasLocation && true}
		>
			<Modal.Header closeButton className="text-center">
				<span className="btn-close invisible" />
				<Modal.Title>Localização do acesso</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{hasLocation ? (
					<>
						{can([manager]) && GOOGLE_MAPS_API_KEY ? (
							<GoogleMapsAccessHistory accessHistory={accessHistory} />
						) : (
							<LocationAccessMap accessHistory={accessHistory} />
						)}
					</>
				) : (
					<h2 className="text-center">Sem localização</h2>
				)}
			</Modal.Body>
		</Modal>
	);
}
