import { useEffect } from "react";
import { Control, DomUtil, Map } from "leaflet";

import { get } from "@/utils/tracking/accessors";

import "./styles.scss";

type LegendProps = {
	map: Map | null;
	showNoCommunicationIndicator?: boolean;
	statuses?: MapVehicleStatuses;
};

export function Legend({
	map,
	showNoCommunicationIndicator = true,
	statuses = "all",
}: LegendProps) {
	useEffect(() => {
		if (map) {
			const legend = new Control({ position: "bottomleft" });

			legend.onAdd = () => {
				const div = DomUtil.create("div", "m-0 w-100");
				const legendContent = get().legendContent(showNoCommunicationIndicator, statuses);

				div.append(legendContent);

				return div;
			};

			legend.addTo(map);
		}
	}, [map]);

	return null;
}
