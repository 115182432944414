import classNames from "clsx";

type Props = {
	attribute: number;
	isVehicleMovementCode?: boolean;
};

export function FormattedBadge({ attribute, isVehicleMovementCode }: Props) {
	const priorityAttributes = [
		{ color: "badge-blue", label: "Azul" },
		{ color: "badge-green", label: "Verde" },
		{ color: "badge-yellow", label: "Amarelo" },
		{ color: "badge-orange", label: "Laranja" },
		{ color: "badge-red", label: "Vermelho" },
	];

	const vehicleMovementCodeAttributes = [
		{ color: "badge-green", label: "Código 1" },
		{ color: "badge-yellow", label: "Código 2" },
		{ color: "badge-red", label: "Código 3" },
	];

	const badgeAttributes = isVehicleMovementCode
		? vehicleMovementCodeAttributes
		: priorityAttributes;

	return (
		<span
			className={classNames("badge formatted-badge", badgeAttributes[attribute - 1]?.color)}
		>
			{badgeAttributes[attribute - 1]?.label}
		</span>
	);
}
