import dayjs from "dayjs";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { createSentence } from "@/utils/createSentence";

type Props = {
	radioOperation: RadioOperationResponse;
};

export function LastCommittedVehicle({ radioOperation }: Props) {
	const totalFleetHistories = radioOperation?.fleet_histories?.length || 0;
	const commitmentDate =
		totalFleetHistories > 1
			? radioOperation?.fleet_histories?.[1]?.created_at
			: radioOperation.created_at;

	const lastVehicleCommitted = radioOperation?.fleet_histories?.[0];

	const team = lastVehicleCommitted?.fleet.users.map((user) => user.name) || [];

	const vehicle = lastVehicleCommitted?.fleet.vehicle;

	const vehicleDescription =
		vehicle?.base?.city && vehicle?.vehicle_type?.name
			? `${vehicle?.vehicle_type.name} ${vehicle?.code} - ${vehicle.base?.city?.name}`
			: vehicle?.description;

	return (
		<>
			<h4>VTR anteriormente empenhada</h4>

			<Row>
				<Col lg={4}>
					<span className="fw-bold">Data e hora do empenho: </span>
					<span>{dayjs(commitmentDate).format("DD/MM/YYYY HH:mm:ss")}</span>
				</Col>

				<Col lg={4}>
					<span className="fw-bold">Profissional responsável pelo empenho: </span>
					<span>{lastVehicleCommitted?.creator.name}</span>
				</Col>
			</Row>

			<Row>
				<Col lg={4}>
					<span className="fw-bold">VTR: </span>
					<span>{vehicleDescription}</span>
				</Col>

				<Col lg={4}>
					<span className="fw-bold">Justificativa: </span>
					<span>{lastVehicleCommitted?.change_reason}</span>
				</Col>
			</Row>

			<Row>
				<Col lg={4}>
					<span className="fw-bold">Equipe: </span>
					<span>{createSentence(team)}</span>
				</Col>
			</Row>
		</>
	);
}
