import { ActionType } from "@/enums/ActionType";
import Yup from "@/utils/mixins/yup";

export const medicalRegulationSchema = Yup.object().shape({
	medical_regulation: Yup.string()
		.max(5000, "Comprimento máximo de 5000 caracteres")
		.required("Campo obrigatório"),
	action_type_id: Yup.number().required("Campo obrigatório"),
	priority_type_id: Yup.number().when("action_type_id", {
		is: ActionType.WithIntervention,
		then: (schema) => schema.required("Campo obrigatório"),
	}),
	vehicle_movement_code_id: Yup.number().when("action_type_id", {
		is: ActionType.WithIntervention,
		then: (schema) => schema.required("Campo obrigatório"),
	}),
	action_details: Yup.array(Yup.string()).when("action_type_id", {
		is: ActionType.WithIntervention,
		then: (schema) => schema.min(1, "Tipo de veículo obrigatório"),
	}),
});
