import { ReactNode } from "react";
import { FormControlProps } from "react-bootstrap/FormControl";

import Skeleton, { SkeletonProps } from "react-loading-skeleton";
import Form from "react-bootstrap/Form";

import { MaskedFormControl } from "../MaskedFormControl";
import { RedAsterisk } from "@/components/RedAsterisk";

export type Props = FormControlProps &
	SkeletonProps & {
		label?: ReactNode;
		labelClassName?: string;
		isMaskedInput?: boolean;
		mask?: string | NumberConstructor | { mask: string }[] | RegExp;
		isRequired?: boolean;
		validationMessage?: string;
		skeleton?: boolean;
		max?: string;
		maxLength?: number;
		onAccept?: (value: string, mask: string) => void;
	};

export function FormattedFormControl({
	id,
	label,
	labelClassName,
	isMaskedInput = false,
	mask,
	isRequired = false,
	validationMessage,
	skeleton = false,
	width,
	height,
	max,
	maxLength,
	...rest
}: Props) {
	return (
		<Form.Group controlId={id}>
			{label && (
				<>
					{skeleton ? (
						<Skeleton width={width} />
					) : (
						<>
							<Form.Label className={labelClassName}>{label}</Form.Label>{" "}
							{isRequired && <RedAsterisk />}
						</>
					)}
				</>
			)}

			{skeleton ? (
				<Skeleton width={width} height={height} />
			) : (
				<>
					{isMaskedInput ? (
						<MaskedFormControl mask={mask} {...(rest as any)} />
					) : (
						<Form.Control
							autoComplete="nope"
							list="autocompleteOff"
							max={max}
							maxLength={maxLength}
							{...rest}
						/>
					)}
				</>
			)}

			{validationMessage && <span className="text-danger">{validationMessage}</span>}
		</Form.Group>
	);
}
