import { useCallback } from "react";

export function useClipboard() {
	const notInformed = "Não informado";

	const copyAddressToClipboard = useCallback(async (baseOrUnit: BaseOrUnit) => {
		const address = `${baseOrUnit.street || notInformed}, ${
			baseOrUnit.house_number || notInformed
		}, ${baseOrUnit.neighborhood || notInformed}, - ${baseOrUnit.city?.name || notInformed}`;

		try {
			await navigator.clipboard.writeText(address);
		} catch (error) {
			console.error(error);
		}
	}, []);

	return { copyAddressToClipboard };
}
