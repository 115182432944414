import { FormikProps } from "formik";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { userInitialValues } from "@/utils/initialValues/user";
import { streetTypeOptions } from "@/utils/options/streetType";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { FederalUnitsSelect } from "@/components/Selects/FederalUnitsSelect";
import { CitiesSelect } from "@/components/Selects/CitiesSelect";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { RedAsterisk } from "@/components/RedAsterisk";

type Props = {
	formik: FormikProps<typeof userInitialValues>;
};

export function Address({ formik }: Props) {
	const { values, errors } = formik;

	const selectedStreetTypeOption =
		streetTypeOptions.find((option) => option.value === values.street_type) || null;

	function handleSelectFederalUnit(option: ReactSelectOption) {
		formik.setFieldValue("federal_unit", String(option.value));
		formik.setFieldValue("federal_unit_label", option.label);
		formik.setFieldValue("city_id", "");
		formik.setFieldValue("city_label", "");
	}

	function handleSelectCity(option: ReactSelectOption) {
		formik.setFieldValue("city_id", (option as ReactSelectOption).value);
		formik.setFieldValue("city_label", (option as ReactSelectOption).label);
	}

	return (
		<>
			<Row className="mb-4">
				<Col lg={2}>
					<Form.Label className="fw-bold" htmlFor="federal-unit">
						UF <RedAsterisk />
					</Form.Label>

					<FederalUnitsSelect
						inputId="federal-unit"
						isInvalid={!!errors.federal_unit}
						placeholder="Selecione"
						value={
							values.federal_unit
								? {
										value: values.federal_unit,
										label: values.federal_unit_label,
								  }
								: ""
						}
						onChange={(option) => handleSelectFederalUnit(option as ReactSelectOption)}
					/>

					{errors.federal_unit && (
						<span className="text-danger">{errors.federal_unit}</span>
					)}
				</Col>

				<Col lg={3}>
					<Form.Label className="fw-bold" htmlFor="city">
						Cidade <RedAsterisk />
					</Form.Label>

					<CitiesSelect
						inputId="city"
						federalUnitId={values.federal_unit}
						isInvalid={!!errors.city_id}
						onChange={(option) => handleSelectCity(option as ReactSelectOption)}
						placeholder="Selecione uma cidade"
						value={
							values.city_id
								? { value: values.city_id, label: values.city_label }
								: ""
						}
					/>

					{errors.city_id && <span className="text-danger">{errors.city_id}</span>}
				</Col>

				<Col lg={4}>
					<FormattedFormControl
						id="neighborhood"
						label="Bairro"
						labelClassName="fw-bold"
						isInvalid={!!errors.neighborhood}
						validationMessage={errors.neighborhood}
						{...formik.getFieldProps("neighborhood")}
						isRequired
					/>
				</Col>
			</Row>

			<Row className="mb-4">
				<Col lg={2}>
					<Form.Label className="fw-bold" htmlFor="street-type">
						Tipo de logradouro <RedAsterisk />
					</Form.Label>

					<ReactSelect
						inputId="street-type"
						options={streetTypeOptions}
						isInvalid={!!errors.street_type}
						value={selectedStreetTypeOption}
						placeholder="Selecione um tipo"
						onChange={(option) => {
							formik.setFieldValue(
								"street_type",
								(option as ReactSelectOption).value
							);
						}}
					/>

					{errors.street_type && (
						<span className="text-danger">{errors.street_type}</span>
					)}
				</Col>

				<Col lg={5}>
					<FormattedFormControl
						id="street"
						label="Logradouro"
						labelClassName="fw-bold"
						isInvalid={!!errors.street}
						validationMessage={errors.street}
						{...formik.getFieldProps("street")}
						isRequired
					/>
				</Col>

				<Col lg={2}>
					<FormattedFormControl
						id="house-number"
						label="N°"
						labelClassName="fw-bold"
						isInvalid={!!errors.house_number}
						validationMessage={errors.house_number}
						{...formik.getFieldProps("house_number")}
						isRequired
					/>
				</Col>
			</Row>

			<Row>
				<Col lg={9}>
					<FormattedFormControl
						id="complement"
						label="Complemento"
						labelClassName="fw-bold"
						{...formik.getFieldProps("complement")}
					/>
				</Col>
			</Row>
		</>
	);
}
