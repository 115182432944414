import { VehicleMovementCode, VehicleMovementCodeMessages } from "@/enums/VehicleMovementCode";

export const vehicleMovementCodeOptions = [
	{
		label: VehicleMovementCodeMessages[VehicleMovementCode.Code1],
		value: VehicleMovementCode.Code1,
		background: "#DFFFEA",
		color: "#17C690",
	},
	{
		label: VehicleMovementCodeMessages[VehicleMovementCode.Code2],
		value: VehicleMovementCode.Code2,
		background: "#FFF5D2",
		color: "#FFC700",
	},
	{
		label: VehicleMovementCodeMessages[VehicleMovementCode.Code3],
		value: VehicleMovementCode.Code3,
		background: "#FFE1E1",
		color: "#FF0000",
	},
];
