import { NarrativeCertificate } from "@/enums/narrativeCertificate";

export const narrativeCertificateInitialValues = {
	default_or_free: NarrativeCertificate.Default,
	free_narrative_certificate: "",
	patient_name: "",
	general_registration: "",
	identifier: "",
	date: "",
	city: "",
	street: "",
	diagnostic_hypotheses: "",
	destination_unit: "",
};
