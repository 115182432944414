import { Link, useLocation } from "react-router-dom";
import classNames from "clsx";
import dayjs from "dayjs";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { GenderMessages } from "@/enums/Gender";
import { formatPhone } from "@/utils/formatPhone";
import { TimeUnitMessages } from "@/enums/TimeUnit";
import { ResourceMessages } from "@/enums/Resource";
import { formatAddress } from "@/utils/formatAddress";
import { LocationTypeMessages } from "@/enums/LocationType";
import { DistanceTypeMessages } from "@/enums/DistanceType";
import { RequesterTypeMessages } from "@/enums/RequesterType";
import { TransferReasonsMessages } from "@/enums/TransferReason";
import { useWindowDimensions } from "@/hooks/useWindowDimensions";
import { AttendanceType, AttendanceTypeMessages } from "@/enums/AttendanceType";

type OccurrenceDataProps = {
	attendanceType?: number;
	attendable?: Attendable;
	attendance?: Attendance;
	summarize?: boolean;
	showFatherLink?: boolean;
};

type KeyOfTransferReasonMessages = keyof typeof TransferReasonsMessages;
type KeyOfAttendanceTypeMessages = keyof typeof AttendanceTypeMessages;
type KeyOfRequesterTypeMessages = keyof typeof RequesterTypeMessages;
type KeyOfLocationTypeMessages = keyof typeof LocationTypeMessages;
type KeyOfDistanceTypeMessages = keyof typeof DistanceTypeMessages;
type KeyOfTimeUnitMessages = keyof typeof TimeUnitMessages;
type KeyOfGenderMessages = keyof typeof GenderMessages;
type KeyOfResourceMessages = keyof typeof ResourceMessages;

export function OccurrenceData({
	attendanceType,
	attendable,
	attendance,
	summarize = false,
	showFatherLink = false,
}: OccurrenceDataProps) {
	const location = useLocation();

	const fatherLinkId = attendance?.father_link?.id;
	const fatherLinkNumber = attendance?.father_link?.number;

	const attendableData = attendance?.attendable || attendable;
	const attendanceData = attendance || attendable?.attendance;
	const sceneRecording = attendanceData?.scene_recording;
	const ticket = attendanceData?.ticket;

	const patient = attendanceData?.patient;

	const unitOrigin = attendableData?.unit_origin;

	const unitDestination =
		sceneRecording?.latest_counter_referral?.unit_destination ||
		sceneRecording?.unit_destination ||
		attendableData?.unit_destination;

	const inCentralBedUpdatedAt = attendable?.in_central_bed_updated_at
		? dayjs(attendable?.in_central_bed_updated_at).format("DD/MM/YYYY [às] HH:mm[hr]")
		: "-";

	const genderMessage = GenderMessages[patient?.gender_code as KeyOfGenderMessages] ?? "-";

	const timeUnitMessage = TimeUnitMessages[patient?.time_unit_id as KeyOfTimeUnitMessages];

	const complementOrigin = attendable?.complement_origin;
	const complementDestination = attendable?.complement_destination;

	const inCentralBed = attendable?.in_central_bed;

	const unitOriginAddress: AddressParams = {
		street: unitOrigin?.street || "",
		houseNumber: unitOrigin?.house_number,
		city: unitOrigin?.city?.name,
		federalUnit: unitOrigin?.city?.federal_unit.uf,
	};

	const unitDestinationAddress: AddressParams = {
		street: unitDestination?.street || "",
		houseNumber: unitDestination?.house_number,
		city: unitDestination?.city?.name,
		federalUnit: unitDestination?.city?.federal_unit.uf,
	};

	const { width } = useWindowDimensions();
	const rowOrCol = width >= 504 ? "row" : "col";

	function showRequesterPhone() {
		const requester = ticket?.requester;

		const primaryPhone = formatPhone(requester?.primary_phone);
		const secondaryPhone = formatPhone(requester?.secondary_phone);

		if (primaryPhone && secondaryPhone) {
			return `${primaryPhone} | ${secondaryPhone}`;
		}

		return primaryPhone || secondaryPhone;
	}

	return (
		<>
			<div className={classNames(width > 560 ? "d-none" : "fw-bold")}>
				{dayjs(ticket?.opening_at).format("[Aberto em] DD/MM/YYYY [às] HH:mm[hr]")}
			</div>

			<div className="d-flex gap-4 pb-5 fw-bold">
				<p>N° {attendanceData?.number}</p>

				<p>Aberto por {ticket?.created_by?.name}</p>

				<p>{dayjs(ticket?.opening_at).format("[Criado em] DD/MM/YYYY HH:mm")}</p>
			</div>

			{showFatherLink && fatherLinkId && (
				<div className="d-flex fw-bold gap-2">
					<p>Vinculado à:</p>

					<Link
						to={`/ocorrencias/resumo/${fatherLinkId}`}
						state={{
							pathname: "/resumo",
							from: location,
						}}
						className="color-orange text-decoration-underline"
					>
						{fatherLinkNumber}
					</Link>
				</div>
			)}

			{summarize ? (
				<div className="d-flex flex-column gap-4">
					<h3>Dados da ocorrência</h3>

					<Row>
						<Col lg={3}>
							<span className="fw-bold">Tipo de chamado: </span>
							{AttendanceTypeMessages[
								attendanceType as KeyOfAttendanceTypeMessages
							] ?? "-"}
						</Col>

						{attendanceType === AttendanceType.PrimaryOccurrence ? (
							<>
								<Col lg={3}>
									<span className="fw-bold">Múltiplas vítimas: </span>
									{ticket?.multiple_victims ? "Sim" : "Não"}
								</Col>

								<Col sm={3}>
									<span className="fw-bold">Local: </span>
									{LocationTypeMessages[
										attendableData?.location_type_id as KeyOfLocationTypeMessages
									] ?? "-"}
								</Col>
							</>
						) : (
							<>
								<Col lg={3}>
									<span className="fw-bold">Telefone: </span>
									{ticket?.requester.primary_phone ??
										ticket?.requester.secondary_phone ??
										""}
								</Col>

								<Col>
									<span className="fw-bold">
										Unidade de origem transferência:{" "}
									</span>
									{unitOrigin?.name}
								</Col>
							</>
						)}
					</Row>

					<Row>
						<Col lg={3}>
							<span className="fw-bold">Nome: </span>
							{patient?.name ?? "-"}
						</Col>

						<Col lg={3}>
							<span className="fw-bold">Sexo: </span>
							{genderMessage}
						</Col>

						{attendanceType === AttendanceType.PrimaryOccurrence ? (
							<Col lg={3}>
								<span className="fw-bold">Quantidade de vítimas: </span>
								{ticket?.number_of_victims ?? "-"}
							</Col>
						) : (
							<Col lg={3}>
								<span className="fw-bold">Endereço da unidade de origem: </span>
								{formatAddress(unitOriginAddress) || "-"}
							</Col>
						)}
					</Row>

					<Row>
						<Col lg={3}>
							<span className="fw-bold">Cidade: </span>
							{ticket?.city.name ?? "-"}
						</Col>

						{attendanceType === AttendanceType.PrimaryOccurrence ? (
							<>
								<Col lg={3} sm={4}>
									<span className="fw-bold">Quem solicitou: </span>
									{RequesterTypeMessages[
										ticket?.requester
											.requester_type_id as KeyOfRequesterTypeMessages
									] ?? "-"}
								</Col>

								<Col>
									<span className="fw-bold">Localização da ocorrência: </span>
									{attendableData?.street ? `${attendableData.street}, ` : ""}
									{attendableData?.house_number
										? `${attendableData.house_number}, `
										: ""}
									{attendableData?.neighborhood ?? ""}
									{attendableData?.reference_place
										? ` - ${attendableData.reference_place}`
										: ""}
								</Col>
							</>
						) : (
							<>
								<Col lg={3} sm={4}>
									<span className="fw-bold">Quem solicitou: </span>
									{RequesterTypeMessages[
										ticket?.requester
											.requester_type_id as KeyOfRequesterTypeMessages
									] ?? "-"}
								</Col>

								<Col>
									<span className="fw-bold">
										Unidade de destino de transferência:{" "}
									</span>
									{unitDestination?.name}
								</Col>
							</>
						)}
					</Row>

					<Row>
						{attendanceType === AttendanceType.PrimaryOccurrence ? (
							<Col lg={3}>
								<span className="fw-bold">Nome do solicitante: </span>
								{ticket?.requester.name}
							</Col>
						) : (
							<Col lg={3}>
								<span className="fw-bold">Profissional solicitante: </span>
								{ticket?.requester.name}
							</Col>
						)}

						<Col lg={3}>
							<span className="fw-bold">Número do conselho: </span>
							{ticket?.requester?.council_number ?? "-"}
						</Col>

						<Col lg={3}>
							<span className="fw-bold">Idade: </span>
							{patient?.age} {timeUnitMessage}
						</Col>

						{attendanceType !== AttendanceType.PrimaryOccurrence && (
							<Col>
								<span className="fw-bold">Endereço da unidade de destino: </span>
								{formatAddress(unitDestinationAddress) || "-"}
							</Col>
						)}
					</Row>

					<Row>
						{attendanceType === AttendanceType.PrimaryOccurrence ? (
							<>
								<Col lg={3}>
									<span className="fw-bold">Telefone: </span>
									{ticket?.requester.primary_phone ??
										ticket?.requester.secondary_phone ??
										""}
								</Col>

								<Col sm={4}>
									<span className="fw-bold">Distância do paciente: </span>
									{DistanceTypeMessages[
										attendableData?.distance_type_id as KeyOfDistanceTypeMessages
									] ?? "-"}
								</Col>
							</>
						) : (
							<>
								<Col lg={3}>
									<span className="fw-bold">Número do conselho: </span>
									{ticket?.requester.council_number}
								</Col>

								<Col lg={3}>
									<span className="fw-bold">Está na central de leitos: </span>
									{attendableData?.in_central_bed ? "Sim" : "Não"}
								</Col>

								<Col>
									<span>Motivo da transferência: </span>
									{TransferReasonsMessages[
										attendableData?.transfer_reason_id as KeyOfTransferReasonMessages
									] ?? "-"}
								</Col>
							</>
						)}
					</Row>

					<div>
						<span className="fw-bold">Queixa: </span>
						{(attendanceType === AttendanceType.PrimaryOccurrence
							? attendableData?.primary_complaint
							: attendableData?.diagnostic_hypothesis) ?? "-"}
					</div>

					<Row>
						{attendanceType === AttendanceType.PrimaryOccurrence ? (
							<Col>
								<span className="fw-bold">Observação da regulação médica: </span>
								{attendanceData?.latest_medical_regulation?.medical_regulation}
							</Col>
						) : (
							<Col>
								<span className="fw-bold">Observação transferência médica: </span>
								{attendableData?.transfer_observation}
							</Col>
						)}
					</Row>

					<Row>
						{attendanceType === AttendanceType.PrimaryOccurrence ? (
							<Col>
								<span className="fw-bold">Observações TARM: </span>
								{attendableData?.observations ?? "-"}
							</Col>
						) : (
							<Col>
								<span className="fw-bold">Observações transferência TARM: </span>
								{attendableData?.observations ?? "-"}
							</Col>
						)}
					</Row>
				</div>
			) : (
				<>
					<Row className="gap-8 mb-4">
						<Col
							xs={width <= 520 ? 12 : 6}
							className={classNames(
								"d-flex flex-column gap-2 border-block-end border-end border-end-dashed border-gray-500",
								width >= 504 ? "border-none" : "border-0"
							)}
						>
							<h3>Dados do solicitante</h3>

							<Col>
								<span className="fw-bold">Nome do solicitante: </span>
								{ticket?.requester?.name}
							</Col>

							<Col>
								<span className="fw-bold">Número do conselho: </span>
								{ticket?.requester?.council_number ?? "-"}
							</Col>

							<Col>
								<span className="fw-bold">Telefone: </span>
								{showRequesterPhone()}
							</Col>

							<h3 className="mt-4">Dados do paciente</h3>

							<Col>
								<span className="fw-bold">Nome do paciente: </span>
								{patient?.name ?? "-"}
							</Col>

							<Col>
								<span className="fw-bold">Sexo: </span>
								{genderMessage}
							</Col>

							<Col>
								<span className="fw-bold">Idade: </span>
								{patient?.age} {timeUnitMessage}
							</Col>
						</Col>

						<Col className="d-flex flex-column gap-2">
							<h3>Dados do ocorrência</h3>

							<Col>
								<span className="fw-bold">Cidade: </span>
								{ticket?.city?.name}
							</Col>

							{attendanceType === AttendanceType.PrimaryOccurrence ? (
								<>
									<Row className="mb-2">
										<Col>
											<span className="fw-bold">Logradouro: </span>
											{attendable?.street ?? "-"}
										</Col>

										<Col>
											<span className="fw-bold">N°: </span>
											{attendable?.house_number ?? "-"}
										</Col>
									</Row>

									<Col>
										<span className="fw-bold">Bairro: </span>
										{attendable?.neighborhood ?? "-"}
									</Col>

									<Col>
										<span className="fw-bold">
											Referência / Via de acesso:{" "}
										</span>
										{attendable?.reference_place ?? "-"}
									</Col>

									<Col>
										<span className="fw-bold">Queixa: </span>
										{attendable?.primary_complaint ?? "-"}
									</Col>

									<Col>
										<span className="fw-bold">Unidade de destino: </span>
										{unitDestination?.name ?? "-"}
									</Col>

									<Row className="mb-2">
										<Col>
											<span className="fw-bold">
												Está na central de leitos:{" "}
											</span>
											{inCentralBed ? "Sim" : "Não"}
										</Col>

										{inCentralBed && (
											<Col>
												<span className="fw-bold">Nº do protocolo: </span>
												{attendable?.protocol ?? "-"}
											</Col>
										)}
									</Row>

									<Col>
										<span className="fw-bold">Ùltima atualização: </span>
										{inCentralBedUpdatedAt}
									</Col>
								</>
							) : (
								<>
									<div className={classNames("mb-2", rowOrCol)}>
										<Col className="d-flex flex-column">
											<span className="fw-bold">
												Unidade de origem transferência:
											</span>
											{attendable?.unit_origin?.name ?? "-"}
										</Col>

										<Col className="d-flex flex-column">
											<span className="fw-bold">
												Unidade de destino transferência:
											</span>
											{unitDestination?.name ?? "-"}
										</Col>
									</div>

									<div className={classNames("mb-2", rowOrCol)}>
										<Col className="d-flex flex-column">
											<span className="fw-bold">
												Endereço da unidade de origem:
											</span>
											{formatAddress(unitOriginAddress) || "-"}
										</Col>

										<Col className="d-flex flex-column">
											<span className="fw-bold">
												Endereço da unidade de destino:
											</span>
											{formatAddress(unitDestinationAddress) || "-"}
										</Col>
									</div>

									<Row className="mb-2">
										<Col className="d-flex flex-column">
											<span className="fw-bold">
												Complemento/Setor de origem:
											</span>
											{complementOrigin || "-"}
										</Col>

										<Col className="d-flex flex-column">
											<span className="fw-bold">
												Complemento/Setor de destino:
											</span>
											{complementDestination || "-"}
										</Col>
									</Row>

									<Col>
										<span className="fw-bold">Hipótese Diagnóstico: </span>
										{attendable?.diagnostic_hypothesis ?? "-"}
									</Col>

									<Col>
										<span className="fw-bold">Motivo da Transferência: </span>
										{TransferReasonsMessages[
											attendableData?.transfer_reason_id as KeyOfTransferReasonMessages
										] ?? "-"}
									</Col>

									<Row>
										<Col>
											<span className="fw-bold">
												Está na Central de Leitos:{" "}
											</span>
											{attendable?.in_central_bed ? "Sim" : "Não"}
										</Col>

										{attendable?.protocol && (
											<Col>
												<span className="fw-bold">N° do protocolo: </span>
												{attendable?.protocol}
											</Col>
										)}
									</Row>

									<Col>
										<span className="fw-bold">Ùltima atualização: </span>
										{inCentralBedUpdatedAt}
									</Col>
								</>
							)}
						</Col>
					</Row>

					<Col className="mb-3">
						<span className="fw-bold">Observações: </span>
						{attendableData?.observations ?? "-"}
					</Col>

					{attendanceType === AttendanceType.SecondaryOccurrence && (
						<Col className="mb-3">
							<span className="fw-bold">Observação da transferência: </span>
							{attendableData?.transfer_observation ?? "-"}
						</Col>
					)}

					<Row>
						<Col lg={5} sm={4}>
							<span className="fw-bold">Quem solicitou: </span>
							{RequesterTypeMessages[
								ticket?.requester?.requester_type_id as KeyOfRequesterTypeMessages
							] ?? "-"}
						</Col>

						{attendanceType === AttendanceType.PrimaryOccurrence ? (
							<>
								<Col lg={5} sm={4}>
									<span className="fw-bold">Distância do paciente: </span>
									{DistanceTypeMessages[
										attendableData?.distance_type_id as KeyOfDistanceTypeMessages
									] ?? "-"}
								</Col>

								<Col lg={2} sm={3}>
									<span className="fw-bold">Local: </span>
									{LocationTypeMessages[
										attendableData?.location_type_id as KeyOfLocationTypeMessages
									] ?? "-"}
								</Col>
							</>
						) : (
							<Col sm={4}>
								<span className="fw-bold">Recurso solicitado: </span>
								{ResourceMessages[
									attendableData?.requested_resource_id as KeyOfResourceMessages
								] ?? "-"}
							</Col>
						)}
					</Row>
				</>
			)}
		</>
	);
}
