import Modal from "react-bootstrap/Modal";
import classNames from "clsx";

import { MdContentCopy } from "react-icons/md";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { useClipboard } from "@/hooks/useClipboard";
import { formatPhone } from "@/utils/formatPhone";

import { Separator } from "@/components/Separator";

type Props = {
	baseOrUnit: BaseOrUnit;
	showViewModal: boolean;
	handleCloseViewModal: () => void;
	dataType: "base" | "unidade";
};

export function ViewModal({ baseOrUnit, showViewModal, handleCloseViewModal, dataType }: Props) {
	const { copyAddressToClipboard } = useClipboard();

	const baseDataType = dataType === "base";

	const handleCopyToClipboard = () => copyAddressToClipboard(baseOrUnit);

	return (
		<Modal size="xl" show={showViewModal} onHide={handleCloseViewModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Visualização da {dataType}</h2>
			</Modal.Header>

			<Modal.Body>
				<div className="d-flex flex-column gap-4">
					<Row>
						<Col lg={6}>
							<span className="fw-bold">Nome: </span>
							{baseOrUnit.name}
						</Col>

						<Col>
							<span className="fw-bold">CNES: </span>
							{baseOrUnit.national_health_registration}
						</Col>

						<Col>
							<span className="fw-bold">Telefone: </span>
							{formatPhone(baseOrUnit.telephone)}
						</Col>
					</Row>

					<Row>
						<Col lg={6}>
							<span className="fw-bold">Tipo de unidade: </span>
							{baseOrUnit.unit_type?.name}
						</Col>

						<Col>
							<span className="fw-bold">Razão social da unidade: </span>
							{baseOrUnit.company_name}
						</Col>

						<Col>
							<span className="fw-bold">CNPJ mantenedor: </span>
							{baseOrUnit.company_registration_number}
						</Col>
					</Row>

					{baseDataType && (
						<Row>
							<Col>
								<span className="fw-bold">Tipo de VTR: </span>
								{baseOrUnit.vehicle_type?.name}
							</Col>

							<Col>
								<span className="fw-bold">CRU vinculada: </span>
								{baseOrUnit.urgency_regulation_center?.name}
							</Col>
						</Row>
					)}
				</div>

				<Separator />

				<div className="d-flex flex-column gap-4">
					<div className="d-flex align-items-center gap-4">
						<h3 className={classNames(!baseDataType && "mb-0")}>Endereço</h3>

						{!baseDataType && (
							<Button
								className="d-flex flex-center gap-2 btn-sm button-bg-orange-color-white px-4 py-2"
								onClick={handleCopyToClipboard}
							>
								<MdContentCopy /> Copiar
							</Button>
						)}
					</div>

					<Row>
						<Col lg={3}>
							<span className="fw-bold">UF: </span>
							{baseOrUnit.city?.federal_unit?.uf}
						</Col>

						<Col lg={3}>
							<span className="fw-bold">Cidade: </span>
							{baseOrUnit.city?.name}
						</Col>

						<Col>
							<span className="fw-bold">Latitude: </span>
							{baseOrUnit.latitude}
						</Col>
					</Row>

					<Row>
						<Col lg={3}>
							<span className="fw-bold">Logradouro: </span>
							{baseOrUnit.street}
						</Col>

						<Col lg={3}>
							<span className="fw-bold">Nº: </span>
							{baseOrUnit.house_number}
						</Col>

						<Col>
							<span className="fw-bold">Longitude: </span>
							{baseOrUnit.longitude}
						</Col>
					</Row>

					<Row>
						<Col lg={3}>
							<span className="fw-bold">Complemento: </span>
							{baseOrUnit.complement}
						</Col>

						<Col>
							<span className="fw-bold">Bairro: </span>
							{baseOrUnit.neighborhood}
						</Col>
					</Row>
				</div>

				<div className="text-center mt-8">
					<button
						type="button"
						className="btn button-bg-light-color-gray-400"
						onClick={handleCloseViewModal}
					>
						Fechar
					</button>
				</div>
			</Modal.Body>
		</Modal>
	);
}
