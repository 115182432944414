import { Route, Routes } from "react-router-dom";

import { OutpatientProductionBulletin } from "@/pages/Reports/OutpatientProductionBulletin";
import { AttendanceConsultation } from "@/pages/Reports/AttendanceConsultation";
import { PowerBIReportsView } from "@/pages/Reports/PowerBIReport";
import { DutyReport } from "@/pages/Reports/DutyReport";

export function ReportRoutes() {
	return (
		<Routes>
			<Route path="consultas" element={<AttendanceConsultation />} />
			<Route path="plantao" element={<DutyReport />} />
			<Route path="bpa" element={<OutpatientProductionBulletin />} />
			<Route path="power-bi" element={<PowerBIReportsView />} />
		</Routes>
	);
}
