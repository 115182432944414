import { ReactNode } from "react";
import { useLocation } from "react-router";
import classNames from "clsx";

import { checkIsActive, WithChildren } from "../../../helpers";

type Props = {
	to: string;
	title: string;
	icon?: ReactNode;
	fontIcon?: string;
	hasBullet?: boolean;
};

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
	children,
	to,
	title,
	icon,
	fontIcon,
	hasBullet,
}) => {
	const { pathname } = useLocation();
	const isActive = checkIsActive(pathname, to);

	return (
		<div className="menu-item menu-accordion" data-kt-menu-trigger="click">
			<span className="menu-link">
				{hasBullet && (
					<span className="menu-bullet">
						<span className="bullet bullet-dot"></span>
					</span>
				)}

				{icon && <span className="menu-icon scale-1.25">{icon}</span>}

				{fontIcon && <i className={classNames("bi fs-3", fontIcon)}></i>}

				<span className="menu-title">{title}</span>

				<span className="menu-arrow"></span>
			</span>

			<div
				className={classNames("menu-sub menu-sub-accordion gap-1 mt-1", {
					"menu-active-bg": isActive,
				})}
			>
				{children}
			</div>
		</div>
	);
};

export { AsideMenuItemWithSub };
