import { ReactNode, forwardRef, LegacyRef } from "react";

import { Header } from "@/components/Prints/Layout/Header";

import "./styles.scss";

type LayoutProps = {
	children: ReactNode;
};

export const Content = forwardRef(({ children }: LayoutProps, ref: LegacyRef<HTMLTableElement>) => {
	return (
		<table className="w-100" ref={ref}>
			<thead>
				<tr>
					<td>
						<Header />
					</td>
				</tr>
			</thead>

			<tbody>
				<tr>
					<td>{children}</td>
				</tr>
			</tbody>
		</table>
	);
});
