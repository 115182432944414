import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useApi } from "@/hooks/useApi";

import { Additional, ReactSelect, ReactSelectProps } from "../ReactSelect";

type DiagnosticHypothesesSelectProps = ReactSelectProps & {
	natureTypes?: string | number | string[] | number[] | null;
};

export function DiagnosticHypothesesSelect({
	natureTypes,
	...rest
}: DiagnosticHypothesesSelectProps) {
	const [key, setKey] = useState(uuidv4());
	const { api } = useApi();

	async function fetchDiagnosticHypotheses(page?: number, search?: string) {
		try {
			if (!natureTypes) {
				return {} as PaginatedDiagnosticHypothesis;
			}

			const { data } = await api.get("/diagnostic-hypothesis", {
				params: {
					search,
					page: page || 1,
					nature_types: Array.isArray(natureTypes) ? natureTypes : [natureTypes] || [],
					filter_by_active: 1,
				},
			});

			return data as PaginatedDiagnosticHypothesis;
		} catch (error) {
			console.log(error);
			return {} as PaginatedDiagnosticHypothesis;
		}
	}

	const loadAsyncDiagnosticHypothesesProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, _: DiagnosticHypothesis, { page }: Additional) {
			const diagnosticHypotheses = await fetchDiagnosticHypotheses(page, search);

			return {
				options:
					diagnosticHypotheses.results?.map((diagnosticHypothesis) => ({
						value: diagnosticHypothesis.id,
						label: diagnosticHypothesis.name,
					})) || [],
				hasMore:
					diagnosticHypotheses.meta?.current_page <
						diagnosticHypotheses.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	const noOptionsMessage = () =>
		!natureTypes ? "Selecione um tipo de natureza" : "Nenhuma hipótese diagnóstica encontrada";

	useEffect(() => {
		setKey(uuidv4()); // remount component, running load options again
	}, [natureTypes]);

	return (
		<ReactSelect
			key={key}
			placeholder="Selecione uma hipótese"
			noOptionsMessage={noOptionsMessage}
			{...loadAsyncDiagnosticHypothesesProps}
			{...rest}
		/>
	);
}
