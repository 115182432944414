import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";

import { useApi } from "@/hooks/useApi";
import { useAuth } from "@/modules/auth";
import { usePusher } from "@/hooks/usePusher";
import { useDebounce } from "@/hooks/useDebounce";
import { AttendanceType } from "@/enums/AttendanceType";
import { AttendanceStatus } from "@/enums/AttendanceStatus";
import { filterAttendanceSchema } from "@/utils/validation/filterAttendanceSchema";
import { filterAttendanceInitialValues } from "@/utils/initialValues/filterAttendance";

import { AttendanceStatusFilterModal } from "@/components/Modals/AttendanceStatusFilterModal";
import { AttendanceCard } from "@/components/Attendance/AttendanceCard";
import { AttendanceHeader } from "@/components/Attendance/AttendanceHeader";

type RefreshedAttendance = {
	results: {
		action: "created" | "updated";
		attendable: Attendable;
	};
};

type OccurrenceTypesByAttendanceStatus = {
	attendable_type: "primary_attendance" | "secondary_attendance";
	total: number;
};

type TotalAwaitingRegulation = {
	primary: number;
	secondary: number;
};

export function Attendance() {
	// primary attendances
	const [primaryAttendances, setPrimaryAttendances] = useState<PaginatedAttendable>(
		{} as PaginatedAttendable
	);
	const [isLoadingPrimary, setIsLoadingPrimary] = useState(true);
	const [primarySearch, setPrimarySearch] = useState("");
	const debouncedPrimarySearch = useDebounce(primarySearch, 500);

	// secondary attendances
	const [secondaryAttendances, setSecondaryAttendances] = useState<PaginatedAttendable>(
		{} as PaginatedAttendable
	);
	const [isLoadingSecondary, setIsLoadingSecondary] = useState(true);
	const [secondarySearch, setSecondarySearch] = useState("");
	const debouncedSecondarySearch = useDebounce(secondarySearch, 500);

	// attendances
	const [totalAwaitingRegulation, setTotalAwaitingRegulation] = useState<TotalAwaitingRegulation>(
		{} as TotalAwaitingRegulation
	);
	const [isLoadingTotalAwaitingRegulation, setIsLoadingTotalAwaitingRegulation] = useState(true);
	const [showFilterAttendanceModal, setShowFilterAttendanceModal] = useState(false);
	const { auth, currentAuth } = useAuth();
	const { api } = useApi();

	const handleShowFilterAttendanceModal = () => setShowFilterAttendanceModal(true);
	const handleCloseFilterAttendanceModal = () => setShowFilterAttendanceModal(false);

	const currentURCId = String(currentAuth?.current_urgency_regulation_center.id);

	const pusher = usePusher();

	function handleRefreshAttendance(
		type: AttendanceType.PrimaryOccurrence | AttendanceType.SecondaryOccurrence,
		urcId: string
	) {
		const isPrimary = type === AttendanceType.PrimaryOccurrence;

		const channelSlug = isPrimary ? "primary" : "secondary";
		const eventSlug = channelSlug.charAt(0).toUpperCase() + channelSlug.slice(1);

		const channelName = `private-${channelSlug}-attendance.refresh.${urcId}`;
		const eventName = `Refresh${eventSlug}Attendance`;

		const attendances = isPrimary ? primaryAttendances : secondaryAttendances;
		const setAttendances = isPrimary ? setPrimaryAttendances : setSecondaryAttendances;
		const searchAttendances = isPrimary
			? handleSearchPrimaryAttendances
			: handleSearchSecondaryAttendances;

		const channel = pusher.subscribe(channelName);

		channel.bind(eventName, ({ results }: RefreshedAttendance) => {
			const { attendable } = results;

			if (!attendable.attendance) {
				return;
			}

			const { action } = results;

			const status = attendable.attendance.attendance_status_id;
			const isCompletedOrCanceled =
				status === AttendanceStatus.Completed || status === AttendanceStatus.Canceled;

			const attendanceIsAlreadyInList = attendances.results?.some(
				(occurrence) => occurrence.id === attendable.id
			);

			getDashboard();

			if (action === "updated" && !isCompletedOrCanceled && attendanceIsAlreadyInList) {
				const newAttendances = attendances.results?.map((occurrence) => {
					if (occurrence.id === attendable.id) {
						return attendable;
					}

					return occurrence;
				});

				setAttendances((prevState) => ({
					...prevState,
					results: newAttendances,
				}));

				return;
			}

			searchAttendances();
		});
	}

	const formik = useFormik({
		initialValues: filterAttendanceInitialValues,
		validationSchema: filterAttendanceSchema,
		async onSubmit(_, { setSubmitting }) {
			try {
				setIsLoadingPrimary(true);
				setIsLoadingSecondary(true);

				const primaryAttendances = await fetchPrimaryAttendances();
				const secondaryAttendances = await fetchSecondaryAttendances();

				setPrimaryAttendances(primaryAttendances);
				setSecondaryAttendances(secondaryAttendances);
			} catch (error) {
				console.log(error);
			} finally {
				handleCloseFilterAttendanceModal();
				setIsLoadingPrimary(false);
				setIsLoadingSecondary(false);
				setSubmitting(false);
			}
		},
	});

	const { values } = formik;

	async function getDashboard() {
		try {
			const { data } = await api.get<OccurrenceTypesByAttendanceStatus[]>(
				"/dashboard/occurrence-types-by-attendance-status",
				{
					params: {
						attendance_status_id: AttendanceStatus.AwaitingMedicalRegulation,
						urc_id: currentURCId,
					},
				}
			);

			data.forEach((item) => {
				if (item.attendable_type === "primary_attendance") {
					setTotalAwaitingRegulation((prevState) => ({
						...prevState,
						primary: item.total,
					}));
				} else if (item.attendable_type === "secondary_attendance") {
					setTotalAwaitingRegulation((prevState) => ({
						...prevState,
						secondary: item.total,
					}));
				}
			});
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingTotalAwaitingRegulation(false);
		}
	}

	useEffect(() => {
		getDashboard();
	}, []);

	// primary attendances
	const primaryAbortController = useRef(new AbortController());

	async function fetchPrimaryAttendances(increasePage = false) {
		try {
			primaryAbortController.current.abort();
			primaryAbortController.current = new AbortController();

			const page = primaryAttendances.meta?.current_page || 1;

			const { data } = await api.get("/ticket/primary-attendance", {
				params: {
					page: increasePage ? page + 1 : 1,
					...(debouncedPrimarySearch && { search: debouncedPrimarySearch }),
					attendance_status_id: values.attendance_status_id || null,
				},
				signal: primaryAbortController.current.signal,
			});

			return data as PaginatedAttendable;
		} catch (error) {
			console.log(error);
			return {} as PaginatedAttendable;
		}
	}

	async function handleSearchPrimaryAttendances() {
		try {
			setIsLoadingPrimary(true);

			const attendances = await fetchPrimaryAttendances();

			setPrimaryAttendances(attendances);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingPrimary(false);
		}
	}

	async function loadMorePrimaryAttendances() {
		const newAttendances = await fetchPrimaryAttendances(true);

		setPrimaryAttendances((prevState) => ({
			...newAttendances,
			results: [...prevState.results, ...newAttendances.results],
		}));
	}

	useEffect(() => {
		if (currentAuth && auth) {
			handleRefreshAttendance(AttendanceType.PrimaryOccurrence, currentURCId);
		}

		return () => {
			pusher.unsubscribe(`private-primary-attendance.refresh.${currentURCId}`);
		};
	}, [primaryAttendances, auth]);

	useEffect(() => {
		handleSearchPrimaryAttendances();
	}, [debouncedPrimarySearch]);

	// secondary attendances
	const secondaryAbortController = useRef(new AbortController());

	async function fetchSecondaryAttendances(increasePage = false) {
		try {
			secondaryAbortController.current.abort();
			secondaryAbortController.current = new AbortController();

			const page = secondaryAttendances.meta?.current_page || 1;

			const { data } = await api.get("/ticket/secondary-attendance", {
				params: {
					page: increasePage ? page + 1 : page,
					...(debouncedSecondarySearch && { search: debouncedSecondarySearch }),
					attendance_status_id: values.attendance_status_id || null,
				},
				signal: secondaryAbortController.current.signal,
			});

			return data as PaginatedAttendable;
		} catch (error) {
			console.log(error);
			return {} as PaginatedAttendable;
		}
	}

	async function handleSearchSecondaryAttendances() {
		try {
			setIsLoadingSecondary(true);

			const attendances = await fetchSecondaryAttendances();

			setSecondaryAttendances(attendances);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingSecondary(false);
		}
	}

	async function loadMoreSecondaryAttendances() {
		const newAttendances = await fetchSecondaryAttendances(true);

		setSecondaryAttendances((prevState) => ({
			...newAttendances,
			results: [...prevState.results, ...newAttendances.results],
		}));
	}

	useEffect(() => {
		if (currentAuth && auth) {
			handleRefreshAttendance(AttendanceType.SecondaryOccurrence, currentURCId);
		}

		return () => {
			pusher.unsubscribe(`private-secondary-attendance.refresh.${currentURCId}`);
		};
	}, [secondaryAttendances, auth]);

	useEffect(() => {
		handleSearchSecondaryAttendances();
	}, [debouncedSecondarySearch]);

	return (
		<>
			<AttendanceHeader
				handleShowFilterAttendanceModal={handleShowFilterAttendanceModal}
				isLoadingTotalAwaitingRegulation={isLoadingTotalAwaitingRegulation}
				totalAwaitingRegulation={totalAwaitingRegulation}
			/>

			<AttendanceCard
				type={AttendanceType.PrimaryOccurrence}
				isLoading={isLoadingPrimary}
				occurrences={primaryAttendances}
				next={loadMorePrimaryAttendances}
				onSearch={setPrimarySearch}
				scrollableTarget="primary-attendance-table"
				fetchAttendances={handleSearchPrimaryAttendances}
			/>

			<AttendanceCard
				type={AttendanceType.SecondaryOccurrence}
				isLoading={isLoadingSecondary}
				occurrences={secondaryAttendances}
				next={loadMoreSecondaryAttendances}
				onSearch={setSecondarySearch}
				scrollableTarget="secondary-attendance-table"
				fetchAttendances={handleSearchSecondaryAttendances}
			/>

			<AttendanceStatusFilterModal
				showFilterAttendanceModal={showFilterAttendanceModal}
				handleCLoseFilterAttendanceModal={handleCloseFilterAttendanceModal}
				formik={formik}
			/>
		</>
	);
}
