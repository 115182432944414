export enum TransferReason {
	ZeroVacancy = 1,
	VacancyReleasedByBedCentral = 2,
	RegulatedVacancy = 3,
}

export const TransferReasonsMessages = {
	[TransferReason.ZeroVacancy]: "Vaga zero",
	[TransferReason.VacancyReleasedByBedCentral]: "Vaga liberada pela central de leitos",
	[TransferReason.RegulatedVacancy]: "Vaga regulada",
};
