import { useState } from "react";

import { FaPlus } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";

import { SearchInputGroupFormControl } from "@/components/FormControl/SearchInputGroupFormControl";

export function Token() {
	const [accessToken, setAccessToken] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const { api } = useApi();
	const { Toast } = useSwal();

	const buttonLabel = (
		<>
			<FaPlus /> Novo token
		</>
	);

	async function handleAccessToken() {
		setIsLoading(true);

		try {
			const { data } = await api.post("/unauthenticated-access-tokens");
			setAccessToken(data.access_token);

			localStorage.setItem("totemAccessToken", data.access_token);

			Toast.fire({
				icon: "success",
				title: "Token gerado com sucesso!",
			});
		} catch (error) {
			console.error(error);

			Toast.fire({
				icon: "error",
				title: "Erro ao gerar token!",
			});
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Card>
			<Card.Header className="d-flex align-items-center">
				<h3 className="fw-normal m-0">Token</h3>
			</Card.Header>

			<Card.Body>
				<Col xs={6}>
					<SearchInputGroupFormControl
						label="Token de acesso"
						labelClassName="fw-bold"
						buttonLabel={buttonLabel}
						onClick={handleAccessToken}
						value={accessToken}
						isLoading={isLoading}
						disabled
					/>
				</Col>
			</Card.Body>
		</Card>
	);
}
