export function createSentence(words: string[]) {
	const validWords = words.filter(Boolean);
	const { length } = validWords;
	const allExceptTheLast = validWords.slice(0, length - 1).join(", ");

	if (length === 0) {
		return;
	}

	if (length === 1) {
		return validWords[0];
	}

	return `${allExceptTheLast} e ${validWords.at(-1)}`;
}
