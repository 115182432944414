import { LegacyRef, forwardRef } from "react";
import dayjs from "dayjs";

import { useAuth } from "@/modules/auth";
import { narrativeCertificateInitialValues } from "@/utils/initialValues/narrativeCertificate";
import { NarrativeCertificate } from "@/enums/narrativeCertificate";

import "./styles.scss";

type NarrativeCertificateProps = {
	values: typeof narrativeCertificateInitialValues;
};

export const NarrativeCertificateSheet = forwardRef(
	({ values }: NarrativeCertificateProps, ref: LegacyRef<HTMLTableElement>) => {
		const { currentAuth } = useAuth();

		return (
			<table ref={ref}>
				<tbody>
					<tr className="d-flex flex-column">
						<td className="d-flex justify-content-evenly align-items-center">
							<img
								className="h-90px"
								src={"/media/logos/federal-gov-logo.png"}
								alt="Federal government logo"
							/>
							<img
								className="h-50px"
								src={"/media/logos/government-logo.png"}
								alt="Ceará government logo"
							/>
							<img
								className="h-90px"
								src={"/media/logos/samu-logo-2.png"}
								alt="Samu logo"
							/>
						</td>

						<td>
							<h3 className="text-center mt-3">Certidão Narrativa</h3>
						</td>

						<td className="mt-5 fs-4">
							{values.default_or_free === NarrativeCertificate.Default ? (
								<>
									CERTIFICAMOS, em virtude da faculdade que nos é conferida por
									lei, e, tendo em vista o requerimento por escrito da parte
									interessada, que o SAMU 192 CEARÁ prestou atendimento a{" "}
									{values.patient_name}, portador(a) do RG{" "}
									{values.general_registration || "-"} inscrito(a) no CPF{" "}
									{values.identifier || "-"}, no dia{" "}
									{dayjs(values.date).format("DD/MM/YYYY")}, às{" "}
									{dayjs(values.date).format("HH:mm")}, no município de{" "}
									{values.city}, na {values.street}, vítima de{" "}
									{values.diagnostic_hypotheses || "-"}, sendo encaminhado(a) para
									o(a) {values.destination_unit || "-"}. E para constar, eu,{" "}
									{currentAuth?.name}, lavrei a presente Certidão, a qual vai
									datada e assinada.
								</>
							) : (
								<div
									dangerouslySetInnerHTML={{
										__html: values.free_narrative_certificate,
									}}
								/>
							)}
						</td>
					</tr>
				</tbody>

				<tfoot>
					<tr>
						<td>
							<div className="text-center mt-4rem">
								<span className="border-top-black p-3 fs-4">
									{values.city}, {dayjs().format("DD [de] MMMM [de] YYYY")}
								</span>
							</div>
						</td>
					</tr>
				</tfoot>
			</table>
		);
	}
);
