import { useEffect } from "react";
import { useFormik } from "formik";

import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { messageValidationSchema } from "@/utils/validation/messageValidationSchema";

import { RedAsterisk } from "@/components/RedAsterisk";
import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";

type Props = {
	showNewMessageModal: boolean;
	handleCloseNewMessageModal: () => void;
	message?: Message;
	fetchMessages: () => void;
};

export function NewMessageModal({
	showNewMessageModal,
	handleCloseNewMessageModal,
	message,
	fetchMessages,
}: Props) {
	const { Toast, toastRequestErrors } = useSwal();
	const { api } = useApi();

	const messageLength = message && Object.entries(message).length;

	const formik = useFormik({
		initialValues: {
			note: "",
		},
		validationSchema: messageValidationSchema,
		async onSubmit(values, { setSubmitting, resetForm }) {
			const method = messageLength ? "put" : "post";

			try {
				await api[method](
					`/coordination-notes${messageLength ? "/" + message.id : ""}`,
					values
				);

				handleCloseNewMessageModal();
				fetchMessages();

				resetForm();

				Toast.fire({
					icon: "success",
					title: `Recado ${messageLength ? "atualizado" : "cadastrado"} com sucesso!`,
				});
			} catch (error: any) {
				console.log(error);

				toastRequestErrors(error.response.data?.errors);

				setSubmitting(false);
			}
		},
	});

	const { errors } = formik;

	useEffect(() => {
		if (messageLength) {
			formik.setFieldValue("note", message.note);

			return;
		}

		formik.setFieldValue("note", "");

		setTimeout(() => {
			formik.validateForm();
		});
	}, [message]);
	return (
		<Modal
			id="note-show-modal"
			size="lg"
			show={showNewMessageModal}
			onHide={handleCloseNewMessageModal}
		>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">{messageLength ? "Edição de " : "Novo "} recado</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<Form.Label className="fw-bold fs-2">
						Recado <RedAsterisk />
					</Form.Label>

					<FormattedFormControl
						id="note"
						as="textarea"
						placeholder="Digite o recado"
						isRequired
						isInvalid={!!errors.note}
						validationMessage={errors.note}
						{...formik.getFieldProps("note")}
					/>

					<div className="d-flex justify-content-center gap-2 mt-8">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseNewMessageModal}
						>
							Fechar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{formik.isSubmitting ? (
								<Spinner animation="border" size="sm" className="mx-8" />
							) : messageLength ? (
								"Salvar"
							) : (
								"Cadastrar"
							)}
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
