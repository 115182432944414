import { WoundType, WoundTypeMessages } from "@/enums/WoundType";

export const woundTypesOptions: ReactSelectOption[] = [
	{ value: WoundType.Amputation, label: WoundTypeMessages[WoundType.Amputation] },
	{ value: WoundType.Contusion, label: WoundTypeMessages[WoundType.Contusion] },
	{ value: WoundType.Cutting, label: WoundTypeMessages[WoundType.Cutting] },
	{ value: WoundType.CuttingContusion, label: WoundTypeMessages[WoundType.CuttingContusion] },
	{ value: WoundType.Excoriation, label: WoundTypeMessages[WoundType.Excoriation] },
	{ value: WoundType.StabWounds, label: WoundTypeMessages[WoundType.StabWounds] },
	{ value: WoundType.FirearmWounds, label: WoundTypeMessages[WoundType.FirearmWounds] },
	{ value: WoundType.ExposedFracture, label: WoundTypeMessages[WoundType.ExposedFracture] },
	{ value: WoundType.ClosedFracture, label: WoundTypeMessages[WoundType.ClosedFracture] },
	{ value: WoundType.Bleeding, label: WoundTypeMessages[WoundType.Bleeding] },
	{ value: WoundType.Perforating, label: WoundTypeMessages[WoundType.Perforating] },
	{ value: WoundType.Burn, label: WoundTypeMessages[WoundType.Burn] },
	{ value: WoundType.Others, label: WoundTypeMessages[WoundType.Others] },
];
