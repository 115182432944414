export const unitTypeOptions: ReactSelectOption[] = [
	{ value: 1, label: "CENTRAL DE ABASTECIMENTO" },
	{ value: 2, label: "CENTRAL DE GESTAO EM SAUDE" },
	{ value: 4, label: "POLICLINICA" },
	{ value: 5, label: "HOSPITAL GERAL" },
	{ value: 7, label: "UNIDADE MISTA" },
	{ value: 20, label: "PRONTO SOCORRO GERAL" },
	{ value: 21, label: "PRONTO SOCORRO ESPECIALIZADO" },
	{ value: 22, label: "CONSULTORIO ISOLADO" },
	{ value: 32, label: "UNIDADE MÓVEL FLUVIAL" },
	{ value: 36, label: "CLINICA/CENTRO DE ESPECIALIDADE" },
	{ value: 39, label: "UNIDADE DE APOIO DIAGNOSE E TERAPIA (SADT ISOLADO)" },
	{ value: 40, label: "UNIDADE MÓVEL TERRESTRE" },
	{ value: 43, label: "FARMÁCIA" },
	{ value: 50, label: "UNIDADE DE VIGILÂNCIA EM SAÚDE" },
	{ value: 60, label: "COOPERATIVA OU EMPRESA DE CESSÃO DE TRABALHADORES NA SAÚDE" },
	{ value: 61, label: "CENTRO DE PARTO NORMAL - ISOLADO" },
	{ value: 62, label: "HOSPITAL/DIA - ISOLADO" },
	{ value: 64, label: "CENTRAL DE REGULAÇÃO DE SERVIÇOS DE SAÚDE" },
	{ value: 67, label: "LABORATÓRIO CENTRAL DE SAÚDE PUBLICA LACEN" },
	{ value: 68, label: "CENTRAL DE GESTÃO EM SAÚDE" },
	{ value: 69, label: "CENTRO DE ATENÇÃO HEMOTERÁPICA E OU HEMATOLÓGICA" },
	{ value: 70, label: "CENTRO DE ATENÇÃO PSICOSSOCIAL" },
	{ value: 71, label: "CENTRO DE APOIO À SAÚDE DA FAMÍLIA" },
	{ value: 72, label: "UNIDADE DE ATENÇÃO À SAÚDE INDÍGENA" },
	{ value: 73, label: "PRONTO ATENDIMENTO" },
	{ value: 74, label: "POLO ACADEMIA DA SAÚDE" },
	{ value: 75, label: "TELESSAÚDE" },
	{ value: 77, label: "SERVIÇO DE ATENÇÃO DOMICILIAR ISOLADO (HOME CARE)" },
	{ value: 78, label: "UNIDADE DE ATENÇÃO EM REGIME RESIDENCIAL" },
	{ value: 79, label: "OFICINA ORTOPÉDICA" },
	{ value: 80, label: "LABORATÓRIO DE SAÚDE PUBLICA" },
	{ value: 81, label: "CENTRAL DE REGULAÇÃO DO ACESSO" },
	{ value: 82, label: "CENTRAL DE NOTIFICAÇÃO, CAPTAÇÃO E DISTRIB DE ÓRGÃOS ESTADUAL" },
	{ value: 83, label: "POLO DE PREVENÇÃO DE DOENÇAS E AGRAVOS E PROMOÇÃO DA SAÚDE" },
	{ value: 84, label: "CENTRAL DE ABASTECIMENTO" },
	{ value: 85, label: "CENTRO DE IMUNIZAÇÃO" },
];
