import { useApi } from "@/hooks/useApi";

import { Additional, ReactSelect, ReactSelectProps } from "@/components/Selects/ReactSelect";

type PaginatedMedicines = GenericPagination<Medicine>;

export function MedicinesSelect({ ...rest }: ReactSelectProps) {
	const { api } = useApi();

	async function handleGetMedicines(page?: number, search?: string) {
		try {
			const { data } = await api.get("/medicines", {
				params: {
					page: page || 1,
					search,
				},
			});

			return data as PaginatedMedicines;
		} catch (error) {
			console.log(error);
			return {} as PaginatedMedicines;
		}
	}

	const loadAsyncMedicinesOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, _: Medicine, { page }: Additional) {
			const medicines = await handleGetMedicines(page, search);

			return {
				options: medicines.results?.map((medicine) => ({
					value: medicine.id,
					label: medicine.name,
				})),
				hasMore: medicines.meta?.current_page < medicines.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	return <ReactSelect {...loadAsyncMedicinesOptionsProps} {...rest} />;
}
