export enum WoundPlaceType {
	Abdomen = 1,
	Forearm = 2,
	MouthTongue = 3,
	Arm = 4,
	Head = 5,
	Thigh = 6,
	HandFingers = 7,
	FootFingers = 8,
	Back = 9,
	Face = 10,
	Knee = 11,
	Hand = 12,
	Eye = 13,
	Shoulder = 14,
	Foot = 15,
	PelvisBasin = 16,
	Perineum = 17,
	Leg = 18,
	Neck = 19,
	Chest = 20,
}

export const WoundPlaceTypeMessages = {
	[WoundPlaceType.Abdomen]: "Abdômen",
	[WoundPlaceType.Forearm]: "Antebraço",
	[WoundPlaceType.MouthTongue]: "Boca/Língua",
	[WoundPlaceType.Arm]: "Braço",
	[WoundPlaceType.Head]: "Cabeça",
	[WoundPlaceType.Thigh]: "Coxa",
	[WoundPlaceType.HandFingers]: "Dedos da mão",
	[WoundPlaceType.FootFingers]: "Dedos do pé",
	[WoundPlaceType.Back]: "Dorso",
	[WoundPlaceType.Face]: "Face",
	[WoundPlaceType.Knee]: "Joelho",
	[WoundPlaceType.Hand]: "Mão",
	[WoundPlaceType.Eye]: "Olho",
	[WoundPlaceType.Shoulder]: "Ombro",
	[WoundPlaceType.Foot]: "Pé",
	[WoundPlaceType.PelvisBasin]: "Pelve/Bacia",
	[WoundPlaceType.Perineum]: "Períneo",
	[WoundPlaceType.Leg]: "Perna",
	[WoundPlaceType.Neck]: "Pescoço",
	[WoundPlaceType.Chest]: "Tórax",
};
