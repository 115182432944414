export const dutyReportInitialValues = {
	record_at: "",
	duty_report_type_id: "",
	period_type_id: "",
	medical_regulators: [] as DutyReportProfessional[],
	radio_operators: [] as DutyReportProfessional[],
	tarms: [] as DutyReportProfessional[],
	internal_complications: "",
	external_complications: "",
	compliments: "",
	events: "",
};
