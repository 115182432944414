export const radioOperationInitialValues = {
	radio_operation_fleet: [] as RadioOperationFleet[],
	awaiting_fleet_confirmation: "",
	observation: "",

	// Datetimes
	vehicle_requested_at: "",
	vehicle_dispatched_at: "",
	arrived_to_site_at: "",
	left_from_site_at: "",
	vehicle_released_at: "",
	release_from_destination_at: "",
	arrived_to_destination_at: "",

	// dates
	vehicle_requested_at_date: "",
	vehicle_dispatched_at_date: "",
	arrived_to_site_at_date: "",
	left_from_site_at_date: "",
	vehicle_released_at_date: "",
	release_from_destination_at_date: "",
	arrived_to_destination_at_date: "",

	// Times
	vehicle_requested_at_time: "",
	vehicle_dispatched_at_time: "",
	arrived_to_site_at_time: "",
	left_from_site_at_time: "",
	vehicle_released_at_time: "",
	release_from_destination_at_time: "",
	arrived_to_destination_at_time: "",
};
