export enum AttendanceType {
	PrimaryOccurrence = 1,
	SecondaryOccurrence = 2,
	PrankCall = 3,
	Information = 4,
	Mistake = 5,
	CallDrop = 6,
	ContactWithMECSTeam = 7,
}

export const AttendanceTypeMessages = {
	[AttendanceType.PrimaryOccurrence]: "Ocorrência primária",
	[AttendanceType.SecondaryOccurrence]: "Ocorrência secundária",
	[AttendanceType.PrankCall]: "Trote",
	[AttendanceType.Information]: "Informação",
	[AttendanceType.Mistake]: "Engano",
	[AttendanceType.CallDrop]: "Queda da ligação",
	[AttendanceType.ContactWithMECSTeam]: "Contato com equipe SAMU",
};

export const SimplifiedAttendanceTypeMessages = {
	[AttendanceType.PrimaryOccurrence]: "Primária",
	[AttendanceType.SecondaryOccurrence]: "Secundária",
};
