import { FC } from "react";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/de";
import "@formatjs/intl-relativetimeformat/locale-data/es";
import "@formatjs/intl-relativetimeformat/locale-data/fr";
import "@formatjs/intl-relativetimeformat/locale-data/ja";
import "@formatjs/intl-relativetimeformat/locale-data/zh";

import brMessages from "./messages/pt-br.json";

import { WithChildren } from "../helpers";

const allMessages = {
	"pt-br": brMessages,
};

const I18nProvider: FC<WithChildren> = ({ children }) => {
	const messages = allMessages["pt-br"];

	return (
		<IntlProvider locale={"pt-br"} messages={messages}>
			{children}
		</IntlProvider>
	);
};

export { I18nProvider };
