import { useFormik } from "formik";

import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { forgotPasswordSchema } from "@/utils/validation/forgotPasswordSchema";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";

export function ForgotPassword() {
	const { Toast } = useSwal();
	const { api } = useApi();

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		validationSchema: forgotPasswordSchema,
		async onSubmit(values, { setSubmitting }) {
			try {
				const { data } = await api.post("/forgot-password", values);

				Toast.fire({
					icon: "success",
					title: data.message,
				});
			} catch (error: any) {
				const { message } = error.response.data;

				Toast.fire({
					icon: "error",
					title: message,
				});

				setSubmitting(false);
			}
		},
	});

	const { errors } = formik;

	return (
		<Form onSubmit={formik.handleSubmit}>
			<div className="text-center text-dark mt-3 mb-10">
				<h2>LOGIN</h2>
			</div>

			<div className="text-center mb-10">
				<h2>Esqueceu a senha?</h2>

				<span className="text-center text-muted fs-3">
					Insira seu endereço de e-mail para redefinir sua senha.
				</span>
			</div>

			<div className="fv-row mb-10">
				<FormattedFormControl
					type="email"
					id="forgot-password-email"
					className="form-control-lg form-control-solid"
					label="EMAIL"
					labelClassName="fw-bold m-0"
					isValid={!errors.email}
					isInvalid={!!errors.email}
					validationMessage={errors.email}
					{...formik.getFieldProps("email")}
				/>
			</div>

			<div className="d-flex justify-content-center gap-4 mb-5">
				<Link
					className="btn button-bg-light-color-gray-400 fs-3  fw-normal w-100 py-3"
					to="/login"
				>
					CANCELAR
				</Link>

				<button
					type="submit"
					className="btn text-white fs-3 w-100 bg-samu login-button py-3"
					disabled={formik.isSubmitting || !formik.isValid}
				>
					{!formik.isSubmitting && (
						<span className="indicator-label text-white">ENVIAR</span>
					)}

					{formik.isSubmitting && (
						<span className="d-block indicator-progress">
							Aguarde...
							<span className="spinner-border spinner-border-sm align-middle ms-2" />
						</span>
					)}
				</button>
			</div>
		</Form>
	);
}
