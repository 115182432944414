import { useEffect, useRef } from "react";

import Pusher from "pusher-js";

import { useAuth } from "@/modules/auth";

const baseURL = process.env.REACT_APP_API_URL || "/api";

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
	cluster: process.env.REACT_APP_PUSHER_CLUSTER as string,
	authEndpoint: `${baseURL}/broadcasting/auth`,
});

export function usePusher() {
	const { auth } = useAuth();
	const hasConnected = useRef(false);

	useEffect(() => {
		const accessToken = auth?.access_token;

		if (accessToken && !hasConnected.current) {
			(function (open) {
				XMLHttpRequest.prototype.open = function (method, url) {
					open.call(this, method, url, true);

					if (url.toString().includes("broadcasting/auth")) {
						this.setRequestHeader("Authorization", `Bearer ${accessToken}`);
					}
				};
			})(XMLHttpRequest.prototype.open);

			hasConnected.current = true;
		}
	}, [auth]);

	return pusher;
}
