import Yup from "@/utils/mixins/yup";

export const updatePasswordSchema = Yup.object().shape({
	current_password: Yup.string().when("has_password", {
		is: true,
		then: Yup.string()
			.required("Campo obrigatório")
			.min(8, "A senha deve ter no mínimo 8 caracteres"),
	}),
	new_password: Yup.string()
		.required("Campo obrigatório")
		.min(8, "A senha deve ter no mínimo 8 caracteres")
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/,
			"A senha deve conter pelo menos uma letra maiúscula, um número e um caractere especial"
		),
	new_password_confirmation: Yup.string()
		.required("Campo obrigatório")
		.min(8, "A senha deve ter no mínimo 8 caracteres")
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).*$/,
			"A senha deve conter pelo menos uma letra maiúscula, um número e um caractere especial"
		)
		.oneOf([Yup.ref("new_password"), null], "As senhas não coincidem"),
});
