import { useEffect, useState } from "react";
import { FormControlProps } from "react-bootstrap/FormControl";

import Form from "react-bootstrap/Form";

import { RedAsterisk } from "@/components/RedAsterisk";

import "./styles.scss";

type Props = FormControlProps & {
	label?: string;
	labelClassName?: string;
	maxLength: number;
	isRequired?: boolean;
	validationMessage?: string;
};

export function FormControlWithCharactersCount({
	id,
	label,
	labelClassName,
	maxLength,
	isRequired = false,
	validationMessage,
	value,
	...rest
}: Props) {
	const [remainingCharacters, setRemainingCharacters] = useState(maxLength);

	useEffect(() => {
		setRemainingCharacters(maxLength - (value as string)?.length);
	}, [value]);

	return (
		<Form.Group controlId={id}>
			{label && (
				<Form.Label className={labelClassName}>
					{isRequired ? (
						<>
							{label} <RedAsterisk />
						</>
					) : (
						label
					)}
				</Form.Label>
			)}

			<Form.Control
				className="form-control-with-characters-count"
				maxLength={maxLength}
				value={value}
				{...rest}
			/>
			{validationMessage && <div className="text-danger">{validationMessage}</div>}
			<div className="w-100">Caracteres restantes: {remainingCharacters}</div>
		</Form.Group>
	);
}
