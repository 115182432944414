import Modal from "react-bootstrap/Modal";

import { PatrimonyForm } from "@/components/FleetControl/Patrimonies/PatrimonyForm";

type Props = {
	showPatrimonyModal: boolean;
	handleClosePatrimonyModal: () => void;
	patrimony: Patrimony;
	fetchPatrimonies: (pageToUse?: number) => void;
};

export function PatrimonyModal({
	showPatrimonyModal,
	handleClosePatrimonyModal,
	patrimony,
	fetchPatrimonies,
}: Props) {
	const patrimonyLength = patrimony && Object.entries(patrimony).length;

	return (
		<Modal show={showPatrimonyModal} onHide={handleClosePatrimonyModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">{`${patrimonyLength ? "Editar" : "Cadastrar"} equipamento`}</h2>
			</Modal.Header>

			<Modal.Body>
				<PatrimonyForm
					patrimony={patrimony}
					fetchPatrimonies={fetchPatrimonies}
					handleClosePatrimonyModal={handleClosePatrimonyModal}
					patrimonyLength={patrimonyLength}
				/>
			</Modal.Body>
		</Modal>
	);
}
