import { useApi } from "@/hooks/useApi";

import { ReactSelect, ReactSelectProps, Additional } from "../ReactSelect";

type BasesSelectProps = ReactSelectProps & {
	canSelectNoBase?: boolean;
	isRegionalGroupForm?: boolean;
};

type BaseOptions = ReactSelectOption & {
	isDisabled: boolean;
};

export function BasesSelect({ canSelectNoBase, isRegionalGroupForm, ...rest }: BasesSelectProps) {
	const { api } = useApi();

	async function fetchBases(page?: number, search?: string) {
		try {
			const { data } = await api.get("/bases", {
				params: {
					page: page || 1,
					search,
					is_active: 1,
				},
			});

			return data as PaginatedBaseOrUnit;
		} catch (error) {
			console.log(error);
			return {} as PaginatedBaseOrUnit;
		}
	}

	const loadAsyncBasesOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, __: BaseOrUnit, { page }: Additional) {
			const bases = await fetchBases(page, search);

			const options = bases?.results.map((base) => {
				const hasRegionalGroup = isRegionalGroupForm && !!base.regional_group_id;
				const tooltip =
					hasRegionalGroup &&
					`Base vinculada ao grupo regional ${base.regional_group.name}`;

				return {
					value: base.id,
					label: base.name,
					extraData: base,
					isDisabled: hasRegionalGroup,
					tooltip,
				};
			});

			const noBaseOption = { label: "Sem base", value: "0" };
			const formattedOptions = canSelectNoBase ? [noBaseOption, ...options] : options;

			return {
				options: formattedOptions,
				isOptionDisabled: (option: BaseOptions) => option.isDisabled,
				hasMore: bases.meta?.current_page < bases.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	return <ReactSelect {...loadAsyncBasesOptionsProps} {...rest} />;
}
