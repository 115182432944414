import { useEffect } from "react";
import { useFormik } from "formik";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { UserStatus } from "@/enums/UserStatus";
import { changeUserStatusSchema } from "@/utils/validation/changeUserStatusSchema";
import { changeUserStatusInitialValues } from "@/utils/initialValues/changeUserStatus";

import { FormControlWithCharactersCount } from "@/components/FormControl/FormControlWithCharactersCount";

type Props = {
	showChangeUserStatusModal: boolean;
	handleCloseChangeUserStatusModal: () => void;
	currentUser: User;
	fetchPaginatedUsers: () => void;
};

export function ChangeUserStatusModal({
	showChangeUserStatusModal,
	handleCloseChangeUserStatusModal,
	currentUser,
	fetchPaginatedUsers,
}: Props) {
	const { toastRequestErrors } = useSwal();
	const { api } = useApi();

	const isActive = currentUser.latest_user_status_history?.status_id === UserStatus.Active;

	const formik = useFormik({
		initialValues: changeUserStatusInitialValues,
		validationSchema: changeUserStatusSchema,
		async onSubmit(values, { setSubmitting, resetForm }) {
			try {
				await api.post(`users/${currentUser?.id}/status`, values);

				fetchPaginatedUsers();
				handleCloseChangeUserStatusModal();
				resetForm();
			} catch (error: any) {
				console.error(error);

				toastRequestErrors(error.response.data.errors);
				setSubmitting(false);
			}
		},
	});

	const { validateForm, errors } = formik;

	useEffect(() => {
		formik.setFieldValue("status_id", isActive ? UserStatus.Inactive : UserStatus.Active);

		setTimeout(() => {
			validateForm();
		});
	}, [showChangeUserStatusModal]);

	return (
		<Modal show={showChangeUserStatusModal} onHide={handleCloseChangeUserStatusModal} centered>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">{isActive ? "Desativar" : "Ativar"} usuário</h2>
			</Modal.Header>

			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<FormControlWithCharactersCount
						label="Motivo"
						labelClassName="fw-bold"
						as="textarea"
						maxLength={1000}
						isRequired={isActive}
						isInvalid={!!errors.reason}
						validationMessage={errors.reason}
						{...formik.getFieldProps("reason")}
					/>

					<div className="d-flex justify-content-center gap-2 mt-8">
						<button
							type="button"
							className="btn button-bg-light-color-gray-400"
							onClick={handleCloseChangeUserStatusModal}
						>
							Cancelar
						</button>

						<button
							type="submit"
							className="btn button-bg-samu-color-white"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							Salvar
						</button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
