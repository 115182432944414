import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { ImArrowLeft2 } from "react-icons/im";
import { IoIosPaper } from "react-icons/io";
import { FaFilePdf } from "react-icons/fa6";

import { Role } from "@/enums/Role";
import { useApi } from "@/hooks/useApi";
import { useCanAccess } from "@/hooks/useCanAccess";

import { OccurrenceData } from "@/components/OccurrenceData";
import { HistoryRegister } from "@/components/HistoryRegister";
import { AttendanceData } from "@/components/AttendanceSummary/AttendanceData";
import { AttendanceSummarySheet } from "@/components/Prints/AttendanceSummarySheet";
import { SceneRegisterData } from "@/components/AttendanceSummary/SceneRegisterData";
import { RadioOperationView } from "@/components/AttendanceSummary/RadioOperationView";
import { NarrativeCertificateModal } from "@/components/Modals/NarrativeCertificateModal";

export function AttendanceSummary() {
	const [showNarrativeCertificateModal, setShowNarrativeCertificateModal] = useState(false);
	const [defaultActiveKey, setDefaultActiveKey] = useState("attendance-data-tab");
	const [attendance, setAttendance] = useState<Attendance>({} as Attendance);
	const [childrenLinks, setChildrenLinks] = useState<Attendance[]>();
	const [isPrintLoading, setIsPrintLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const componentRef = useRef<HTMLTableElement>(null);
	const { attendanceId } = useParams();
	const { can } = useCanAccess();
	const { api } = useApi();

	const handleShowNarrativeCertificateModal = () => setShowNarrativeCertificateModal(true);
	const handleCloseNarrativeCertificateModal = () => setShowNarrativeCertificateModal(false);

	const { admin, coordinator } = Role;

	const sceneRegisters = attendance?.scene_recordings;

	const print = useReactToPrint({
		content: () => componentRef.current,
		onAfterPrint: () => setIsPrintLoading(false),
	});

	async function handleSearchAttendanceData() {
		try {
			const { data } = await api.get(`/attendance/${attendanceId}`, {
				params: {
					load_scene_recordings: 1,
					load_latest_vehicle_status_history: 1,
					full_detail: 1,
					load_father_link: 1,
				},
			});

			setAttendance(data);
			handleSearchAttendanceLinks();
		} catch (error) {
			console.log(error);
		}
	}

	function handleSelectTab(tab: string) {
		setDefaultActiveKey(tab);

		const element = document.getElementById(tab);

		if (element) {
			const rect = element.getBoundingClientRect();
			const HARDER_SCROLL = 100;
			const offset = rect.top - HARDER_SCROLL;

			window.scrollBy({
				top: offset,
				behavior: "smooth",
			});
		}
	}

	async function handleSearchAttendanceLinks() {
		try {
			const { data } = await api.get(`/attendance/link/${attendanceId}`);

			setChildrenLinks(data);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	function handlePrint() {
		setIsPrintLoading(true);
		print();
	}

	useEffect(() => {
		if (attendanceId) {
			handleSearchAttendanceData();
		}
	}, [attendanceId]);

	return (
		<>
			<div className="d-flex gap-4 mb-6">
				<button
					className="d-flex align-items-center btn button-bg-white-color-samu gap-2"
					onClick={handlePrint}
					disabled={isPrintLoading}
				>
					{isPrintLoading ? (
						<>
							<Spinner
								animation="border"
								style={{ height: "1.5rem", width: "1.5rem" }}
							/>
							Por favor, aguarde...
						</>
					) : (
						<>
							<FaFilePdf className="fs-2" />
							Ficha da ocorrência
						</>
					)}
				</button>

				{can([admin, coordinator]) && (
					<button
						className="btn button-bg-orange-color-white d-flex gap-4 align-items-center"
						onClick={handleShowNarrativeCertificateModal}
					>
						<IoIosPaper className="fs-3" />
						Certidão narrativa
					</button>
				)}

				<div className="d-none">
					<AttendanceSummarySheet ref={componentRef} attendance={attendance} />
				</div>
			</div>

			<Card>
				<Card.Header>
					<div className="d-flex justify-content-between align-items-center w-100 default-form-card-header">
						<span className="w-100px" />

						<h2 className="m-0">Resumo da ocorrência</h2>

						<button
							className="d-flex align-items-center btn button-bg-light-color-gray-400 gap-2 w-100px"
							onClick={() => window.close()}
						>
							<ImArrowLeft2 />
							Voltar
						</button>
					</div>
				</Card.Header>

				<Card.Body>
					<Tabs
						activeKey={defaultActiveKey}
						onSelect={(tab) => handleSelectTab(String(tab))}
						className="nav-line-tabs nav-line-tabs-2x fs-5 fw-bolder flex-nowrap w-fit-content mb-6"
					>
						<Tab
							eventKey="attendance-data-tab"
							title={<span className="text-center px-6">Dados da ocorrência</span>}
						/>

						{(childrenLinks ?? []).length > 0 && (
							<Tab
								eventKey="attendance-children-links"
								title={
									<span className="text-center px-6">Ocorrência vinculada</span>
								}
							/>
						)}

						<Tab
							eventKey="medical-regulation-tab"
							title={<span className="text-center px-6">Regulação médica</span>}
						/>

						<Tab
							eventKey="scene-register-tab"
							title={<span className="text-center px-6">Registro de cena</span>}
						/>

						<Tab
							eventKey="radio-operation-tab"
							title={<span className="text-center px-6">Rádio operador</span>}
						/>
					</Tabs>

					{isLoading ? (
						<div className="d-flex flex-center vh-80">
							<Spinner
								variant="samu"
								animation="border"
								style={{ width: "4rem", height: "4rem" }}
							/>
						</div>
					) : (
						<>
							<div id="attendance-data-tab">
								<div className="bg-samu text-center rounded py-4 mb-5">
									<h1 className="text-white mb-0">DADOS DA OCORRÊNCIA</h1>
								</div>

								<AttendanceData attendance={attendance} />
							</div>

							<div id="children-links">
								{childrenLinks?.map((childrenLink, index) => (
									<div key={`children-link-${index}`}>
										<div className="bg-samu text-center rounded py-4 mt-10 mb-5">
											<h1 className="text-white mb-0">
												OCORRÊNCIA VINCULADA - {index + 1}
											</h1>
										</div>

										<OccurrenceData
											attendanceType={childrenLink?.ticket?.ticket_type_id}
											attendance={childrenLink}
										/>
									</div>
								))}
							</div>

							<div id="medical-regulation-tab">
								<div className="bg-samu text-center rounded py-4 mt-10 mb-5">
									<h1 className="text-white mb-0">
										REGULAÇÃO MÉDICA E OBSERVAÇÕES
									</h1>
								</div>

								<HistoryRegister attendanceId={attendanceId} />
							</div>

							<div id="scene-register-tab">
								{sceneRegisters?.map((sceneRegister, index) => (
									<div key={`scene-register-${index}`}>
										<div className="bg-samu text-center rounded py-4 mt-10 mb-5">
											<h1 className="text-white mb-0">
												REGISTRO DE CENA - {index + 1}
											</h1>
										</div>

										<SceneRegisterData sceneRegister={sceneRegister} />
									</div>
								))}

								{!sceneRegisters?.length && (
									<div>
										<div className="bg-samu text-center rounded py-4 mt-10 mb-5">
											<h1 className="text-white mb-0">REGISTRO DE CENA</h1>
										</div>

										<div className="text-center">
											<h2 className="m-0 pt-5">
												Nenhum resultado encontrado
											</h2>
										</div>
									</div>
								)}
							</div>

							<div id="radio-operation-tab">
								<div className="bg-samu text-center rounded py-4 mt-10 mb-5">
									<h1 className="text-white mb-0">RÁDIO OPERADOR</h1>
								</div>

								<RadioOperationView radioOperation={attendance.radio_operation} />
							</div>
						</>
					)}
				</Card.Body>
			</Card>

			<NarrativeCertificateModal
				showNarrativeCertificateModal={showNarrativeCertificateModal}
				handleCloseNarrativeCertificateModal={handleCloseNarrativeCertificateModal}
			/>
		</>
	);
}
