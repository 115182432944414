import Modal from "react-bootstrap/Modal";

import { RegistrationForm } from "@/components/BasesAndUnits/RegistrationForm";

type Props = {
	baseOrUnit: BaseOrUnit;
	showEditModal: boolean;
	handleCloseEditModal: () => void;
	dataType: "base" | "unidade";
	fetchPaginatedBasesOrUnits: (pageToUse?: number) => void;
};

export function EditModal({
	baseOrUnit,
	showEditModal,
	handleCloseEditModal,
	dataType,
	fetchPaginatedBasesOrUnits,
}: Props) {
	return (
		<Modal size="xl" show={showEditModal} onHide={handleCloseEditModal}>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Edição da {dataType}</h2>
			</Modal.Header>

			<Modal.Body>
				<RegistrationForm
					baseOrUnit={baseOrUnit}
					handleCloseEditModal={handleCloseEditModal}
					fetchPaginatedBasesOrUnits={fetchPaginatedBasesOrUnits}
				/>
			</Modal.Body>
		</Modal>
	);
}
