import { useApi } from "@/hooks/useApi";

import { Additional, ReactSelect, ReactSelectProps } from "@/components/Selects/ReactSelect";

type PaginatedIcds = GenericPagination<Icd>;

export function IcdsSelect({ ...rest }: ReactSelectProps) {
	const { api } = useApi();

	async function handleGetIcds(page?: number, search?: string) {
		try {
			const { data } = await api.get("/icds", {
				params: {
					page: page || 1,
					search,
				},
			});

			return data as PaginatedIcds;
		} catch (error) {
			console.log(error);
			return {} as PaginatedIcds;
		}
	}

	const loadAsyncIcdsOptionsProps = {
		additional: {
			page: 1,
		},
		isAsyncPaginate: true,
		async loadOptions(search: string, _: Icd, { page }: Additional) {
			const icds = await handleGetIcds(page, search);

			return {
				options: icds.results?.map((icd) => ({
					value: icd.code,
					label: `${icd.code} - ${icd.description}`,
				})),
				hasMore: icds.meta?.current_page < icds.meta?.last_page || false,
				additional: {
					page: page + 1,
				},
			};
		},
	};

	return <ReactSelect {...loadAsyncIcdsOptionsProps} {...rest} />;
}
