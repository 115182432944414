import Yup from "@/utils/mixins/yup";

export const basesAndUnitsSchema = Yup.object().shape({
	name: Yup.string().required("Campo obrigatório"),
	national_health_registration: Yup.string().required("Campo obrigatório"),
	unit_type_id: Yup.number().required("Campo obrigatório"),
	city_id: Yup.number().required("Campo obrigatório"),
	federal_unit_id: Yup.number().required("Campo obrigatório"),
	urc_id: Yup.string().when("dataType", {
		is: "base",
		then: Yup.string().required("Campo obrigatório"),
	}),
	vehicle_type_id: Yup.number().when("dataType", {
		is: "base",
		then: Yup.number().required("Campo obrigatório"),
	}),
});
