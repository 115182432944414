import { DutyReportType } from "@/enums/DutyReportType";
import Yup from "@/utils/mixins/yup";

export const dutyReportSchema = Yup.object().shape({
	record_at: Yup.date()
		.max(new Date(), "A data não pode ser maior que a atual")
		.required("Campo obrigatório"),
	duty_report_type_id: Yup.string().required("Campo obrigatório"),
	period_type_id: Yup.string().required("Campo obrigatório"),
	medical_regulators: Yup.array().min(1, "Campo obrigatório"),
	radio_operators: Yup.array().min(1, "Campo obrigatório"),
	tarms: Yup.array().when("duty_report_type_id", {
		is: String(DutyReportType.fleetManager),
		then: Yup.array().min(1, "Campo obrigatório"),
	}),
	internal_complications: Yup.lazy(() =>
		Yup.string().when(["external_complications", "compliments", "events"], {
			is: (external_complications: string, compliments: string, events: string) =>
				!external_complications && !compliments && !events,
			then: Yup.string().required("Campo obrigatório"),
			otherwise: Yup.string().nullable(),
		})
	),
	external_complications: Yup.lazy(() =>
		Yup.string().when(["internal_complications", "compliments", "events"], {
			is: (internal_complications: string, compliments: string, events: string) =>
				!internal_complications && !compliments && !events,
			then: Yup.string().required("Campo obrigatório"),
			otherwise: Yup.string().nullable(),
		})
	),
	compliments: Yup.lazy(() =>
		Yup.string().when(["internal_complications", "external_complications", "events"], {
			is: (internal_complications: string, external_complications: string, events: string) =>
				!internal_complications && !external_complications && !events,
			then: Yup.string().required("Campo obrigatório"),
			otherwise: Yup.string().nullable(),
		})
	),
	events: Yup.lazy(() =>
		Yup.string().when(["internal_complications", "external_complications", "compliments"], {
			is: (
				internal_complications: string,
				external_complications: string,
				compliments: string
			) => !internal_complications && !external_complications && !compliments,
			then: Yup.string().required("Campo obrigatório"),
			otherwise: Yup.string().nullable(),
		})
	),
});
