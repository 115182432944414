import { useEffect } from "react";
import { useFormik } from "formik";

import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useApi } from "@/hooks/useApi";
import { useSwal } from "@/hooks/useSwal";
import { VehicleTypeMessages } from "@/enums/VehicleType";
import { patrimonyTypeOptions } from "@/utils/options/patrimonyType";
import { patrimonySchema } from "@/utils/validation/patrimonySchema";
import { patrimonyInitialValues } from "@/utils/initialValues/patrimony";

import { FormattedFormControl } from "@/components/FormControl/FormattedFormControl";
import { FormGroupRadio } from "@/components/Radios/FormGroupRadio";
import { VehicleSelect } from "@/components/Selects/VehicleSelect";
import { ReactSelect } from "@/components/Selects/ReactSelect";
import { FormRadio } from "@/components/Radios/FormRadio";
import { RedAsterisk } from "@/components/RedAsterisk";

type Props = {
	patrimony: Patrimony;
	fetchPatrimonies: (pageToUse?: number) => void;
	handleClosePatrimonyModal: () => void;
	patrimonyLength: number;
};

type EnumVehicleTypeMessages = keyof typeof VehicleTypeMessages;

export function PatrimonyForm({
	patrimony,
	fetchPatrimonies,
	handleClosePatrimonyModal,
	patrimonyLength,
}: Props) {
	const { api } = useApi();
	const { Toast, toastRequestErrors } = useSwal();

	const vehicleTypeId = Number(patrimony.vehicle?.vehicle_type?.id || 0);
	const vehicleTypeMessage = VehicleTypeMessages[vehicleTypeId as EnumVehicleTypeMessages];
	const vehicleDescription = `${vehicleTypeMessage} ${patrimony.vehicle?.description}`;

	const initialValues = patrimonyLength
		? { ...patrimony, vehicle_label: vehicleDescription }
		: patrimonyInitialValues;

	const formik = useFormik({
		initialValues,
		validationSchema: patrimonySchema,
		async onSubmit(values, { resetForm }) {
			const method = patrimonyLength ? "put" : "post";

			try {
				const route = patrimonyLength ? `/patrimonies/${patrimony.id}` : "/patrimonies";

				await api[method](route, values);

				handleClosePatrimonyModal();
				fetchPatrimonies();
				resetForm();

				Toast.fire({
					icon: "success",
					title: `Equipamento ${
						patrimonyLength ? "atualizado" : "cadastrado"
					} com sucesso!`,
				});
			} catch (error: any) {
				if (error.isAxiosError && error.response.status === 422) {
					toastRequestErrors(error.response.data.errors);
				} else {
					console.error(error);
					Toast.fire({
						icon: "error",
						title: "Ocorreu um erro desconhecido ao cadastrar",
					});
				}
			}
		},
	});

	const { values, errors } = formik;

	const selectedPatrimonyTypeOption = patrimonyTypeOptions.find(
		(option) => option.value === patrimony.patrimony_type_id
	);

	const selectedVehicle = values.vehicle_id
		? {
				id: values.vehicle_id,
				label: values.vehicle_label,
		  }
		: {
				label: "Sem viatura",
				value: "",
		  };

	function handleVehicleChange(option: ReactSelectOption) {
		formik.setFieldValue("vehicle_id", option.value);
		formik.setFieldValue("vehicle_label", option.label);
	}

	useEffect(() => {
		formik.validateForm();
	}, [errors]);

	return (
		<Form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-4">
			<div>
				<Form.Label className="fw-bold" htmlFor="patrimony-type-id">
					Tipo <RedAsterisk />
				</Form.Label>

				<ReactSelect
					inputId="patrimony-type-id"
					options={patrimonyTypeOptions}
					isInvalid={!!errors.patrimony_type_id}
					value={selectedPatrimonyTypeOption}
					placeholder="Selecione um tipo"
					onChange={(option) =>
						formik.setFieldValue(
							"patrimony_type_id",
							(option as ReactSelectOption).value
						)
					}
				/>

				{errors.patrimony_type_id && (
					<span className="text-danger">{errors.patrimony_type_id}</span>
				)}
			</div>

			<Row>
				<Col>
					<FormattedFormControl
						id="identifier"
						label="Identificador"
						labelClassName="fw-bold"
						isInvalid={!!errors.identifier}
						validationMessage={errors.identifier}
						{...formik.getFieldProps("identifier")}
						isRequired
					/>
				</Col>

				<Col>
					<FormGroupRadio
						label="Status"
						labelClassName="fw-bold align-items-middle"
						requestedValue={values.patrimony_status_id}
						{...formik.getFieldProps("patrimony_status_id")}
						isInvalid={!!errors.patrimony_status_id}
						isRequired
						inline
					>
						<FormRadio id="available" label="Disponível" value="1" />
						<FormRadio id="unavailable" label="Indisponível" value="0" />
					</FormGroupRadio>

					{errors.patrimony_status_id && (
						<span className="text-danger">{errors.patrimony_status_id}</span>
					)}
				</Col>
			</Row>

			<div>
				<Form.Label className="fw-bold" htmlFor="vehicle-id">
					Selecionar VTR
				</Form.Label>

				<VehicleSelect
					inputId="vehicle-id"
					name="vehicle_id"
					isInvalid={!!errors.vehicle_id}
					value={selectedVehicle}
					onChange={(option) => handleVehicleChange(option as ReactSelectOption)}
				/>

				{errors.vehicle_id && <span className="text-danger">{errors.vehicle_id}</span>}
			</div>

			<div className="d-flex justify-content-center gap-2 mt-4">
				<button
					type="button"
					className="btn button-bg-light-color-gray-400"
					onClick={handleClosePatrimonyModal}
				>
					Fechar
				</button>

				<button
					type="submit"
					className="btn button-bg-samu-color-white"
					disabled={formik.isSubmitting || !formik.isValid}
				>
					{formik.isSubmitting ? (
						<Spinner animation="border" size="sm" className="mx-8" />
					) : patrimonyLength ? (
						"Salvar"
					) : (
						"Cadastrar"
					)}
				</button>
			</div>
		</Form>
	);
}
