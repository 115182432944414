import { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";

import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import Modal from "react-bootstrap/Modal";

import { useApi } from "@/hooks/useApi";

import { Separator } from "@/components/Separator";

type Props = {
	showViewMessage?: boolean;
	handleCloseViewMessageModal: () => void;
};

type LoaderProps = {
	isTheFirstLoader?: boolean;
};

export function ViewMessageModal({ showViewMessage, handleCloseViewMessageModal }: Props) {
	const [messages, setMessages] = useState<PaginatedMessage>({} as PaginatedMessage);
	const [skeleton, setSkeleton] = useState(true);
	const { api } = useApi();

	const { results } = messages;

	const Loader = ({ isTheFirstLoader }: LoaderProps) => (
		<>
			{Array.from({ length: 5 }).map((_, index) => (
				<Fragment key={`view-message-on-modal-loader-${index}`}>
					{(isTheFirstLoader ? index !== 0 : true) && <Separator />}
					<div className="px-7">
						<Skeleton />
					</div>
				</Fragment>
			))}
		</>
	);

	async function fetchMessages() {
		setSkeleton(true);

		try {
			const { data } = await api.get(messages.links?.next || "/coordination-notes");

			const oldData = messages?.results || [];
			const newData = oldData.concat(data?.results);

			setMessages({ ...data, results: newData });
		} catch (error) {
			console.log(error);
		} finally {
			setSkeleton(false);
		}
	}

	useEffect(() => {
		if (showViewMessage) {
			fetchMessages();
		}
	}, [showViewMessage]);

	return (
		<Modal
			size="xl"
			show={showViewMessage}
			onHide={handleCloseViewMessageModal}
			onExited={() => setMessages({} as PaginatedMessage)}
		>
			<Modal.Header className="d-flex justify-content-center">
				<h2 className="m-0">Recados da coordenação</h2>
			</Modal.Header>

			<Modal.Body className="overflow-y-scroll max-h-65vh px-0" id="paginate-message">
				<InfiniteScroll
					dataLength={results?.length || 1}
					next={fetchMessages}
					hasMore={results?.length !== messages.meta?.total}
					scrollableTarget="paginate-message"
					loader={<Loader />}
				>
					{results?.map((message, index) => (
						<div key={`view-message-${index}`} className="px-7">
							<div>
								Última atualização:{" "}
								{dayjs(message.updated_at).format("DD/MM/YYYY [às] HH:mm")}
							</div>
							<div dangerouslySetInnerHTML={{ __html: message.note }} />
							{results?.length - 1 !== index && <Separator />}
						</div>
					))}

					{skeleton && !results?.length && <Loader isTheFirstLoader />}

					{!skeleton && !results?.length && (
						<div className="text-center">
							<h2 className="mt-12 mb-0">Não há recados no momento</h2>
						</div>
					)}
				</InfiniteScroll>
			</Modal.Body>

			<Modal.Footer className="d-flex justify-content-center border-0 pt-8">
				<button
					className="btn button-bg-light-color-gray-400"
					onClick={handleCloseViewMessageModal}
				>
					Fechar
				</button>
			</Modal.Footer>
		</Modal>
	);
}
